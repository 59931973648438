import { debounce } from "./utils"

const infiniteScrollingItem = () => {
  // add lazy load for image
  $("img.lazyload").lazyload();
  if ($('.infinite-scrolling').length > 0) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          loadMore()
        }
      })
    }, {
      rootMargin: '200px'
    })

    const paginations = document.querySelectorAll('.infinite-scrolling')

    paginations.forEach(element => {
      observer.observe(element)
    })
  }
}

const loadMore = () => {
  var more_collections_url = $('.pagination.--collections > .next_page').attr('href');
  var more_users_url = $('.pagination.--users > .next_page').attr('href');
  var more_guarantees_url = $('.pagination.--guarantees > .next_page').attr('href');
  var more_nft_collections_url = $('.pagination.--nft-collections > .next_page').attr('href');
  var more_recyclers_url = $('.pagination.--recyclers > .next_page').attr('href');
  var more_users_explore_url = $('.pagination.--users-explore > .next_page').attr('href');
  var more_campaigns_url = $('.pagination.--campaigns > .next_page').attr('href');
  var more_sustainable_actions_url = $('.pagination.--sustainable_action > .next_page').attr('href');

  var tab = $('.main__filter-element.active, .main__filter-element--explore.active').data('filter');
  var user_tab = $('#current_user_tab').val();

  if (user_tab === "dashboard") {
    if (more_sustainable_actions_url) fetchMore(more_sustainable_actions_url);
  }

  if (tab === "collections") {
    if (more_collections_url) fetchMore(more_collections_url)
  } else if (tab === "users") {
    if (more_users_url) fetchMore(more_users_url)
  } else if (tab === "recyclers") {
    if (more_recyclers_url) fetchMore(more_recyclers_url)
  } else if (tab === "nft_collections") {
    if (more_nft_collections_url) fetchMore(more_nft_collections_url)
  } else if (tab ==="users_explore") {
    if (more_users_explore_url) fetchMore(more_users_explore_url)
  } else if (tab === "campaigns") {
    if (more_campaigns_url) fetchMore(more_campaigns_url)
  } else {
    if (more_guarantees_url) fetchMore(more_guarantees_url)
  }
}

const fetchMore = debounce(url => {
  ajaxFetch(url)
}, 200)

const ajaxFetch = (url) => {
  $('.preview-loading').removeClass('hide');
  $.ajax({
    url,
    method: 'get',
    dataType: 'script',
    success: function (data) {
      if (data.errors) {
        toastr.error(data['error'])
      } else {
        $('.preview-loading').addClass('hide');
      }
    }
  })
}

export { infiniteScrollingItem }
