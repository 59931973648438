export const triggerEventRecoveryProjectImpactGallery = () => {
  const switchTab = (tab) => {
    $('.btn-default.active').removeClass('active')
    $(`.btn-default--${tab}`).addClass('active')

    $('.category-tab').attr('data-filter', tab)
  }

  const handleSortBy = (tab, elementChoosed, reset) => {
    $('.filter-collection li.active').removeClass('active')
    elementChoosed.addClass('active')
    $('.filter-collection').attr('data-filter', elementChoosed.attr('data-filter'))

    if(tab === 'prg') {
      $('.filter-collection .--asc').html('Least KG')
      $('.filter-collection .--desc').html('Most KG')
    } else if (tab === 'nft') {
      $('.filter-collection .--asc').html('Lowest price')
      $('.filter-collection .--desc').html('Highest price')
    }

    let newSortByContext = reset == true ? 'Sort by' : elementChoosed.html()
    $('.filter-collection .search-filter-label').html(newSortByContext)
  }

  const fetchCollections = (sale_type = '', only_nfts = false) => {
    const address = $('#user-address').val();
    let sort_by = $('.filter-collection').attr('data-filter') || 'recently-add'
    let tab_collection = $('.category-tab').attr('data-filter') || 'nft'
    $("div.loading-gif.displayInMiddle").show();

    $.ajax({
      url: `/users/${address}/show_collections`,
      type: 'get',
      dataType: 'script',
      data: {
        tab: 'plastic_credit',
        tab_collection: tab_collection,
        sale_type: sale_type,
        only_nfts: only_nfts,
        sort_by: sort_by
      },
      success: function (data) {
        if (data.errors) {
          toastr.error(data['error'])
        }

        $("div.loading-gif.displayInMiddle").hide();
        $('img.lazyload').lazyload();
      }
    });
  }

  // PRG or NFT Button
  $('.category-tab .btn-default').on('click', function () {
    let tab = $(this).hasClass('btn-default--nft') ? 'nft' : 'prg'
    let only_nfts = tab == 'prg' ? false : true
    let sale_type = ''

    switchTab(tab)
    handleSortBy(tab, $('.filter-collection .--recently-add'), true)
    fetchCollections(sale_type, only_nfts)
  })

  // Sort Button
  $('.filter-collection li').on('click', function () {
    let tab = $('.category-tab').attr('data-filter')
    let only_nfts = tab == 'prg' ? false : true
    let sale_type = ''

    handleSortBy(tab, $(this), false)
    fetchCollections(sale_type, only_nfts)
  })
}
