import { infiniteScrollingItem } from "./infinity_scroll_item"

$(document).ready(function () {
  $(document).on("keydown", ".change-price-form", function(event) {
    if (event.key == "Enter") {
      event.preventDefault();
      return false;
    }
  });

  function setChainIdCreateNft() {
    if ($('#chain_id').length > 0) {
      window.getChainId()
        .then(function (chainId) {
          $('#chain_id').val(chainId)
          if(gon.celoChainNetworkId != chainId) {
            $('.collection-submit-btn').addClass('disabled-pay-button--network')
            $('#networkError').css('display', 'flex').css('justify-content', 'center');

            $('.wrong-network, .wrong-celo').removeClass('hide');
          } else {
            $('.collection-submit-btn').removeClass('disabled-pay-button--network')
            $('#networkError').css('display', 'none');
          }
          if (['44787', '42220'].includes('' + chainId)) {
            $('.prev__card__network--celo').removeClass('hide')
            $('.prev__card__network--binance').addClass('hide')
          } else {
            $('.prev__card__network--celo').addClass('hide')
            $('.prev__card__network--binance').removeClass('hide')
          }
        })
        .catch(err => console.log("Error: ", err))
    }
  }

  function initializeTooltip(selector, minLength) {
    var elements = $(selector);

    elements.hover(function() {
      var text = $(this).text().trim();
      if (text.length >= minLength) {
        $(this).tooltip({
          title: text,
          placement: "top",
          trigger: "hover"
        });
        $(this).tooltip('show');
      }
    });

    elements.on('mouseleave', function() {
      $(this).tooltip('hide');
    });
  }

  initializeTooltip(".name-tooltip", 33);

  function validationSelectAmount(inputField) {
    return +inputField.attr('value') >= inputField.attr('min') && +inputField.attr('value') <= inputField.attr('max')
  }

  function showErrorSelectAmount(inputField, errorId) {
    if (validationSelectAmount(inputField)) {
      errorId.css('display', 'none')
    } else {
      errorId.css('display', 'flex')
    }
  }

  function handleStartCheckout(kgNumber) {
    const urlParams = new URLSearchParams(window.location.search)

    window.location.href = $('#startCheckout').attr('href') + "?kg_number=" + kgNumber + "&campaign_id=" + urlParams.get('campaign_id')
  }

  $(document).on('keyup', '.select-amount__item--input', function () {
    let newSelectAmount = $(this).find('input').val() || 0

    if (newSelectAmount.length > 7) {
      newSelectAmount = newSelectAmount.substring(0, 7)
      $(this).find('input').val(newSelectAmount)
    }

    $(this).attr('value', this.value)

    $('.select-amount__item.active').removeClass('active')
    $('#select_amount_id').val(newSelectAmount).trigger('change')
    $(this).addClass('active')
  })

  $(document).on('click', '.select-amount__item--select', function () {
    if (!$(this).hasClass('disabled')) {
      let newSelectAmount = $(this).attr('value') || 0

      $('#selectAmountItemInputId').val('')
      $('#selectAmountItemInputId').attr('value', '')

      $('.select-amount__item.active').removeClass('active')
      $('#select_amount_id').val(newSelectAmount).trigger('change')
      $(this).addClass('active')
    }
  })

  $(document).on('change', '#select_amount_id', function () {
    $('#selectAmountDescriptionId').text(this.value)
    showErrorSelectAmount($(this), $('#selectAmountItemErrorId'))
  })

  $(document).on('click', '#triggerStartCheckout', function() {
    if (validationSelectAmount($('#select_amount_id'))) {
      let kgNumber = $('#select_amount_id').attr('value')

      handleStartCheckout(kgNumber)
    }
  })

  $('#collectionCreateForm').on('input', '.nft__sign__input#no_of_copies, .nft__sign__input#guarantee', function(e) {
    const noOfCopies = $('.nft__sign__input#no_of_copies').val()
    const plasticsPerCopy = $('.nft__sign__input#guarantee').val()
    const totalRecovered = new Intl.NumberFormat().format((parseInt(noOfCopies) || 1) * (parseInt(plasticsPerCopy) || 1))

    $('.multiple-nft-recovered-amount').html(`${totalRecovered} kg`)
  })

  $('#d-label__plastik-type-id').on('change', function() {
    if ($("#typePlasticPreviewId").length > 0) {
      $("#typePlasticPreviewId").text(this.value)
    }
  })

  // $("#bidsIconId").on("click", function () {
  //   document.getElementById("flashIconId").click();
  // })

  if (document.getElementById('boxPropertiesId') && document.getElementById('accordion')) {
    var boxProperties = document.getElementById('boxPropertiesId').offsetHeight || 0;
    var recoveryGuarantee = document.getElementById('accordion').offsetHeight || 0;

    if ($("#accordion")[0] && window.getComputedStyle($("#accordion")[0]).display !== "none") {
      $("#exploreCollectionId").css('margin-top', boxProperties);
    }
  }

  if ($("#accordion")[0] && window.getComputedStyle($("#accordion")[0]).display !== "none") {
    $('.panel-collapse').on('show.bs.collapse', function () {
      $(this).siblings('.panel-heading').addClass('active');
      // $("#exploreCollectionId").css('margin-top', boxProperties);
    });

    $('.panel-collapse').on('hide.bs.collapse', function () {
      $(this).siblings('.panel-heading').removeClass('active');
      // $("#exploreCollectionId").css('margin-top', boxProperties - recoveryGuarantee);
    });
  }

  $(document).on("click", "#notVerifiedSectionBtn", function () {
    $("#notVerified").addClass("hide")
    $("#notVerifiedHotpot").removeClass("hide")
  })

  $(document).on("change", "#collection-put-on-sale", function () {
    if (!$(this).is(":checked")) {
      $('#collection_instant_sale_enabled').prop("checked", false).change();
      $('#collection-unlock-on-purchase').prop("checked", false).change();
    }
  })

  $(document).on("change", "#instant-price", () => {
    if (+$("#instant-price").val() <= 0) {
      $("#instant-price-error").addClass('active')
      $("#instant-price-submit").addClass('disabledCustomButton')
    } else {
      $("#instant-price-error").removeClass('active')
      $("#instant-price-submit").removeClass('disabledCustomButton')
    }
  })

  $(document).on("click", "#instant-price-submit", () => {
    if (+$("#instant-price").val() <= 0) {
      $("#instant-price-error").addClass('active')
      $("#instant-price-submit").addClass('disabledCustomButton')
    } else {
      $("#instant-price-error").removeClass('active')
      $("#instant-price-submit").removeClass('disabledCustomButton')
    }
  })

  $(document).on("click", "#show-nft-form-advance-settings", function () {
    $(".nft-form-advance-settings--group").slideToggle();
    $("#show-nft-form-advance-settings").slideToggle();
    $("#hide-nft-form-advance-settings").toggle();
  });

  $(document).on("click", "#hide-nft-form-advance-settings", function () {
    $(".nft-form-advance-settings--group").slideToggle();
    $("#show-nft-form-advance-settings").slideToggle();
    $("#hide-nft-form-advance-settings").toggle();
  });

  $(document).on("change", "#collection-unlock-on-purchase", function () {
    if ($(this).is(":checked")) {
      $(".unlock-description-section").removeClass("hide")
    } else {
      $(".unlock-description-section").addClass("hide")
    }
  });

  function getDdMmYyyyFormat(date) {
    if (isNaN(Date.parse(date))) {
      return '00/00/0000'
    }

    let dd = date.slice(-2)
    let mm = date.slice(5, 7)
    let yyyy = date.slice(0, 4)

    return dd + '/' + mm + '/' + yyyy
  }

  // Collection Attribute Add/Remove section
  function updateJsonField() {
    var data = $(".collection-data-val").val() || { "info": {} };
    if (typeof (data) !== 'object') {
      data = JSON.parse(data);
    }
    $.each($(".collection-attribute-section .collection-attribute-entry"), function (i, collection) {
      var attrKey = $(collection).find(".attr-key").val()
      var attrVal = $(collection).find(".attr-val").val()
      if (attrKey.length > 0 && attrVal.length > 0 && $(collection).find('.plastic-type').length == 0) {
        data[attrKey] = attrVal
      }
    })
    $(".collection-data-val").val(JSON.stringify(data))
  }

  function processAttribute(_this) {
    var inputKey = _this.closest(".collection-attribute-entry").find(".attr-key").val()
    var inputVal = _this.closest(".collection-attribute-entry").find(".attr-val").val()

    if (inputKey?.length > 0 && inputVal?.length > 0) {
      var totalEntry = $(".collection-attribute-section .collection-attribute-entry").length
      var nonEmptyKey = $('.attr-key').filter(function () {
        return this.value === ''
      });
      var nonEmptyval = $('.attr-val').filter(function () {
        return this.value === ''
      });

      if (nonEmptyKey.length <= 1 && nonEmptyval.length <= 1 && $("input[name='collection[art]']").val() == 'true') {
        var collectionAttrLength = $(".collection-attribute-entry").length
        var clonedDiv = $('.collection-attribute-entry-base').clone()
        clonedDiv.removeClass('hide collection-attribute-entry-base')
        clonedDiv.find(".attr-key").attr("name", "collection[attributes][" + collectionAttrLength + "][key]")
        clonedDiv.find(".attr-val").attr("name", "collection[attributes][" + collectionAttrLength + "][val]")
        clonedDiv.appendTo(".collection-attribute-section")
      }
    }

    if (inputKey?.length === 0 || inputVal?.length === 0) {
      var emptyKey = $('.attr-key').filter(function () {
        return this.value === ''
      });
      var emptyval = $('.attr-val').filter(function () {
        return this.value === ''
      });

      if (emptyKey?.length == 3 || emptyval?.length === 3) {
        var totalEntry = $(".collection-attribute-section .collection-attribute-entry").length
        var collections = $(".collection-attribute-section .collection-attribute-entry")
        var currentCollection = collections[totalEntry - 1]
        currentCollection.remove()
      }
    }

    updateJsonField()
  }

  function processInfoAttribute(_this, _key) {
    var inputVal = _this.val();
    var data = $(".collection-data-val").val() || { "info": {} };
    if (typeof (data) !== 'object') {
      data = JSON.parse(data);
    }
    data["info"][_key] = inputVal;

    $(".collection-data-val").val(JSON.stringify(data))
  }

  function showPreviewWrapImage(prevWrap, prevFix) {
    if (prevWrap.hasClass("hide")) {
      prevFix.addClass("hide")
      prevWrap.removeClass("hide")
    }
  }

  // Collection Attribute Add/Remove section end

  $(document).on("change", ".attr-key", function () {
    processAttribute($(this))
  });

  $(document).on("keyup", ".attr-val", function () {
    processAttribute($(this))
  });

  $(document).on("change", ".plastic-type-input", function () {
    updatePlasticTypeJsonField($(this))
  });

  $('#js-d-label__plastik-type li').on('click', function ({target}) {
    $(target).closest('.dropdown').find('#d-label__plastik-type p').html($(this).find('.dropdown-menu__content').html() + '<span class="caret"></span>');
    $(target).closest('.dropdown').find('#d-label__plastik-type input[type=text].plastic-type-input').val($(this).find('.dropdown-menu__content').html()).change();
    updatePlasticTypeJsonField();
    $("#d-label__plastik-type").dropdown("toggle");
  })

  updatePlasticTypeJsonField()

  function updatePlasticTypeJsonField() {
    let data = $(".plastic-data-val").val() || {};

    if (typeof (data) !== 'object') {
      data = JSON.parse(data);
    }

    data["plastic_type"] = $('.plastic-type').val();
    data["weight"] = $('.plastic-type-val').val();

    $(".plastic-data-val").val(JSON.stringify(data))
  }

  $(document).on("change", ".attr-input", function () {
    processInfoAttribute($(this), $(this).attr("data-attr-input"));
  });

  function validationFile1() {
    if (file1.validity.valid && file1Error) {
      file1Error.textContent = '';
      file1Error.className = 'error';
    } else {
      file1ShowError(file1, file1Error);
    }
  }

  $('#d-label__plastik-type-id, #dateOfRecycling, .create-recycler__unit_weight').on("change", function () {
    var fileId = $('#file-1');
    var placeholder = $('#placeholder');
    let previewSection = $('#my-preview-section')

    fileId.val('')
    fileId.prop("disabled", false)
    placeholder.fadeIn(100)
    if (previewSection) {
      previewSection.css('background-image', 'none');
      previewSection.html('');
    }
    if ($('#d-label__plastik-type-id').val() && $('#dateOfRecycling').val() && $('.create-recycler__unit_weight').val()) {
      fetchAutoGenerateImage().done(function (file) {
        var field = document.getElementById('file-1')
        let container = new DataTransfer();
        container.items.add(new File([file], 'AutoGenerateImage.png'));
        document.querySelector('#file-1').files = container.files;
        var previewSection = $('#my-preview-section');
        var chooseFile = $('#choose_file_selected');

        readURLSingle1(field, file, previewSection, placeholder, fileId, chooseFile);

        validationFile1()
      })
    }
  });

  $('#closePreviewArtistId').on('click', function () {
    let fileId = $('#file-1');
    let previewId = $('#my-preview-section');
    let placeholderId = $('#placeholder');
    let notificationId = $('#uploadSuccessfully');

    closePreviewArtist(fileId, previewId, placeholderId, notificationId)
    closePreviewArtist(fileId, $('#nft-create-large-preview'), placeholderId, notificationId)
    closePreviewArtist(fileId, $('#formArtistPreviewSmallId'), placeholderId, notificationId)

    $('.prev_i__card--info').addClass('hide')
  })

  // // ERC 721 section
  // $(document).on("click", ".chooseCollectionNft", function() {
  //   $("#createOwnErc721").modal("hide")
  //   $("#createOwnErc721").find(":input").prop("disabled", true)
  // })
  // // ERC 721 section end

  // Process and Approve section
  const listCountryWithoutCity = ["American Samoa",
                                  "Antarctica",
                                  "Aruba",
                                  "Bouvet Island",
                                  "British Indian Ocean Territory",
                                  "Cayman Islands",
                                  "Christmas Island",
                                  "Cocos (Keeling) Islands",
                                  "Curaçao",
                                  "Falkland Islands (Malvinas)",
                                  "Faroe Islands",
                                  "French Guiana",
                                  "French Polynesia",
                                  "French Southern Territories",
                                  "Gibraltar",
                                  "Guadeloupe",
                                  "Guam",
                                  "Guernsey",
                                  "Heard Island and McDonald Islands",
                                  "Holy See",
                                  "Hong Kong",
                                  "Isle of Man",
                                  "Jersey",
                                  "Macao",
                                  "Martinique",
                                  "Mayotte",
                                  "Montserrat",
                                  "New Caledonia",
                                  "Niue",
                                  "Norfolk Island",
                                  "Northern Mariana Islands",
                                  "Pitcairn",
                                  "Puerto Rico",
                                  "Réunion",
                                  "Saint Barthélemy",
                                  "Saint Martin (French part)",
                                  "Saint Pierre and Miquelon",
                                  "Sint Maarten (Dutch part)",
                                  "South Georgia and the South Sandwich Islands",
                                  "Turks and Caicos Islands",
                                  "Virgin Islands (British)",
                                  "Virgin Islands (U.S.)",
                                  "Åland Islands"]

  // number of copies
  const numberOfCopies = document.getElementById("no_of_copies");
  const numberOfCopiesError = document.getElementsByClassName('numberOfCopiesError')[0];

  // FILE 1
  const file1 = document.getElementById("file-1");
  const file1Error = document.getElementsByClassName('fileError')[0];

  // FILE Invoice
  const fileInvoice = document.getElementById("file-invoice");
  const fileInvoiceError = document.getElementsByClassName('file-invoice-error')[0];

  // ITEM NAME
  const name = document.getElementById("itemname");
  const nameError = document.querySelector('#itemname + span.error');
  const nameLabel = $("label[for='itemname']")[0];

  // DESCRIPTION
  const description = document.getElementById("description");
  const descriptionError = document.querySelector('#description + span.error');

  // NGO DONATION
  const ngoDonation = document.getElementById('ngoDonation');
  const ngoDonationError = document.querySelector('#ngoDonation + span.error')

  // INSTANT-PRICE
  const instantPrice = document.getElementById("instant-price");
  const instantPriceBorder = document.getElementById("instantPriceBorder");
  const instantPriceError = document.getElementById("instantPriceErrorId");

  // ROYALTIES
  const royalty = document.getElementById("royalty");
  const royaltyError = document.getElementById("royaltyErrorId");

  // FORM ERROR
  const formError = document.getElementById("formError");

  // Guarantee
  const guarantee = document.getElementById("guarantee");
  const guaranteeError = document.querySelector('#guaranteeErrorId');
  const guaranteeLabel = $("label[for='guarantee']");

  // invoiceID
  const invoiceID = document.getElementById('idOfInvoice');
  const invoiceIDError = document.querySelector('#idOfInvoice + span.error')

  // country
  const country = document.getElementById('selectCountryOptions');
  const countryError = document.querySelector('#selectCountryOptions + span.error')

  // city
  const city = document.getElementById('selectCityOptions');
  const cityError = document.querySelector('#selectCityOptions + span.error')

  // date
  const date = document.getElementById('dateOfRecycling');
  const dateError = document.querySelector('#dateOfRecycling + span.error')

  // PROPERTIES
  const properties = document.getElementById("properties");
  const propertiesError = document.querySelector('#properties + span.error');

  $("#no_of_copies").on("change", function (e) {
    if (numberOfCopies.validity.valid && numberOfCopiesError) {
      if(parseInt(numberOfCopies.value) < 2 || parseInt(numberOfCopies.value) > 100 ){
        numberOfCopiesShowAmountError(numberOfCopies, numberOfCopiesError);
        return false;
      }
      numberOfCopiesError.textContent = '';
      numberOfCopiesError.className = 'error';
    } else {
      numberOfCopiesShowError(numberOfCopies, numberOfCopiesError);
    }
  })

  $("#groupFile1").on("change", function (e) {
    if (file1.validity.valid && file1Error) {
      file1Error.textContent = '';
      file1Error.className = 'error';
    } else {
      file1ShowError(file1, file1Error);
    }

    showPreviewWrapImage($(".prev__wrap"), $(".prev_fix"))
  })

  $("#groupFileInvoice").on("change", function (e) {
    if (fileInvoice.validity.valid && fileInvoiceError) {
      fileInvoiceError.textContent = '';
      fileInvoiceError.className = 'error';
    } else {
      invoiceShowError(fileInvoice, fileInvoiceError);
    }
  })

  $("#ngoDonation").on("keyup", function (e) {
    if (ngoDonation.validity.valid && ngoDonationError) {
      ngoDonationError.textContent = '';
      ngoDonationError.className = 'error';

      ngoDonation.classList.remove('active');
    } else {
      ngoDonationShowError(ngoDonation, ngoDonationError);
    }
  })

  $("#guarantee").on("keyup", function (e) {
    if (checkIntegerValue(guarantee?.value) && (parseInt(guarantee?.value) >= 1) && guaranteeError) {
      guaranteeError.textContent = '';
      guaranteeError.className = 'error';
    } else {
      guaranteeShowError(guarantee, guaranteeError, guaranteeLabel);
    }
  })
  function nospaces(t){
    if(t.value.match(/\s|\.|\,/g)){
        t.value = t.value.replace(/\s|\.|,/g,'');
    }
  }

  $("#properties").on("keyup", function (e) {
    nospaces(this)
    if (checkIntegerValue(properties?.value) && parseInt(properties?.value) >= properties?.min && parseInt(properties?.value) <= properties?.max && propertiesError) {
      propertiesError.textContent = '';
      propertiesError.className = 'error';

      properties.classList.remove('active');
    } else {
      propertiesShowError(properties, propertiesError);
    }

    if ($("#totalWeightPreviewId").length > 0 && $("#priceDollasPreviewId").length > 0) {
      $("#totalWeightPreviewId").text(this.value)
      $("#priceDollasPreviewId").text(this.value)

      if ($("#instant-price")) {
        $("#instant-price").val(parseFloat(this.value))
      }

      let price = e.currentTarget.value
      let formatter = Intl.NumberFormat('en', { notation: 'compact' });

      if (price && !isNaN(price)) {
        let ratio = $('#plastik_ratio').val()
        let fiat_price = (parseFloat(e.currentTarget.value) * (10 ** 9)) / BigNumber(ratio)
        fiat_price = formatter.format(fiat_price)

        $("#pricePlasticPreviewId").html(fiat_price)
      } else {
        $("#pricePlasticPreviewId").html('0')
      }
    }

    showPreviewWrapImage($(".prev__wrap"), $(".prev_fix"))
  })

  $("#properties").on("change", function(e) {
    setChainIdCreateNft()
  })

  $("#itemname").on("change", function(e) {
    setChainIdCreateNft()
  })

  $("#royalty").on("keyup", function (e) {
    let value = parseFloat(royalty?.value) || ''
    if (checkIntegerValue(value) && (value !== '' && value <= 30 && value >= 0) && royaltyError) {
      royaltyError.textContent = '';
      royaltyError.className = 'error';
    } else {
      royaltyShowError(royalty, royaltyError);
    }
  })

  $("#instant-price").on("keyup", function (e) {
    let value = parseFloat(instantPrice?.value) || ''

    if (value !== '' && value >= 1 && instantPriceError) {
      instantPriceError.textContent = '';
      instantPriceError.className = 'error';

      instantPriceBorder.classList.remove('active')
    } else {
      instancePriceShowError(instantPrice, instantPriceBorder, instantPriceError);
    }
  })

  $('#description').keypress(function(e){
    e.stopPropagation();
  });

  $(document).on("change", "#collectionCreateForm", function (e) {
    e.preventDefault()
    var form = document.getElementById("collectionCreateForm");

    $("#itemname").on("keyup", function (e) {
      if (name.validity.valid && nameError) {
        nameError.textContent = '';
        nameError.className = 'error';

        name.classList.remove('active');
        nameLabel.classList.remove('active');
      } else {
        formShowError(name, nameError, nameLabel);
      }

      if ($("#itemNamePreviewId").length > 0) {
        $("#itemNamePreviewId").html(this.value)
      }
    })

    $("#description").on("keyup", function (e) {
      if (description.validity.valid && descriptionError) {
        descriptionError.textContent = '';
        descriptionError.className = 'error';

        description.classList.remove('active');
      } else {
        descriptionShowError(description, descriptionError);
      }
    })

    if (form.checkValidity() && formError) {
      formError.textContent = '';
      formError.classList.remove('active');
    }

    $("#idOfInvoice").on("keyup", function (e) {
      if (invoiceID.validity.valid && invoiceIDError) {
        invoiceIDError.textContent = '';
        invoiceIDError.className = 'error';

        invoiceID.classList.remove('active');
      } else {
        formShowError(invoiceID, invoiceIDError);
      }
    })

    $("#dateOfRecycling").on("change", function (e) {
      if (date.validity.valid && dateError) {
        dateError.textContent = '';
        dateError.className = 'error';

        date.classList.remove('active');
      } else {
        formShowError(date, dateError);
      }

      if ($("#dateRecoveryPreviewId").length > 0) {
        $("#dateRecoveryPreviewId").text(getDdMmYyyyFormat(this.value))
      }

      showPreviewWrapImage($(".prev__wrap"), $(".prev_fix"))
    })

    $("#selectCountryOptions").on("change", function (e) {
      if (listCountryWithoutCity.includes(this.value)) {
        $("#selectCityOptions").prop('required', false)

        cityError.textContent = '';
        cityError.className = 'error';
        city.classList.remove('active');
        $('#selectCityId').find('.select2-selection').removeClass('error--input active');
      } else {
        $("#selectCityOptions").prop('required', true)
      }

      if (country.validity.valid && countryError) {
        countryError.textContent = '';
        countryError.className = 'error';
        country.classList.remove('active');
        $('#selectCountryId').find('.select2-selection').removeClass('error--input active');
      } else {
        formShowError(country, countryError);
      }
    })

    $("#selectCityOptions").on("change", function (e) {
      if (city.validity.valid && cityError) {
        cityError.textContent = '';
        cityError.className = 'error';
        city.classList.remove('active');
        $('#selectCityId').find('.select2-selection').removeClass('error--input active');
      } else {
        formShowError(city, cityError);
      }
    })
  })

  $(document).on("click", '.triggerCollectionValidation', function (event) {
    event.preventDefault()
    var form = document.getElementById("collectionCreateForm");

    const numberOfCopiesInValid = numberOfCopies && (parseInt(numberOfCopies.value) < 2 || parseInt(numberOfCopies.value) > 100);
    if (form.checkValidity()) {
      if ($('#collection_instant_sale_enabled').is(":checked") && (!validFloat($("#instant-price").val())) ) {
        instancePriceShowError(instantPrice, instantPriceBorder, instantPriceError);
        formShowError(formError);
      } else if (numberOfCopiesInValid) {
        numberOfCopiesShowAmountError(numberOfCopies, numberOfCopiesError);
        formShowError(formError);
      } else {
        $("#submitCollection").click();
      }

    } else {
      nameShowError(name, nameError, nameLabel);
      file1ShowError(file1, file1Error);
      descriptionShowError(description, descriptionError);
      ngoDonationShowError(ngoDonation, ngoDonationError);
      guaranteeShowError(guarantee, guaranteeError, guaranteeLabel);
      propertiesShowError(properties, propertiesError);
      royaltyShowError(royalty, royaltyError);
      invoiceIDShowError(invoiceID, invoiceIDError);
      countryShowError(country, countryError);
      cityShowError(city, cityError);
      dateShowError(date, dateError);
      ngoDonationShowError(ngoDonation, ngoDonationError);
      formShowError(formError);
      instancePriceShowError(instantPrice, instantPriceBorder, instantPriceError);
      invoiceShowError(fileInvoice, fileInvoiceError);
      if (numberOfCopies && numberOfCopiesError) {
        numberOfCopiesShowError(numberOfCopies, numberOfCopiesError);
        numberOfCopiesShowAmountError(numberOfCopies, numberOfCopiesError);
      }

      event.preventDefault();
    }
  })

  function numberOfCopiesShowError(numberOfCopies, numberOfCopiesError) {
    if (numberOfCopies.validity.valueMissing && numberOfCopiesError) {
      numberOfCopiesError.textContent = 'Number of copies is required!';
      numberOfCopiesError.className = 'error active';
    }
  }

  function numberOfCopiesShowAmountError(numberOfCopies, numberOfCopiesError) {
    if(parseInt(numberOfCopies.value) < 2 || parseInt(numberOfCopies.value) > 100 ){
      numberOfCopiesError.textContent = 'Please select a number between 2-100';
      numberOfCopiesError.className = 'error active';
    }
  }

  function file1ShowError(file1, file1Error) {
    if (file1 && file1.validity.valueMissing && file1Error) {
      file1Error.textContent = 'Photo is required!';
      file1Error.className = 'error active';
    }
  }

  function nameShowError(name, nameError, nameLabel) {
    if (name && name.validity.valueMissing && nameError) {
      nameError.textContent = 'Name is required!';
      nameError.className = 'error active';

      name.classList.add('active');
      if (nameLabel) {
        nameLabel.classList.add('active');
      }
    }
  }

  function descriptionShowError(description, descriptionError) {
    if (description && description.validity.valueMissing && descriptionError) {
      descriptionError.textContent = 'Description is required!';
      descriptionError.className = 'error active';

      description.classList.add('active');
    }
  }

  function ngoDonationShowError(ngoDonation, ngoDonationError) {
    if ((ngoDonation?.value < 0 || ngoDonation?.value >= 100) && ngoDonationError) {
      ngoDonationError.textContent = 'NGO donations can not be more than 99%!';
      ngoDonationError.className = 'error active';

      ngoDonation.classList.add('active');
    } else if (!checkIntegerValue(ngoDonation?.value) && ngoDonationError && ngoDonation) {
      ngoDonationError.textContent = 'This field must be a positive integer!';
      ngoDonationError.className = 'error active';

      ngoDonation.classList.add('active');
    }
  }

  function instancePriceShowError(instantPrice, instantPriceBorder, instantPriceError) {
    if (instantPrice?.value === '' && instantPriceError && instantPriceBorder) {
      instantPriceError.textContent = 'Instant price is required. The price cannot be less than 1$!';
      instantPriceError.classList.add('active');
      instantPriceBorder.classList.add('active');
    }
  }

  function guaranteeShowError(guarantee, guaranteeError, guaranteeLabel) {
    if (guarantee?.value < 1 && guaranteeError) {
      guaranteeError.textContent = 'Minimum 1KG of plastic must be connected!';
      guaranteeError.className = 'error active';

      guarantee.classList.add('active');
      guaranteeLabel.addClass('active');
    } else if (!checkIntegerValue(guarantee?.value) && guaranteeError) {
      guaranteeError.textContent = 'This field must be a positive integer!';
      guaranteeError.className = 'error active';

      guarantee.classList.add('active');
      guaranteeLabel.addClass('active');
    }
  }

  function propertiesShowError(properties, propertiesError) {
    if (properties.value === '' && propertiesError) {
      propertiesError.textContent = 'This field is required!';
      propertiesError.className = 'error active';

      properties.classList.add('active');
    } else if (+properties?.value < properties.min && propertiesError) {
      propertiesError.textContent = `Minimum ${properties.min}KG`;
      propertiesError.className = 'error active';

      properties.classList.add('active');
    } else if (+properties?.value > properties.max && propertiesError) {
      propertiesError.textContent = `Maximum ${properties.max}KG`;
      propertiesError.className = 'error active';

      properties.classList.add('active');
    }
  }

  function royaltyShowError(royalty, royaltyError) {
    if ((royalty?.value == '' || royalty?.value < 0 || royalty?.value > 30) && royaltyError) {
      royaltyError.textContent = 'Royalty is required. The value cannot be greater than 30%!';
      royaltyError.className = 'error active';

      royalty.classList.add('active');
    } else if (!checkIntegerValue(royalty?.value) && royaltyError) {
      royaltyError.textContent = 'This field must be a positive integer!';
      royaltyError.className = 'error active';

      royalty.classList.add('active');
    }
  }

  function invoiceShowError(invoice, invoiceError) {
    if (invoice?.validity?.valueMissing && invoiceError) {
      invoiceError.textContent = 'Invoice file is required!';
      invoiceError.className = 'error active file-invoice-error';
    }
  }

  function invoiceIDShowError(invoiceID, invoiceIDError) {
    if (invoiceID?.validity?.valueMissing && invoiceIDError) {
      invoiceIDError.textContent = 'Invoice ID is required!';
      invoiceIDError.className = 'error active';
      invoiceID.classList.add('active');
    }
  }

  function countryShowError(country, countryError) {
    if (country?.validity?.valueMissing && countryError) {
      countryError.textContent = 'Country is required!';
      countryError.className = 'error active';
      $('#selectCountryId').find('.select2-selection').addClass('error--input active');
      country.classList.add('active');
    }
  }

  function cityShowError(city, cityError) {
    if (city?.validity?.valueMissing && cityError) {
      cityError.textContent = 'City is required!';
      cityError.className = 'error active';
      $('#selectCityId').find('.select2-selection').addClass('error--input active');
      city.classList.add('active');
    }
  }

  function dateShowError(date, dateError) {
    if (date?.validity?.valueMissing && dateError) {
      dateError.textContent = 'Date of Recycling is required!';
      dateError.className = 'error active';
      date.classList.add('active');
    }
  }

  function formShowError(formError) {
    if (formError) {
      formError.textContent = 'There is something missing';
      formError.classList.add('active');
    }
  }

  function checkIntegerValue(value) {
    if (value === '') return true
    return Number.isInteger(parseFloat(value))
  }

  $(document).on("click", ".collection-submit", function (e) {
    e.preventDefault()
    $(this).text("In Progress");
    $(this).closest(".row").find("status-icon").html('<div class="follow-step-2-icon"><div class="loader"></div></div>')
    $(".collection-submit-btn").click()
  })

  $(document).on("click", ".default-btn", function (e) {
    e.preventDefault()
  })

  $(document).on("click", ".createOwnErc721Form", function () {
    startContractDeploy($('#collection_contract_type').val())
  });

  window.startContractDeploy = function startContractDeploy(contractType) {
    var name = $('#nft_contract_name').val();
    var symbol = $('#nft_contract_symbol').val();
    var collectionId = $('#collection_id').val();
    if (!name || !symbol) {
      toastr.info('Provide valid name and symbol')
      $.magnificPopup.close();
      $.magnificPopup.open({
        closeOnBgClick: false,
        enableEscapeKey: false,
        items: {
          src: '#createOwnErc721'
        },
        type: 'inline'
      });
    } else {
      var compiled_details = getContractABIAndBytecode('', contractType, false); //shared=false
      console.log("compiled_details", compiled_details)
      var abi = compiled_details['compiled_contract_details']['abi']
      var bytecode = compiled_details['compiled_contract_details']['bytecode']
      console.log(abi, bytecode, name, symbol, contractType, collectionId)
      contractDeployInit()
      deployContract(abi, bytecode, name, symbol, contractType, collectionId);
    }
  }

  window.contractDeployInit = function contractDeployInit() {
    //        $("#createOwnErc721").modal("hide")
    // $.magnificPopup.close();
    //        $("#deployContract").modal("show")
    $.magnificPopup.open({
      closeOnBgClick: false,
      enableEscapeKey: false,
      items: {
        src: '#deployContract'
      }
    });
    $('.deployProgress').removeClass('hide')
    $('.deployDone').addClass('hide')
    $('.deployRetry').addClass('hide')
    $('.deployCollectionRetry').addClass('hide')
    $('.signStart').addClass('grey').removeClass('hide')
    $('.signProgress').addClass('hide')
    $('.signRetry').addClass('hide')
    $('.signDone').addClass('hide')
  }

  window.contractDeploySuccess = function contractDeploySuccess(contractAddress, contractType) {
    console.log("Contract Address: " + contractAddress);
    $('.deployProgress').addClass('hide')
    $('.deployProgress').addClass('hide')
    $('.deployDone').addClass('disabledLink').removeClass('hide')

    //  OPEN SIGN METHOD
    // $('.signDone').addClass('hide')
    // $('.signStart').addClass('hide')
    // $('.signProgress').removeClass('hide')
    console.log(contractAddress, contractType)
    initCollectionCreate(contractAddress, contractType)
  }

  window.contractDeployFailed = function contractDeployFailed(errorMsg) {
    toastr.error(errorMsg)
    $('.deployProgress').addClass('hide')
    $('.deployDone').addClass('hide')
    $('.deployRetry').removeClass('hide').addClass('grey')
    $('.deployCollectionRetry').removeClass('hide')
  }

  // $(document).on("click", ".deployRetry", function () {
  //   startContractDeploy($('#collection_contract_type').val())
  // })

  $(document).on("click", ".deployCollectionRetry", function () {
    startCollectionContractDeploy($('#nft_collection_contract_type').val(), $('#nft_collection_modal_form').val())
  })

  window.initCollectionCreate = async function initCollectionCreate(contractAddress, contractType) {
    await collectionCreateInit(contractAddress)
    var sharedCollection = ($("input[name=chooseCollection]").filter(":checked").val() === 'nft')
    await approveNFT(contractType, contractAddress, sharedCollection, 'collection', $('#nft_collection_new_sc').val())
  }

  window.collectionCreateInit = function collectionCreateInit(contractAddress) {
    console.log("collectionCreateInit")
    if ($('#collection_instant_sale_enabled').is(":checked")) {
      $('.signFixedPrice').removeClass('hide')
    } else {
      $('.signFixedPrice').addClass('hide')
    }
    // $("#deployContract").modal("hide")
    // $("#collectionStepModal").modal("show")
    $.magnificPopup.close();
    $.magnificPopup.open({
      closeOnBgClick: false,
      enableEscapeKey: false,
      items: {
        src: '#collectionStepModal'
      },
      type: 'inline'
    });
    $('.allProgress').addClass('hide')
    $('.allDone').addClass('hide')
    $('.allRetry').addClass('hide')
    $('.allStart').removeClass('hide').addClass('grey')
    $('.approveProgress').removeClass('hide')
  }

  window.collectionApproveSuccess = function collectionApproveSuccess(contractType) {
    mintCollectionCreate(contractType)
  }

  async function mintCollectionCreate(contractType) {
    $('.allProgress').addClass('hide')
    $('.allDone').addClass('hide')
    $('.allRetry').addClass('hide')
    $('.allStart').addClass('hide').addClass('grey')
    $('.approveDone').removeClass('hide').removeClass('grey').addClass('disabledLink')
    $('.mintProgress').removeClass('hide')
    $('.signFixPriceStart').removeClass('hide').addClass('grey')
    // TODO: WHILE CHANGE NFT TO SHARED/OWNER THS HAS TO BE CHANGED
    var sharedCollection = ($("input[name=chooseCollection]").filter(":checked").val() === 'nft')
    if (contractType === 'art_nft' || contractType === 'recycling_nft' || contractType === 'multiple_nft') {
      // create voucher
      await signAndCreateVoucher($('#collection_contract_address').val(), $('#collection_token_uri').val(),
        $('#collection_royalty_fee').val(), $('#collection_pid').val(), $('#collection_id').val(), contractType, $('#collection_amount').val(), $('#nft_collection_new_sc').val())
    }
  }

  window.collectionApproveFailed = function collectionApproveFailed(errorMsg) {
    toastr.error(errorMsg)
    $('.allProgress').addClass('hide')
    $('.allDone').addClass('hide')
    $('.allRetry').addClass('hide')
    $('.allStart').removeClass('hide').addClass('grey')
    $('.approveRetry').removeClass('hide')
  }

  $(document).on("click", ".approveRetry", function () {
    if ($('#priceChange').length) {
      initApproveResale()
    } else {
      initCollectionCreate($('#collection_contract_address').val(), $('#collection_contract_type').val())
    }
  })

  $(document).on("click", ".mintRetry", function () {
    mintCollectionCreate($('#collection_contract_type').val())
  })

  window.collectionMintSuccess = async function collectionMintSuccess(collectionId, isBuying = false) {
    if ($('#collection_instant_sale_enabled').is(":checked") || $('#instant-price').val()) {
      $('.mintProgress').addClass('hide')
      $('.mintDone').removeClass('hide')
      await initsignFixedPriceProcess()
    } else {
      if(isBuying) {
        toastr.success('Your purchase was successful.')
        const account = await getCurrentAccount()
        redirectToPurchaseSuccessPage(collectionId);
      } else {
        toastr.success('Plastic Credit successfully created.')
        let collectionDetail = redirectToCollectionDetail();
        window.location.href = collectionDetail + collectionId
      }
    }
  }

  window.collectionMintFailed = function collectionMintFailed(errorMsg, contractType) {
    toastr.error(errorMsg)
    $('.allProgress').addClass('hide')
    $('.allDone').addClass('hide')
    $('.allRetry').addClass('hide')
    $('.allStart').removeClass('hide').addClass('grey')
    $('.approveDone').removeClass('hide').removeClass('grey').addClass('disabledLink')
    $('.mintStart').addClass('hide')
    $('.mintRetry').removeClass('hide')
  }

  window.initsignFixedPriceProcess = async function initsignFixedPriceProcess() {
    hideAll()
    $('.convertDone').removeClass('hide')
    $('.approveDone').removeClass('hide')
    $('.mintDone').removeClass('hide')
    $('.signFixPriceProgress').removeClass('hide')
    var pay_token_address = $('#collection_erc20_token_id option:selected, this').attr('address')
    var details = fetchCollectionDetails(null, pay_token_address)
    console.log("fetchCollectionDetails", details)

    if (details) {
      var decimals = toNum(details['pay_token_decimal']);
      var unitPrice = details['contract_type'] == 'recycling_nft' ? '1.0' : details['unit_price'];
      var paymentAmt = roundNumber(mulBy(paymentAmt, 10 ** decimals), 0);
      await signSellOrder(unitPrice, details['pay_token_decimal'], details['pay_token_address'],
        details['token_id'], details['asset_address'], details['collection_id'], details['donation'], '', $('#collection_amount').val(),
        details['contract_type'], details['plastik_crypto_address'])
        // signSellOrder(details['asset_address'], details['token_id'].toString(), unitPrice,
      //   details['pay_token_address'], details['owner_address'], details['collection_id'])
    } else {
      bidSignFixedFailed('Unable to fetch tokan details. Please try again later')
    }
  }

  window.bidSignFixedSuccess = function bidSignFixedSuccess(collectionId, contractType = 'art_nft') {
    toastr.success(`${contractType == 'art_nft' || contractType == 'multiple_nft' ? 'NFT' : 'Plastic Credit'} succcessfully created`);
    let collectionDetail = redirectToCollectionDetail();
    if (contractType == 'art_nft' || contractType == 'multiple_nft') {
      window.location.href = collectionDetail + collectionId
    } else { 
      let url = collectionDetail + collectionId + '/create_success';
      window.location.href = url;
    }
  }

  window.bidSignFixedFailed = function bidSignFailed(errorMsg) {
    toastr.error(errorMsg)
    hideAll()
    $('.convertDone').removeClass('hide')
    $('.approveDone').removeClass('hide')
    $('.mintDone').removeClass('hide')
    $('.signFixPriceRetry').removeClass('hide')
  }

  $(document).on("click", ".signFixPriceRetry", function () {
    if ($('#priceChange').length) {
      initsignFixedPriceUpdate()
    } else {
      initsignFixedPriceProcess()
    }
  })

  $("input#dateOfRecycling").on("change", function () {
    if (this.value != '') {
      var date = new Date(this.value)
      var stringDate = date.toLocaleString('en-GB', { year: "numeric", month: "2-digit", day: "2-digit" })
      this.setAttribute("data-date", stringDate)
    } else {
      this.setAttribute("data-date", "dd/mm/yyyy")
    }
  }).trigger("change")

  $('input#buy_qty').on('keyup', function () {
    limitText(this, 4)
  })

  function limitText(field, maxChar) {
    var ref = $(field),
      val = ref.val();
    if (val == 10000 || val == 100000) {
      ref.val(10000);
    } else if (val.length >= maxChar) {
      ref.val(function () {
        return val.substr(0, maxChar);
      });
    }
  }

  $(document).on("click", ".triggerBuyValidation", function (e) {
    clearToastr();
    e.preventDefault()
    var qty = $('#buy_qty').val() || $('#kg-input').val() || $('#select_amount_id').val()
    var maxQty = $('#buy_qty').attr('maxQuantity') || $('#max-rg').val()

    if (!validNum(qty)) {
      return toastr.error('Please enter valid quantity');
    } else if (!isLessThanOrEqualTo(qty, maxQty)) {
      return toastr.error("Looks like the recovery projects don't have enough plastic available, select another in explore projects")
    } else {
      $("#buyForm :input").prop("disabled", true);
      ($('.request-from').html() === 'MergeNFT') ?
        initBuyProcess('Merge') : initBuyProcess();
    }
  })

  window.initBuyProcess = function initBuyProcess(requestFrom) {
    var curErc20Balance = $('#cur-balance-as-number').val()
    var ethBalance = $('#eth_balance').text()
    var totalAmt = requestFrom === 'Merge' ? $("#buy-total-amt-dp").attr('buyAmtPlastik') : $("#buy-total-amt-dp").attr('buyAmt')
    if (isGreaterThanOrEqualTo(curErc20Balance, totalAmt)) {
      $('.convertEth').addClass("hide")
      initApproveBuyProcess($("#buyContractAddress").text(), $("#buyContractDecimals").text(), requestFrom)
    } else if (isGreaterThanOrEqualTo(ethBalance, totalAmt)) {
      convertBnbToWbnb(totalAmt - curErc20Balance, 'Buy')
    } else {
      $("#buyForm :input").prop("disabled", false);
      // $("#placeBuy").modal("hide");
      $.magnificPopup.close();
      return toastr.error('Not enough balance');
    }
  }

  window.buyConvertSuccess = function buyConvertSuccess(transactionHash) {
    $('.convertProgress').addClass('hide')
    $('.convertDone').removeClass('hide')
    initApproveBuyProcess($("#buyContractAddress").text(), $("#buyContractDecimals").text())
  }

  window.buyConvertFailed = function buyConvertFailed(errorMsg) {
    toastr.error(errorMsg)
    hideAll()
    $('.allStart').removeClass('hide').addClass('grey')
    $('.convertRetry').removeClass('hide')
  }

  window.initApproveBuyProcess = function initApproveBuyProcess(contractAddress, contractDecimals, requestFrom = 'Buy') {
    hideAll()
    $('.convertDone').removeClass('hide')
    $('.approvebuyProgress').removeClass('hide')
    $('.purchaseStart').removeClass('hide')
    $.magnificPopup.close();

    $.magnificPopup.open({
      closeOnBgClick: false,
      enableEscapeKey: false,
      items: {
        src: '#placeBuy'
      },
      type: 'inline'
    });
    var qty = $('#buy_qty').val() || $('#kg-input').val()
    approveERC20(contractAddress, 'erc20', $("#buy-total-amt-dp").attr('buyAmt'), contractDecimals, requestFrom, [qty])
  }

  window.buyApproveSuccess = function buyApproveSuccess(transactionHash, contractAddress) {
    console.log("buyApproveSuccess")
    console.log(contractAddress)
    $('.approvebuyProgress').addClass('hide')
    $('.approvebuyDone').removeClass('hide')
    initPurchaseProcess(contractAddress)
  }

  window.buyApproveFailed = function buyApproveFailed(errorMsg) {
    toastr.error(errorMsg)
    hideAll()
    $('.convertDone').removeClass('hide')
    $('.approvebuyRetry').removeClass('hide')
    $('.purchaseStart').removeClass('hide')
  }

  $(document).on("click", ".approvebuyRetry", function () {
    initApproveBuyProcess($("#buyContractAddress").text(), $("#buyContractDecimals").text())
  })

  window.initPurchaseProcess = function initPurchaseBuyProcess(contractAddress) {
    hideAll()
    $('.convertDone').removeClass('hide')
    $('.approvebuyDone').removeClass('hide')
    $('.purchaseProgress').removeClass('hide')
    var giftAddress = $('#giftAddress').val()
    console.log("initPurchaseProcess")
    console.log(contractAddress)
    var paymentDetails = fetchCollectionDetails(null, contractAddress)

    if (paymentDetails['contract_type'] == "nft721") {
      buyAsset(paymentDetails['owner_address'], toNum(paymentDetails['asset_type']), paymentDetails['asset_address'],
        paymentDetails['token_id'], toNum(paymentDetails['unit_price']), toNum($('#buy_qty').val()), toNum($("#buy-total-amt-dp").attr('buyAmt')),
        paymentDetails['pay_token_address'], toNum(paymentDetails['pay_token_decimal']),
        paymentDetails['seller_sign'], paymentDetails['collection_id'], giftAddress)
    }
    else {
      buyLazyMintedPRG(paymentDetails, toNum($("#buy-total-amt-dp").attr('buyAmt')), toNum($('#kg-input').val() || $('#select_amount_id').val()), giftAddress)
    }
  }

  window.buyPurchaseSuccess = async function buyPurchaseSuccess(collectionId) {
    $('.convertDone').removeClass('hide')
    $('.approvebuyDone').removeClass('hide')
    $('.purchaseProgress').addClass('hide')
    $('.purchaseDone').removeClass('hide')
    toastr.success('Your purchase was successful.')
    const account = await getCurrentAccount()
    redirectToPurchaseSuccessPage(collectionId);
  }

  window.buyPurchaseFailed = function buyPurchaseFailed(errorMsg) {
    toastr.error(errorMsg)
    hideAll()
    $('.convertDone').removeClass('hide')
    $('.approvebuyDone').removeClass('hide')
    $('.purchaseRetry').removeClass('hide')
  }


  $(document).on("click", ".purchaseRetry", function () {
    initPurchaseProcess($("#buyContractAddress").text())
  })


  $(document).on("click", ".execButton", function (e) {
    clearToastr();
    $('.bidExecDetail').text($(this).attr('bidDetail'))
    $('#bidByUser').text($(this).attr('bidUser'))
    $('.executeBidSymbol').text($(this).attr('bidSymbol'))
    $('#contractAddress').text($(this).attr('contractAddress'))
    $('#erc20ContractAddress').text($(this).attr('erc20ContractAddress'))
    $('#bidId').val($(this).attr('bidId'))
    calculateBidExec($(this))
    // $("#bidDetail").modal("show")
    show_modal('#bidDetail')
  })

  // EXECUTING BID MODEL HERE
  $(document).on("click", ".triggerExecuteBidValidation", function (e) {
    clearToastr();
    e.preventDefault();
    // $("#bidDetail").modal("hide")
    // $("#executeBid").modal("show");
    show_modal('#executeBid')
    initApproveExecBidProcess();
  })

  window.initApproveExecBidProcess = function initApproveExecBidProcess() {
    var contractType = $('#contractType').text()
    var contractAddress = $('#contractAddress').text()
    approveNFT(contractType, contractAddress, gon.collection_data['contract_shared'], 'executeBid')
  }

  window.approveBidSuccess = function approveBidSuccess(collectionId) {
    hideAll()
    $('.approveExecbidDone').removeClass('hide')
    $('.acceptBidProgress').removeClass('hide')
    initAcceptBidProcess()
  }

  window.approveBidFailed = function approveBidFailed(errorMsg) {
    toastr.error(errorMsg)
    hideAll()
    $('.approveExecbidRetry').removeClass('hide')
    $('.approveBidStart').removeClass('hide')
  }

  $(document).on("click", ".approveExecBidRetry", function () {
    initApproveExecBidProcess()
  })

  window.initAcceptBidProcess = function initAcceptBidProcess() {
    var contractAddress = $('#erc20ContractAddress').text();
    var paymentDetails = fetchCollectionDetails($('#bidId').val(), contractAddress);
    console.log(paymentDetails['buyer_address'], toNum(paymentDetails['asset_type']), paymentDetails['asset_address'],
      paymentDetails['token_id'], toNum(paymentDetails['amount']), toNum(paymentDetails['quantity']),
      paymentDetails['pay_token_address'], toNum(paymentDetails['pay_token_decimal']),
      paymentDetails['buyer_sign'], paymentDetails['collection_id'])
    executeBid(paymentDetails['buyer_address'], toNum(paymentDetails['asset_type']), paymentDetails['asset_address'],
      paymentDetails['token_id'], toNum(paymentDetails['amount_with_fee']), toNum(paymentDetails['quantity']),
      paymentDetails['pay_token_address'], toNum(paymentDetails['pay_token_decimal']),
      paymentDetails['buyer_sign'], paymentDetails['collection_id'], paymentDetails['bid_id'])
  }

  window.acceptBidSuccess = function acceptBidSuccess(collectionId) {
    hideAll()
    $('.allDone').removeClass('hide')
    toastr.success('Bid accept succces.')
    let collectionDetail = redirectToCollectionDetail();
    window.location.href = collectionDetail + collectionId
  }

  window.acceptBidFailed = function acceptBidFailed(errorMsg) {
    toastr.error(errorMsg)
    hideAll()
    $('.approveExecbidDone').removeClass('hide')
    $('.acceptBidRetry').removeClass('hide')
  }

  $(document).on("click", ".acceptBidRetry", function () {
    initAcceptBidProcess()
  })


  // BUYING MODEL STARTS HERE
  $(document).on("click", ".triggerBurn", function (e) {
    clearToastr();
    e.preventDefault()
    // $("#burnModal").modal("hide");
    // $("#burnToken").modal("show");
    show_modal('#burnToken');
    initBurnProcess();
  })

  window.initBurnProcess = function initBurnProcess() {
    var paymentDetails = fetchCollectionDetails()
    console.log(paymentDetails['contract_type'], paymentDetails['asset_address'],
      paymentDetails['token_id'], paymentDetails['owned_tokens'], paymentDetails['collection_id'], paymentDetails['shared'])
    burnNFT(paymentDetails['contract_type'], paymentDetails['asset_address'],
      paymentDetails['token_id'], paymentDetails['owned_tokens'], paymentDetails['collection_id'], paymentDetails['shared'])
  }

  window.burnSuccess = function burnSuccess(transactionHash) {
    $('.burnProgress').addClass('hide')
    $('.burnDone').removeClass('hide')
    toastr.success('Burned successfully.')
    window.location.href = '/'
  }

  window.burnFailed = function burnFailed(errorMsg) {
    toastr.error(errorMsg)
    $('.burnProgress').addClass('hide')
    $('.burnRetry').removeClass('hide')
  }

  $(document).on("click", ".burnRetry", function () {
    initBurnProcess();
  })

  function readURLSingle1(input, file, previewSection, placeholder, fileId, chooseFile) {
    var ftype = file.type;
    var fsize = file.size / 1024 / 1024; // in MBs

    if (fsize > 10) {
      return toastr.error('Invalid file size!. Must be less than 10MB');
    }
    var imgExt = ['image/png', 'image/jpeg', 'image/gif', 'image/webp'];

    if (input) {
      var reader = new FileReader();

      reader.onload = function (e) {
        if (imgExt.includes(ftype)) {
          previewSection.css('background-image', 'url(' + e.target.result + ')');
          previewSection.css({
            'width': '100%',
            'min-height': '300px',
            'background-size': 'contain',
            'background-repeat': 'no-repeat',
            'background-position': 'center',
            'margin-left': 'auto',
            'margin-right': 'auto',
          });
          previewSection.hide();
          previewSection.fadeIn(650);
        } else if (coverImg) {
          return toastr.error('Invalid file type!');
        }
        placeholder.fadeOut(100);
        fileId.fadeOut(100);
        chooseFile.fadeOut(100);
      }
      reader.readAsDataURL(file);
    }
  }

  function closePreviewArtist(fileId, previewId, placeholderId, notificationId) {
    fileId.val('');
    previewId.css('background-image', 'none');
    $('#nft-create-large-preview__iframe').remove();
    previewId.removeAttr("style");
    previewId.hide();

    $('.prev_i__empty').show();
    $('.nft-create__form--box').removeClass('hide')
    $('.nft-create-large-preview__wrapper').addClass('hide')
    $('.prev_i__card--info').addClass('hide')

    notificationId.fadeOut(100);
    placeholderId.fadeIn(100);
  }

  // $(document).on("change", "#autoGenerateImage", function (e) {
  //   var fileId = $('#file-1');
  //   var placeholder = $('#placeholder');
  //   var previewSection = $('#my-preview-section');
  //   var chooseFile = $('#choose_file_selected');

  //   if ($(this).is(":checked") && ($(".plastic-type-val").val() !== '')) {
  //     e.preventDefault()
  //     fetchAutoGenerateImage().done(function (file) {
  //       if ($(this).is(":checked")) {
  //         var field = document.getElementById('file-1')
  //         let container = new DataTransfer();
  //         container.items.add(new File([file], 'AutoGenerateImage.png'));
  //         document.querySelector('#file-1').files = container.files;
  //         readURLSingle1(field, file, previewSection, placeholder, fileId, chooseFile);
  //       }

  //       validationFile1()
  //     })
  //   } else if (!$(this).is(":checked")) {
  //     fileId.val('');
  //     fileId.prop("disabled", false);
  //     placeholder.fadeIn(100);

  //     if (previewSection) {
  //       previewSection.css('background-image', 'none');
  //       previewSection.html('');
  //     }
  //   }
  // })

  // $(document).on("click", ".labelForFile1", function () {
  //   if ($("#autoGenerateImage").is(":checked")) {
  //     $("#autoGenerateImage").prop('checked', false)
  //     $('.preview-loading').addClass('hide');
  //   }
  // })

  function blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.lastModified = new Date().getTime();
    theBlob.name = fileName;
    return theBlob;
  }

  function fetchAutoGenerateImage() {
    var resp = false
    $('.preview-loading').removeClass('hide');
    return $.ajax({
      url: '/image-generator',
      type: 'POST',
      data: {
        "recycler_name": $("#owner_name").val(),
        "recycler_id": $("#user_id").val(),
        "weight": $(".plastic-type-val").val(),
        "material": $(".plastic-type").val(),
        "date": $("#dateOfRecycling").val(),
      },
      xhr: function () {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob'
        return xhr;
      },
      success: function (data) {
        if (data.errors) {
          toastr.error(data['error'])
        } else {
          $('.preview-loading').addClass('hide');
          resp = data
          var file = blobToFile(data, 'AutoGenerate.png')
          return file;
        }
      }
    });
  }

  // TRANSFERRING MODEL STARTS HERE
  $(document).on("click", ".triggerTransfer", function (e) {
    clearToastr();
    e.preventDefault()
    var address = fetchTransferDetails()
    if (address) {
      // $("#transferTokenForm").modal("hide");
      // $("#transferToken").modal("show");
      show_modal('#transferToken');
      initTransferProcess($('.transferAddress').val());
    } else {
      return toastr.error('Invalid user address. Please provide address of the user registered in the application')
    }
  })

  function fetchTransferDetails() {
    var resp = false
    $.ajax({
      url: '/collections/' + $('#collection_id').val() + '/fetch_transfer_user',
      type: 'GET',
      async: false,
      data: { address: $('.transferAddress').val() },
      success: function (data) {
        if (data.errors) {
          toastr.error(data['error'])
        } else {
          resp = data['address']
        }
      }
    });
    return resp;
  }

  window.initTransferProcess = function initTransferProcess(recipientAddress) {
    var paymentDetails = fetchCollectionDetails()
    console.log(paymentDetails['contract_type'], paymentDetails['asset_address'], recipientAddress,
      paymentDetails['token_id'], paymentDetails['owned_tokens'], paymentDetails['collection_id'])
    console.log(gon.collection_data['contract_shared'])
    directTransferNFT(paymentDetails['contract_type'], paymentDetails['asset_address'], recipientAddress,
      paymentDetails['token_id'], paymentDetails['owned_tokens'], gon.collection_data['contract_shared'],
      paymentDetails['collection_id'], paymentDetails['new_sc'])
  }

  window.directTransferSuccess = function directTransferSuccess(transactionHash, collectionId) {
    $('.transferProgress').addClass('hide')
    $('.transferDone').removeClass('hide')
    toastr.success('Transferred successfully.')
    let collectionDetail = redirectToCollectionDetail();
    window.location.href = collectionDetail + collectionId
  }

  window.directTransferFailed = function directTransferFailed(errorMsg) {
    toastr.error(errorMsg)
    $('.transferProgress').addClass('hide')
    $('.transferRetry').removeClass('hide')
  }

  $(document).on("click", ".transferRetry", function () {
    initTransferProcess($('.transferAddress').val());
  })

  // NGO DONATION CHANGED
  // $("input#ngoDonation").on("keyup", function (e) {
  //   e.preventDefault()
  //   var percent = parseFloat(e.currentTarget.value)

  //   if (percent > 99 || percent < 0) {
  //     this.value = Math.abs(percent).toString().substr(0, 2);
  //     return toastr.error('Please enter a number from 0 to 99')
  //   }
  //   if (percent + parseFloat(gon.serviceFee) > 100) {
  //     return toastr.error(`ngoDonation fee and platform fee is larger than 100%`)
  //   }
  // })

  // PRICECHANGE MODEL STARTS HERE

  $(document).on("click", ".triggerPriceChange", function (e) {
    e.preventDefault()
    initApproveResale()
  })

  $(document).on("click", "#listing-price-submit", function (e) {
    e.preventDefault();

    var form = document.getElementById("listing-price-form");
    var amountNftAvailableForResell = parseInt($('#amount_nft_available_for_resell').val());
    // send token form validation
    const numberOfItems = document.getElementById('number_of_items');
    const numberOfItemsError = document.querySelector('#number_of_items + span.error')

    const instantPrice = document.getElementById('instant-price');
    const instantPriceError = document.querySelector('#instant-price + span.error')

    if(form.checkValidity()) {
      $('#loading-list-on-sale').removeClass('hide');
      setTimeout(function() {
        if (parseInt(numberOfItems.value) > amountNftAvailableForResell) {
          quantityIncorrectError(numberOfItems, numberOfItemsError);
          return false;
        }
        var artSuppoterId = $('#art_supporter_id').val();
        initApproveResaleMultipleNft(artSuppoterId);
      }, 1);
    }else{
      priceRequiredError(instantPrice, instantPriceError);
      quantityRequiredError(numberOfItems, numberOfItemsError);
    }

  })

  function priceRequiredError(price, priceError) {
    if ((price.validity.valueMissing && priceError) || (price.value == 0 && priceError)) {
      priceError.textContent = 'Price is required!';
      priceError.className = 'error active';

      price.classList.add('input-error');
    }
  }

  function quantityRequiredError(numberOfItems, numberOfItemsError) {
    if ((numberOfItems.validity.valueMissing && numberOfItemsError) || (numberOfItems.value == 0 && numberOfItemsError)) {
    numberOfItemsError.textContent = 'Number of items is required!';
    numberOfItemsError.className = 'error active';

    numberOfItems.classList.add('input-error');
    }
  }

  function quantityIncorrectError(numberOfItems, numberOfItemsError) {
    numberOfItemsError.textContent = 'Number of items is incorrect!';
    numberOfItemsError.className = 'error active';

    numberOfItems.classList.add('input-error');
  }

  $('#copies_of_multiple_nft_listing_price').on('input', "#number_of_items, #instant-price", function(e) {
    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
    let guaranteeConnected = $('#guarantee_connected').val();
    let price = $('#instant-price').val();
    let quantity = parseInt($('#number_of_items').val());
    let royalties = $('#royalties').val();
    const ratio = $('#plastik_ratio').val();
    let amountNftAvailableForResell = parseInt($('#amount_nft_available_for_resell').val());

    const numberOfItems = document.getElementById('number_of_items');
    const numberOfItemsError = document.querySelector('#number_of_items + span.error')

    const instantPrice = document.getElementById('instant-price');
    const instantPriceError = document.querySelector('#instant-price + span.error')

    if (numberOfItems.validity.valid && numberOfItemsError) {
      numberOfItemsError.textContent = '';
      numberOfItemsError.className = 'error';

      numberOfItems.classList.remove('input-error');

      if(quantity > amountNftAvailableForResell){
        quantityIncorrectError(numberOfItems, numberOfItemsError);
        return false;
      }
    } else {
      quantityRequiredError(numberOfItems, numberOfItemsError);
    }

    if (instantPrice.validity.valid && instantPriceError) {
      instantPriceError.textContent = '';
      instantPriceError.className = 'error';
      instantPrice.classList.remove('input-error');
    } else {
      priceRequiredError(instantPrice, instantPriceError);
    }

    let totalGuaranteePrice = quantity * guaranteeConnected;
    let totalNftPrice = price * quantity;
    let totalPrice = totalGuaranteePrice + totalNftPrice;
    let fees = royalties / 100 * totalNftPrice;
    let totalEarning = totalNftPrice - fees;

    // let PlastikPrice = (totalEarning * (10 ** 9)) / BigNumber(ratio)
    // let totalEarningInPlastiks = formatter.format(PlastikPrice);

    let totalPlastikPrice = (totalNftPrice * (10 ** 9)) / BigNumber(ratio)
    let totalPlastikPriceInPlastiks = formatter.format(totalPlastikPrice);

    $("#guarantee-kg").text(formatter.format(totalGuaranteePrice));
    $("#guarantee-price").text(formatter.format(totalGuaranteePrice));
    $('#div-total-price').text('$'+formatter.format(totalPrice));

    $('#total-earning').text(formatter.format(totalEarning));
    // $('#total-earning-plastiks').text(totalEarningInPlastiks);

    // for approve listing modal
    $('#approve-total-earn').text(formatter.format(totalNftPrice));
    $('#approve-total-earn-plastiks').text(totalPlastikPriceInPlastiks);
    $('#approve-amount-items').text(quantity);
  });

  $('.remove-from-sale').on('click', function(e){
    $('.remove-from-sale i').removeClass('d-none');
    e.preventDefault();
    let artSupportId = $(this).data('art-support-id');
    let collectionId = $(this).data('collection-id');

    const prefixPath = $('#prefix_path').val();
    let path = (prefixPath == 'nft') ? '/nft/collectibles/' : '/collections/';
    let url = `${path + collectionId}/remove_from_sale_copies_of_multiple_nft`;

    var request = $.ajax({
      url: url,
      type: "POST",
      async: false,
      data: {
        artSupportId: artSupportId,
        collectionId: collectionId
      },
      dataType: "script"
    });
    request.done(function (msg) {
      toastr.success('Removed from sale');
      $('.remove-from-sale i').addClass('d-none');
      const collectionDetail = redirectToCollectionDetail();
      window.location.href = collectionDetail + collectionId
    });
    request.fail(function (jqXHR, textStatus) {
      toastr.error('Removed from sale failed');
    });
  })


  $(document).on("keyup", "#instant-price", function (e) {
    e.preventDefault()
    var price = e.currentTarget.value
    var formatter = Intl.NumberFormat('en', { notation: 'compact' });

    if (price && !isNaN(price)) {
      var ratio = $('#plastik_ratio').val()
      // var fiat_price = (parseFloat(e.currentTarget.value) * parseFloat(usdPrice)).toFixed(2)
      var fiat_price = (parseFloat(e.currentTarget.value) * (10 ** 9)) / BigNumber(ratio)

      fiat_price = formatter.format(fiat_price)

      var usdPriceMsg = '<span class=\'nft__para-color\'>(' + fiat_price + ' PLASTIK)</span>'
      $("#instant-price-to-dollar").html(usdPriceMsg)
    } else {
      $("#instant-price-to-dollar").empty()
    }
  });

  $(document).on("keyup", "#bid_amt", function (e) {
    e.preventDefault()
    var price = e.currentTarget.value
    if (price && !isNaN(price)) {
      var usdPrice = $('#usd_price').val()
      var fiat_price = (parseFloat(e.currentTarget.value) * parseFloat(usdPrice)).toFixed(2)
      var usdPriceMsg = '<span class=\'nft__para-color\'>(' + fiat_price + ' PLASTIK)</span>'
      $("#instant-price-to-dollar").html(usdPriceMsg)
    } else {
      $("#instant-price-to-dollar").empty()
    }
  });

  window.initApproveResaleMultipleNft = async function initApproveResaleMultipleNft(artSupporterId) {
    if (!validFloat($("#instant-price").val())) {
      return toastr.error('Please enter valid instant price')
    }

    show_modal('#approve-listing-modal');

    const account = await getCurrentAccount();
    var details = fetchCollectionDetails(null, null, account, artSupporterId)
    approveResaleNFT(details['contract_type'], details['asset_address'], details['shared'], details['approving_address'], details['new_sc'], artSupporterId)
  }

  window.initApproveResale = function initApproveResale() {
    if ($('#collection-put-on-sale').is(":checked") || ($('#collection_instant_sale_enabled').is(":checked"))) {
      if ($('#collection_instant_sale_enabled').is(":checked")) {
        if (!validFloat($("#instant-price").val())) {
          return toastr.error('Please enter valid instant price')
        } else if ($('#instant-price').val() !== $('#instant-price').attr('prevVal')) {
          $('.signFixedPrice').removeClass('hide')
        }
      }
      // $("#change-price").modal("hide");
      // $("#priceChange").modal("show");
      $.magnificPopup.close();
      $.magnificPopup.open({
        closeOnBgClick: false,
        enableEscapeKey: false,
        items: {
          src: '#priceChange'
        },
        type: 'inline'
      });
      if ($('#collection-put-on-sale').is(":checked") || $('#collection_instant_sale_enabled').is(":checked")) {
        $('.approveRetry').addClass('hide')
        $('.approveProgress').removeClass('hide')
        var details = fetchCollectionDetails()
        approveResaleNFT(details['contract_type'], details['asset_address'], details['shared'], details['approving_address'], details['new_sc'])
      } else {
        hideAll()
        $('.approveFlow').addClass('hide')
        initsignFixedPriceUpdate()
      }

    } else {
      $("#submitPriceChange").click()
    }
  }

  window.approveResaleSuccess = function approveResaleSuccess() {
    hideAll()
    $('.approveDone').removeClass('hide')
    if ($('#collection_instant_sale_enabled').is(":checked") || $('#instant-price').val()) {
      initsignFixedPriceUpdate()
    } else {
      $("#submitPriceChange").click()
    }
  }

  window.approveResaleSuccessMultipleNft = async function approveResaleSuccessMultipleNft(contractType, artSupporterId) {
    hideAll()
    $('.approveDone').removeClass('hide')
    if ($('#collection_instant_sale_enabled').is(":checked") || $('#instant-price').val()) {
      await initsignFixedPriceUpdateMultipleNft(artSupporterId)
    } else {
      $("#submitPriceChange").click()
    }


  }

  window.approveResaleFailed = function approveResaleFailed(errorMsg) {
    toastr.error(errorMsg)
    $('.approveProgress').addClass('hide')
    $('.approveRetry').removeClass('hide')
  }

  window.initsignFixedPriceUpdate = async function initsignFixedPriceUpdate() {
    hideAll()
    $('.approveDone').removeClass('hide')
    $('.signFixedPrice').removeClass('hide')
    $('.signFixPriceRetry').addClass('hide')
    $('.signFixPriceProgress').removeClass('hide')
    var pay_token_address = $('#collection_erc20_token_id option:selected, this').attr('address')
    var pay_token_decimal = $('#collection_erc20_token_id option:selected, this').attr('decimals')
    var details = fetchCollectionDetails(null, pay_token_address)
    var quantity = $('#collection_amount').val() || details['owned_tokens'];

    // for resell multiple nft
    if($('#number_of_items').val()) quantity = $('#number_of_items').val()

    if (details) {
      await signSellOrder($('#instant-price').val(), details['pay_token_decimal'], details['pay_token_address'],
        details['token_id'], details['asset_address'], details['collection_id'],
        details['donation'], 'update', quantity, details['contract_type'], details['plastik_crypto_address'])
    } else {
      bidSignFixedFailed('Unable to fetch tokan details. Please try again later')
    }
  }

  window.initsignFixedPriceUpdateMultipleNft = async function initsignFixedPriceUpdate(artSupporterId) {
    hideAll()
    var pay_token_address = $('#collection_erc20_token_id option:selected, this').attr('address')
    var pay_token_decimal = $('#collection_erc20_token_id option:selected, this').attr('decimals')
    var account = await getCurrentAccount()
    var details = fetchCollectionDetails(null, pay_token_address, account, artSupporterId);

    if (details) {
      var quantity = details['owned_tokens'];
      await signSellOrder($('#instant-price').val(), details['pay_token_decimal'], details['pay_token_address'],
        details['token_id'], details['asset_address'], details['collection_id'],
        details['donation'], 'update', quantity, details['contract_type'], details['plastik_crypto_address'])
    } else {
      bidSignFixedFailed('Unable to fetch tokan details. Please try again later')
    }
  }

  window.updateSignFixedSuccess = function updateSignFixedSuccess(collectionId, contract_type = 'art_nft') {
    if(contract_type == 'multiple_nft'){
      $.magnificPopup.close();
      $.magnificPopup.open({
        closeOnBgClick: false,
        enableEscapeKey: false,
        items: {
          src: '#complete-listing'
        },
        type: 'inline',
        callbacks: {
          close: function() {
            let collectionDetail = redirectToCollectionDetail();
            window.location.href = collectionDetail + $('#collection_id').val()
          }
        }
      });
    }
    $("#submitPriceChange").click()
  }

  window.updateSignFixedFailed = function updateSignFailed(errorMsg) {
    toastr.error(errorMsg)
    hideAll()
    $('.approveDone').removeClass('hide')
    $('.signFixPriceRetry').removeClass('hide')
  }

  // COMMON METHODS FOR BIDDING MODEL
  function hideAll() {
    $('.allProgress').addClass('hide')
    $('.allDone').addClass('hide')
    $('.allRetry').addClass('hide')
    $('.allStart').addClass('hide')
  }

  $('#createOwnErc721, #deployContract, #collectionStepModal').on('hidden.bs.modal', function () {
    $("#collectionCreateForm :input").prop("disabled", false);
  })

  $('#placeBid').on('hidden.bs.modal', function () {
    $("#biddingForm :input").prop("disabled", false);
    $(".bid-now").trigger("click");
    // $("#Bid-modal").modal("show")
    // $.magnificPopup.close();
    $.magnificPopup.open({
      closeOnBgClick: false,
      enableEscapeKey: false,
      items: {
        src: '#Bid-modal'
      },
      type: 'inline'
    });
  })

  $('#placeBuy').on('hidden.bs.modal', function () {
    $("#buyForm :input").prop("disabled", false);
    // $("#Buy-modal").modal("show")
    // $.magnificPopup.close();
    $.magnificPopup.open({
      closeOnBgClick: false,
      enableEscapeKey: false,
      items: {
        src: '#Buy-modal'
      },
      type: 'inline'
    });
  })
  function convertBnbToWbnb(totalAmt, callBackType = 'Bid') {
    $('.allRetry').addClass('hide')
    $('.convertProgress').removeClass('hide')
    // $("#" + callBackType + "-modal").modal("hide")
    // $("#place" + callBackType).modal("show")
    $.magnificPopup.close();
    $.magnificPopup.open({
      closeOnBgClick: false,
      enableEscapeKey: false,
      items: {
        src: "#place" + callBackType
      },
      type: 'inline'
    });
    convertWBNB(totalAmt, callBackType)
  }

  $(document).on("click", ".convertRetry", function () {
    if ($("#bid-total-amt-dp").attr('bidAmt') === undefined) {
      convertBnbToWbnb($("#buy-total-amt-dp").attr('buyAmt'), 'Buy')
    } else {
      convertBnbToWbnb($("#bid-total-amt-dp").attr('bidAmt'), 'Bid')
    }
  })

  $(document).on("click", ".buy-now", function () {
    loadTokenBalance($('#buyContractAddress').text(), $('#buyContractDecimals').text());
  })

  window.initBuyModal = async function initBuyModal() {
    await loadTokenBalance($('#buyContractAddress').text(), $('#buyContractDecimals').text());
    show_modal('#Buy-modal')
  }

  $(document).on("click", ".bid-now", function () {
    var sym = $('#bid_currency :selected').text();
    var contractAddress = $('#bid_currency :selected').attr('address');
    var decimals = $('#bid_currency :selected').attr('decimals');
    loadTokenBalance(contractAddress, decimals, sym);
  })

  window.loadTokenBalance = async function loadTokenBalance(contractAddress, decimals, symbol) {
    var assetBalance = await tokenBalance(contractAddress, decimals);
    $('.ercCurBalance').text(assetBalance);
    $('#erc20_balance').text(assetBalance)
    $("#biding-asset-balance").text(mergeAmountSymbol(assetBalance, symbol));
  }

  window.fetchCollectionDetails = function fetchCollectionDetails(bidId, erc20Address, userAddress, artSupporterId) {
    var resp = false
    var erc20Address;
    $.ajax({
      url: '/collections/' + $('#collection_id').val() + '/fetch_details',
      type: 'GET',
      async: false,
      data: { bid_id: bidId, erc20_address: erc20Address, user_address: userAddress, art_supporter_id: artSupporterId},
      success: function (respVal) {
        resp = respVal['data']
      }
    });
    return resp;
  }

  window.calculateBid = async function calculateBid(feePercentage) {
    var sym = $('#bid_currency :selected').text();
    var contractAddress = $('#bid_currency :selected').attr('address');
    var decimals = $('#bid_currency :selected').attr('decimals');
    if ($('#bid_qty').val()) {
      var qty = $('#bid_qty').val() || 0;
    } else {
      var qty = 1;
    }
    var price = $('#bid_amt').val() || 0;
    var payAmt = multipliedBy(price, qty)
    var serviceFee = percentageOf(feePercentage, payAmt);
    var totalAmt = plusNum(payAmt, serviceFee);
    $("#bid-amt-dp").html(mergeAmountSymbol(serviceFee, sym))
    $("#bid-total-amt-dp").html(mergeAmountSymbol(totalAmt, sym));
    var biddingAssetBalance = await tokenBalance(contractAddress, decimals) || 0;
    $('#erc20_balance').text(biddingAssetBalance);
    $("#biding-asset-balance").text(mergeAmountSymbol(biddingAssetBalance, sym));
    $("#bid-total-amt-dp").attr('bidAmt', totalAmt);
    $("#bid-total-amt-dp").attr('bidPayAmt', payAmt);
  }

  window.calculateBuy = function calculateBuy(feePercentage) {
    var price = $('#buy_price').attr('price');
    var qty = $('#buy_qty').val() || 0;
    var payAmt = multipliedBy(price, qty)
    var serviceFee = percentageOf(feePercentage, payAmt);
    var totalAmt = plusNum(payAmt, serviceFee);
    // $("#buy-amt-dp").html(numToString(price))
    $("#buy-total-amt-dp").html(numToString(totalAmt));
    $("#buy-total-amt-dp").attr('buyAmt', numToString(totalAmt));
  }

  window.calculateBidExec = function calculateBidExec(thisBid) {
    var payAmt = thisBid.attr('price');
    var qty = thisBid.attr('qty');
    var serviceFee = $('#serviceFee').text()
    var serviceFee = percentageOf(serviceFee, payAmt);
    var totalAmt = minusNum(payAmt, serviceFee);
    $("#execServiceFee").html(numToString(serviceFee));
    if ($('#royaltyFee').attr('royaltyPercentage')) {
      var royaltyFeePer = $('#royaltyFee').attr('royaltyPercentage')
      var royaltyFee = percentageOf(royaltyFeePer, payAmt)
      $("#executeBidRoyaltyFee").html(royaltyFee);
      var totalAmt = minusNum(totalAmt, royaltyFee);
    }
    $("#executeBidFinalAmt").html(numToString(totalAmt));
  }

  $(document).on("click", ".change-price", function () {
    $(".change-price-modal-title").text($(this).text())
  })

  // Collection - Detail page buy and Place bid button action
  $(document).on("click", ".show-login-message", function (e) {
    toastr.error('Pending for admin approval.')
    e.preventDefault();
  });

  $(document).on('change', '.invoice_file_upload, .proof_invoice_file_upload, .way_bill_file_upload, .receipt_file_upload', function (e) {
    let $parentSelector = $(e.target.closest('div.upload-file-section'));
    if(e.currentTarget.files.length > 0) {
      let file = e.currentTarget.files[0];
      let imgExt = ['image/png', 'image/jpeg', 'image/gif', 'image/webp', 'application/pdf'];
      let ftype = file.type;
      let fsize = file.size / 1024 / 1024; // in MBs
      if (!imgExt.includes(ftype)) {
        $(this).val('');
        $("[data-wrong-file-id='" + $(this).attr('id') + "']").removeClass('d-none');
        if(!$("[data-el-id='" + $(this).attr('id') + "']").hasClass('d-none'))
        {
          $("[data-el-id='" + $(this).attr('id') + "']").addClass('d-none')
        }
        removeFileUI($parentSelector);
        return;
      } else if (fsize > 10){
        $(this).val('');
        $("[data-el-id='" + $(this).attr('id') + "']").removeClass('d-none');
        if(!$("[data-wrong-file-id='" + $(this).attr('id') + "']").hasClass('d-none'))
        {
          $("[data-wrong-file-id='" + $(this).attr('id') + "']").addClass('d-none')
        }
        removeFileUI($parentSelector);
        return;
      } else {
        $parentSelector.find('.white-list-extention').addClass('d-none');
        $parentSelector.find('.upload-success-msg').removeClass('d-none');
        $parentSelector.find('.file-limited').addClass('d-none');
        $parentSelector.find('.delete-input-file').removeClass('d-none');
        if(!$("[data-el-id='" + $(this).attr('id') + "']").hasClass('d-none'))
        {
          $("[data-el-id='" + $(this).attr('id') + "']").addClass('d-none')
        }

        if(!$("[data-wrong-file-id='" + $(this).attr('id') + "']").hasClass('d-none'))
        {
          $("[data-wrong-file-id='" + $(this).attr('id') + "']").addClass('d-none')
        }
      }
    } else {
      removeFileUI($parentSelector)
    }
  });

  function removeFileUI($parentSelector){
    $parentSelector.find('.white-list-extention').removeClass('d-none');
    $parentSelector.find('.upload-success-msg').addClass('d-none');
    $parentSelector.find('.file-limited').removeClass('d-none');
    $parentSelector.find('.delete-input-file').addClass('d-none');
  }

  // $('#nft_choose_file_selected, .nft_choose_file_selected-outside').on('click', function (e) {
  //   if(fileInvoice) fileInvoice.click();
  // })

  $('.nft_choose_file_selected-art').on('click', function (e) {
    $('#file-1').trigger('click')
  })

  // $('.delete-input-file').on('click', function (e) {
  //   fileInvoice.value = '';
  //   $('.invoice_file_upload').trigger('change');
  // })

  $(document).on('change', '.create_file_upload', function (e) {
    let fileId = $('#file-1');
    let previewId = $('#my-preview-section');
    let placeholderId = $('#placeholder');
    let notificationId = $('#uploadSuccessfully');

    if (this.files.length > 0) {
      // placeholderId.fadeOut(100);
      // notificationId.fadeIn(100);
    } else {
      closePreviewArtist(fileId, previewId, placeholderId, notificationId)
    }
  });

  $("#nft-collection__details--expand-btn").on("click", function (e) {
    $("#nft-collection__details").toggleClass("collapsed")
    $(".nft-collection__details--description").toggleClass('limit-text')
    if ($("#nft-collection__details--expand-btn i").hasClass("fa-chevron-up")) {
      $("#nft-collection__details--expand-btn i").removeClass("fa-chevron-up")
      $("#nft-collection__details--expand-btn i").addClass("fa-chevron-down")
    } else {
      $("#nft-collection__details--expand-btn i").removeClass("fa-chevron-down")
      $("#nft-collection__details--expand-btn i").addClass("fa-chevron-up")
    }
  })

  $("#nft-collection__details--expand-btn--mobile").on("click", function (e) {
    $("#nft-collection__details--mobile").toggleClass("collapsed")
    $("#recovery").toggleClass('lock')
    $(".nft-collection__details--description").toggleClass('limit-text')
    if ($("#nft-collection__details--expand-btn--mobile i").hasClass("fa-chevron-up")) {
      $("#nft-collection__details--expand-btn--mobile i").removeClass("fa-chevron-up")
      $("#nft-collection__details--expand-btn--mobile i").addClass("fa-chevron-down")
    } else {
      $("#nft-collection__details--expand-btn--mobile i").removeClass("fa-chevron-down")
      $("#nft-collection__details--expand-btn--mobile i").addClass("fa-chevron-up")
    }
  })

  $('[data-toggle="tooltip"]').tooltip();

  infiniteScrollingItem()

  $("#list-collections .search__results").on("change", function() {
    infiniteScrollingItem()
  })

  window.voucherSignSuccess = function voucherSignSuccess(collectionId) {
    toastr.success('Plastic Credit successfully created')
    window.location.href = '/collections/' + collectionId
  }

  $("#get-more-plastik").on("click", function (e) {
    $.magnificPopup.close();
    $("#crypto-widget button").trigger("click")
  })

  $(".get-plastik").on("click", function (e) {
    $.magnificPopup.close();
    $("#crypto-widget button").trigger("click")
  })

  $('.nft__sign__form, #nftCollectionForm').on('keyup keypress', function (e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
      return false;
    }
  });

  const redirectToCollectionDetail = () => {
    const prefix_path = $('#prefix_path').val();
    let url = (prefix_path == 'nft') ? '/nft/collectibles/' : '/collections/';
    return url;
  }

  const redirectToPurchaseSuccessPage = (collectionId) => {
    let giftAddress = $('#giftAddress').val();
    let sellerId = $('#seller-id').val();
    const prefix_path = $('#prefix_path').val();
    let url = (prefix_path == 'nft') ? '/nft/collectibles/' : '/collections/'
    url += collectionId + '/purchase_success';

    url += '?seller_id=' + sellerId ?? '';

    let kgNumber = $('#buy_qty').val() || $('#kg-input').val() || $('#select_amount_id').val() || $('#kg-number').val() || null
    if (kgNumber !== null) {
      url += '&kg_number=' + kgNumber
    }

    let amountNft = $('#nft-number-of-copies').val() || null
    if (amountNft !== null) {
      url += '&amount_nft=' + amountNft
    }

    if(giftAddress) url += '&gift_address=' + giftAddress;
    window.location.href = url;
  }

  window.showCardListingVideo = function showCardListingVideo(element, collection_id){
    event.preventDefault();
    $(element).hide();

    if($(`#collection_img_${collection_id}`).length < 1 ||  $(`#collection_video_${collection_id}`).length < 1)
    {
      return console.log('error', 'element not exist!')
    }

    $(`#collection_img_${collection_id}`).addClass('d-none')
    $(`#collection_video_${collection_id}`).removeClass('d-none');

    var video = document.getElementById(`collection_video_${collection_id}`);
    var video_url = $(`#video_avatar_url_${collection_id}`).val();

    video.setAttribute('src', video_url);
    video.setAttribute('type', 'video/mp4');

    video.load();
    video.play();
  }

  $(document).ready(function() {
    $('.view-more-button').click(function(event) {
      event.preventDefault();
      $('.nft-collection__details--recover').removeClass('--hide');
      $('.view-more-button').addClass("lock")
      $('.view-less-button').removeClass("lock")
    });

    $('.view-less-button').click(function(event) {
      event.preventDefault();
      $(event.target).closest('.sus-description-wrapper').find('.nft-collection__details--recover').slice(2).addClass('--hide');
      $('.view-less-button').addClass("lock")
      $('.view-more-button').removeClass("lock")
    });
  });

  const viewMoreButton = $('.view-more-button');
  const viewLessButton = $('.view-less-button');
  const fullDescription = $('.full-description');
  const truncateDescription = $('.truncate-description');
  function showFullDescription() {
    fullDescription.css('display', 'block');
    truncateDescription.css('display', 'none');

  }

  function showTruncatedDescription() {
    fullDescription.css('display', 'none');
    truncateDescription.css('display', 'block');
  }

  viewMoreButton.on('click', function(event) {
    event.preventDefault();
    showFullDescription();
  });

  viewLessButton.on('click', function(event) {
    event.preventDefault();
    showTruncatedDescription();
  });


  $('.buy-copies-button').hover(
    function() {
      $(this).removeClass('buy-copies-button--secondary');
      $('.buy-copies-button').not($(this)).addClass('buy-copies-button--secondary');
    },
  );

  $(document).ready(function() {
    const btnCheckoutNft = $('#btn-view-profile-owned');
  
    btnCheckoutNft.on('click', function() {
      $(this).css('pointer-events', 'none');
    });
  });

  // $(".nft-collection__cover--video-wrapper, .nft-collection__video--mobile").click(function() {
  //   var videoId = $(this).data("video-id");
  //   var videoType = $(this).data("video-type");

  //   if (videoType == "mp4") {
  //     $(this).html('<video width="100%" height="315" playsinline controls autoplay><source src="' + videoId + '" type="video/mp4"></video>');
  //   } else {
  //     $(this).html('<iframe width="560" height="315" src="https://www.youtube.com/embed/' + videoId + '?autoplay=1&rel=0&mute=1" frameborder="0" allowfullscreen allow="autoplay"></iframe>');
  //   }
  // });

  // Check first visit
  if ($('#is_first_visit').val() === "true") {
    $('.publish-collection-wrapper').magnificPopup({
      closeOnBgClick: false,
      enableEscapeKey: false,
      items: {
        src: '#publishCollectionModal'
      },
      type: 'inline'
    });
    $('.publish-collection-wrapper').magnificPopup('open');
  }

  $('#publishCollectionNow, #publishCollectionLater').on('click', function(e) {
    const collectionId = $("#collection_id").html()
    var targetId = $(e.target).data('action') || e.target.id
    var is_published_now = targetId === 'publishCollectionNow';
  
    var request = $.ajax({
      url: `/nft_collections/` + collectionId + '/published',
      type: 'POST',
      async: false,
      dataType: 'script',
      headers: {
        'X-CSRF-TOKEN': document.querySelector('[name=csrf-token]').content,
      },
      data: {
        nft_collection: { 
          published: is_published_now
        }
      },
    })
    request.done(function () {
      $('.nft-collection__cover__info__state--icon.unpublished').toggleClass('hide', is_published_now);
      $('.nft-collection__cover__info__state--icon.published').toggleClass('hide', !is_published_now);
      $("#collection-published input").prop("checked", is_published_now);
      $.magnificPopup.close();
    })
    request.fail(function (res) {
      const data = JSON.parse(res.responseText)

      setTimeout(() => {
        $(e.currentTarget).children("input").prop("checked", data['published']);
      }, 1000);
      toastr.error(data['msg'])
    })
  });


  $(".js-select-rp-multiple").select2({
    closeOnSelect: false,
    placeholder: "Select...",
    allowHtml: true,
    tags: true,
    templateResult: iformat,
    templateSelection: function (data, container) {
      $(container).find('.select2-selection__choice__remove').remove();
      return data.text;
    }
  });

  $(".js-select-rp-multiple").on("select2:open", function (e) {
    $(".select2-container").addClass("select2-container--collection-form");
  });

  // Hide preview input of select2
  $('.select2-selection__rendered').on("change", function() {
    var highlightedOption = $('li.select2-results__option').first();

    if (highlightedOption.attr('id') === undefined) {
        highlightedOption.hide();
    } else {
        highlightedOption.show();
    }
});


  function iformat(rp, country,) {
    var originalOption = rp.element;
    var originalOptionBadge = $(originalOption).data('country') || '';
    
    return $("<span class='select2-rp-name'>" + rp.text + '</span>' + '<span class="select2-rp-country">' + originalOptionBadge + '</span>');
  }

  $(".js-select-rp-multiple").on("change", function (e) {
    var selectedData = $(this).select2("data");
  
    if (selectedData && selectedData.length > 0) {
      $("#chooseDefaultRp").prop("checked", false);
    } else if (selectedData && selectedData.length === 0) {
      $("#chooseDefaultRp").prop("checked", true);
    }
  });
})
