import Web3 from "web3";
import Cropper from 'cropperjs';
import { destroySession } from "packs/ethereum/./web3"
import { getCropImage, initCropper, readURL } from "packs/utils/crop_image"
import { debounce, calculate_equivalence_plastic } from "./utils"

const smartChainNetworkId = parseInt(gon.smartChainNetworkId);
const celoChainNetworkId = parseInt(gon.celoChainNetworkId);
const isLoggedIn = gon.isLoggedIn;

$(document).ready(function () {

	// if (window.web3 && window.web3.eth) {
	// 	checkNetwork();
	// }

	window.setNetworkIdToCookie = (networkId) => {
		document.cookie = 'networkId' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
		document.cookie = `networkId=${networkId}; path=/ `
	}

	window.readNotification = function readNotification(elementId) {
		markReadNotification(elementId);
	}

	window.readAllNotification = function readAllNotification() {
		if ($('.new-notification').length > 0) $('.new-notification').removeClass('new-notification')

		markReadAllNotification();
	}

	function markReadNotification(notification_id) {
		$.ajax({
			url: '/users/read_notification',
			type: 'POST',
			async: false,
			data: { notification_id: notification_id }
		});
	}

	function markReadAllNotification() {
		$.ajax({
			url: '/users/read_all_notification',
			type: 'POST',
			async: false,
			dataType: 'script'
		});
	}

	window.addEventListener("ajax:before", (e) => {
		$(".loading-gif").show();
		$('body').css('overflow', 'hidden');
	});

	window.addEventListener("ajax:complete", (e) => {
		$(".loading-gif").hide();
		$('body').css('overflow', 'auto');
	});

	$(document).on("change", ".localeChange", function () {
		window.location.href = "/?locale=" + $(".localeChange option:selected").val()
	})

	toastr.options = {
		newestOnTop: true,
		progressBar: true,
		closeButton: true,
	}

	// $( ".cross" ).hide();
	// //$( ".menu" ).hide();
	// $( ".hamburger" ).click(function() {
	// 	$( ".menu" ).slideToggle( "slow", function() {
	// 		$( ".hamburger" ).hide();
	// 		$( ".cross" ).show();
	// 	});
	// });

	// $( ".cross" ).click(function() {
	// 	$( ".menu" ).slideToggle( "slow", function() {
	// 		$( ".cross" ).hide();
	// 		$( ".hamburger" ).show();
	// 	});
	// });

	// // header carousel

	// $('.filters ul li').click(function(){
	//   $('.filters ul li').removeClass('active');
	//   $(this).addClass('active');

	//   var data = $(this).attr('data-filter');
	//   $grid.isotope({
	//     filter: data
	//   })
	// });

	// var $grid = $(".grid").isotope({
	//   itemSelector: ".all",
	//   percentPosition: true,
	//   masonry: {
	//     columnWidth: ".all"
	//   }
	// });

	$("#sustainability_profile_images_").change(function () {
		$('#sustainabilityProfileImagePreview').html('Uploaded images successfully!')
	});

	$("#sustainability_profile_video").change(function () {
		$('#sustainabilityProfileVideoPreview').html('Uploaded video successfully!')
	});

	$("#imageTemptUpload").change(function () {
		$(".crop-image").magnificPopup('open')

		let input = $("#imageTemptUpload")[0]
		let aspectRatio = 1 / 1
		let minCropBoxWidth = 440
		let minCropBoxHeight = 400
		let imageUploadId = 'imageUpload'
		let imagePreviewId = 'imagePreview'
		let successNotify = ''

		getCropImage(input, aspectRatio, minCropBoxWidth, minCropBoxHeight, imageUploadId, imagePreviewId, successNotify)
	});

	$("#bannerTemptUpload").change(function () {
		$(".crop-image").magnificPopup('open')

		let input = $("#bannerTemptUpload")[0]
		let aspectRatio = 2.1
		let minCropBoxWidth = 840
		let minCropBoxHeight = 400
		let imageUploadId = 'bannerUpload'
		let imagePreviewId = 'bannerPreview'
		let successNotify = ''

		getCropImage(input, aspectRatio, minCropBoxWidth, minCropBoxHeight, imageUploadId, imagePreviewId, successNotify)
	});

	$("#imageRecyclerTemptUpload").change(function () {
		$(".crop-image").magnificPopup('open')

		let input = $("#imageRecyclerTemptUpload")[0]
		let aspectRatio = 1 / 1
		let minCropBoxWidth = 440
		let minCropBoxHeight = 400
		let imageUploadId = 'imageRecyclerUpload'
		let imagePreviewId = 'imageRecyclerPreview'
		let successNotify = 'Uploaded picture successfully!'

		getCropImage(input, aspectRatio, minCropBoxWidth, minCropBoxHeight, imageUploadId, imagePreviewId, successNotify)
	});

	$(".get-started-avatar #user_attachment").change(function () {
		const callback = () => {
		  const avatarFileError = document.getElementsByClassName('avatarFileError')[0]

			if (this.validity.valid && avatarFileError) {
				avatarFileError.textContent = '';
				avatarFileError.className = 'error avatarFileError';
			} else {
				file1ShowError(this, avatarFileError);
			}
		}

		$(".crop-image").magnificPopup('open')

		let input = $("#user_attachment")[0]
		let aspectRatio = 1 / 1
		let minCropBoxWidth = 440
		let minCropBoxHeight = 400
		let imageUploadId = 'user_attachment'
		let imagePreviewId = 'imageRecyclerPreview'
		let successNotify = 'Uploaded picture successfully!'

		getCropImage(input, aspectRatio, minCropBoxWidth, minCropBoxHeight, imageUploadId, imagePreviewId, successNotify, callback)
	});

	$('#imageRecyclerPreview').click(function () {
		$("#imageRecyclerTemptUpload").trigger('click')
	})

	$("#bannerRecyclerTemptUpload").change(function () {
		$(".crop-image").magnificPopup('open')

		let input = $("#bannerRecyclerTemptUpload")[0]
		let aspectRatio = 4.78
		let minCropBoxWidth = 1434
		let minCropBoxHeight = 300
		let imageUploadId = 'bannerRecyclerUpload'
		let imagePreviewId = 'bannerRecyclerPreview'
		let successNotify = 'Uploaded cover successfully!'

		getCropImage(input, aspectRatio, minCropBoxWidth, minCropBoxHeight, imageUploadId, imagePreviewId, successNotify)
	});

	$('#bannerRecyclerPreview').click(function () {
		$("#bannerRecyclerTemptUpload").trigger('click')
	})

	$("#createCollectionId, #createCollectionIdMobile").on("click", function () {
		$(".select-collection").magnificPopup({
			callbacks: {
				open: function() {
					$(".header, .header-bar--mobile").addClass("header--pop-up");
					$('.mfp-wrap').addClass('mfp-wrap-pop-up');
					$('.mfp-bg').addClass("mfp-ready-pop-up");
					$('.create-artist').addClass('create-artist__mobile-pop-up');
					$(".js-select-rp-multiple").select2({
						closeOnSelect : false,
						placeholder : "Select...",
						allowHtml: true,
						allowClear: true,
						tags: true,
						templateResult: iformat,
					});
				
					$(".js-select-rp-multiple").on("select2:open", function (e) {
						$(".select2-container").addClass("select2-container--collection-form");
					});
				
				  $('.select2-selection__rendered').on("change", function() {
            var highlightedOption = $('li.select2-results__option').first();
        
            if (highlightedOption.attr('id') === undefined) {
                highlightedOption.hide();
            } else {
                highlightedOption.show();
            }
          });

          function iformat(rp, country,) {
            var originalOption = rp.element;
            var originalOptionBadge = $(originalOption).data('country') || '';
            
            return $("<span class='select2-rp-name'>" + rp.text + '</span>' + '<span class="select2-rp-country">' + originalOptionBadge + '</span>');
          }

				},
				close: function() {
					$(".header, .header-bar--mobile").removeClass("header--pop-up");
					$('.mfp-wrap').removeClass('mfp-wrap-pop-up');
					$('.mfp-bg .mfp-ready').removeClass("mfp-ready-pop-up");
					$('.create-artist').removeClass('create-artist__mobile-pop-up');
				}
			}
		}).magnificPopup('open');;
	})

	$("#closeBtnSelectCollection").on("click", function () {
		$(".select-collection").magnificPopup("close");
	})

	$("#triggerNftCollectionDelete").on("click", function () {
		$(".nft-collection-delete").magnificPopup("open");
	})

	$("#closeNftCollectionDeleteModal").on("click", function () {
		$(".nft-collection-delete").magnificPopup("close");
	})

	function AjaxUploadAvatar(formSelector) {
		let formData = new FormData()

		if ($("#imageRecyclerUpload")[0].files.length) formData.append('user[attachment]', $("#imageRecyclerUpload")[0].files[0]);
		if ($("#bannerRecyclerUpload")[0].files.length) formData.append('user[banner]', $("#bannerRecyclerUpload")[0].files[0]);

		$.ajax({
			type: "PUT",
			url: $(formSelector).children("form").attr('action').replace('should_redirect_after_success=true', ''),
			data: formData,
			processData: false,
			contentType: false,
			error: function (jqXHR, textStatus, errorMessage) {
				console.log(errorMessage);
			},
			success: function (data) { console.log(data) }
		});
	}

	function readURLSingle(input, file, previewSection, imagePreview, closePreviewBtn, placeholder, fileId, chooseFile, coverImg) {
		var ftype = file.type;
		var fsize = file.size / 1024 / 1024; // in MBs
		let isInvalid = false
		if (fsize > 10 && !previewSection.is($('#nft-create-large-preview'))) {
			isInvalid = true
			fileId.val('');
			return toastr.error('Invalid file size!. Must be less than 10MB');
		} else if (fsize > 10 && previewSection.is($('#nft-create-large-preview'))){
			isInvalid = true
			fileId.val('');
		}
		if (!isInvalid) {
			$('.nft-create__form--box').addClass('hide')
			$('.nft-create-large-preview__wrapper').removeClass('hide')
			var imgExt = ['image/png', 'image/jpeg', 'image/gif', 'image/webp', 'application/pdf'];
			var audExt = ['audio/mp3', 'audio/webm', 'audio/mpeg'];
			var vidExt = ['video/mp4', 'video/webm'];
			if (input.files && input.files[0]) {
				var reader = new FileReader();

				reader.onload = function (e) {
					if (imgExt.includes(ftype) && ftype !== 'application/pdf' ) {
						let img = $('<img>').attr('src', e.target.result).css({
							"border-radius": "4px",
							"height": "260px",
							"width": "100%",
							"object-fit": "cover"
						})

						let mediaQuery = window.matchMedia("(max-width: 960px)");
						if (mediaQuery.matches) {
							img.css({
								"height": "240px"
							});
						} else {
							img.css({
								"height": "260px"
							});
						}
						previewSection.empty().append(img);
						previewSection.css({
							"position": "relative",
						});
						previewSection.hide();
						previewSection.fadeIn(650);
					} else if (ftype === 'application/pdf') {
						var pdfUrl = e.target.result;
						var pdfHtml = '<iframe id="nft-create-large-preview__iframe" src="' + pdfUrl + '" type="application/pdf" width="100%" height="100%"> \
														</iframe>';
						previewSection.html(pdfHtml);
						previewSection.hide();
						previewSection.fadeIn(650);
					} else if (coverImg) {
						return toastr.error('Invalid file type!');
					} else if (audExt.includes(ftype)) {
						$('.coverUpload').removeClass("hide");
						// $('#file-2').prop('required', true);
						previewSection.hide();
						previewSection.fadeIn(650);
						imagePreview.html('<audio width="300" height="300" controls><source src="mov_bbb.mp4" id="audio_here"> </audio>');
						imagePreview.css({ 'height': '55px' });
						$('#audio_here')[0].src = URL.createObjectURL(input.files[0]);
						$('#audio_here').parent()[0].load();
					} else if (vidExt.includes(ftype)) {
						$('.coverUpload').removeClass("hide");
						previewSection.empty().append('<video controls><source src="mov_bbb.mp4" id="video_here"> </video>');
						previewSection.css({
							"position": "relative",
						});
						if (input.files && input.files[0] && input.files[0] instanceof Blob) {
							$('#video_here').attr('src', URL.createObjectURL(input.files[0]));
							$('#video_here').prop('load', true);
						} else {
							return toastr.error('Invalid file or file type');
						}
						previewSection.hide();
						previewSection.fadeIn(650);
					} else {
						return toastr.error('Invalid file type!');
					}
					imagePreview.css({
						'width': '300px',
						'background-size': 'cover',
						'background-repeat': 'no-repeat',
						'background-position': 'center',
						'margin-left': 'auto',
						'margin-right': 'auto',
						'border-radius': '3px'
					});
					closePreviewBtn.css('display', 'inline-block');
					placeholder.fadeOut(100);
					fileId.fadeOut(100);
					chooseFile.fadeOut(100);
					imagePreview.hide();
					imagePreview.fadeIn(650);
					$('.prev_i__card--info').removeClass('hide')
					$('.prev_i__empty').hide();
				}

				reader.readAsDataURL(input.files[0]);
			}
		}
	}

	$("#file-1").change(function (e) {
		var file = e.currentTarget.files[0];
		var previewSection = $('#my-preview-section');
		var imagePreview = $('#imagePreviewRes');
		var closePreviewBtn = $('#close-preview-button');
		var placeholder = $('#placeholder');
		var fileId = $('#file-1');
		var chooseFile = $('#choose_file_selected');
		if (document.getElementById('groupFile1') && $('#formArtistPreviewLargeId').css('display') == 'none') {
			// $('#groupFile1').addClass('hide')
			$('#formArtistPreviewSmallId').css('display', 'block');
			readURLSingle(this, file, $('#formArtistPreviewSmallId'), imagePreview, closePreviewBtn, placeholder, fileId, chooseFile, false);
		} else {
			readURLSingle(this, file, previewSection, imagePreview, closePreviewBtn, placeholder, fileId, chooseFile, false);

			readURLSingle(this, file, $('#nft-create-large-preview'), imagePreview, closePreviewBtn, placeholder, fileId, chooseFile, false);
		}
	});

	$(`#itemname,
		.nft__sign__input#instant-price,
		.nft__sign__input#guarantee,
		#no_of_copies,
		#description
	`).on('keyup', function(e) {
		updatePreviewValue($(this))
	})

	$(document).on("keyup", ".collection_utilities_attr", function () {
		let attribute = "";
		$(".collection_utilities_attr").each(function() {
			if ($(this).val() != "") {
				attribute += '<li>' + $(this).val() + '</li>';
			}
		});

		if (attribute) {
			$(`[data-preview="description"]`, ".prev_i ").html(attribute);
		}
	})
	function updatePreviewValue(fieldSelector) {
		const previewVal = fieldSelector.data('preview')
		if(previewVal) {
			if(fieldSelector.val()) {
				// re-calculate plastic bottles value
				if (previewVal === 'quantity') {
					let quantity = fieldSelector.val();
					$(`[data-preview="amount_plastic_bottles"]`, ".prev_i").text(calculate_equivalence_plastic(quantity, 'plastic_bottle'));
					
					// update tooltip content
					quantity ??= 0;
					let tooltipEle = $(".collection-tooltip-icon.preview");
					let tooltipContent = $(tooltipEle.attr("data-original-title")); // convert title to html element
					$(`[data-preview=quantity]`, tooltipContent).text(quantity);
					tooltipEle.attr("data-original-title", tooltipContent.prop('outerHTML'));
				}

				if(previewVal == 'price' || previewVal == 'quantity'){
					const plasticsPerCopy = $('.nft__sign__input#guarantee').val()
					const price = $('.nft__sign__input#instant-price').val()

					const collectionPrice = parseInt(price) + parseInt(plasticsPerCopy);

					$(`[data-preview="price"]`, ".prev_i ").html('$' + new Intl.NumberFormat().format(parseInt(collectionPrice) || 0))
					$(`[data-preview="quantity"]`, ".prev_i ").html(parseInt(plasticsPerCopy))
				}else{
					if (!$('.collection_utilities_attr').val()) {
						$(`[data-preview="${previewVal}"]`, ".prev_i ").html(fieldSelector.val())
					}
					
       		}
			} else {
				const fieldValue = displayDefaultPreviewValue(previewVal)
				$(`.prev_i [data-preview="${previewVal}"]`).html(fieldValue)
			}
		}

	}

	const displayDefaultPreviewValue = (field) => {
		switch(field) {
			case 'title':
				return 'Untitled'
			case 'price':
				return 'Not for sale'
			case 'quantity':
				return '1';
			case 'no_of_copies':
				return '0';
			default:
				return '';
		}
	}

	$("#file-2").change(function (e) {
		var file = e.currentTarget.files[0];
		var previewSection = $('#my-preview-section');
		var imagePreview = $('#imagePreviewRes2');
		var closePreviewBtn = $('#close-preview-button2');
		var placeholder = $('#placeholder2');
		var fileId = $('#file-2');
		var chooseFile = $('#choose_file_selected2');
		readURLSingle(this, file, previewSection, imagePreview, closePreviewBtn, placeholder, fileId, chooseFile, true);
	});

	function closePreview(previewSection, imagePreview, closePreviewBtn, placeholder, fileId, chooseFile) {
		fileId.val('');
		placeholder.fadeIn(100);
		fileId.fadeIn(100);
		chooseFile.fadeIn(100);
		chooseFile.html('Choose file');
		imagePreview.css({
			'width': 'auto',
			'height': 'auto',
			'background-size': 'cover',
			'background-repeat': 'no-repeat',
			'background-position': 'center',
			'margin-left': 'auto',
			'margin-right': 'auto'
		});
		closePreviewBtn.css('display', 'none');
		imagePreview.css('background-image', 'none');
		imagePreview.html('');
		previewSection.css('background-image', 'none');
		previewSection.html('');
	}

	$('#close-preview-button').click(function () {
		var previewSection = $('#my-preview-section');
		var imagePreview = $('#imagePreviewRes');
		var closePreviewBtn = $('#close-preview-button');
		var placeholder = $('#placeholder');
		var fileId = $('#file-1');
		var chooseFile = $('#choose_file_selected');
		closePreview(previewSection, imagePreview, closePreviewBtn, placeholder, fileId, chooseFile);
		$('.coverUpload').addClass("hide");
		$('#file-2').prop('required', false);
	});

	$('#close-preview-button2').click(function () {
		var previewSection = $('#my-preview-section');
		var imagePreview = $('#imagePreviewRes2');
		var closePreviewBtn = $('#close-preview-button2');
		var placeholder = $('#placeholder2');
		var fileId = $('#file-2');
		var chooseFile = $('#choose_file_selected2');
		closePreview(previewSection, imagePreview, closePreviewBtn, placeholder, fileId, chooseFile);
	});

	// $('input[name=chooseCollection]').change(function(){
	//     var value = $( 'input[name=chooseCollection]:checked' ).val();
	//     alert(value);
	// });

	$('#token-maximize').click(function () {
		$('.token-section').addClass('main-div-js-element');
		$('.display-section-heart-maximize').css('display', 'none');
		$('.display-section-heart-minimize').css('display', 'block');
		$('.heading-token-details-mm').css('display', 'block');
		$('.token-image').addClass('img-div-js-element');
		$('.token-image img').addClass('img-js-element');
		$('.image_get_attachment').addClass('height-auto-token');
	});

	$('#token-minimize').click(function () {
		$('.token-section').removeClass('main-div-js-element');
		$('.display-section-heart-maximize').css('display', 'flex');
		$('.display-section-heart-minimize').css('display', 'none');
		$('.heading-token-details-mm').css('display', 'none');
		$('.token-image').removeClass('img-div-js-element');
		$('.token-image img').removeClass('img-js-element');
		$('.image_get_attachment').removeClass('height-auto-token');
	});

	$('#header-carousel').owlCarousel({
		loop: false,
		margin: 10,
		dots: false,
		nav: true,
		responsive: {
			0: {
				items: 1
			},
			600: {
				items: 1
			},
			1000: {
				items: 1
			}
		}
	});

	window.checkNetwork = async function checkNetwork() {
		const netid = await window.getChainId()
		const account = await window.getCurrentAccount()

		console.log("Account ", account)
		console.log("netid", netid)

		window.setNetworkIdToCookie(netid);

		if (netid == celoChainNetworkId) {
			$('.celo-collection-default').removeClass('hide');
			$('.celo-collection-default > input[type="hidden"]').removeAttr('disabled');
			$('.select-bsc').addClass('select-disabled');
			$('.select-bsc').attr('data-toggle', 'tooltip');
			$('.select-bsc').attr('data-content', 'You are not able to change the blockchain.').tooltip();;
			$('#nftCollectionBnb').attr('disabled', 'disabled');
			$('#nftCollectionCelo').trigger('click');
			$(`.network-${smartChainNetworkId}`).remove();
		} else if (netid == smartChainNetworkId) {
			$('.bsc-collection-default').removeClass('hide');
			$('.bsc-collection-default > input[type="hidden"]').removeAttr('disabled');
			$('.select-celo').addClass('select-disabled');
			$('.select-celo').attr('data-toggle', 'tooltip');
			$('.select-celo').attr('data-content', 'You are not able to change the blockchain.');
			$('#nftCollectionCelo').attr('disabled', 'disabled');
			$('#nftCollectionBnb').trigger('click');
			$(`.network-${celoChainNetworkId}`).remove()
		}

		if (account && (netid == smartChainNetworkId || netid == celoChainNetworkId)) {
			window.updateBalance()
		}
	}

	window.clearToastr = async function clearToastr() {
		$('.toast').remove();
	}
});
