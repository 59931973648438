import { initDropzone } from './utils/dropzone';

$(document).ready(function() {
  if ($('#user_email').val() !== '') {
    $('.notification-checkbox').removeAttr("disabled")
  } else {
    $('.notification-checkbox').attr("disabled", true)
    $('.notification-checkbox').prop('checked', false)
  }

  if($('#update-profile-form').length) {
    if ($('#user_email').val() !== '') {
      $('.notification-checkbox').removeAttr("disabled")
    } else {
      $('.notification-checkbox').attr("disabled", true)
      $('.notification-checkbox').prop('checked', false)
    }

    $('#user_email').on('change', function() {
      if ($('#user_email').val() !== '') {
        $('.notification-checkbox').removeAttr("disabled")
        $('.notification-checkbox').prop('checked', true)
      } else {
        $('.notification-checkbox').prop('checked', false)
        $('.notification-checkbox').attr("disabled", true)
      }
    })

    Dropzone.autoDiscover = false;

    const dropzone = new Dropzone("#upload-profile-avatar", {
      url: $("#update-profile-form").attr("action"),
      method: "put",
      paramName: "image",
      maxFilesize: 2,
      acceptedFiles: 'image/*',
      previewsContainer: ".dropzone-previews",
      autoProcessQueue: false,
      addedfile: (file) => {
        let container = new DataTransfer();

        container.items.add(new File([file], file.name));
        document.querySelector('#imageRecyclerTemptUpload').files = container.files;
        $("#imageRecyclerTemptUpload").trigger("change")

        $("#imageRecyclerPreview").html("Profile picture successfully uploaded!")
      },
    });
  }

  // DOM is fully loaded, so enable the button
  $('#recycler-sign-btn-submit').prop('disabled', false);
})
