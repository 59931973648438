const debounce = (func, wait) => {
  var timeout;

  return function() {
    var context = this,
        args = arguments;

    var executeFunction = function() {
      func.apply(context, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(executeFunction, wait);
  };
};

// (int, string, int) => string
const calculate_equivalence_plastic = (weight_supported, type, precision = 4) => {
  const numberFormatter = new Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: precision });

  weight_supported = weight_supported ?? 0;
  let value = 0;
  switch (type) {
    case 'plastic_straw':
      value = (weight_supported / gon.straw_weight);
      break;
    case 'plastic_bottle':
      value = (weight_supported / gon.bottle_weight);
      break;
    case 'blue_wale':
      value = (weight_supported / gon.wale_weight);
      break;
    case 'plastic_bag':
      value = (weight_supported / gon.bag_weight); 
      break;
    default:
      console.error("Unsupported type: " + type)
      value = 0;
  }

  return numberFormatter.format(parseInt(value));
}

export { debounce, calculate_equivalence_plastic }
