import Cropper from 'cropperjs';

$(document).ready(function () {
  for (let i = 0; i < 3; i++) {
    $(`#upload-image-${i}`).on('change', function (e) {
      $('.crop-image').magnificPopup('open');

      const label = i
      const previewSection = $(`#preview-${i}`);
      const fileId = $(`#upload-image-${i}`);
      const uploadId = $(`#upload-${i}`);
      const closePreviewBtn = $(`#close-btn-${i}`);
      const file = e.currentTarget.files[0];

      let aspectRatio = (i % 2 === 0 ? 1 / 1 : 2 / 1)
      let minCropBoxWidth = (i % 2 === 0 ? 440 : 800)
      let minCropBoxHeight = 400
      let imagesUploadId = '#sustainability_profile_images_'

      getCropImage(label, file, fileId, fileId[0], aspectRatio, minCropBoxWidth, minCropBoxHeight, imagesUploadId, previewSection, uploadId, closePreviewBtn)
    })

    $(`#close-btn-${i}`).on('click', function () {
      const fileId = $(`#upload-image-${i}`);
      const uploadId = $(`#upload-${i}`);
      const previewSection = $(`#preview-${i}`);
      const closePreviewBtn = $(`#close-btn-${i}`);
      const imagesId = document.querySelector('#sustainability_profile_images_').files
      let container = new DataTransfer();

      for (let index = 0; index < imagesId.length; index++) {
        if (imagesId[index].name !== `upload_image_${i}.png`) {
          container.items.add(new File([imagesId[index]], imagesId[index].name));
        }
      }

      document.querySelector('#sustainability_profile_images_').files = container.files;

      if (closePreviewBtn.parents()[0] && closePreviewBtn.parents()[0].title) {
        let removeImageIds = $('#sustainability_profile_remove_image_indexs').val();
        let removeImageId = closePreviewBtn.parents()[0].title;

        if (!removeImageIds.includes(removeImageId)) {
          $('#sustainability_profile_remove_image_indexs').val(removeImageIds + removeImageId);
        }
      }

      closePreviewImages(fileId, uploadId, previewSection, closePreviewBtn)
    })
  }

  function getCropImage(label, file, fileId, input, aspectRatio, minCropBoxWidth, minCropBoxHeight, imagesUploadId, previewSection, uploadId, closePreviewBtn) {
    if (input.files && input.files[0]) {
      const reader = new FileReader
      reader.onload = function (e) {
        $('#imageCropper').attr('src', e.target.result)
      };
      reader.readAsDataURL(input.files[0]);
      setTimeout(initCropper(label, file, fileId, aspectRatio, minCropBoxWidth, minCropBoxHeight, imagesUploadId, previewSection, uploadId, closePreviewBtn), 1000);
    }
  }

  function initCropper(label, file, fileId, aspectRatio, minCropBoxWidth, minCropBoxHeight, imagesUploadId, previewSection, uploadId, closePreviewBtn) {
    return () => {
      const image = document.getElementById('imageCropper');
      const cropper = new Cropper(image, {
        aspectRatio: aspectRatio,
        minCropBoxWidth: minCropBoxWidth,
        minCropBoxHeight: minCropBoxHeight
      });

      document.getElementById('crop_button').addEventListener('click', function () {
        const canvas = cropper.getCroppedCanvas()

        canvas?.toBlob(function (blob) {
          if (blob) {
            let imagesId = document.querySelector(imagesUploadId).files
            let container = new DataTransfer();
            for (let index = 0; index < imagesId.length; index++) {
              container.items.add(new File([imagesId[index]], imagesId[index].name));
            }
            container.items.add(new File([blob], `upload_image_${label}.png`));
            document.querySelector(imagesUploadId).files = container.files;

            cropper?.destroy();
            $.magnificPopup.close();
            readURLSingleUser(fileId[0], file, previewSection, fileId, uploadId, closePreviewBtn);
          }
        });
      })

      document.getElementsByClassName('mfp-close')[0].addEventListener('click', function () {
        cropper?.destroy();
      })
    }
  }

  function readURLSingleUser(input, file, previewSection, fileId, uploadId, closePreviewBtn) {
    const ftype = file.type;
    const fsize = file.size / 1024 / 1024; // in MBs
    if (fsize > 30) {
      return toastr.error('Invalid file size!. Must be less than 30MB');
    }
    const imgExt = ['image/png', 'image/jpeg', 'image/gif', 'image/webp'];

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        if (imgExt.includes(ftype)) {
          uploadId.css('display', 'none');
          previewSection.css('background-image', 'url(' + e.target.result + ')');
          previewSection.css({
            'background-size': 'cover',
            'background-repeat': 'no-repeat',
            'background-position': 'center',
            'margin-left': 'auto',
            'margin-right': 'auto',
          });
          previewSection.hide();
          previewSection.fadeIn(650);
          closePreviewBtn.css('display', 'flex')
        } else {
          return toastr.error('Invalid file type!');
        }
        fileId.fadeOut(100);
      }
      reader.readAsDataURL(input.files[0]);
    }
  }

  function closePreviewImages(fileId, uploadId, previewSection, closePreviewBtn) {
    fileId.val = '';
    uploadId.fadeIn(100);
    previewSection.css({
      'display': 'none',
      'background-image': 'none'
    });
    closePreviewBtn.css('display', 'none');
  }

  /*==============================
  Modal
  ==============================*/
  $('.open-video, .open-map').magnificPopup({
    disableOn: 0,
    fixedContentPos: true,
    type: 'iframe',
    preloader: false,
    removalDelay: 300,
    mainClass: 'mfp-fade',
    closeOnBgClick: false,
    enableEscapeKey: false,
  });

  $('.asset__img').magnificPopup({
    fixedContentPos: true,
    type: 'image',
    closeOnContentClick: true,
    closeBtnInside: false,
    mainClass: 'my-mfp-zoom-in',
    closeOnBgClick: false,
    enableEscapeKey: false,
    image: {
      verticalFit: true
    },
    zoom: {
      enabled: true,
      duration: 400
    }
  });

  $('.open-modal--share').magnificPopup({
    fixedContentPos: true,
    overflowY: 'auto',
    type: 'inline',
    preloader: false,
    modal: false,
    removalDelay: 300,
    closeOnBgClick: true,
    enableEscapeKey: false,
    callbacks: {
      open: function() {
        $("img.lazyload").lazyload();
      },
    }
  });

  $('.open-modal--suppoters').magnificPopup({
    fixedContentPos: true,
    fixedBgPos: true,
    overflowY: 'auto',
    type: 'inline',
    preloader: false,
    modal: false,
    closeOnBgClick: true,
    enableEscapeKey: false,
  });

  window.connectWallet = function connectWallet() {
    $('.open-modal').magnificPopup('open');
  }

	var checkModalBarca = $('.mfp-viewable-background').length ? '' : 'my-mfp-zoom-in';
  $('.open-modal').magnificPopup({
    fixedContentPos: true,
    fixedBgPos: true,
    overflowY: 'auto',
    type: 'inline',
    preloader: false,
    focus: '#username',
    modal: false,
    removalDelay: 300,
    mainClass: checkModalBarca,
    closeOnBgClick: true,
    enableEscapeKey: false,
  });

  $('.crop-image').magnificPopup({
    fixedContentPos: true,
    overflowY: 'auto',
    type: 'inline',
    preloader: false,
    modal: false,
    removalDelay: 300,
    closeOnBgClick: false,
    enableEscapeKey: false,
  });

  $('.select-collection').magnificPopup({
    fixedContentPos: true,
    overflowY: 'auto',
    type: 'inline',
    preloader: false,
    modal: false,
    removalDelay: 300,
    closeOnBgClick: true,
    showCloseBtn: false,
    enableEscapeKey: false,
  });

  $('.nft-collection-delete').magnificPopup({
    fixedContentPos: true,
    overflowY: 'auto',
    type: 'inline',
    preloader: false,
    modal: false,
    removalDelay: 300,
    closeOnBgClick: true,
    showCloseBtn: false,
    enableEscapeKey: false,
  });

  $('.modal__close, .modal_cancel_close').on('click', function (e) {
    e.preventDefault();
    $.magnificPopup.close();
  });
})
