import { createUserSession } from "./web3"
import axios from "axios";

async function initMagic(selectChainId = null) {
  // default connect to CELO
  const chainId = parseInt(selectChainId) || parseInt(gon.celoChainNetworkId)

  let rpcUrl;

  switch(chainId) {
    case 56:
      rpcUrl = 'https://bsc-dataseed.binance.org/'
      break;
    case 97:
      rpcUrl = 'https://data-seed-prebsc-1-s1.binance.org:8545'
      break;
    case 44787:
      rpcUrl = 'https://alfajores-forno.celo-testnet.org'
      break;
    default:
      rpcUrl = 'https://forno.celo.org'
    break;
  }

  const options = {
    extensions: [new MagicOAuthExtension()],
    network: {
      rpcUrl,
      chainId
    },
    deferPreload: true
  }

  window.localStorage.setItem('MAGIC_NETWORK_CHAINID', JSON.stringify(chainId))

  return new Magic(gon.magicAPIKey, options)
}

// async function magicOauthLogin(provider='google') {
//   const magic = await initMagic()

//   window.localStorage.setItem('MAGIC_SOCIAL_LOGIN_HREF', window.location.href)

//   await magic.oauth.loginWithRedirect({
//     provider: provider,
//     redirectURI: `${window.location.origin}/sessions/oauth_callback`,
//   });
// }

// $(document).on('click', '.connect-wallet--socials',async function(e) {
//   const provider = $(this).data('provider')

//   await magicOauthLogin(provider)

//   function receiveMessageFromLoginPopUp(event) {
//     if (event.origin !== window.location.origin) return;

//     if(event.data) {
//       if(event.data.oauth_login == 'success') window.location.reload();
//     }
//   }

//   window.addEventListener("message", receiveMessageFromLoginPopUp, false);
// })

window.handleAfterMagicLoginRedirect = async function handleAfterMagicLoginRedirect(didToken = '') {
  const magic = await initMagic()
  await magic.auth.loginWithCredential(didToken);
  const { publicAddress } = await magic.user.getInfo();
  try {
    window.smartlook('identify', publicAddress, gon.currentUserInfo)
  } catch(e) {
    console.log(`===Smartlook error on identify: ${e} ===`)
  }

  toastr.success('Successfully logged in')
  window.location.href = window.localStorage.getItem('MAGIC_SOCIAL_LOGIN_HREF') || '/'
}

window.handleAfterSocialLoginRedirect = async function handleAfterSocialLoginRedirect(didToken = '') {
  const magic = await initMagic()
  try {
    const result = await magic.oauth.getRedirectResult();

    console.log('magic_result', result)

    const userEmail = result.oauth.userInfo.email
    const userAddress = result.magic.userMetadata.publicAddress
    const userName = result.oauth.userInfo.name
    const plastikBalanace = await window.updateBalance();
    await createUserSession(userAddress, plastikBalanace, false, { email: userEmail, name: userName, magic_user_type: result.oauth.provider || 'email'})
    toastr.success('Successfully logged in')
    window.location.href = window.localStorage.getItem('MAGIC_SOCIAL_LOGIN_HREF') || '/'
  } catch (e) {
    // handle error here
    console.log(e)
    if (e.message.indexOf('-32603') > -1) {
      toastr.error('Please login again')
      window.disconnect()
    }

    
  }
}

const validateEmail = (email) => {
  if(email) {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  return false
};

window.changeMagicEmail = async function changeMagicEmail() {
  const magic = await initMagic()
  const userMail = $("#change-email__container--input__form--email").val().trim()
  const config = {
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }

  $('.change-email__container--input__form--error').removeClass('active')

  if (validateEmail(userMail)) {
    try {
      await magic.user.updateEmail({ email: userMail, showUI: true });
      const userMetadata = await magic.user.getInfo();

      await axios.put(`/users/${userMetadata.publicAddress}/update_magic_email`, {
        email: `${userMail}`,
      }, config).then((response) => {
        toastr.success('Update email successfully.')
        setTimeout(() => window.location.reload(), 2000)
      })
    } catch(e) {
      console.log(e)
    }
  } else {
    $('.change-email__container--input__form--error').addClass('active')
  }
}

export { initMagic }
