
window.choosePlasticTypes = function choosePlasticTypes() {
  let value = ''
  $('.plastic-type').each(function (index, object) {
    if (object.checked) {
      value = value.concat(object.value, ',')
      $(`#${object.id}Icon`).removeClass('hide')
    } else {
      $(`#${object.id}Icon`).addClass('hide')
    }
  })
  $('#user_plastic_type_detail_plastic_type').val(value)
}

window.formatCountry = function formatCountry(state) {
  if (!state.id) {
    return state.text;
  }

  let countryTitle = state.element.getAttribute('data-title') || ''
  let $state = $("<span><i class='fi fi-" + countryTitle + "'></i><span class='select-text'>" + state.text + "</span></span>");
  return $state;
};

window.formatCity = function formatCountry(state) {
  if (!state.id) {
    return state.text;
  }
  var $state = $("<span class='select-text'>" + state.text + '</span>');
  return $state;
};
