import Cropper from 'cropperjs';


export const getCropImage = (input, aspectRatio, minCropBoxWidth, minCropBoxHeight, imageUploadId, imagePreviewId, successNotify, callback) => {
  if (input.files && input.files[0]) {
    var reader = new FileReader
    reader.onload = function (e) {
      $('#imageCropper').attr('src', e.target.result)
    };
    reader.readAsDataURL(input.files[0]);
    setTimeout(initCropper(aspectRatio, minCropBoxWidth, minCropBoxHeight, imageUploadId, imagePreviewId, successNotify, callback), 1000);
  }
}

export const initCropper = (aspectRatio, minCropBoxWidth, minCropBoxHeight, imageUploadId, imagePreviewId, successNotify, callback) => {
  return () => {
    var image = document.getElementById('imageCropper');
    var cropper = new Cropper(image, {
      aspectRatio: aspectRatio,
      minCropBoxWidth: minCropBoxWidth,
      minCropBoxHeight: minCropBoxHeight
    });

    document.getElementById('crop_button').addEventListener('click', function (event) {
      event.stopPropagation();
      var canvas = cropper.getCroppedCanvas()

      canvas?.toBlob(function (blob) {
        if (blob) {
          let container = new DataTransfer();

          container.items.add(new File([blob], `${imageUploadId}.png`));
          document.querySelector(`#${imageUploadId}`).files = container.files;
          cropper?.destroy();
          $("#selectAvatarPreviewLogo").css("background-image", `url(${URL.createObjectURL(blob)})`);
          // $.magnificPopup.close();
          $(".crop-image").magnificPopup('close')
          
          if ($('#get-started').length > 0) {
            $.magnificPopup.open({
              closeOnBgClick: false,
              enableEscapeKey: false,
              items: {
                src: "#get-started"
              },
              type: "inline"
            });
          }
          $("#selectAvatarPreviewLogo").files = container.files;

          if (successNotify !== '') {
            $(`#${imagePreviewId}`).html(successNotify)
            toastr.success('Picture uploaded!')
          } else {
            readURL($(`#${imageUploadId}`)[0], `#${imagePreviewId}`);
          }
          if (callback) {
            callback()
          }
        }
      });
    })

    document.getElementsByClassName('mfp-close')[0].addEventListener('click', function (event) {
      event.stopPropagation();
      var canvas = cropper.getCroppedCanvas()

      canvas?.toBlob(function (blob) {
        if (blob) {
          cropper?.destroy();
          // $.magnificPopup.close();
          $(".crop-image").magnificPopup('close')
          if ($('#get-started').length > 0) {
            $.magnificPopup.open({
              closeOnBgClick: false,
              enableEscapeKey: false,
              items: {
                src: "#get-started"
              },
              type: "inline"
            });
            callback()
          }
        }
      });
    })
  }
}

export const readURL = (input, previewId) => {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    reader.onload = function (e) {
      $(previewId).css('background-image', 'url(' + e.target.result + ')');
      $(previewId).hide();
      $(previewId).fadeIn(650);
    }
    reader.readAsDataURL(input.files[0]);
  }
}
