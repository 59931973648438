import Sortable from 'sortablejs';
import { initDropzone } from './utils/dropzone';
import urlParse from 'js-video-url-parser'

let arrayDropzones = []
let arrayListUrl = []

const resetErrorCss = (el) => {
  el.removeClass()
  el.addClass('error d-none')
}

const handleAddVideoUrl = (itemUniqueId) => {
  let listUrl = arrayListUrl.find(x => x.id === itemUniqueId).data

  $(`#sendUrl${itemUniqueId}`).on('click', () => {
    let url = $(`#url${itemUniqueId}`).val()
    $(`#url${itemUniqueId}`).val('')
    const videoInfo = urlParse.parse(url)
    let embedUrl = urlParse.create({
      videoInfo: videoInfo,
      params: 'internal',
      format: 'embed'
    })

    if (!videoInfo || !embedUrl) {
      $(`#url-error${itemUniqueId}`).removeClass("d-none")
      return false
    }
    $(`#url-error${itemUniqueId}`).addClass("d-none")

    if (embedUrl && embedUrl.startsWith('//')) {
        embedUrl = 'https:' + embedUrl;
    }

    let len_preview = $(`#file-preview-achievement${itemUniqueId} .dz-preview`).length
    if (len_preview >= 10) {
      return
    }

    const prevHTML = `<div class="dz-preview dz-file-preview link-added" data-index=${listUrl.length} data-order=${len_preview} data-existed="false" data-existed-id="undefined">
                        <div class="dz-details">
                          <div class="dz-filename"></div>
                          <div class="video--thumbnail">
                            <img class="thumbnail--video" src="https://img.youtube.com/vi/${videoInfo.id}/0.jpg" style="" />
                            <div class="play-btn-iframe"><img src="/assets/play-button.svg"/></div>
                          </div>
                          <iframe class="iframe-video" style="display:none;" src="${embedUrl}" frameborder="0" allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                        </div>
                        <a class="dz-remove remove-added-url" href="javascript:undefined;" data-dz-remove=""></a>
                      </div>`
    
    listUrl.push(embedUrl)
    $(`#file-preview-achievement${itemUniqueId}`).append(prevHTML)
  })

  $(document).on('click', '.remove-added-url', ({target}) => {
    let index = $(target).closest('.dz-file-preview').data("index")
    $(target).closest('.dz-file-preview').remove()
    listUrl.splice(+index, 1)
  })
}

$(document).on("click", ".playbtn", function({target}) {
  if ($(target).closest(".dz-preview").find(".video").get(0).paused) {
    $(target).closest(".dz-preview").find(".video").get(0).play()
    $(target).closest(".dz-preview").find(".playbtn").fadeOut()
  } else {
    $(target).closest(".dz-preview").find(".video").get(0).pause()
    $(target).closest(".dz-preview").find(".playbtn").fadeIn()
  }
})

$(document).on("click", ".video--thumbnail", function({target}) {
  $(target).closest(".dz-preview").find(".video--thumbnail").hide()
  let symbol = $(target).closest(".dz-preview").find(".iframe-video")[0].src.indexOf("?") > -1 ? "&" : "?";
  $(target).closest(".dz-preview").find(".iframe-video")[0].src += symbol + "autoplay=1";
  $(target).closest(".dz-preview").find(".iframe-video").css("display", "block")
})

export const handleEventForEditRoadmap = () => {
  let deletedAchievementIds = []
  let deletedFileIds = [];

  $('#openEditAchievement').on('click', () => {
    const openEditAchievementModal = () => {
      let achievementSortable = document.getElementById('achievement-list')
      const sortable = new Sortable(achievementSortable, {
        ghostClass: 'ghost',
        handle: ".achievement__head-tab",
        onUpdate: function (evt) {
          let temp = arrayDropzones[evt.oldIndex];
          arrayDropzones[evt.oldIndex] = arrayDropzones[evt.newIndex];
          arrayDropzones[evt.newIndex] = temp;
          [].forEach.call(evt.from.getElementsByClassName('index_sortable_item'), function (el,index) {
              el.textContent = index + 1
          });
        },
      })

      $.magnificPopup.open({
        closeOnBgClick: false,
        enableEscapeKey: false,
        items: {
          src: '#editRoadmapModal'
        },
        callbacks: {
          close: () => {
            $('.add-achievement-error').addClass('d-none')
            arrayDropzones?.forEach(item => {
              item.dropzone.destroy()
            })
            $('.iframe-video').remove();
            arrayDropzones = []
            deletedAchievementIds = []
            arrayListUrl = []
            deletedFileIds = []
          }
        }
      });

      $('.discard-changes').on('click', () => {
        $.magnificPopup.close();
      })

      if ($('#achievement-list li').length > 0) {
        const onDeleteFileAction = (file) => {
          if(file.mockExistingFile) {
            deletedFileIds.push(file.mockExistingFileId)
          }
        }

        $('#achievement-list li').each((index, item) => {
          const itemUniqueId = $(item).data('id')

          let filePreviewSortable = document.getElementById(`file-preview-achievement${itemUniqueId}`)

          new Sortable(filePreviewSortable, {
            ghostClass: 'ghost',
            onUpdate: function (evt) {
              [].forEach.call(evt.from.getElementsByClassName('dz-preview'), function (el,index) {
                el.setAttribute("data-order", index)
              });
            }
          })
          let dropzoneObject = initDropzone({
                                formSelector: `#dz-${itemUniqueId}`,
                                url: $("#edit-achievements-form").attr("action"),
                                templateSelector: `#tpl-achievement${itemUniqueId}`,
                                previewsContainerSelector: `#file-preview-achievement${itemUniqueId}`,
                                uploadMultiple: true,
                                fileLimit: 10,
                                acceptedFiles: 'image/jpeg,image/png,image/jpg,video/mp4',
                                maxFilesize: 25,
                                isAddingImage: false,
                                isDropzoneRoadmap: true
                              }, onDeleteFileAction)
          let achievementId = $(item).data("achievement")
          let existingImagesList = JSON.parse($(`#existing-images-list${achievementId}`).val())
          existingImagesList.forEach((object) => {
            let mockFile = {}
            if (object[2] === "video/mp4") {
              mockFile = {
                name: 'FileName',
                size: '1000',
                type: 'video/mp4',
                kind: 'file',
                videoUrl: object[0],
                accepted: true,
                mockExistingFile: true,
                mockExistingFileId: object[1]
              };
            } else if (object[2] === "video/url") {
              const getIdVideoRegex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/
              const videoId = object[0].match(getIdVideoRegex)[1]
              mockFile = {
                name: 'FileName',
                size: '1000',
                type: 'video/url',
                kind: 'file',
                videoUrl: object[0],
                videoId: videoId,
                accepted: true,
                mockExistingFile: true,
                mockExistingFileId: object[1]
              };
            } else {
              mockFile = {
                name: 'FileName',
                size: '1000',
                type: 'image/jpeg',
                kind: 'file',
                imageUrl: object[0],
                accepted: true,
                mockExistingFile: true,
                mockExistingFileId: object[1]
              };
            }
      
            dropzoneObject.emit("addedfile", mockFile);
            dropzoneObject.files.push(mockFile);
            dropzoneObject.emit("complete", mockFile);
          })

          arrayListUrl.push({
            id: itemUniqueId,
            data: []
          })

          handleAddVideoUrl(itemUniqueId)          
          
          arrayDropzones.push(
            {
              id: itemUniqueId,
              dropzone: dropzoneObject,
              is_existed: true
            }
          );

          $('.right').on('click', ({target}) => {
            if ($(target).closest('.achievement').find('#achievement_id')) {
              deletedAchievementIds.push($(target).closest('.achievement').find('#achievement_id').val())
            }
            $(target).closest('.achievement').remove()
            arrayDropzones?.forEach((item, index, object) => {
              if (item.id === $(target).closest('.achievement').data('id')) {
                item.dropzone.destroy()
                object.splice(index, 1)
              }
            })
            $('#achievement-list li').each((index, item) => {
              $(item).find('#index_item').text(index + 1)
            })
          })
          
          $(item).find('.title-input').on('keyup', () => {
            if ($(item).find('.title-input').val().length > 50) {
              $(item).find('#title-error').removeClass('d-none')
              $(item).find('#title-error').text('Title is too long. Please use a maximum of 50 characters')
            } else {
              resetErrorCss($(item).find('#title-error'))
            }
            if (!$(item).find('.title-input').val()) {
              $(item).find('#title-error').removeClass('d-none')
              $(item).find('#title-error').text('This field is required.')
            }
          })
          // $(item).find('.description-input').on('keyup', () => {
          //   if ($(item).find('.description-input').val().length > 240) {
          //     $(item).find('#desc-error').removeClass('d-none')
          //     $(item).find('#desc-error').text('Description is too long. Please use a maximum of 240 characters')
          //   } else {
          //     resetErrorCss($(item).find('#desc-error'))
          //   }
          //   if (!$(item).find('.description-input').val()) {
          //     $(item).find('#desc-error').removeClass('d-none')
          //     $(item).find('#desc-error').text('This field is required.')
          //   }
          // })
          $(item).find('.amount-input').on('keyup', () => {
            if ($(item).find('.amount-input').val() > 999999999) {
              $(item).find('#amount-error').removeClass('d-none')
              $(item).find('#amount-error').text('Goal is too long. Please use a maximum of 9 characters.')
            } else {
              resetErrorCss($(item).find('#amount-error'))
            }
            if ($(item).find('.amount-input').val() <= 0) {
              $(item).find('#amount-error').removeClass('d-none')
              $(item).find('#amount-error').text('Goal is not less than or equal 0')
            }
            if (!$(item).find('.amount-input').val()) {
              $(item).find('#amount-error').removeClass('d-none')
              $(item).find('#amount-error').text('This field is required.')
            }
          })
        })
      }
    }

    const userId = $('#user_id').val()

    $.ajax({
      url: `/users/${userId}/achievements`,
      type: 'get',
      dataType: 'json',
      data: {},
      success: (res) => {
        $('#achievement-list').html(res.html);
        openEditAchievementModal();
      }
    }); 
  })

  const expanErrorItem = (item) => {
    !$(item).find('.arrow').hasClass('invert') && $(item).find('.arrow').addClass('invert')
    !$(item).find('.achievement__content').hasClass('expand') && $(item).find('.achievement__content').addClass('expand')
  }

  const showRequiredError = (item, el) => {
    $(item).find(el).removeClass('d-none')
    $(item).find(el).text('This field is required.')
  }

  const validateForm = () => {
    let flag = true
    $('#achievement-list li').each((index, item) => {
      if ($(item).find('.title-input').val().length > 50 || $(item).find('.amount-input').val() > 999999999) {
        flag = false;
        expanErrorItem(item)
      } 
      if (!$(item).find('.title-input').val()) {
        flag = false;
        showRequiredError(item, '#title-error')
        expanErrorItem(item)
      } 
      if (!$(item).find('.description-input').val()) {
        flag = false;
        showRequiredError(item, '#desc-error')
        expanErrorItem(item)
      } 
      if (!$(item).find('.amount-input').val()) {
        flag = false;
        showRequiredError(item, '#amount-error')
        expanErrorItem(item)
      }
      if ($(item).find('.amount-input').val() <= 0) {
        flag = false;
        $(item).find('#amount-error').removeClass('d-none')
        $(item).find('#amount-error').text('Goal is not less than or equal 0')
        expanErrorItem(item)
      }
    })
    return flag;
  }

  const validateAddedDropzone = () => {
    let flag = true
    arrayDropzones.forEach((item) => {
      if (item.dropzone.files.length == 0) {
        flag = false
        $(`.achievement[data-id=${item.id}]`).find('.avatar-error').removeClass('d-none')
        !$(`.arrow[data-id=${item.id}]`).hasClass('invert') && $(`.arrow[data-id=${item.id}]`).addClass('invert')
        !$(`.achievement__content[data-id=${item.id}]`).hasClass('expand') && $(`.achievement__content[data-id=${item.id}]`).addClass('expand')
      }
      return flag
    })
    return flag
  }

  $('#save-achievement').on('click', () => {
    let isValidDataSubmit = validateForm()
    let isValidAddedFile = validateAddedDropzone()
    if (!isValidDataSubmit || !isValidAddedFile) {
      $('#form-error').removeClass('d-none')
      return false
    }
    $("#save-achievement").attr("disabled", "disabled").off('click');
    $('#form-error').removeClass()
    $('#form-error').addClass('form-error d-none')
    $('.avatar-error').addClass('d-none')
    const $form = $('#edit-achievements-form')

    let arrayDropzonesAfterSort = []
    let arrayListUrlAfterSort = []
    document.getElementsByClassName('achievement').forEach((item) => {
      arrayDropzones.forEach(i => {
        if (i.id === item.getAttribute('data-id')) {
          arrayDropzonesAfterSort.push(i)
        }
      })
      arrayListUrl.forEach(i => {
        if (i.id === item.getAttribute('data-id')) {
          arrayListUrlAfterSort.push(i)
        }
      })
    })

    const files = []
    arrayDropzonesAfterSort.forEach(item => {
      files.push(item.dropzone.files)
    })

    const listUrl = []
    arrayListUrlAfterSort.forEach(item => {
      listUrl.push(item.data)
    })

    let listFileInputs = []
    let listUrlInputs = []
    let listOrderFiles = []
    let listOrderLinks = []
    let listOrderExistedItems = []
    arrayDropzonesAfterSort.forEach((_item, index) => {
      listFileInputs.push($(`<input type="file" multiple="multiple" name="achievements[avatars][${index}][]" />`))
      listUrlInputs.push($(`<input type="hidden" name="achievements[avatar_urls][${index}][]" />`))
      listOrderFiles.push($(`<input type="hidden" name="achievements[order_files][${index}][]" />`))
      listOrderLinks.push($(`<input type="hidden" name="achievements[order_links][${index}][]" />`))
      listOrderExistedItems.push($(`<input type="hidden" name="achievements[order_existed_items][${index}][]" />`))
    })

    function fileListItems(files) {
      let clipboard = new ClipboardEvent("").clipboardData || new DataTransfer();

      files.forEach((_file, index) => {
        const processingFile = files[index];

        if(!processingFile.mockExistingFile) {
          clipboard.items.add(files[index]);
        }
      });

      return clipboard.files;
    }

    document.getElementsByClassName('achievement').forEach((item, index) => {
      let orderFilesArray = []
      let orderLinksArray = []
      let orderExistedItemsArray = []
      Array.from($(`#file-preview-achievement${item.getAttribute('data-id')}`).children(".file-added")).forEach((item) => {
        if(item.getAttribute("data-existed") === 'false') {
          orderFilesArray.push(item.getAttribute("data-order"))
        }
      })
      Array.from($(`#file-preview-achievement${item.getAttribute('data-id')}`).children(".link-added")).forEach((item) => {
        if(item.getAttribute("data-existed") === 'false') {
          orderLinksArray.push(item.getAttribute("data-order"))
        }
      })
      Array.from($(`#file-preview-achievement${item.getAttribute('data-id')}`).children()).forEach((item) => {
        if(item.getAttribute("data-existed-id") !== 'undefined') {
          orderExistedItemsArray.push([item.getAttribute("data-existed-id"), item.getAttribute("data-order")])
        }
      })
      listOrderFiles[index].val(orderFilesArray)
      $form.append(listOrderFiles[index])
      listOrderLinks[index].val(orderLinksArray)
      $form.append(listOrderLinks[index])
      listOrderExistedItems[index].val(orderExistedItemsArray)
      $form.append(listOrderExistedItems[index])
    })

    listFileInputs.forEach((item, index) => {
      item[0].files = fileListItems(files[index])
      $form.append(item);
      $(item).css('display', 'none')
    })
    listUrlInputs.forEach((item, index) => {
      item.val(listUrl[index])
      $form.append(item)
    })

    let uniqueAchievementIds = Array.from(new Set(deletedAchievementIds))
    $('#achievements_remove_exists_ids').val(uniqueAchievementIds)
    $('#achievements_remove_exists_file_ids').val(deletedFileIds)
    $form.trigger('submit')
  })
}

export const handleAddAchievementButton = () => {
  $('.add-achievement').on('click', () => {
    let uniqueId = Math.random().toString(36).slice(2, 8);
    if ($('#achievement-list li').length < 3) {
      $('#achievement-list').append(`
        <li class="achievement" data-id=${uniqueId}>
          <input type="hidden" name="achievements[achievement_id][]" id="achievement_id" value=''>
          <div class="achievement__head-tab">
            <div class="left">
              <div class="name">
                <img src='/assets/menu.svg' class='mr-20'> 
                <span>Achievement #<span id="index_item" class="index_sortable_item">${$('#achievement-list li').length + 1}</span></span>
              </div>
              <div class="arrow invert" onclick='handleExpandAchievement("${uniqueId}")' data-id=${uniqueId}>
                <img src='/assets/down-arrow.svg'> 
              </div>
            </div>
            <div class="right" data-id=${uniqueId}>
              <img src='/assets/bin-icon.svg'>
            </div>
          </div>
          <div class="achievement__content expand" data-id=${uniqueId}>
            <h3 class="title">Add photos or videos of what you will achieve when you reach the goal</h3>
            <div class="content">
              <span class="description">You can also provide a link for a video</span>
              <div class="input-video-url">
                <input type="text" name="achievements[url_video][]" id="url${uniqueId}" value="" class="input url-input" placeholder="Enter your link">
                <div id="sendUrl${uniqueId}" class="submit-video-link-btn"><span>Submit URL</span></div>
              </div>
              <div class="error d-none" id="url-error${uniqueId}">The link provided does not appear to be associated with a video.<br>Please double-check the link and try again.</div>
            </div>
            <div class="content" id="dz-${uniqueId}">
              <span class="description">Drag a file here to upload a video or a maximum of 10 pictures <span class="sub-description">(JPG, PNG or MP4 format) Max 25MB</span></span>
              <!-- write dropzone here -->
              <div class='drag-files-dropzone'>
                <div class='drag-files-dropzone__wrapper'>
                  <div class="file-uploader-area d-flex align-items-center justify-content-center">
                    <div class="fallback">
                      <input name="avatar" type="file" multiple />
                    </div>
                    <div class="message">
                      <div class="dz-message needsclick">
                        <img src="/assets/ic-upload-profile.svg">
                      </div>
                      Drop your files here
                    </div>
                  </div>
                  <div id="file-preview-achievement${uniqueId}" class="file-preview">
                  </div>
                  <div id="tpl-achievement${uniqueId}" class="tpl">
                    <div class="dz-preview dz-file-preview">
                      <div class="dz-details">
                        <div class="dz-filename">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="avatar-error-${uniqueId}" class="avatar-error error d-none">Avatar is required when add a new achievement.</div>
              </div>
            </div>
            <div class="content">
              <span class="description">Title of the achievement (Funds will be used on what?)</span>
              <input type="text" name="achievements[title][]" id="achievements_title" value="" class="input title-input" placeholder="Title of achievement (Maximum 50 characters)">
              <div class="error d-none" id="title-error">Title is too long. Please use a maximum of 50 characters</div>
            </div>
            <div class="content">
              <span class="description">Description of the achievement (What benefits will this bring to your project?)</span>
              <textarea name="achievements[description][]" id="achievements_description" class="input description-input" placeholder="Description of achievement" rows="10"></textarea>
              <div class="error d-none" id="desc-error"></div>
            </div>
            <div class="content">
              <span class="description">Goal (1kg=1$)</span>
              <input type="number" name="achievements[goal_amount][]" id="achievements_goal_amount" value="" class="input amount-input" placeholder="Amount of kg.">
              <div class="error d-none" id="amount-error">Goal is too long. Please use a maximum of 9 characters</div>
            </div>
          </div>
        </li>
      `)

      const dropzoneOpts = {
        formSelector: `#dz-${uniqueId}`,
        url: $("#edit-achievements-form").attr("action"),
        templateSelector: `#tpl-achievement${uniqueId}`,
        previewsContainerSelector: `#file-preview-achievement${uniqueId}`,
        uploadMultiple: true,
        fileLimit: 10,
        acceptedFiles: 'image/jpeg,image/png,image/jpg,video/mp4',
        maxFilesize: 25,
        isAddingImage: false,
        isDropzoneRoadmap: true
      }

      $('.right').on('click', ({target}) => {
        $(target).closest('.achievement').remove()
        arrayDropzones?.forEach((item, index, object) => {
          if (item.id === $(target).closest('.achievement').data('id')) {
            item.dropzone.destroy()
            object.splice(index, 1)
          }
        })
        $('#achievement-list li').each((index, item) => {
          $(item).find('#index_item').text(index + 1)
        })
      })

      arrayListUrl.push({
        id: uniqueId,
        data: []
      })

      handleAddVideoUrl(uniqueId)

      let filePreviewSortable = document.getElementById(`file-preview-achievement${uniqueId}`)
      new Sortable(filePreviewSortable, {
        ghostClass: 'ghost',
        onUpdate: function (evt) {
          [].forEach.call(evt.from.getElementsByClassName('dz-preview'), function (el,index) {
            el.setAttribute("data-order", index)
          });
        }
      })

      arrayDropzones.push({
        id: uniqueId,
        dropzone: initDropzone(dropzoneOpts),
        is_existed: false
      });

      let itemAdded = $(`.achievement[data-id=${uniqueId}]`)

      $(itemAdded).find('.title-input').on('keyup', () => {
        if (!$(itemAdded).find('.title-input').val()) {
          $(itemAdded).find('#title-error').removeClass('d-none')
          $(itemAdded).find('#title-error').text('This field is required.')
        } else {
          resetErrorCss($(itemAdded).find('#title-error'))
        }
        if ($(itemAdded).find('.title-input').val().length > 50) {
          $(itemAdded).find('#title-error').removeClass('d-none')
          $(itemAdded).find('#title-error').text('Title is too long. Please use a maximum of 50 characters')
        }
      })
      // $(itemAdded).find('.description-input').on('keyup', () => {
      //   if ($(itemAdded).find('.description-input').val().length > 240) {
      //     $(itemAdded).find('#desc-error').removeClass('d-none')
      //     $(itemAdded).find('#desc-error').text('Description is too long. Please use a maximum of 240 characters')
      //   } else {
      //     resetErrorCss($(itemAdded).find('#desc-error'))
      //   }
      //   if (!$(itemAdded).find('.description-input').val()) {
      //     $(itemAdded).find('#desc-error').removeClass('d-none')
      //     $(itemAdded).find('#desc-error').text('This field is required.')
      //   }
      // })
      $(itemAdded).find('.amount-input').on('keyup', () => {
        if ($(itemAdded).find('.amount-input').val() > 999999999) {
          $(itemAdded).find('#amount-error').removeClass('d-none')
          $(itemAdded).find('#amount-error').text('Goal is too long. Please use a maximum of 9 characters.')
        } else {
          resetErrorCss($(itemAdded).find('#amount-error'))
        }
        if ($(itemAdded).find('.amount-input').val() <= 0) {
          $(itemAdded).find('#amount-error').removeClass('d-none')
          $(itemAdded).find('#amount-error').text('Goal is not less than or equal 0')
        }
        if (!$(itemAdded).find('.amount-input').val()) {
          $(itemAdded).find('#amount-error').removeClass('d-none')
          $(itemAdded).find('#amount-error').text('This field is required.')
        }
      })

    } else {
      $('.add-achievement-error').removeClass('d-none')
    }
  })
}
