import { updateLocal } from "web3modal";
const MAX_WORD_OF_DESCRIPTION = 300;

$(document).ready(function () {
  function countWordAndCharacter(_this, MAX_WORD_OF_DESCRIPTION = 0) {
    let res = [];
    let indexOfMax = 0;
    let str = _this?.value.replace(/[\t\n\r\.\?\!]/gm, " ").split(" ") || [];

    str.map((substr, index) => {
      let trimStr = substr.trim();
      if (trimStr.length > 0) {
        res.push(trimStr);
        if (res.length <= MAX_WORD_OF_DESCRIPTION) {
          indexOfMax = index;
        }
      }
    });

    if (indexOfMax == 0) {
      indexOfMax = res.length
    }
    res = res.slice(0, MAX_WORD_OF_DESCRIPTION)


    return { 'numberOfWord': res.length, 'numberOfCharacter': str.slice(0, indexOfMax).join(' ').length }
  }

  function updateNumberWordShow(_this, showId, MAX_WORD_OF_DESCRIPTION) {
    let { numberOfWord, numberOfCharacter } = countWordAndCharacter(_this, MAX_WORD_OF_DESCRIPTION);

    if ($(showId).length === 1) {
      if (numberOfWord >= 300) {
        let newValue = _this?.value.substr(0, numberOfCharacter + 1) || '';

        _this.setAttribute("maxLength", numberOfCharacter + 1);
        _this.value = newValue;
      } else {
        _this.removeAttribute("maxLength")
      }

      $(showId).text(numberOfWord);
    }
  }

  function showPreviewImage(fileId, previewId, editBtnId, validFileId) {
    var file = fileId[0].files[0]
    var ftype = file.type;
    var fsize = file.size / 1024 / 1024; // in MBs

    if (fsize > 30) {
      previewId.removeAttr("style");  // Remove preview background if invalid
      fileId[0].value = ''; // Remove value of input if invalid

      return toastr.error('Invalid file size!. Must be less than 30MB');
    }

    var imgExt = ['image/png', 'image/jpeg', 'image/gif', 'image/webp'];

    if (fileId) {
      var reader = new FileReader();

      reader.onload = function (e) {
        if (imgExt.includes(ftype)) {
          previewId.css('background-image', 'url(' + e.target.result + ')');
          previewId.fadeIn(650);
          editBtnId.fadeIn(100);
          if (fileId.is($("#nft_collection_cover"))) {
            let selectCollectionCover = $(".select-collection__cover");
            let selectCollectionCoverPlaceholder = $(".select-collection__cover--placeholder");
            if (selectCollectionCover && selectCollectionCoverPlaceholder) {
              selectCollectionCover.addClass("select-collection__cover--preview");
              selectCollectionCoverPlaceholder.removeClass("hide");
            }
          }
        } else {
          previewId.removeAttr("style"); // Remove preview background if invalid
          fileId[0].value = ''; // Remove value of input if invalid

          return toastr.error('Invalid file type!');
        }
      }
      reader.readAsDataURL(file);
    }
  }

  window.hidePreviewImage = function hidePreviewImage(previewId, editBtnId) {
    previewId.css('background-image', '').css('z-index', '0');
    editBtnId.fadeOut(100);
  }

  function file1ShowError(file1, file1Error) {
    if (file1 && file1.validity.valueMissing && file1Error) {
      file1Error.textContent = 'Photo is required!';
      file1Error.className = 'error active';
    }
  }

  function nameShowError(name, nameError, nameLabel) {
    if (name.validity.valueMissing && nameError) {
      nameError.textContent = 'Name is required!';
      nameError.className = 'error active';

      name.classList.add('active');
      if (nameLabel) {
        nameLabel.classList.add('active');
      }
    }
  }

  function descriptionShowError(description, descriptionError, countWordErr = false) {
    if (description.validity.valueMissing && descriptionError) {
      descriptionError.textContent = 'Description is required!';
      descriptionError.className = 'error active';

      description.classList.add('active');
    }

    if (countWordErr && descriptionError) {
      descriptionError.textContent = 'Description is too long!';
      descriptionError.className = 'error active';

      description.classList.add('active');
    }
  }

  function scrollToFirstFieldError(errorFields) {
    if (errorFields.length > 0) {
      $('html').animate({
        scrollTop: errorFields.first().parent().offset().top
      }, 500);
    }
  }

  function scrollModalToFirstFieldError(errorFields) {
    if (errorFields) {
      errorFields.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  }

  // nft_collection - logo
  const logoFile = document.getElementById("nft_collection_logo");
  const logoFileError = document.getElementsByClassName('logoFileError')[0];

  // nft_collection - cover
  const coverFile = document.getElementById("nft_collection_cover");
  const coverFileError = document.getElementsByClassName('coverFileError')[0];

  // nft_collection - name
  const nftCollectionName = document.getElementById("nft_collection_name");
  const nftCollectionNameError = document.querySelector('#nft_collection_name + span.error');
  const nftCollectionNameLabel = $("label[for='nft_collection_name']")[0];

  // nft_collection - description
  const nftCollectionDescription = document.getElementById("nft_collection_description");
  const nftCollectionDescriptionError = document.querySelector('#nft_collection_description + span.error');

  $("#nft_collection_logo_tempt").on("change", function (e) {
    let fileId = $("#nft_collection_logo");
    let previewId = $("#selectCollectionPreviewLogo");
    let editBtnId = $("#logoEditBtn");
    let uploadIcon = $('#uploadIcon');
    
    if (this.files.length == 1) {
      logoFile.files = this.files
    }

    if (logoFile.validity.valid && logoFileError) {
      logoFileError.textContent = '';
      logoFileError.className = 'error logoFileError';
    } else {
      file1ShowError(logoFile, logoFileError);
    }

    showPreviewImage(fileId, previewId, editBtnId);

    if (this.files.length > 0) {
      uploadIcon.removeClass('select-collection__background').addClass('bg-light');
    }
  })

  $("#nft_collection_cover_tempt").on("change", function (e) {
    if (this.files.length == 1) {
      coverFile.files = this.files
    }

    if (coverFile.validity.valid && coverFileError) {
      coverFileError.textContent = '';
      coverFileError.className = 'error coverFileError';
    } else {
      file1ShowError(coverFile, coverFileError);
    }

    let fileId = $("#nft_collection_cover");
    let previewId = $("#selectCollectionPreviewCover");
    let editBtnId = $("#coverEditBtn");
    showPreviewImage(fileId, previewId, editBtnId);
  })

  function closePreviewCollection(fileId, previewId, placeholderId, notificationId, chooseFile, videoPreview) {
    fileId.val('');
    previewId.css('background-image', 'none');
    chooseFile.fadeIn(100);
    $('#nft-create-large-preview__iframe').remove();
    previewId.removeAttr("style");
    previewId.hide();
    videoPreview.removeAttr("style");

    $('#groupFile1').removeClass('hide')
    $('#groupFileCollection').removeClass('hide')
    $('.collection-create-large-preview__wrapper').addClass('hide')

    notificationId.fadeOut(100);
    placeholderId.fadeIn(100);
  }

  $('#closePreviewCollectionId').on('click', function (e) {
    e.preventDefault();

    let fileId = $('#fileCollection-1');
    let placeholderId = $('#placeholder');
    let notificationId = $('#uploadSuccessfully');
    let chooseFile = $('#choose_video_selected')
    let videoPreview = $('#videoPreviewRes')

    closePreviewCollection(fileId, $('#nft-create-large-preview'), placeholderId, notificationId, chooseFile, videoPreview)
    closePreviewCollection(fileId, $('#formArtistPreviewSmallId'), placeholderId, notificationId, chooseFile, videoPreview)
    $('#remove_video').val(true)
  })

  function readURLSingle(input, file, previewSection, imagePreview, closePreviewBtn, placeholder, fileId, chooseFile, coverImg) {
		var ftype = file.type;
		var fsize = file.size / 1024 / 1024; // in MBs
		let isInvalid = false
		if (fsize > 30 && !previewSection.is($('#nft-create-large-preview'))) {
			isInvalid = true
			fileId.val('');
			return toastr.error('Invalid file size!. Must be less than 10MB');
		} else if (fsize > 30 && previewSection.is($('#nft-create-large-preview'))){
			isInvalid = true
			fileId.val('');
		}
		if (!isInvalid) {
      console.log("In Invalid");
			$('#groupFileCollection').addClass('hide');
			$('.collection-create-large-preview__wrapper').removeClass('hide')
			var imgExt = ['image/png', 'image/jpeg', 'image/gif', 'image/webp', 'application/pdf'];
			var audExt = ['audio/mp3', 'audio/webm', 'audio/mpeg'];
			var vidExt = ['video/mp4', 'video/webm'];
			if (input.files && input.files[0]) {
				var reader = new FileReader();

				reader.onload = function (e) {
					if (imgExt.includes(ftype) && ftype !== 'application/pdf' ) {
						let img = $('<img>').attr('src', e.target.result).css({
							"border-radius": "4px",
							"height": "260px",
							"width": "100%",
							"object-fit": "cover"
						})

						let mediaQuery = window.matchMedia("(max-width: 960px)");
						if (mediaQuery.matches) {
							img.css({
								"height": "240px"
							});
						} else {
							img.css({
								"height": "260px"
							});
						}
						previewSection.empty().append(img);
						previewSection.css({
							"position": "relative",
						});
						previewSection.hide();
						previewSection.fadeIn(650);
					} else if (ftype === 'application/pdf') {
						var pdfUrl = e.target.result;
						var pdfHtml = '<iframe id="nft-create-large-preview__iframe" src="' + pdfUrl + '" type="application/pdf" width="100%" height="100%"> \
														</iframe>';
						previewSection.html(pdfHtml);
						previewSection.hide();
						previewSection.fadeIn(650);
					} else if (coverImg) {
						return toastr.error('Invalid file type!');
					} else if (audExt.includes(ftype)) {
						// $('#file-2').prop('required', true);
						previewSection.hide();
						previewSection.fadeIn(650);
						imagePreview.html('<audio width="300" height="300" controls><source src="mov_bbb.mp4" id="audio_here"> </audio>');
						imagePreview.css({ 'height': '55px' });
						$('#audio_here')[0].src = URL.createObjectURL(input.files[0]);
						$('#audio_here').parent()[0].load();
					} else if (vidExt.includes(ftype)) {
						previewSection.empty().append('<video controls><source src="mov_bbb.mp4" id="video_here"> </video>');
						previewSection.css({
							"position": "relative",
						});
						if (input.files && input.files[0] && input.files[0] instanceof Blob) {
							$('#video_here').attr('src', URL.createObjectURL(input.files[0]));
							$('#video_here').prop('load', true);
						} else {
							return toastr.error('Invalid file or file type');
						}
						previewSection.hide();
						previewSection.fadeIn(650);
					} else {
						return toastr.error('Invalid file type!');
					}
					imagePreview.css({
						'width': '300px',
						'background-size': 'cover',
						'background-repeat': 'no-repeat',
						'background-position': 'center',
						'margin-left': 'auto',
						'margin-right': 'auto',
						'border-radius': '3px'
					});
					closePreviewBtn.css('display', 'inline-block');
					placeholder.fadeOut(100);
					fileId.fadeOut(100);
					chooseFile.fadeOut(100);
					imagePreview.hide();
					imagePreview.fadeIn(650);
				}

				reader.readAsDataURL(input.files[0]);
			}
		}
	}

	$("#fileCollection-1").change(function (e) {
		var file = e.currentTarget.files[0];
		var previewSection = $('#videoCollectionPreviewCover');
		var closePreviewBtn = $('#close-preview-button');
    var imagePreview = $('#videoPreviewRes');
		var placeholder = $('#placeholder');
		var fileId = $('#fileCollection-1');
		var chooseFile = $('#choose_video_selected');

		readURLSingle(this, file, $('#nft-create-large-preview'), imagePreview, closePreviewBtn, placeholder, fileId, chooseFile, false);
	});

  $("#nft_collection_name").on("keyup", function (e) {
    if (nftCollectionName.validity.valid && nftCollectionNameError) {
      nftCollectionNameError.textContent = '';
      nftCollectionNameError.className = 'error';

      nftCollectionName.classList.remove('active');
    } else {
      nameShowError(nftCollectionName, nftCollectionNameError, nftCollectionNameLabel);
    }
  })
  

  $("#nft_collection_description").ready(function () {
    let showId = $("#showNumberOfWord");

    updateNumberWordShow($("#nft_collection_description")[0], showId, MAX_WORD_OF_DESCRIPTION)
  })

  $("#nft_collection_description").on("keyup", function (e) {
    let numberOfWord = countWordAndCharacter(this, MAX_WORD_OF_DESCRIPTION).numberOfWord;
    let showId = $("#showNumberOfWord");

    updateNumberWordShow(this, showId, MAX_WORD_OF_DESCRIPTION)

    if (nftCollectionDescription.validity.valid && nftCollectionDescriptionError) {
      nftCollectionDescriptionError.textContent = '';
      nftCollectionDescriptionError.className = 'error';
      nftCollectionDescription.classList.remove('active');
    } else if (numberOfWord > MAX_WORD_OF_DESCRIPTION) {
      descriptionShowError(nftCollectionDescription, nftCollectionDescriptionError, true);
    }
    else {
      descriptionShowError(nftCollectionDescription, nftCollectionDescriptionError);
    }
  })

  $('#nft_collection_description').keypress(function(e){
    e.stopPropagation();
  });

  $(document).on("keyup", ".utilities_attr", function () {
    const MAX_UTILITIES_ELEMENT = 10;
    let collections = $(".nft-collection-utilities-section .sign__group");
    let totalEntry = collections.length;
    let emptyUtilitiesElements = $('.nft-collection-utilities-section .sign__group .utilities_attr').filter(function () {
      return this.value === ''
    })

    if (this.value !== '') {
      if (emptyUtilitiesElements.length === 0 && totalEntry < MAX_UTILITIES_ELEMENT) {
        let clonedDiv = $('.nft-collection-utilities-entry-base').clone();

        clonedDiv.removeClass('nft-collection-utilities-entry-base hide');
        clonedDiv.appendTo(".nft-collection-utilities-section");
      }
    } else {
      if (emptyUtilitiesElements.length === 2) {
        let currentCollection = emptyUtilitiesElements[1].parentElement;
        currentCollection.remove();
      }
    }
  })

  $(document).on("keyup", ".collection_utilities_attr", function () {
    const MAX_UTILITIES_ELEMENT = 5;
    let collections = $(".collection-utilities-section .sign__group");
    let totalEntry = collections.length;
    let emptyUtilitiesElements = $('.collection-utilities-section .sign__group .collection_utilities_attr').filter(function () {
      return this.value === ''
    })

    if (this.value !== '') {
      if (emptyUtilitiesElements.length === 0 && totalEntry < MAX_UTILITIES_ELEMENT) {
        let clonedDiv = $('.collection-utilities-entry-base').clone();

        clonedDiv.removeClass('collection-utilities-entry-base hide');
        clonedDiv.appendTo(".collection-utilities-section");
      }
    } else {
      if (emptyUtilitiesElements.length === 2) {
        let currentCollection = emptyUtilitiesElements[1].parentElement;
        currentCollection.remove();
      }
    }
  })

  $(document).on("click", ".triggerNftCollectionValidation", function (event) {
    event.preventDefault();
    let form = document.getElementById("nftCollectionForm");

    if (form.checkValidity()) {
      $("#submitNftCollection").click();
    } else {
      file1ShowError(logoFile, logoFileError);
      file1ShowError(coverFile, coverFileError);
      nameShowError(nftCollectionName, nftCollectionNameError, nftCollectionNameLabel);
      descriptionShowError(nftCollectionDescription, nftCollectionDescriptionError);
      if ($('#selectCollectionModal').length > 0) {
        let errorFields = document.querySelector('.error.active');
        scrollModalToFirstFieldError(errorFields);
      } else {
        let errorFields = $('.error.active');
        scrollToFirstFieldError(errorFields);
      }
    }
  })

  $('[data-toggle="popover"]').popover({
    html: true,
    trigger: 'focus',
    placement: 'right',
    offset: 1,
    content: function () { return $('.nft-collection-list').html() }
  });

  $('.nft-collection-item').on('click', function (e) {
    let collection_id = $(this).attr('id');
    let collection_name = $(this).find('.collection-name').html();
    let collection_network = $(this).find('.collection-network').html();
    let new_sc = $(this).find('.collection-new-sc').html();

    $('.current-selected').removeClass('hide');
    $('.current-selected .title-network').html(collection_network);
    $('.current-selected .title').html(collection_name);
    $('#collection_nft_collection_id').val(collection_id);
    $('#nft_collection_new_sc').val(new_sc);
    $('#collection_new_sc').val(new_sc);
    $('[data-toggle="popover"]').popover('hide')
  });

  window.startCollectionContractDeploy = async function startCollectionContractDeploy(contractType, isModalForm = false) {
    var name = $('#nft_collection_name').val();
    var symbol = $('#nft_collection_symbol').val();
    var collectionId = $('#collection_id').val();

    if (!name || !symbol) {
      toastr.info('Provide valid name and symbol')
    } else {
      var compiled_details = getContractABIAndBytecode('', contractType, false); //shared=false
      var abi = compiled_details['compiled_contract_details']['abi']
      var bytecode = compiled_details['compiled_contract_details']['bytecode']

      await contractDeployInit()
      await deployCollectionContract(abi, bytecode, name, symbol, contractType, collectionId, "#nft_collection_contract_address", isModalForm)
    }
  }

  window.updateCollectionAddress = function updateCollectionAddress(contractAddressHolder, isModalForm = false) {
    const collectionAddress = $(contractAddressHolder).val()
    const collectionId = $("#nft_collection_id").val()

    var request = $.ajax({
      url: '/nft/nft_collections/' + collectionId,
      type: 'PATCH',
      async: false,
      dataType: 'script',
      headers: {
        'X-CSRF-TOKEN': document.querySelector('[name=csrf-token]').content,
      },
      data: { nft_collection: { address: collectionAddress }, modal_form: isModalForm },
    })
    request.done(function (msg) {
      let selectCollectionCover = $(".select-collection__cover");
      let selectCollectionCoverPlaceholder = $(".select-collection__cover--placeholder:last");
      if (selectCollectionCover && selectCollectionCoverPlaceholder) {
        selectCollectionCover.removeClass("select-collection__cover--preview");
        selectCollectionCoverPlaceholder.addClass("hide");
      }

      if ($(".artist-form__select-collection--dropdown.dropdown-menu").length) {
        $(".artist-form__select-collection--dropdown.dropdown-menu").empty();

        var renewRequest = $.ajax({
          url: 'updated_nft_collections',
          type: 'GET',
          data: {
            collection_nft_type: $("#nft_collection_nft_type").val()
          },
          async: true,
          dataType: 'json'
        })

        renewRequest.done(function (msg) {
          const nftCollections = msg.nft_collections

          const artistFormDropdown = document.querySelector('.artist-form__select-collection--dropdown.dropdown-menu');

          nftCollections.forEach(nftCollection => {
            // create a new li element
            const collectionItem = document.createElement('li');

            // set the li element class and id attributes
            collectionItem.classList.add(`nft-collection-item`, `network-${nftCollection.chain_id}`);
            collectionItem.id = nftCollection.id;

            // create and append the span elements to the li element
            const nameElement = document.createElement('span');
            nameElement.classList.add('collection-name', 'text-truncate-1');
            nameElement.textContent = nftCollection.name;

            const networkElement = document.createElement('span');
            networkElement.classList.add('collection-network', 'hide');
            networkElement.textContent = nftCollection.network;

            const scElement = document.createElement('span');
            scElement.classList.add('collection-new-sc', 'hide');
            scElement.textContent = nftCollection.new_sc;

            collectionItem.appendChild(nameElement);
            collectionItem.appendChild(networkElement);
            collectionItem.appendChild(scElement);

            artistFormDropdown.appendChild(collectionItem);
          });

          $('.nft-collection-item').on('click', function (e) {
            let collection_id = $(this).attr('id');
            let collection_name = $(this).find('.collection-name').html();
            let collection_network = $(this).find('.collection-network').html();
            let new_sc = $(this).find('.collection-new-sc').html();

            $('.current-selected').removeClass('hide');
            $('.current-selected .title-network').html(collection_network);
            $('.current-selected .title').html(collection_name);
            $('#collection_nft_collection_id').val(collection_id);
            $('#nft_collection_new_sc').val(new_sc);
            $('#collection_new_sc').val(new_sc);
            $('[data-toggle="popover"]').popover('hide')
          });
        })
      }

      toastr.success('Collection created successfully!');
      $('#deployContract').magnificPopup('close');
    })
    request.fail(function (msg) {
      toastr.error('Something went wrong. Please try again')
    })
  }

  $("#collection-published").on("change", function (e) {
    const collectionId = $("#collection_id").html()

    var request = $.ajax({
      url: `/nft_collections/` + collectionId + '/published',
      type: 'POST',
      async: false,
      dataType: 'script',
      headers: {
        'X-CSRF-TOKEN': document.querySelector('[name=csrf-token]').content,
      },
      data: { nft_collection: { published: $("#collection-published input").is(':checked') } },
    })
    request.done(function (msg) {
      $('.nft-collection__cover__info__state--icon').toggleClass('hide')
    })
    request.fail(function (res) {
      const data = JSON.parse(res.responseText)

      setTimeout(() => {
        $(e.currentTarget).children("input").prop("checked", data['published']);
      }, 1000);
      toastr.error(data['msg'])
    })
  })

  $('.js-nft-collection-multiple').select2({
    maximumSelectionLength: $('.js-nft-collection-multiple').attr('data-count'),
    allowClear: true,
    placeholder: "Select...",
  });

});
