
function getNetWorkName(chainID) {
  const networks = {
    97: 'BSC Testnet',
    56: 'BSC Mainnet',
    44787: 'CELO Testnet',
    42220: 'CELO Mainnet'
  }

  return networks[chainID]
}

function checkNetworkType(chainId) {
  if($('#collection_chain_id').length > 0 && $('#networkError').length > 0) {
    if(parseInt($('#collection_chain_id').val()) != chainId) {
      $('.buy-btn').addClass('disabled')
      $('.pay-with-crypto').addClass('disabled-pay-button--network');
      $('#networkError').css('display', 'flex').css('justify-content', 'center');

      console.log('CHAINID-check', chainId)
      if ([97, 56].includes(chainId)) {

        $('.wrong-celo').removeClass('hide')
      } else if ([44787, 42220].includes(chainId)) {
        $('.wrong-bsc').removeClass('hide');
      } else {
        $('.wrong-network').removeClass('hide');
      }
    } else {
      $('.buy-btn').removeClass('disabled')
      $('.pay-with-crypto').removeClass('disabled-pay-button');
      $('#networkError').css('display', 'none');
    }
  }
}

const getNetworkId = async () => {
  const currentChainId = await web3.eth.net.getId()
  return currentChainId
}

export { getNetWorkName, checkNetworkType, getNetworkId }
