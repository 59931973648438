import Chart from 'chart.js/auto'
import IconCircle from '../../assets/images/ic-map-circle-hover.svg';
import selectedIconCircle from '../../assets/images/ic-map-circle-hover-yellow.svg';
import nextIcon from '../../assets/images/next-icon.svg';
import previousIcon from '../../assets/images/prev-icon.svg';

const moment = require('moment')

$(document).ready(function() {
  let mapInstance = null;
  const handleMapStatistic = () => {
    $('#show-supporter-map-button').on('click', () => {
      $.magnificPopup.open({
        closeOnBgClick: true ,
        enableEscapeKey: false,
        items: {
          src: '#supporter-show-map-popup'
        },
        type: 'inline',

      });
      if (!mapInstance) {
        if ($('#map-stat').length > 0) {

          const buildCarouselImagesHtml = (images) => {
            let carouselHtml = ''
            images.forEach(image => {
              carouselHtml += `<div class="owl-item">
                                  <img class='' src=${image} />
                                </div>`
            })

            return carouselHtml;
          }

          const markerPopupHtml = ({recyclerAddress, userImage, countryFlagClass, countryName, images, RpName, plasticSupported, addedClass = ''}) =>
            `<div class="map__pop-up ${addedClass}">
              <div class='map__pop-up__banner-image ${addedClass}'>
                <div class="main__carousel explore-recovery-project-profile-images owl-carousel owl-loaded" id="${recyclerAddress}exploreRecoveryProjectProfileImages">
                  <div class="owl-stage-outer owl-height">
                    <div class="owl-stage">
                      ${buildCarouselImagesHtml(images)}
                    </div>
                  </div>
                  <button class="home__nav home__nav--prev" data-nav="#${recyclerAddress}exploreRecoveryProjectProfileImages" type="button">
                    <img class='' src=${previousIcon} />
                  </button>
                  <button class="home__nav home__nav--next" data-nav="#${recyclerAddress}exploreRecoveryProjectProfileImages" type="button">
                    <img class='' src=${nextIcon} />
                  </button>
                </div>
              </div>
              <div class="map__pop-up__title">
                <div class='map__pop-up__title__recovert-project-info'>
                  <div class="img">
                    <a href='/users/${recyclerAddress}/recovery_project_profile'>
                      <img class='' src=${userImage} />
                    </a>
                  </div>
                  <div class="content">
                    <div class='font-size-14-text font-weight-600-text text-truncate-1'>
                      <a href='/users/${recyclerAddress}/recovery_project_profile'>
                        ${RpName}
                      </a>
                    </div>
                    <span class="map__pop-up__description font-size-12-text text-truncate-1 neutral-500-color-text">
                      <i class='${countryFlagClass}'></i>
                      ${countryName}
                    </span>
                  </div>
                </div>
                <div class='map__pop-up__title__recycle-info'>
                  <div class='recycle-quantity font-weight-600-text text-truncate-1'>
                    ${plasticSupported} kg
                  </div>
                  <div class='font-size-12-text neutral-500-color-text map__pop-up__title__recycle-info__text'>
                    ${addedClass ? 'plastic supported' : 'plastic recovered'}
                  </div>
                </div>
              </div>
            </div>`

          let recycler_country_coordinates_id = $('#recycler_country_coordinates');
          let supporter_coordinates_id = $('#supporter_country_coordinates');
          let recovery_project_coordinates = []
          let supporter_coordinates = []

          try {
            recovery_project_coordinates = JSON.parse(recycler_country_coordinates_id.val().replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t"))
            supporter_coordinates = JSON.parse(supporter_coordinates_id.val().replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t"))
          } catch {
            recovery_project_coordinates = []
            supporter_coordinates = []
          }

          const myRPIcon = L.icon({
            iconUrl: IconCircle,
            iconSize: [38, 38],
            popupAnchor: [0, -10],
            className: 'recycler-icon --recovery-project-map-point'
          });

          const mySelectedRPIcon = L.icon({
            iconUrl: selectedIconCircle,
            iconSize: [38, 38],
            popupAnchor: [0, -10],
            className: 'recycler-icon --recovery-project-map-point'
          });

          const mySupporterIcon = L.icon({
            iconUrl: IconCircle,
            iconSize: [38, 38],
            popupAnchor: [0, -10],
            className: 'recycler-icon --supporter-map-point'
          });

          const mySelectedSupporterIcon = L.icon({
            iconUrl: selectedIconCircle,
            iconSize: [38, 38],
            popupAnchor: [0, -10],
            className: 'recycler-icon --supporter-map-point'
          });

          const bounds = new L.LatLngBounds(new L.LatLng(85.0, -180.0), new L.LatLng(-85.0, 180.0));

          const map = L.map('map-stat', {
            'center': bounds.getCenter(),
            'zoom': 2,
            'trackResize': false,
            'maxBound': bounds,
            'maxBoundsViscosity': 1.0,
            'zoomControl': false,
            'layers': [
              L.tileLayer('https://api.mapbox.com/styles/v1/garciapd/cl4w6ylk1003o15lkwraq18sv/draft/tiles/{tileSize}/{z}/{x}/{y}@2x?access_token={accessToken}', {
                minZoom: 1,
                id: 'cl4w6ylk1003o15lkwraq18sv',
                tileSize: 512,
                zoomOffset: -1,
                accessToken: 'pk.eyJ1IjoiZ2FyY2lhcGQiLCJhIjoiY2w0cnd1dXk2MGcxYjNpbzB2NnViM3VudyJ9.t7T0_cx2_51iTXSUv9LiYA'
              })
            ]
          })

          mapInstance = map

          map.on('drag', function () {
            map.panInsideBounds(bounds, { animate: false });
          });

          const drawPointsOnMapByCoordinates = (coordinates, icon, selectedIcon, addedClass = '') => {
            for (let i = 0; i < coordinates.length || 0; i++) {
              if(!coordinates[i]) continue;
              if (coordinates[i][1] && coordinates[i][1].length === 2) {
                let marker = L.marker(coordinates[i][1], { icon: icon })
                marker.bindPopup(markerPopupHtml({
                  recyclerAddress: coordinates[i][3][0],
                  userImage: coordinates[i][3][2],
                  countryFlagClass: coordinates[i][4],
                  countryName: coordinates[i][0],
                  images: coordinates[i][3][4],
                  RpName: coordinates[i][3][1],
                  plasticSupported: coordinates[i][5] || 0,
                  addedClass: addedClass
                })).openPopup();
                marker.addTo(map).on('click', () => {
                  window.initExploreRecoveryProjectImagesCarousel();
                });
                marker.on('popupopen', (e) => {
                  e.target.setIcon(selectedIcon);
                });
                marker.on('popupclose', function(e) {
                  e.target.setIcon(icon);
                });
              }
            }
          }

          drawPointsOnMapByCoordinates(recovery_project_coordinates, myRPIcon, mySelectedRPIcon)
          drawPointsOnMapByCoordinates(supporter_coordinates, mySupporterIcon, mySelectedSupporterIcon, '--supporter')

          $('.stat__supporter__tab__btn.inner-map').on('click', function (e) {
            map.closePopup();
            $('.stat__supporter__tab__btn.inner-map').removeClass('active')
            $(e.currentTarget).addClass('active')
            let tab = $(e.currentTarget).data("filter") || "supporter"
            $(`.recycler-icon`).addClass('hide')
            $(`.recycler-icon.--${tab}-map-point`).removeClass('hide')
          })

          $(`.recycler-icon.--recovery-project-map-point`).addClass('hide')

          map.touchZoom.disable();
          map.doubleClickZoom.disable();
          map.scrollWheelZoom.disable();
          map.boxZoom.disable();
          map.keyboard.disable();
          map.dragging.disable();

          $(`#map-stat`).on('click', () => {
            map.dragging.enable();
            map.touchZoom.enable();
            map.doubleClickZoom.enable();
            map.scrollWheelZoom.enable();
            map.boxZoom.enable();
            map.keyboard.enable();
          })
        }
      }
    })
  }

  handleMapStatistic()

  function handlePlasticRecoveredChart() {
    if($('#plastic_recovered_chart').length === 0) return;

    let plastic_recovered_chart = $('#plastic_recovered_chart')
    let data_for_total_plastic_recovered_id = $('#data_for_total_plastic_recovered')
    let data = data_for_total_plastic_recovered_id.val() ? JSON.parse(data_for_total_plastic_recovered_id.val()) : []
    const recovered_data = {
      labels: data.labels,
      datasets: [
        {
          label: 'Direct',
          data: data.values,
          backgroundColor: '#2FCDB3',
          stack: 'Stack 0',
        },
        {
          label: 'Indirect',
          data: [],
          backgroundColor: '#7FA1DD',
          stack: 'Stack 0',
        },
      ]
    };

    let chartStatus = Chart.getChart(plastic_recovered_chart);
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    new Chart(plastic_recovered_chart, {
      type: 'bar',
      data: recovered_data,
      options: {
        plugins: {
          title: {
            display: false
          },
          legend: {
            display: false
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            ticks: {
              stepSize: 150
            }
          },
        }
      }
    })
  }

  function handleAcquisitionChart() {
    if($('#acquisition_chart').length === 0) return;

    let acquisition_chart = $('#acquisition_chart')
    let data_for_aquisition_chart_id = $('#data_for_aquisition_chart')
    let data = data_for_aquisition_chart_id.val() ? JSON.parse(data_for_aquisition_chart_id.val()) : []
    const acquisition_data = {
      labels: data.labels,
      datasets: [
        {
          label: 'Visits',
          data: data.visit_values,
          backgroundColor: '#2FCDB3',
          borderColor: '#2FCDB3',
          pointRadius: 5,
        },
        {
          label: 'Codes redeemed',
          data: data.codes_redeems_values,
          backgroundColor: '#7FA1DD',
          borderColor: '#7FA1DD',
          pointRadius: 5,
        },
      ]
    }

    let chartStatus = Chart.getChart(acquisition_chart);
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    new Chart(acquisition_chart, {
      type: 'line',
      data: acquisition_data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          }
        },
        scales: {
          y: {
            ticks: {
              stepSize: 20,
            }
          },
        }
      },
    })
  }

  handlePlasticRecoveredChart()
  handleAcquisitionChart()

  let startDateSuperScope = moment().subtract(6, 'days').format('YYYY-MM-DD')
  let endDateSuperScope = moment().format('YYYY-MM-DD')

  function fetchDataCampaignStatistic(start_date, end_date) {
    $.ajax({
      url: $('#statUrl').attr('href'),
      type: 'GET',
      data: {
        start_date: start_date,
        end_date: end_date
      },
      success: function (res) {
        $("div.loading-gif.displayInMiddle").hide();
        handleAcquisitionChart()
        handlePlasticRecoveredChart()
        mapInstance?.remove()
        mapInstance = null
        handleMapStatistic()
        $('.stat__supporter__tab__btn.outer-map').removeClass('active')
        $('.stat__supporter__tab__btn.outer-map.--supporter').addClass('active')
      }
    });
  }

  function fetchRecoveryProjects(start_date, end_date, sort_by = 'weight', sort_desc = true) {
    $.ajax({
      url: $('#getRPUrl').attr('href'),
      type: 'GET',
      data: {
        start_date: start_date,
        end_date: end_date,
        sort_by: sort_by,
        sort_desc: sort_desc
      },
      success: function (res) {
        $("div.loading-gif.displayInMiddle").hide();
      }
    });
  }

  function fetchSupporters(start_date, end_date, sort_by = 'weight', sort_desc = true) {
    $.ajax({
      url: $('#getSupportersUrl').attr('href'),
      type: 'GET',
      data: {
        start_date: start_date,
        end_date: end_date,
        sort_by: sort_by,
        sort_desc: sort_desc
      },
      success: function (res) {
        $("div.loading-gif.displayInMiddle").hide();
      }
    });
  }

  let sortDescWeightRp = 0
  $(document).on('click', '.sort-supported-kg.--recovery-project-weight', function(e) {
    $(e.currentTarget).toggleClass('invert')
    $("div.loading-gif.displayInMiddle").show();
    fetchRecoveryProjects(startDateSuperScope, endDateSuperScope, 'weight', sortDescWeightRp)
    sortDescWeightRp = +!sortDescWeightRp
  })
  let sortDescTimeRp = 0
  $(document).on('click', '.sort-supported-kg.--recovery-project-time', function(e) {
    $(e.currentTarget).toggleClass('invert')
    $("div.loading-gif.displayInMiddle").show();
    fetchRecoveryProjects(startDateSuperScope, endDateSuperScope, 'time', sortDescTimeRp)
    sortDescTimeRp = +!sortDescTimeRp
  })
  let sortDescWeightSupporter = 0
  $(document).on('click', '.sort-supported-kg.--supporter-weight', function(e) {
    $(e.currentTarget).toggleClass('invert')
    $("div.loading-gif.displayInMiddle").show();
    fetchSupporters(startDateSuperScope, endDateSuperScope, 'weight', sortDescWeightSupporter)
    sortDescWeightSupporter = +!sortDescWeightSupporter
  })
  let sortDescTimeSupporter = 0
  $(document).on('click', '.sort-supported-kg.--supporter-time', function(e) {
    $(e.currentTarget).toggleClass('invert')
    $("div.loading-gif.displayInMiddle").show();
    fetchSupporters(startDateSuperScope, endDateSuperScope, 'time', sortDescTimeSupporter)
    sortDescTimeSupporter = +!sortDescTimeSupporter
  })

  function handleDatePicker() {
    var start = moment().subtract(6, 'days');
    var end = moment();

    function cb(start, end) {
      $('#report_daterange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
      $("div.loading-gif.displayInMiddle").show();
      startDateSuperScope = start.format('YYYY-MM-DD')
      endDateSuperScope = end.format('YYYY-MM-DD')
      fetchDataCampaignStatistic(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
      sortDescWeightRp = sortDescTimeRp = sortDescWeightSupporter = sortDescTimeSupporter = 0
    }

    $('#report_daterange').daterangepicker({
        startDate: start,
        endDate: end,
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 days': [moment().subtract(6, 'days'), moment()],
            'Last 30 days': [moment().subtract(29, 'days'), moment()],
            'Last 90 days': [moment().subtract(89, 'days'), moment()],
            'Last 180 days': [moment().subtract(179, 'days'), moment()],
        },
        locale: {
          fromLabel: "Start Date",
          toLabel: "End Date",
          firstDay: 1,
          separator: " to "
        }
    }, cb);
  }

  handleDatePicker();

  $('.stat__supporter__tab__btn.outer-map').on('click', function (e) {
    $('.stat__supporter__tab__btn.outer-map').removeClass('active')
    $(e.currentTarget).addClass('active')

    const tab = $(e.currentTarget).data('filter') || 'supporters'
    $(`.data-table`).addClass('hide')
    $(`.data-table.--${tab}`).removeClass('hide')
    $(`.empty-data`).addClass('hide')
    $(`.empty-data.--${tab}`).removeClass('hide')

    $(`.stat__supporter__pagination`).addClass('hide')
    $(`.stat__supporter__pagination.--${tab}`).removeClass('hide')
  })
})
