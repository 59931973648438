import { debounce } from "./utils";
import IconCircle from '../../assets/images/ic-map-circle-hover.svg';
import IconHoverCircle from '../../assets/images/ic-map-circle-selected.svg';
import selectedIconCircle from '../../assets/images/ic-map-circle-hover-yellow.svg';
import nextIcon from '../../assets/images/next-icon.svg';
import previousIcon from '../../assets/images/prev-icon.svg';

$(document).ready(function () {
  let progressValue = parseFloat($('.progress-bar').attr('aria-valuenow'));
  let milestones = [
    { percent: 25, dot: $('.milestone__25 .dot') },
    { percent: 50, dot: $('.milestone__50 .dot') },
    { percent: 75, dot: $('.milestone__75 .dot') },
  ];

  for (let i = 0; i < milestones.length; i++) {
    if (progressValue >= milestones[i].percent) {
      milestones[i].dot.addClass('completed');
    } else {
      milestones[i].dot.removeClass('completed');
    }
  }

  if ($('#select_recovery_project_map').length > 0) {
    let currentZoomLevel = null;
    let currentCenter = null;

    const setAllMapMarkersToDefaultIcon = (layer) => {
      if(layer.getAllChildMarkers) {
        layer.getAllChildMarkers().forEach((subLayer) => {
          if (layer instanceof L.Marker) subLayer?.setIcon(myIcon)
        });
      } else {
        if (layer instanceof L.Marker) layer?.setIcon(myIcon)
      }
    }

    const buildCarouselImagesHtml = (images) => {
      let carouselHtml = ''
      images.forEach(image => {
        carouselHtml += `<div class="owl-item">
                            <img class='' src=${image} />
                          </div>`
      })

      return carouselHtml;
    }

    const markerPopupHtml = ({recyclerAddress, userImage, countryFlagClass, countryName, images, RpName, plasticSupported}) =>
      `<div class="map__pop-up">
        <div class='map__pop-up__banner-image'>
          <div class="main__carousel explore-recovery-project-profile-images owl-carousel owl-loaded" id="${recyclerAddress}exploreRecoveryProjectProfileImages">
            <div class="owl-stage-outer owl-height">
              <div class="owl-stage">
                ${buildCarouselImagesHtml(images)}
              </div>
            </div>
            <button class="home__nav home__nav--prev" data-nav="#${recyclerAddress}exploreRecoveryProjectProfileImages" type="button">
              <img class='' src=${previousIcon} />
            </button>
            <button class="home__nav home__nav--next" data-nav="#${recyclerAddress}exploreRecoveryProjectProfileImages" type="button">
              <img class='' src=${nextIcon} />
            </button>
          </div>
        </div>
        <div class="map__pop-up__title">
          <div class='map__pop-up__title__recovert-project-info'>
            <div class="img">
              <a href='/users/${recyclerAddress}/recovery_project_profile'>
                <img class='' src=${userImage} />
              </a>
            </div>
            <div class="content">
              <div class='font-size-14-text font-weight-600-text text-truncate-1'>
                <a href='/users/${recyclerAddress}/recovery_project_profile'>
                  ${RpName}
                </a>
              </div>
              <span class="map__pop-up__description font-size-12-text text-truncate-1 neutral-500-color-text">
                <i class='${countryFlagClass}'></i>
                ${countryName}
              </span>
            </div>
          </div>
          <div class='map__pop-up__title__recycle-info'>
            <div class='recycle-quantity font-weight-600-text text-truncate-1'>
              ${plasticSupported} kg
            </div>
            <div class='font-size-12-text neutral-500-color-text map__pop-up__title__recycle-info__text'>
              plastic recovered
            </div>
          </div>
        </div>
      </div>`

    let recycler_country_coordinates_id = $('#recycler_country_coordinates');
    let coordinates = []

    try {
      coordinates = JSON.parse(recycler_country_coordinates_id.val())
    } catch {
      coordinates = []
    }

    const myIcon = L.icon({
      iconUrl: IconCircle,
      iconSize: [38, 38],
      className: 'recycler-icon'
    });

    const myYellowIcon = L.icon({
      iconUrl: selectedIconCircle,
      iconSize: [38, 38],
      className: 'recycler-icon'
    });

    const myHoverIcon = L.icon({
      iconUrl: IconHoverCircle,
      iconSize: [38, 38],
      className: 'recycler-icon'
    });

    const bounds = new L.LatLngBounds(new L.LatLng(85.0, -180.0), new L.LatLng(-85.0, 180.0));

    const map = L.map('select_recovery_project_map', {
      'center': bounds.getCenter(),
      'zoom': 2,
      'trackResize': false,
      'maxBound': bounds,
      'maxBoundsViscosity': 1.0,
      'layers': [
        L.tileLayer('https://api.mapbox.com/styles/v1/garciapd/clrkjctdu002401pjc8h3213n/draft/tiles/{tileSize}/{z}/{x}/{y}@2x?access_token={accessToken}', {
          minZoom: 1,
          id: 'clckcwc4j002q14qn66nlvor6',
          tileSize: 512,
          zoomOffset: -1,
          accessToken: 'pk.eyJ1IjoiZ2FyY2lhcGQiLCJhIjoiY2w0cnd1dXk2MGcxYjNpbzB2NnViM3VudyJ9.t7T0_cx2_51iTXSUv9LiYA'
        })
      ]
    })

    map.on('drag', function () {
      map.panInsideBounds(bounds, { animate: false });
    });

    let markers = L.markerClusterGroup({
      showCoverageOnHover: false,
      iconCreateFunction: function (cluster) {
        const html = `
          <div class="cluster-markers-group-content">
            <span>${cluster.getChildCount()}</span>
            <img src="${IconCircle}">
          </div>
        `
        return L.divIcon({ html, className: 'prg-map-cluster-markers-group-icon', iconSize: L.point(40, 40) });
      },
    });

    for (let i = 0; i < coordinates.length; i++) {
      if(!coordinates[i]) continue;

      if (coordinates[i][0] && coordinates[i][0].length === 2) {
        let marker = L.marker(coordinates[i][0], { icon: myIcon, popUpID: `${coordinates[i][1]}landingRPCard` })
        markers.addLayer(marker);
      }
    }

    map.addLayer(markers);
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    map.dragging.disable();

    markers.on('click', (e) => {
      map.eachLayer((l) => {
        setAllMapMarkersToDefaultIcon(l);
      })

      currentZoomLevel = map.getZoom();
      currentCenter = e.layer.getLatLng();

      e.layer.setIcon(myYellowIcon)

      $(".landing__map__pop-up").addClass('hide')
      $(`#${e.sourceTarget.options.popUpID}`).removeClass('hide')
    });

    $(`#select_recovery_project_map`).on('click', () => {
      map.dragging.enable();
      map.touchZoom.enable();
      map.doubleClickZoom.enable();
      map.scrollWheelZoom.enable();
      map.boxZoom.enable();
      map.keyboard.enable();
    })

    $(".faq-section__item").on('click', ({target}) => {
      let toggle = $(target).closest('.faq-section__item').data("toggle")
      $(`#toggle-${toggle}`).toggleClass('d-block')
      $(target).closest('.faq-section__item').toggleClass('active')
    })

    $('#open-photo-popup').on('click', () => {
      $.magnificPopup.open({
        closeOnBgClick: true,
        enableEscapeKey: false,
        items: {
          src: '#allPhotoModal'
        }
      });
    })

    $('.open-modal--suppoters').magnificPopup({
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: 'auto',
      type: 'inline',
      preloader: false,
      modal: false,
      closeOnBgClick: true,
      enableEscapeKey: false,
    });

    $('.landing__map__pop-up__support__wrapper').on('click', function(e) {
      $('img.redeem-code__container--content__project--avatar__img').attr('src', $('.landing__map__pop-up:not(.hide) .landing__map__pop-up__title__recovert-project-info img').attr('src'))
      $('.redeem-code__container--content__project--info__name').html($('.landing__map__pop-up:not(.hide) .landing__map__pop-up__title__recovert-project-info__name').html())
      $('.redeem-code__container--content__project--info__country span').html($('.landing__map__pop-up:not(.hide) .landing__map__pop-up__country span').html())
      const flag = $('.landing__map__pop-up:not(.hide) .landing__map__pop-up__country i').clone()
      $('.redeem-code__container--content__project--info__country i').replaceWith(flag)
      $('.redeem-code__container--content__input #recovery_project_address').val($('.landing__map__pop-up:not(.hide) .landing__map__pop-up__title__recycler-info__address').val())

      $.magnificPopup.open({
        closeOnBgClick: false,
        enableEscapeKey: false,
        items: {
          src: '#redeem-code-modal'
        },
        type: 'inline'
      });    
    })

    $('.redeem-code__container--content__input--button.--support').on('click', function(e) {
      e.stopPropagation()
    })

    $(document).on("ajax:before", (e) => {
      e.stopPropagation()
      $("#redeem-code-modal .mfp-close").trigger('click')

      $.magnificPopup.open({
        closeOnBgClick: false,
        enableEscapeKey: false,
        items: {
          src: '#loading-modal'
        },
        type: 'inline'
      });
    });
  
    $(document).on("ajax:complete", (e) => {
      e.stopPropagation()
    });
  }

  window.triggerSuccessRedeemModal = function triggerSuccessRedeemModal(e) {
    $.magnificPopup.open({
      closeOnBgClick: false,
      enableEscapeKey: false,
      items: {
        src: '#redeem-code-modal-success'
      },
      type: 'inline'
    });
  }

  window.triggerRedeemModal = function triggerRedeemModal(e) {
    $.magnificPopup.open({
      closeOnBgClick: false,
      enableEscapeKey: false,
      items: {
        src: '#redeem-code-modal'
      },
      type: 'inline'
    });
  }
});
