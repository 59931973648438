$(document).ready(function() {
  if ($('.recovery-projects-home-page').length > 0) {
    $('#selecting-recovery-projects-country-filter').on('change', ({target}) => {
      $(".loading-gif").show();
      const country = $(target).val() !== 'Show all' ? $(target).val() : '';
      const sort_filter = $('.search-filter-before').data('filter');

      $.ajax({
        url: `/recovery_projects_sort_by?country=${country}${sort_filter}`,
        type: "get",
        dataType: "script",
        data: {}
      });
    })

    $('body').on('mouseover', '.explore-recovery-project-profile-images', ({target}) => {
      $($(target).closest('.explore-recovery-project-profile-images')).find('.home__nav').removeClass('d-none')
    })

    $('body').on('mouseout', '.explore-recovery-project-profile-images', ({target}) => {
      $($(target).closest('.explore-recovery-project-profile-images')).find('.home__nav').addClass('d-none')
    })
  }
  $('#show-recovery-project-map-button').on('click', () => {
    $.magnificPopup.open({
      closeOnBgClick: true,
		  enableEscapeKey: false,
      items: {
        src: '#recovery-project-show-map-popup'
      },
      type: 'inline',
      callbacks: {
        close: () => {
          // triggerUpdateCenterForMiniMap();
          window.triggerChangeRecyclerSlide();
          // triggerUpdateRecyclerPointForMiniMap();
        }
      }
    });

    if ($('#recovery-project-map').length > 0) {
      window.initRecoveryEntitiesMap('recovery-project-map', true);
    }
  })
})

