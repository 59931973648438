$(document).ready(function () {
  $('.header-mobile__bar__item').on('click', function() {
    $('.header-mobile__bar__item').removeClass("show");
    $(this).addClass('show')
  })
  
  $("#about_us").on("click", function () {
    $(".about-us").magnificPopup().magnificPopup('open');
  })
  
  $(".btn__cancel").on("click", function() {
    $(".about-us").magnificPopup().magnificPopup('close');;
  });

  $("#about_us--mobile").on("click", function() {
    $(".about-us").magnificPopup().magnificPopup('open');
  });
  
  $('#get_plastik--mobile').on('click', function() {
    $(".get-plastik").magnificPopup({
    }).magnificPopup('open');
  })
  
  const copyToClipboard = () => {
		const copyBtn = $('#wallet-copy');
		const address = $('#my-wallet__address').data('address');
		copyBtn.on('click', function() {
			const textArea = document.createElement('textarea');
			textArea.value = address;
	
			textArea.style.position = 'fixed';
			textArea.style.left = '-999999px';
			textArea.style.top = '-999999px';
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			document.execCommand('copy');
	
			textArea.remove();
			toastr.success('Text has been copied to clipboard!');
		});
	}

  const toggleHeaderMenu = () => {
		$('#toggle-send').on('click', function () {
			$(".my-wallet").removeClass('show-menu-bar').addClass('hide-menu-bar');
			$(".send-plastik").removeClass('hide-menu-bar').addClass('show-menu-bar');
		});

		$('.my-wallet__back-btn').on('click', function () {
			$(".my-wallet").removeClass('show-menu-bar').addClass('hide-menu-bar');
			$("#header__profile-wallet").attr('style', 'right: 0 !important');
		});

		$('.send-plastik__back-btn').on('click', function () {
			$(".my-wallet").removeClass('hide-menu-bar').addClass('show-menu-bar');
			$(".send-plastik").removeClass('show-menu-bar').addClass('hide-menu-bar');
		});

		$('#header__profile-menu__wallet').on('click', function () {
			$("#header__profile-wallet").attr('style', 'right: -428px !important');
			$(".my-wallet").removeClass('hide-menu-bar').addClass('show-menu-bar');
      $(document).on("mouseup", outsideClickHandler);
		});

    $("#buy-plastik").on("click", function (event) {
      event.stopPropagation();
      $(".get-plastik").magnificPopup({
        callbacks: {
        close: function() {
          $(".my-wallet").removeClass('hide-menu-bar')
          $(".my-wallet").addClass('show-menu-bar');
          $(document).on("mouseup", outsideClickHandler);
        }
      }
      }).magnificPopup('open');
    })

		// Close menu when clicking outside
    $(document).on('click', function(event) {
      if(event.originalEvent === undefined){
        console.log('triggered')
      }else {
        const isMenuClicked = $(event.target).closest('#header__profile-wallet, .my-wallet, .send-plastik').length > 0;
        const isClickShowMenu = $(event.target).closest('#show_profile_menu').length > 0 || $(event.target).attr('id') == 'menu-profile_wallet';
        const isGetPlastikClicked = $(event.target).attr('id') == 'get_plastik_link'; 
        if(!isMenuClicked && !isClickShowMenu && !isGetPlastikClicked){
          $("#header__profile-wallet").attr('style', 'right: -428px !important');
          // $(".my-wallet, .send-plastik").removeClass('show-menu-bar').addClass('hide-menu-bar');
          $('input#menu-profile_wallet').prop('checked', false);
        }else if(isClickShowMenu){
          event.stopPropagation();
          if(!$('input#menu-profile_wallet').is(':checked')) {
            let headerHeight = $('.header').outerHeight() + 15;
            $('#header__profile-wallet').attr('style', 'padding-top: '+ headerHeight +'px !important')
            $(".my-wallet, .send-plastik").removeClass('show-menu-bar').addClass('hide-menu-bar');
            $("#header__profile-wallet").attr('style', 'right: -428px !important');
          }else{
            let headerHeight = $('.header').outerHeight() + 15;
            $("#header__profile-wallet").attr('style', 'right: 0 !important');
            $('#header__profile-wallet').attr('style', 'padding-top: '+ headerHeight +'px !important')

          }
        }
      }
    });

  }
  copyToClipboard();
  toggleHeaderMenu();

  console.log('header  height', $('.header__profile-menu').outerHeight());
  $('.my-wallet__buy-plastik-btn, #wallet_get_plastik').on('click', function(){
    $.magnificPopup.close();
    $("#crypto-widget button").trigger("click")
    $("#header__profile-wallet").attr('style', 'right: -428px !important');
    $(".my-wallet, .send-plastik").removeClass('show-menu-bar').addClass('hide-menu-bar');
    $('input#menu-profile_wallet').prop('checked', false);
  });

  /* Multi level dropdowns
	==============================*/
	$('ul.dropdown-menu [data-toggle="dropdown"]').on('click', function (event) {
		event.preventDefault();
		event.stopPropagation();

		$(this).siblings().toggleClass('show');
	});


	$(document).on('click', function (e) {
		$('.dropdown-menu').removeClass('show');
	});

  $('#max-amount').on('click', function () {
    $('#input-plastik-amount').val($('#plastik-balance').val());
    if (amount.validity.valid && amountError) {
      amountError.textContent = '';
      amountError.className = 'error';

      amount.classList.remove('input-error');
    }
  });

  // send token form validation
  const address = document.getElementById('input-address-receive');
  const addressError = document.querySelector('#input-address-receive + span.error')

  const amount = document.getElementById('input-plastik-amount');
  const amountError = document.querySelector('#input-plastik-amount + span.error')

  function amountRequiredError(amount, amountError) {
    if (amount.validity.valueMissing && amountError) {
      amountError.textContent = 'Amount is required!';
      amountError.className = 'error active';

      amount.classList.add('input-error');
    }
  }

  function amountIncorrectError(amount, amountError) {
    amountError.textContent = 'The amount you entered exceeds your current balance.';
    amountError.className = 'error active';

    amount.classList.add('input-error');
  }

  function addressRequiredError(address, addressError) {
    if (address.validity.valueMissing && addressError) {
      addressError.textContent = 'Address is required!';
      addressError.className = 'error active';

      address.classList.add('input-error');
    }
  }

  function addressIncorrectShowError(address, addressError) {
    addressError.textContent = 'The address you entered could not be found.';
    addressError.className = 'error active';

    address.classList.add('input-error');
  }

  $("#input-address-receive").on("keyup", async function (e) {
      if(!window.web3.utils.isAddress(address.value) || address.value == await getCurrentAccount()){
        addressIncorrectShowError(address, addressError);
      }else if(address.value == ''){
        addressRequiredError(address, addressError);
      }else{
        addressError.textContent = '';
        addressError.className = 'error';

        address.classList.remove('input-error');
      }
  })

  $(document).on('keyup', '#input-plastik-amount', function () {
    let plastikBalanace = parseFloat($('#plastik-balance').val());
    let amountPlastik = parseFloat(amount.value);

    if (amount.validity.valid && amountError) {
      amountError.textContent = '';
      amountError.className = 'error';

      amount.classList.remove('input-error');
    } else {
      amountRequiredError(amount, amountError);
    }

    if(amountPlastik > plastikBalanace){
      amountIncorrectError(amount, amountError);
    }
  });

  $(document).on('change', '#input-plastik-amount, #input-address-receive', function () {
    let amountInput = $('#input-plastik-amount').val();
    let toAddressInput = $('.send-plastik #input-address-receive').val();

    if(toAddressInput != '' && amountInput != ''){
      getMaxGasFeesTotal(toAddressInput, amountInput);
    }
  });

  /////////////////// end validation
  $(document).on("click", '#send-plastiks, #icon-send-plastiks', async function (event) {
    event.preventDefault()
    $('#progress-status-loading').removeClass('d-none');
    $(this).find('.btn-text').addClass('d-none');
    var form = document.getElementById("send_plastik_form");
    let toAddress = $('.send-plastik #input-address-receive').val();
    let plastikBalanace = parseFloat($('#plastik-balance').val());
    let amountPlastik = parseFloat(amount.value);

    if(form.checkValidity()){
      if(amountPlastik > plastikBalanace){
        amountIncorrectError(amount, amountError);
        $('#progress-status-loading').addClass('d-none');
        $(this).find('.btn-text').removeClass('d-none');
        return false;
      }
  
      if(!window.web3.utils.isAddress(address.value) || address.value == await getCurrentAccount()){
        addressIncorrectShowError(address, addressError);
        $('#progress-status-loading').addClass('d-none');
        $(this).find('.btn-text').removeClass('d-none');
        return false;
      }

      clearToastr();
      $(this).addClass('disabled');
      $('#input-plastik-amount').prop('disabled', true);
      $('#input-address-receive').prop('disabled', true);
      initSendProcess(amountPlastik, toAddress);
    } else {
      $('#progress-status-loading').addClass('d-none');
      $(this).find('.btn-text').removeClass('d-none');
      amountRequiredError(amount, amountError);
      addressRequiredError(address, addressError);
    }
  })

  $('.sign-out').on('click', function(){
    $('#sign-out-loading-icon').removeClass('d-none');
    $(this).attr('disabled', true);
  })
})


function closeMyWallet() {
  $(".my-wallet").removeClass("show-menu-bar").addClass("hide-menu-bar");
  $(document).off("mouseup", outsideClickHandler);
}

function outsideClickHandler(event) {
  if (
    !$(event.target).closest('#header__profile-wallet, .my-wallet, #buy-plastik, #buyPlastikModal').length > 0
  ) {
    closeMyWallet();
  }
}
