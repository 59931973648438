export const triggerEventRecoveryProjectSustainability = () => {
  const setDefaultSwitch = (master, slave) => {
    $(slave).removeClass('active')
    $(master).removeClass('active')
    $(master).addClass('active')
  }
  const openRecoveryProjectProfileModalMobile = (modal) => {
    $('#recovery-project-profile-page').addClass('hide');
    $('#recovery-project-profile-page__modal').removeClass('hide');
    $("html, body").animate({ scrollTop: 0 });
    $(modal).removeClass('hide')
  }

  const fetchAllTypePlactics = (screen) => {
    const address = $('#user-address').val();

    $.ajax({
      url: `/users/${address}/view_all_plastics`,
      type: 'get',
      dataType: 'json',
      data: {},
      success: (res) => {
        switch (screen) {
          case 'desktop':
            setDefaultSwitch('#switch__recovered--desktop', '#switch__supported--desktop')
            $(`.modal-body`).closest('#allPlasticTypeModal').find('.table-plastic-type').html(res.html)
    
            $.magnificPopup.open({
              closeOnBgClick: true,
              enableEscapeKey: true,
              items: {
                src: '#allPlasticTypeModal'
              }
            });
            break
          case 'mobile':
            $('.all-plastic-type.--mobile').find('.modal__plastic-type__data').html(res.html)
            break
        }
      }
    });
  }

  // Button Show All Plastic Type
  $('.js__view-all-plastic-type.--desktop').on('click', (e) => {
    e.preventDefault();

    fetchAllTypePlactics('desktop')

    $('.switch__supported').on('click', (e) => {
      e.preventDefault();

      $('.switch__recovered.active').removeClass('active')
      $(`.switch__supported`).addClass('active')

      $('.table--recovered').addClass('hide')
      $(`.table--supported`).removeClass('hide')
    })

    $('.switch__recovered').on('click', (e) => {
      e.preventDefault();

      $('.switch__supported.active').removeClass('active')
      $(`.switch__recovered`).addClass('active')

      $('.table--supported').addClass('hide')
      $(`.table--recovered`).removeClass('hide')
    })
  })

  $('.js__view-all-plastic-type.--mobile').on('click', (e) => {
    e.preventDefault();

    fetchAllTypePlactics('mobile')
    openRecoveryProjectProfileModalMobile('.modal__plastic-type.--mobile')

    $('.switch__supported').on('click', (e) => {
      e.preventDefault();

      let tab = 'supported'
      let parentElement = '.all-plastic-type'

      activeSwitch('mobile', tab, parentElement)
    })

    $('.switch__recovered').on('click', (e) => {
      e.preventDefault();
      let tab = 'recovered'
      let parentElement = '.all-plastic-type'

      activeSwitch('mobile', tab, parentElement)
    })
  })
  // End Button Show All Plastic Type

  // View Supporters
  const fetchSupporters = (screen, tab) => {
    const address = $('#user-address').val();

    $.ajax({
      url: `/users/${address}/view_all_supporter?tab=${tab}&screen=${screen}`,
      type: 'get',
      dataType: 'json',
      data: {},
      success: (res) => {
        if(screen === 'mobile') {
          $('.all-suppoter-modal.--mobile').find('.supporter-data').html(res.html);
        } else {
          setDefaultSwitch(".switch__total", ".switch__historical")
          $(`#allSupporterModal`).find('#table-supporter').html(res.html);

          $.magnificPopup.open({
            closeOnBgClick: true,
            enableEscapeKey: true,
            items: {
              src: '#allSupporterModal'
            }
          });
        }
      }
    });
  }

  const fetchSupportersCampaign = (screen, tab) => {
    const address = $('#user-address').val();

    $.ajax({
      url: `/users/${address}/view_all_supporter_campaign?tab=${tab}&screen=${screen}`,
      type: 'get',
      dataType: 'json',
      data: {},
      success: (res) => {
        if(screen === 'mobile') {
          $('.all-sustainable-campaign.--mobile').find('.supporter-data').html(res.html);
        } else {
          $(`#allSupporterModalCampaign`).find('#table-supporter-campaign').html(res.html);

          $.magnificPopup.open({
            closeOnBgClick: true,
            enableEscapeKey: true,
            items: {
              src: '#allSupporterModalCampaign'
            }
          });
        }
      }
    });
  }
  
  const activeSwitch = (screen, tab, parentElement) => {
    $(`${parentElement} .switch__default.active`).removeClass('active')
    $(`.switch__${tab}`).addClass('active')

    if (screen === 'desktop' || parentElement === '.all-plastic-type') {
      $(`${parentElement} .table`).addClass('hide')
      $(`.table--${tab}`).removeClass('hide')
    }

    if (parentElement === '.all-suppoter-modal') {
      $('.modal__supporters__title').addClass('hide')
      $(`.modal__supporters__title.--${tab}`).removeClass('hide')
    }
  }

  // Button Show All Supporter
  $('.js__view-all-supporter.--desktop').on('click', (e) => {
    e.preventDefault();

    let tab = 'total'
    fetchSupporters('desktop', tab)

    // Desktop All Supporter
    $('#allSupporterModal .switch__historical').on('click', (e) => {
      e.preventDefault();

      let tab = 'historical'
      let parentElement = '#allSupporterModal'

      activeSwitch('desktop', tab, parentElement)
    })

    $('#allSupporterModal .switch__total').on('click', (e) => {
      e.preventDefault();

      let tab = 'total'
      let parentElement = '#allSupporterModal'

      activeSwitch('desktop', tab, parentElement)
    })
    // End Desktop All Supporter
  })

  $('.js__view-all-supporter.--mobile').on('click', (e) => {
    e.preventDefault();

    let tab = 'total'
    fetchSupporters('mobile', tab)
    openRecoveryProjectProfileModalMobile('.modal__supporters')

    // Mobile All Supporter
    $('.all-suppoter-modal.--mobile .switch__historical').on('click', (e) => {
      e.preventDefault();

      let tab = 'historical'
      let parentElement = '.all-suppoter-modal'

      activeSwitch('mobile', tab, parentElement)

      $('.supporter-data--historical').removeClass('hide')
      $('.supporter-data--total').addClass('hide')
    })

    $('.all-suppoter-modal.--mobile .switch__total').on('click', (e) => {
      e.preventDefault();

      let tab = 'total'
      let parentElement = '.all-suppoter-modal'

      activeSwitch('mobile', tab, parentElement)
      $('.supporter-data--historical').addClass('hide')
      $('.supporter-data--total').removeClass('hide')
    })
    // End Mobile All Supporter

  })
  // End Button Show All Supporter
  $('.js__view-all-supporter-campaign.--desktop').on('click', (e) => {
    e.preventDefault();

    let tab = 'total'
    fetchSupportersCampaign('desktop', tab)

    // Desktop All Supporter
    $('#allSupporterModalCampaign .switch__historical').on('click', (e) => {
      e.preventDefault();

      let tab = 'historical'
      let parentElement = '#allSupporterModalCampaign'

      activeSwitch('desktop', tab, parentElement)
    })

    $('#allSupporterModalCampaign .switch__total').on('click', (e) => {
      e.preventDefault();

      let tab = 'total'
      let parentElement = '#allSupporterModalCampaign'

      activeSwitch('desktop', tab, parentElement)
    })
    // End Desktop All Supporter
  })

  $('.js__view-all-supporter-campaign.--mobile').on('click', (e) => {
    e.preventDefault();

    let tab = 'total'
    fetchSupportersCampaign('mobile', tab)
    openRecoveryProjectProfileModalMobile('.all-sustainable-campaign')
    $('.supporter-data--total').addClass('hide')
    // End Mobile All Supporter
  })
}
