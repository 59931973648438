import axios from "axios";

$(document).ready(function () {
  var rRGsSelected = []
  const minimumRequired = parseInt($("#minimum_required").val())

  $(".field__radio__check").bind('touchstart mousedown', function (e) {
    selectRG(e.currentTarget)
    let fiat_price = (parseFloat($(".merge-nft__bill__guarantee__volume span").html()) * (10 ** 9)) / BigNumber($("#plastik_ratio").val())
    $(".merge-nft__bill__guarantee__price span").html(fiat_price.toFixed(2))
    reCaculateTotalPrice()

    if (parseInt($(".merge-nft__note > p > span").html()) >= minimumRequired) {
      $(".merge-nft__btn").removeAttr('disabled')
      $("#not_enough_plastics").addClass("hide")
    } else {
      $(".merge-nft__btn").attr("disabled", true)
    }
  });

  $("#merge-nft__filter__btn").on("click", function (e) {
    $("#merge-nft__filter-table").toggleClass("hide")
  })

  $(".merge-nft__btn").on("click", async function (e) {
    await loadTokenBalance($('#buyContractAddress').text(), $('#buyContractDecimals').text());
    await $('#buy_price').text($(".merge-nft__bill__total__volume span").html());
    await $('#buy_price').attr('price', $(".merge-nft__bill__total__volume span").html());
    await calculateMerge($('#serviceFee').text());

    if (!$(e.currentTarget).hasClass("--mobile")) {
      await $.magnificPopup.open({
        closeOnBgClick: false,
        enableEscapeKey: false,
        items: {
          src: '#merge-modal'
        },
        type: 'inline'
      });
    } else {
      $(".triggerBuyValidation").trigger("click")
    }
  });

  $(".merge-group-btn").on("click", function (e) {
    if ($(".merge-nft__btn")[0].getAttribute("disabled") != null) {
      $("#not_enough_plastics").css('display', 'flex');
      $("#not_enough_plastics").css('justify-content', 'center');
      $("#not_enough_plastics").css('margin-top', '5px');
    }
  })

  function reCaculateTotalPrice() {
    $(".merge-nft__bill__total__volume span").html(parseFloat($(".merge-nft__bill__nft__volume span").html()) + parseFloat($(".merge-nft__bill__guarantee__volume span").html()))
    const totalArt = parseFloat($(".merge-nft__bill__nft__price span").html().replace(',', ''));
    const totalRRG = parseFloat($(".merge-nft__bill__guarantee__price span").html().replace(',', ''));
    $(".merge-nft__bill__total__price span").html((totalArt + totalRRG).toFixed(2));
  }

  function deSelectAllRGs() {
    const selectedRGs = $("tr.selected > td").map(function () { return $(this).data("id") }).get()

    selectedRGs.forEach(x => {
      selectRG(`td[data-id=${x}] > input`)
    })
    let fiat_price = (parseFloat($(".merge-nft__bill__guarantee__volume span").html()) * (10 ** 9)) / BigNumber($("#plastik_ratio").val())
    $(".merge-nft__bill__guarantee__price span").html(fiat_price.toFixed(2))

    // $(".merge-nft__bill__guarantee__price span").html(($(".merge-nft__bill__guarantee__volume span").html() * parseFloat($("#plastik_ratio").val())).toFixed(2))
    reCaculateTotalPrice()

    if (parseInt($(".merge-nft__note > p > span").html()) >= minimumRequired) {
      $(".merge-nft__btn").removeAttr('disabled')
      $("#not_enough_plastics").addClass("hide")
    } else {
      $(".merge-nft__btn").attr("disabled", true)
    }
  }

  function selectRG(rgInputSelector) {
    const self = $(rgInputSelector).first()

    self.toggleClass("field__radio--checked")
    self.closest("tr").toggleClass("selected")

    var currWeightSelected = parseInt($(".merge-nft__note > p > span").html())
    var currPriceSelected = $(".merge-nft__bill__guarantee__volume span").html()
    // UPDATE TOTAL PRICE
    if (self.hasClass("field__radio--checked")) {
      currWeightSelected = currWeightSelected + +self.closest("td").data("weight")

      $(".merge-nft__note > p > span").html(currWeightSelected)
      $(".merge-nft__bill__guarantee__volume span").html(parseFloat(currPriceSelected) + parseFloat(self.closest("td").data("price")))
      rRGsSelected = [...rRGsSelected, self.closest("td").data("id")]
    } else {
      currWeightSelected = currWeightSelected - +self.closest("td").data("weight")

      $(".merge-nft__note > p > span").html(currWeightSelected)
      $(".merge-nft__bill__guarantee__volume span").html(parseFloat(currPriceSelected) - parseFloat(self.closest("td").data("price")))
      rRGsSelected = rRGsSelected.filter(x => x !== self.closest("td").data("id"))
    }
  }

  window.autoSelect = function autoSelect(weightList, requiredWeight) {
    let sortedArr = weightList.sort((a, b) => a - b)
    let currSum = 0
    let currSelected = []

    sortedArr.forEach(e => {
      if (currSum < requiredWeight) {
        currSelected.push(e)
        currSum += e
      }
    })

    while (currSum >= (requiredWeight + currSelected[0])) {
      currSum -= currSelected.shift()
    }

    return currSelected
  }

  window.fetchRGData = async function fetchRGData() {
    const token = document.querySelector('[name=csrf-token]').content
    let account = await getCurrentAccount();
    const giftAddress = $('#giftAddress').val();
    if(giftAddress) account = giftAddress;
    return await axios.post(`/collections/${$("#collection_id").val()}/fetch_merge_data`,
      { prgInputQuantity: $('#kg-input').val(), recyclerId: $('#defaut-recycler-id').val(), plasticType: $('#defaut-plastic-type').val(), art_supporter_id: $('#art_supporter_id').val() , sendTo: account, nft_amount: $('#nft_amount').val() || 1, defaultPrgsIds: $('#defaut-prgs-ids').val(), defaultKgNumberList: $('#kg-input').val()}, getHeader(token))
      .then(response => response.data)
      .catch(err => console.log(err))
  }

  function getHeader(token) {
    return {
      headers: {
        'X-CSRF-TOKEN': token,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }
  }

  // FILTERS
  window.filterMaterial = function filterMaterial(material) {
    $("#MergeNFTMaterial > span").html(material)

    if (material == "Any") {
      $("tbody > tr").show()
    } else {
      $("tbody > tr").hide()
      $(`td[data-material='${material}']`).closest("tr").show()
    }
  }

  window.filterContinent = function filterContinent(continent) {
    $("#MergeNFTContinent > span").html(continent)

    if (continent == "Any") {
      $("tbody > tr").show()
    } else {
      $("tbody > tr").hide()
      $(`td[data-continent='${continent}']`).closest("tr").show()
    }
  }

  window.calculateMerge = function calculateMerge(feePercentage) {
    var price = $(".merge-nft__bill__total__price span").html();
    var priceDollar = $(".merge-nft__bill__total__volume span").html();
    var qty = $('#buy_qty').val() || 1;
    var payAmt = multipliedBy(price, qty);
    var payAmtDollar = multipliedBy(priceDollar, qty);
    var serviceFee = percentageOf(feePercentage, payAmt);
    var serviceFeeDollar = percentageOf(feePercentage, payAmtDollar);
    var totalAmt = plusNum(payAmt, serviceFee);
    var totalAmtDollar = plusNum(payAmtDollar, serviceFeeDollar);
    var curErc20Balance = $('#erc20_balance').text()
    if (!isGreaterThanOrEqualTo(curErc20Balance, totalAmt)) {
      $('.merge-confirm-btn').css('pointer-events', 'none').css('opacity', '0.5').html('Insufficient PLASTIK');
    } else {
      $('.merge-confirm-btn').css('pointer-events', 'auto').css('opacity', '1').html('Confirm');
    }

    $('.total-kg-recovered').html($('.total-kg').html());
    $("#buy-amt-dp").html(numToString(price))
    $("#buy-total-amt-pks").html(numToString(totalAmt));
    $("#buy-total-amt-dp").html(numToString(totalAmtDollar));
    $("#buy-total-amt-dp").attr('buyAmt', numToString(totalAmtDollar));
  }

  window.processMergeNFT = async function processMergeNFT(transactionHash, contractAddress, qty) {
    $('.approvebuyProgress').addClass('hide')
    $('.approvebuyDone').removeClass('hide')
    $('.purchaseStart').addClass('hide')

    $('.convertDone').removeClass('hide')
    $('.approvebuyDone').removeClass('hide')
    $('.purchaseProgress').removeClass('hide')

    const { purchase, artSellRequest, artVoucher, prgSellRequests, prgVouchers, sellPrice, signaturePrice, new_sc, selectedPrgs, store_front_address, art_supporter_id } = await fetchRGData()

    const isMerged = $("#is_merged").val()
    const isShared = $("#is_shared").val()

    isMerged === "true" ?
      await mixExistingNFTWithPRGs(
        $("#collection_id").val(),
        isShared,
        purchase,
        artSellRequest,
        prgSellRequests,
        prgVouchers,
        selectedPrgs,
        store_front_address,
        art_supporter_id
      ) :
      (new_sc === true ? await mixLazyMintedNFTWithPRGs(
        $("#collection_id").val(),
        isShared,
        purchase,
        artSellRequest,
        artVoucher,
        prgSellRequests,
        prgVouchers,
        selectedPrgs,
        store_front_address,
        art_supporter_id
      ) :
      await mixLazyMintedNFTV1WithPRGs(
        $("#collection_id").val(),
        isShared,
        purchase,
        artSellRequest,
        artVoucher,
        prgSellRequests,
        prgVouchers,
        selectedPrgs,
        store_front_address,
        art_supporter_id
      )
    )
  }

  window.updateCollectionMerged = function updateCollectionMerged(collectionId, quantity, transactionHash, buyerAddress, selectedPrgs, nftAmount = 1, art_supporter_id) {
    
    var request = $.ajax({
      url: '/nft/collectibles/' + collectionId + '/merged',
      type: 'POST',
      async: false,
      data: { quantity: quantity, transaction_hash: transactionHash, merged_ids: selectedPrgs, buyer_address: buyerAddress, nft_amount: nftAmount, art_supporter_id: art_supporter_id },
      dataType: "script",
      success: function (respVal) {
        console.log(respVal)
      }
    });
  }

  // Click on Auto-Select btn
  $(".merge-nft__auto-select__btn").on("click", function () {
    deSelectAllRGs()

    const weightList = $("td").map(function () { return $(this).data("weight") }).get()
    const selectedWeights = autoSelect(weightList, minimumRequired)

    selectedWeights.forEach(x => {
      selectRG(`tr:not(.selected) > td[data-weight=${x}] > input`)
    })

    let fiat_price = (parseFloat($(".merge-nft__bill__guarantee__volume span").html()) * (10 ** 9)) / BigNumber($("#plastik_ratio").val())

    $(".merge-nft__bill__guarantee__price span").html(fiat_price.toFixed(2))
    reCaculateTotalPrice()

    if (parseInt($(".merge-nft__note > p > span").html()) >= minimumRequired) {
      $(".merge-nft__btn").removeAttr('disabled')
      $("#not_enough_plastics").addClass("hide")
    } else {
      $(".merge-nft__btn").attr("disabled", true)
    }
  })

  if ($(".merge-nft.--mobile").length && $(".merge-nft.--mobile").css("display") !== "none") {
    $(".merge-nft__auto-select__btn.--mobile")[0].click()
    $('.total-kg-recovered').html($('.total-kg').html());
  }

  window.fetchPriceRatio = async function fetchPriceRatio() {
    const token = document.querySelector('[name=csrf-token]').content

    return await axios.get(`/collections/${$("#collection_id").val()}/fetch_price_ratio`, getHeader(token))
      .then(response => response.data)
      .catch(err => console.log('adb',err))
  }
})
