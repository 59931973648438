import { debounce } from "./utils";
import { formatPrice, isInsufficientFunds } from './ethereum/stripe_utils';
$(document).ready(async function() {
  window.prefixPath = $('#prefix_path').val();

  let currentSelectedMaxWeight = 0;
  let currentRecyclerId = null;
  let currentPlasticType = null;
  let currentPlasticKgNumber = null;
  let validToGoToPaymentPage = true;
  let isInsufficientFunds = false;
  let totalPriceWithFee = 0.0;
  const account = await getCurrentAccount();

  const currentCountryFilter = $('#current-country-filter').val();
  if(currentCountryFilter) {
    $('#selectCountryOptions').val(currentCountryFilter).change()
  }

  const triggerUpdatePrgPrice = (inputPrice) => {
    if(inputPrice) {
      $('.recovery-guarantee-kg-number').html(inputPrice);
      $('.project-kg-price').html(inputPrice);
      $('.prg-price-in-dolar').html(`$${inputPrice}`);
      $('.recovery-guarantee-kg').html(`${inputPrice}`);
    }
  }

  if (window.location.href.includes("kg_number") && parseInt($('#default-kg-number').val()) > 0) {
    const defaultCheckoutValue = parseInt($('#default-kg-number').val());
    $('.select-amount__item').removeClass('active')
    $('#select_amount_id').val(defaultCheckoutValue);
    $('#selectAmountDescriptionId').text(defaultCheckoutValue)

    if ([5, 50, 100].includes(defaultCheckoutValue)) {
      $(`.kg-number-${defaultCheckoutValue}`).addClass('active');
    } else {
      $('#selectAmountItemInputId').val(defaultCheckoutValue);

      $('.select-amount__item--input').addClass('active')
    }
  } else {
    triggerUpdatePrgPrice(parseInt($('#select_amount_id').val()));
  }

  $('#nft_amount').on('input', ({target})=> {
    let nft_amount = parseInt(target.value);
    let availableNft = parseInt($('#available_nft_for_resell').val());
    let minValue = parseInt($('#min-rg').val());
    let nftBasePrice = parseInt($('#nft-base-price').val());

    $('.div-nft-price').html('$' + nft_amount * nftBasePrice);
    $('#nft-price').val(nft_amount * nftBasePrice);
    $('#kg-input').val(nft_amount * minValue);
    $('#nft-number-of-copies').val(nft_amount);
    changeMinMaxValidation($('#nft_amount'));

    const kgInputVal = parseInt($('#kg-input').val());
    const maxLimit = parseInt($('#checkout-max-limit').val());
    const nftPriceSummary = parseInt($('#nft-price').val());

    $('.project-kg-price, .recovery-guarantee-kg').html(kgInputVal);
    $('#number-of-collectibles').html(nft_amount);
    $('#nft-price-summary').html(nftPriceSummary);

    if (nft_amount > 1) {
      $('#plural-collectible-text').html('collectibles');
    } else {
      $('#plural-collectible-text').html('collectible');
    }

    handlerForMinMaxValueForMultipleNft(nft_amount,availableNft, kgInputVal, maxLimit, null, triggerTotalPriceCalculation);
    initPayButton();
  });

  const availableNftError = document.querySelector('span.availableNftError');
  const availableNftElement = document.getElementById("nft_amount");

  function availableNftShowError(availableNftElement, availableNftError, availableNft) {
    if (parseInt(availableNftElement.value) > parseInt(availableNft)) {
      let availableNftForResell = $('#available_nft_for_resell').val();
      availableNftForResell = availableNftForResell > 1 ? availableNftForResell + ' copies' : availableNftForResell + ' copy'
      $('#nft-input-error').text(`Quantity cannot exceed total collectible quantity`);
      $('#iconWarning').removeClass("hide");
      $("#warning-message:not([class*='active'])").addClass('active');
    } else {
      $('#iconWarning').addClass("hide");
      $('#nft-input-error').text('');
      $("#warning-message").removeClass('active')
    }
  }

  function changeMinMaxValidation(inputSelector) {
    const minValue = parseInt($('#art-connected-kg').val())

    $('.min-max-limit__min').html(minValue * inputSelector.val())
  }

  $('#kg-input').on('input', debounce(({target}) => {
    let nft_amount = parseInt($('#nft_amount').val());
    let availableNft = parseInt($('#available_nft_for_resell').val());

    if(!isNaN(nft_amount) && !isNaN(availableNft) && (nft_amount < 1 || nft_amount > availableNft)) return false;

    const maxValue = parseInt($('#max-rg').val());

    const value = parseInt(target.value);

    // if(value) {
    //   $('.project-kg-price').html(value);
    //   $('.recovery-guarantee-kg').html(value);
    // }

    handlerForMinMaxValue(value, maxValue, null, triggerTotalPriceCalculation);
    initPayButton();
  }, 200));

  $('#giftAddress').on('input', debounce(() => {
    console.log('change gift address')
    initPayButton();
  }, 200))

  $('.kg-input').on('input', ({target}) => {
    const parentCard = $(target).closest('.recovery-project-card');

    const maxValue = currentSelectedMaxWeight;

    const value = parseInt(target.value);

    const isValidValue = handlerForMinMaxValue(value, maxValue, parentCard, triggerCalculationForCheckoutResultPage);

    if(isValidValue) {
      currentPlasticKgNumber = value
    } else {
      currentPlasticKgNumber = null
    }
  })

  const handlerForMinMaxValueForMultipleNft = (nft_amount, availableNft, value, maxPrgValue = null, parentCard = null, action, type = null) => {
    availableNftShowError(availableNftElement, availableNftError, availableNft);

    var minPrgValue = parseInt($('#checkout-min-limit').val());

    if (!maxPrgValue) {
      var maxPrgValue = parseInt($('#checkout-max-limit').val());
    }

    if(!parentCard) {
      parentCard = $('body')
    }

    $('.prg_kg').text(formatNumber(value || 0));
    $('.platic_bottles').text(formatNumber((value * Math.round(1 / gon.bottle_weight)) || 0));

    if(value > 0 && value <= maxPrgValue && value >= minPrgValue && nft_amount <= availableNft) {
      validToGoToPaymentPage = true;
      $('.pay-button').removeClass('disabled');
      $('#payment-request-button').removeClass('disabled');
      action();
      return true;
    } else {
      $('.pay-button').addClass('disabled');
      $('#payment-request-button').addClass('disabled');
      validToGoToPaymentPage = false

      return false;
    }

  }

  function formatNumber(number) {
    if (number > 10000) {
      const prefixes = ['', 'K', 'M', 'B', 'T'];
      const exp = Math.floor(Math.log10(number) / 3);
      const prefix = prefixes[exp];
      const compactNumber = number / Math.pow(10, exp * 3);
      return compactNumber.toFixed(1) + prefix;
    } else {
      return number.toLocaleString('en');
    }
  }

  const handlerForMinMaxValue = (value, maxValue = null, parentCard = null, action, type = null) => {
    if (type === "nft_multiple_amount") {
      var minValue = 1;
    } else if ($('#checkout-min-limit').length) {
      var minValue = parseInt($('#checkout-min-limit').val())
    } else {
      var minValue = parseInt($('#art-connected-kg').val());
    }

    if (!maxValue) {
      maxValue = parseInt($($($($('.recovery-project-card.active')).find('#d-label__plastik-type')).find('span')[1]).data('weight')) || parseInt($('#available_nft_for_resell').val())
    }

    if(!parentCard) {
      parentCard = $('body')
    }

    if(value <= maxValue && value >= minValue) {
      if (type === "nft_multiple_amount") {
        // value is multiply with min RG per copy
        // value = value * parseInt($('#min-rg').val());
      }

      validToGoToPaymentPage = true;
      $('.pay-button').removeClass('disabled');
      $('#payment-request-button').removeClass('disabled');
      action();
      return true;
    } else {
      $('.pay-button').addClass('disabled');
      $('#payment-request-button').addClass('disabled');
      validToGoToPaymentPage = false

      return false;
    }
  }

  $('.pay-with-card').on('click', ({target: payButton}) => {
    $('#pay-width-card-loading-icon').removeClass('d-none');
    $(this).css("pointer-events", "none").css("opacity", "0.5");
    if (!validToGoToPaymentPage) {
      return false;
    }

    const style = {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: 'Helvetica Neue',
        fontSize: '15px',

        '::placeholder': {
          color: '#CCCCCC',
        },
      },
    };

    const stripePublicKey = $('#stripe-public-key').val();

    const handleAfter3DSSuccess = (payment_order_id) => {
      $.post(`/charges/payment_intent_succeeded?`,
        {
          payment_order_id: payment_order_id,
          nft_id: parseInt($('#nft-id').val())
        })
        .done((response) => {
          $(".stripe-form").replaceWith(response.html)
          window.scrollTo(0, 0)

          if($('.transfer-progress-form').length > 0) {
            const orderId = $('.transfer-progress-form').data('order-id')

            const checkPaymentStatusRequest = async () => {
              await $.get( `/charges/get_payment_info/${orderId}`, function( data ) {
                const paymentOrder = data.order
                const userProfileOwnedTablUrl = $('.transfer-progress-form').data('owned-tab-url')

                if(paymentOrder.transfer_status == 'succeeded') {
                  $('#progress-status-loading').addClass('d-none')
                  $('#progress-status-completed').removeClass('d-none')
                  const transactionHash = paymentOrder.transaction_hash

                  $('#transaction-hash-section').html(transactionHash.substr(0,10) + '...' + transactionHash.substr(transactionHash.length - 3, transactionHash.length))

                  if(paymentOrder.payment_resource == 'art') {
                    toastr.success("Congratulations, you got your NFT.")
                  } else {
                    toastr.success("Congratulations, you got your PRG.")
                  }

                  redirectToPurchaseSuccessPageForCard(response.collection_address, paymentOrder)

                } else if (paymentOrder.transfer_status == 'failed') {
                  toastr.error("Card payment failed, please try again.", {timeOut: 10000})
                  const nftDetailUrl = $('.transfer-progress-form').data('nft-url')
                  window.location.replace(nftDetailUrl);
                }
              });
            }

            setInterval(checkPaymentStatusRequest, 7000);

            setTimeout(() => {
              toastr.error("Card payment failed, please try again.", {timeOut: 10000})
              setTimeout(() => {
                if($('.transfer-progress-form').length > 0) {
                  const nftDetailUrl = $('.transfer-progress-form').data('nft-url')
                  window.location.replace(nftDetailUrl);
                }
              }, 2000);
            }, 80000)
          }
        })
    }

    const createPaymentIntentRequest = (payButton) => {
      let buyerAddress = $('#giftAddress').val();
      let discountCode = $('#input-discount-code').val();
      if(!gon.isLoggedIn){
        buyerAddress = gon.anonymousAddress;
      }
      const is_multiple = $('#is_multiple').val();
      let intentUrl = $(payButton).data('intent-url');
      if($('#kg-input').length > 0) {
        intentUrl = intentUrl + `&kg_number=${parseInt($('#kg-input').val())}`
      } else if($('#select_amount_id').length > 0) {
        if(validationSelectAmount($('#select_amount_id'))) {
          intentUrl = intentUrl + `&kg_number=${parseInt($('#select_amount_id').val())}`
        } else {
          return toastr.error('Please enter valid quantity');
        }
      }
      if(buyerAddress) {
        intentUrl = intentUrl + `&buyer_address=${buyerAddress}`;
      }

      if(discountCode){
        let discount_amount = $('#discount-amount').text()
        intentUrl = intentUrl + `&discount_code=${discountCode}&discount_amount=${discount_amount}`;
      }

      if(is_multiple == 'true'){
        const amountNft = parseInt($('#nft_amount').val());
        const artSupporterId = $('#art_supporter_id').val();
        intentUrl += `&amount_nft=${amountNft}&art_supporter_id=${artSupporterId}`;
      }

      const urlParams = new URLSearchParams(window.location.search)

      if(parseInt(urlParams.get('campaign_id'))) {
        intentUrl += `&campaign_id=${urlParams.get('campaign_id')}`
      }

      $('.checkout-form').addClass('d-none', 200);
      $('.checkout-form__new').addClass('d-none', 200);
      $('.stripe-form').removeClass('d-none', 200);
      window.scrollTo(0, 0)

      triggerCardFeeCalculation();

      const stripe = Stripe(stripePublicKey);
      const elements = stripe.elements();

      const cardNumberElement = elements.create('cardNumber', {
        style: style,
        placeholder: 'XXXX XXXX XXXX XXXX',
      });

      const cardExpiryElement = elements.create('cardExpiry', {
        style: style,
        placeholder: 'MM / YY',
      });

      const cardCvcElement = elements.create('cardCvc', {
        style: style,
        placeholder: 'Code',
      });

      cardNumberElement.mount('#card-number-element');
      cardExpiryElement.mount('#card-expiry-element');
      cardCvcElement.mount('#card-cvc-element');

      let intentClientSecrect, paymentOrderParams;
      $('body').on('click', '#stripe-pay-art, #stripe-pay-prg', async ({target}) => {
        $(target).find('#progress-icon').removeClass('d-none');
        $(target).css("pointer-events", "none");
        $(target).css("opacity", "0.5");

        const validCardHolderField = validateCardHolderField();
        const validEmailField = validateEmailField();
        const orderEmail = $('#stripe-email').val();
        intentUrl = intentUrl + `&order_email=${orderEmail}`
        if(validEmailField && validCardHolderField) {
          await $.post( `${intentUrl}`, function( data ) {
            intentClientSecrect = data.intent_client_secrect
            paymentOrderParams = data.payment_order_params
    
            if(!intentClientSecrect) {
              return false
            }
          });

          paymentOrderParams['email'] = $('#stripe-email').val();
          const { payment_order_id } = await $.post(`/charges/create_payment_order?`,
          {
            payment_order_params: paymentOrderParams
          })

          $('.stripe-custom-input').removeClass('stripe-invalid-input');
          $('.stripe-invalid').addClass('hide');

          await stripe.confirmCardPayment(
            intentClientSecrect,
            {
              payment_method: {card: cardNumberElement}
            }
          ).then(async function(result) {
            if (result.error) {
              handleStripeFormError(result.error)
              $(target).find('#progress-icon').addClass('d-none');
              $(target).css("pointer-events", "auto");
              $(target).css("opacity", "1");
            } else {
              handleAfter3DSSuccess(payment_order_id)
            }
          });
        } else {
          $(target).find('#progress-icon').addClass('d-none');
          $(target).css("pointer-events", "auto");
          $(target).css("opacity", "1");
        }
      });
    }

    createPaymentIntentRequest(payButton);

    const validateCardHolderField = () => {
      const $cardHolderInput = $('#stripe-card-holder');
      const value = $cardHolderInput.val()

      if(value) {
        $cardHolderInput.removeClass('stripe-invalid-input');
        $('#invalid-card-holder').addClass('hide');
        return true
      } else {
        $cardHolderInput.addClass('stripe-invalid-input');
        $('#invalid-card-holder').removeClass('hide');
        return false
      }
    }

    const validateEmailField = () => {
      const mailFormat = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      const $emailInput = document.getElementById('stripe-email');
      const $invalidEmail = document.getElementById('invalid-email');

      if ($emailInput == null) return true;
      const value = $emailInput.value;

      if(value.match(mailFormat)) {
        $invalidEmail.classList.add('hide');
        $emailInput.classList.remove('stripe-invalid-input');
        return true
      } else {
        $emailInput.classList.add('stripe-invalid-input');
        $invalidEmail.classList.remove('hide');
        return false
      }
    }


    const handleStripeFormError = (error) => {
      const errorCode = error.code;

      switch (errorCode) {
        case 'incomplete_number':
          $('#card-number-element').addClass('stripe-invalid-input');
          $('#invalid-card-number').removeClass('hide');
          break;
        case 'incomplete_expiry':
          $('#card-expiry-element').addClass('stripe-invalid-input');
          $('#invalid-expiry').removeClass('hide');
          break;
        case 'incomplete_cvc':
          $('#card-cvc-element').addClass('stripe-invalid-input');
          $('#invalid-cvc').removeClass('hide');
          break;
        default:
          toastr.error(error.message)
          break;
      }
    }
  })

  // add payment request button
  const initPayButton = async () => {
    triggerCardFeeCalculation();
    const stripeObject = await Stripe($('#stripe-public-key').val());
    const paymentRequest = stripeObject.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Checkout NFT',
        amount: parseInt(totalPriceWithFee.toFixed(2)* 100),
      },
      requestPayerEmail: true,
    });

    const elements = stripeObject.elements();
    let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let buttonHeight = '47px';

    const prButton = elements.create('paymentRequestButton', {
      paymentRequest,
      style: {
        paymentRequestButton: {
          height: buttonHeight,
        },
      },
    });
    (async () => {
      const result = await paymentRequest.canMakePayment();
      if (result) {
        prButton.mount('#payment-request-button');
      } else {
        $('#payment-request-button').css('display', 'none');
      }
    })();
    handleCompletePayment(paymentRequest, stripeObject);
  }

  const handleCompletePayment = (paymentRequest, stripeObject) => {
    let buyerAddress = $('#giftAddress').val();
    let discountCode = $('#input-discount-code').val();
    if(!gon.isLoggedIn){
      buyerAddress = gon.anonymousAddress;
    }
    let intentUrl = $('#payment-itent-url').val();
    const is_multiple = $('#is_multiple').val();

    if($('#kg-input').length > 0) {
      intentUrl = intentUrl + `&kg_number=${parseInt($('#kg-input').val())}`
    } else if($('#select_amount_id').length > 0) {
      if(validationSelectAmount($('#select_amount_id'))) {
        intentUrl = intentUrl + `&kg_number=${parseInt($('#select_amount_id').val())}`
      } else {
        return toastr.error('Please enter valid quantity');
      }
    }
    if(buyerAddress) {
      intentUrl = intentUrl + `&buyer_address=${buyerAddress}`
    }

    if(discountCode){
      let discount_amount = $('#discount-amount').text()
      intentUrl = intentUrl + `&discount_code=${discountCode}&discount_amount=${discount_amount}`;
    }

    if(is_multiple == 'true'){
      const amountNft = parseInt($('#nft_amount').val());
      const artSupporterId = $('#art_supporter_id').val();
      intentUrl += `&amount_nft=${amountNft}&art_supporter_id=${artSupporterId}`;
    }

    const urlParams = new URLSearchParams(window.location.search)

    if(parseInt(urlParams.get('campaign_id'))) {
      intentUrl += `&campaign_id=${urlParams.get('campaign_id')}`
    }

    // handle complete the payment
    paymentRequest.on('paymentmethod', async (ev) => {
      const orderEmail = ev.payerEmail;
      intentUrl += `&order_email=${orderEmail}`;
      const {
        intent_client_secrect,
        payment_order_params
      } = await $.post( `${intentUrl}`);

      const { payment_order_id } = await $.post(`/charges/create_payment_order?`,
      {
        payment_order_params: payment_order_params
      });

      // Confirm the PaymentIntent without handling potential next actions (yet).
      const {
        paymentIntent,
        error: confirmError
      } = await stripeObject.confirmCardPayment(
                              intent_client_secrect,
                              {payment_method: ev.paymentMethod.id},
                              {handleActions: false}
                            );

      if (confirmError) {
        ev.complete('fail');
      } else {
        ev.complete('success');
        if (paymentIntent.status === "requires_action") {
          const {error} = await stripeObject.confirmCardPayment(intent_client_secrect);
          if (error) {
            console.log("The payment has failed")
          } else {
            handleAfterPaymentSucceeded(payment_order_id);
            console.log("The payment has succeeded")
          }
        } else {
          handleAfterPaymentSucceeded(payment_order_id);
          console.log("The payment has succeeded")
        }
      }
    });
  }

  //handle after payment succeeded for link, google pay button
  const handleAfterPaymentSucceeded = (payment_order_id) => {
    window.scrollTo(0, 0)
    $.post(`/charges/payment_intent_succeeded?`,
      {
        payment_order_id: payment_order_id,
        nft_id: parseInt($('#nft-id').val())
      })
      .done((response) => {
        $(".checkout-form").replaceWith(response.html)
        $(".checkout-form__new").replaceWith(response.html)
        if($('.transfer-progress-form').length > 0) {
          const orderId = $('.transfer-progress-form').data('order-id')
          const checkPaymentStatusRequest = () => {
            $.get(`/charges/get_payment_info/${orderId}`, function(data) {
              const paymentOrder = data.order
              const userProfileOwnedTablUrl = $('.transfer-progress-form').data('owned-tab-url')

              if(paymentOrder.transfer_status == 'succeeded') {
                $('#progress-status-loading').addClass('d-none')
                $('#progress-status-completed').removeClass('d-none')

                const transactionHash = paymentOrder.transaction_hash
                $('#transaction-hash-section').html(transactionHash.substr(0,10) + '...' + transactionHash.substr(transactionHash.length - 3, transactionHash.length))

                if(paymentOrder.payment_resource == 'art') {
                  toastr.success("Congratulations, you got your NFT.")
                } else {
                  toastr.success("Congratulations, you got your PRG.")
                }

                redirectToPurchaseSuccessPageForCard(response.collection_address, paymentOrder)
              } else if (paymentOrder.transfer_status == 'failed') {
                toastr.error("Card payment failed, please try again.", {timeOut: 10000})
                const nftDetailUrl = $('.transfer-progress-form').data('nft-url')
                window.location.replace(nftDetailUrl);
              }
            });
          }

          setInterval(checkPaymentStatusRequest, 7000);

          setTimeout(() => {
            toastr.error("Card payment failed, please try again.", {timeOut: 10000})
            setTimeout(() => {
              if($('.transfer-progress-form').length > 0) {
                const nftDetailUrl = $('.transfer-progress-form').data('nft-url')
                window.location.replace(nftDetailUrl);
              }
            }, 2000);
          }, 80000)
        }
      })
  }

  $('#finish-selecting-rg-button').on('click', ({target}) => {
    let url = $(target).data('url');
    const minValue = parseInt($('#art-connected-kg').val());

    currentPlasticType = $($('.recovery-project-card.active').find('.selected-plastic-type').find('span')[0]).data('plastic-type')

    if(currentRecyclerId && currentPlasticType && (parseInt(currentPlasticKgNumber) >= minValue && parseInt(currentPlasticKgNumber) <= currentSelectedMaxWeight)) {
      url += url.includes('?') ? "&" : "?";

      url += `recycler=${currentRecyclerId}&plastic_type=${currentPlasticType.trim()}&kg_number=${currentPlasticKgNumber}`
      window.location.href = url;
    }
  })

  $('.recovery-project-card').on('click', ({target}) => {
    const parentCard = $(target).closest('.recovery-project-card')
    const recyclerId = parentCard.data('recycler-id')
    currentRecyclerId = recyclerId
    currentPlasticType = $(parentCard.find('.selected-plastic-type').find('span')[0]).data('plastic-type')
    currentPlasticKgNumber = parentCard.find('.kg-input').val()
    currentSelectedMaxWeight = parentCard.find('.selected-plastic-type').find('.selected-plastic-weight').data('weight')
    $('#plastic-kg-result').html(parseInt(currentPlasticKgNumber || 0));
    $('#plastic-kg-result-dollar-price').html(`$${parseInt(currentPlasticKgNumber || 0)}`);
    triggerCalculationForCheckoutResultPage(currentPlasticKgNumber || 0, target);
  })

  $('.kg-input').on('input', ({target}) => {
    triggerCalculationForCheckoutResultPage($(target).val() || 0)
  })

  $('body').on('change', '#selecting-prg-country-filter, #name-filter-input', ({target}) => {
    const country = $('#selecting-prg-country-filter').val()
    const name = $('#name-filter-input').val()

    let url = $('#current-base-url').val();

    url += url.includes('?') ? "&" : "?";

    url +=`filter_country=${country}` + `&filter_name=${name}`;
    window.location.href = url;
  });

  const triggerCardFeeCalculation = () => {
    const rgDollarPrice = parseInt($('#kg-input').val()) || parseInt($('#select_amount_id').val())
    const nftDollarPrice = parseFloat($('#nft-price').val());
    const discountAmount = parseFloat($('#discount-amount').text()) || 0;

    let totalPrice = rgDollarPrice + nftDollarPrice;
    let cardFee = (totalPrice * 0.03) + 0.3;
    let priceAfterDiscount = totalPrice - discountAmount;

    if(priceAfterDiscount <= 0) {
      priceAfterDiscount = 0;
      if(cardFee < 0.5){
        cardFee = 0.5
      }
    }

    totalPriceWithFee = priceAfterDiscount + cardFee;
    $('#card-fee-price').html(formatPrice(parseFloat(cardFee).toFixed(2)))
    $('.price-in-dolar-with-card-fee').html(parseFloat(totalPriceWithFee).toFixed(2))
  }

  $('.view-more').on('click', ({target}) => {
    const url = $(target).data('url');

    $.get(url, function(data, status){
      $('#recycler-details-modal-body').html(data.html);

      $.magnificPopup.open({
        closeOnBgClick: false,
        enableEscapeKey: false,
        items: {
          src: '#recycler-details-modal'
        },
        type: 'inline'
      });
    });
  })

  $('.recovery-project-card').on('click', ({target}) => {
    handleActiveStatusForRecyclerCard(target)
  })

  const handleActiveStatusForRecyclerCard = (target) => {
    $('.recovery-project-card').removeClass('active')
    $(target).closest('.recovery-project-card').addClass('active')

    const recyclerId = $(target).closest('.recovery-project-card').data('recycler-id');

    if(recyclerId) {
      currentRecyclerId = recyclerId
    }
  }

  const triggerCalculationForCheckoutResultPage = (plasticKgNumber, target = '') => {
    $('#plastic-kg-result').html(formatPrice(plasticKgNumber));
    $('#plastic-kg-result-dollar-price').html(`$${formatPrice(plasticKgNumber)}`);

    const avatarResult = $(target).closest('.recovery-project-card').find('.avatar').attr('src')
    const nameResult = $(target).closest('.recovery-project-card').find('.recovery-project-name').html()
    const nameHrefResult = $(target).closest('.recovery-project-card').find('.recovery-project-name').attr('href')

    const plastikPrice = dollarToPlastik(parseInt(plasticKgNumber))
    const nftPrice = parseFloat($('#nft-price').val());

    const totalPrice = parseInt(plasticKgNumber) + nftPrice;
    const totalPlastikPrice = dollarToPlastik(parseFloat(totalPrice))

    $('#result-recovery-project-avatar').attr('src', avatarResult)
    $('#result-recovery-project-name').html(nameResult)
    $('#result-recovery-project-name').attr('href', nameHrefResult)
    $('#result-final-dollar-price').html(`$${formatPrice(totalPrice)}`)
    $('#result-final-plastik-price').html(totalPlastikPrice)
    $('#plastic-kg-result-plastik-price').html(`${plastikPrice}`);
  }

  const dollarToPlastik = (dollarPrice) => {
    const ratio = $('#plastik-ratio').val()
    const formatter = Intl.NumberFormat('en', { notation: 'compact' });

    const fiatPrice = (parseInt(dollarPrice) * (10 ** 9)) / BigNumber(ratio)
    return formatter.format(fiatPrice)
  }

  if($('#select_recovery_project_section').length) {
    const defaultActiveRecyclerCard = $('.recovery-project-card.active');
    const defaultActiveRecyclerKgNumber = parseInt(defaultActiveRecyclerCard.find('input.kg-input').val());
    currentRecyclerId = defaultActiveRecyclerCard.data('recycler-id');
    currentSelectedMaxWeight = defaultActiveRecyclerCard.find('.selected-plastic-type').find('.selected-plastic-weight').data('weight')
    if(defaultActiveRecyclerKgNumber) {
      currentPlasticKgNumber = defaultActiveRecyclerKgNumber;
      triggerCalculationForCheckoutResultPage(defaultActiveRecyclerKgNumber, defaultActiveRecyclerCard);
    }

    $('#js-d-label__checkout-plastik-type li').on('click', function ({target}) {
      const parentCard = $(target).closest('.recovery-project-card');
      const kgNumber = parentCard.find('.kg-input').val()

      handlerForMinMaxValue(kgNumber, $(target).data('weight'), parentCard, triggerCalculationForCheckoutResultPage);

      handleActiveStatusForRecyclerCard(parentCard)

      // parentCard.find('.kg-input').val(null);
      parentCard.find('.kg-input').removeAttr('disabled');

      let selectValue = $(this).find('.dropdown-menu__content').html().toString()

      if(selectValue) {
        currentPlasticType = $(selectValue).data('plastic-type').trim();
      }

      parentCard.find('#d-label__plastik-type .selected-plastic-type').html(selectValue);
      parentCard.find('#d-label__plastik-type input[type=text].plastic-type-input').val($(target).find('.dropdown-menu__content').html()).change();
      parentCard.find("#d-label__plastik-type").dropdown("toggle");

      const weight = $(target).data('weight')
      currentSelectedMaxWeight = $(target).data('weight');

      parentCard.find('.max-limit-kg-number').html(weight);
      parentCard.find('.min-max-limit').removeClass('d-none')
    })
  }

  (function handleShowMaxMinValueOnLoad() {
    Array.prototype.forEach.call($('.recovery-project-card'), function(element) {
      let weight = $($($(element).find('#d-label__plastik-type')).find('span')[1]).data('weight')
      $(element).find('.max-limit-kg-number').html(weight);
      $(element).find('.min-max-limit').removeClass('d-none')
    })
  })()

  triggerTotalPriceCalculation();
  if (window.location.href.indexOf("checkout_art") > -1 || window.location.href.indexOf("checkout_prg") > -1){
    console.log('init pay button');
    initPayButton();
  }

  const minValue = parseInt($('#art-connected-kg').val());
  // const artTotalConnected = parseInt($('#art-connected-kg').val());

  $('#cur-balance-as-number').on('input', function() {
    triggerTotalPriceCalculation();
  })

  if(parseInt($('#kg-input').val()) < minValue) {
    $('.pay-button').addClass('disabled');
    $('#payment-request-button').addClass('disabled');
  } else {
    $('.pay-button').removeClass('disabled');
    $('#payment-request-button').removeClass('disabled');
  }
  $('.recovery-guarantee-kg').text(minValue);

  const validationSelectAmount = (inputField) => {
    return +inputField.attr('value') >= inputField.attr('min') && +inputField.attr('value') <= inputField.attr('max')
  }

  if ($('.checkout-form.checkout-prg').length > 0) {
    function showErrorSelectAmount(inputField, errorId) {
      let newSelectAmount = parseInt($(inputField).attr('value')) || 0

      if (validationSelectAmount(inputField)) {
        errorId.css('display', 'none')
        triggerUpdatePrgPrice(newSelectAmount);
        window.triggerTotalPriceCalculation(newSelectAmount);
        triggerCardFeeCalculation();
        $('.pay-button.pay-with-card').removeClass('disabled-pay-button')
      } else {
        errorId.css('display', 'flex')
        $('.pay-button.pay-with-card').addClass('disabled-pay-button')
      }

      // if (isInsufficientFunds) {
      //   $('.pay-button.pay-with-crypto').addClass('disabled-pay-button')
      // } else {
      //   $('.pay-button.pay-with-crypto').removeClass('disabled-pay-button')
      // }
    }

    $(document).on('change', '#select_amount_id', debounce((e) => {
      let value = $(e.currentTarget).val()
      $('#selectAmountDescriptionId').text(value)
      $('.prg_kg').text(value)
      $('.platic_bottles').text(formatNumber((value * Math.round(1 / gon.bottle_weight)) || 0));
      showErrorSelectAmount($(e.currentTarget), $('#selectAmountItemErrorId'))
      initPayButton();
    },200))

    $(document).on('click', '.select-amount__item--select', ({target}) => {
      if (!$(target).hasClass('disabled')) {
        let newSelectAmount = $(target).attr('value') || 0

        $('#selectAmountItemInputId').val('')
        $('#selectAmountItemInputId').attr('value', '')

        $('.select-amount__item.active').removeClass('active')
        $('#select_amount_id').val(newSelectAmount).trigger('change')
        $(target).addClass('active')
      }
    })

    // prevent type text on number input Safari
    $('#selectAmountItemInputId').keypress((e) => {
      e = e || window.event;
      let charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
      let charStr = String.fromCharCode(charCode);

      if (!charStr.match(/^[0-9]+$/))
        e.preventDefault();
    })

    $(document).on('keyup', '.select-amount__item--input', function () {
      let newSelectAmount = $(this).find('input').val() || 0

      if (newSelectAmount.length > 7) {
        newSelectAmount = newSelectAmount.substring(0, 7)
        $(this).find('input').val(newSelectAmount)
      }

      $(this).attr('value', this.value)

      $('.select-amount__item.active').removeClass('active')
      $('#select_amount_id').val(newSelectAmount).trigger('change')
      $(this).addClass('active')
    })
  }

  if ($('.checkout-form__new.checkout-prg').length > 0) {
    function showErrorSelectAmount(inputField, errorId) {
      let newSelectAmount = parseInt($(inputField).attr('value')) || 0

      if (validationSelectAmount(inputField)) {
        errorId.css('display', 'none')
        triggerUpdatePrgPrice(newSelectAmount);
        window.triggerTotalPriceCalculation(newSelectAmount);
        triggerCardFeeCalculation();
        $('.pay-button.pay-with-card').removeClass('disabled-pay-button')
      } else {
        errorId.css('display', 'flex')
        $('.pay-button.pay-with-card').addClass('disabled-pay-button')
      }

      // if (isInsufficientFunds) {
      //   $('.pay-button.pay-with-crypto').addClass('disabled-pay-button')
      // } else {
      //   $('.pay-button.pay-with-crypto').removeClass('disabled-pay-button')
      // }
    }

    $(document).on('change', '#select_amount_id', debounce((e) => {
      let value = $(e.currentTarget).val()
      $('#selectAmountDescriptionId').text(value)
      $('.prg_kg').text(value)
      $('.platic_bottles').text(formatNumber((value * Math.round(1 / gon.bottle_weight)) || 0));
      showErrorSelectAmount($(e.currentTarget), $('#selectAmountItemErrorId'))
      initPayButton();
    },200))

    $(document).on('click', '.select-amount__item--select', ({target}) => {
      if (!$(target).hasClass('disabled')) {
        let newSelectAmount = $(target).attr('value') || 0

        $('#selectAmountItemInputId').val('')
        $('#selectAmountItemInputId').attr('value', '')

        $('.select-amount__item.active').removeClass('active')
        $('#select_amount_id').val(newSelectAmount).trigger('change')
        $(target).addClass('active')
      }
    })

    // prevent type text on number input Safari
    $('#selectAmountItemInputId').keypress((e) => {
      e = e || window.event;
      let charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
      let charStr = String.fromCharCode(charCode);

      if (!charStr.match(/^[0-9]+$/))
        e.preventDefault();
    })

    $(document).on('keyup', '.select-amount__item--input', function () {
      let newSelectAmount = $(this).find('input').val() || 0

      if (newSelectAmount.length > 7) {
        newSelectAmount = newSelectAmount.substring(0, 7)
        $(this).find('input').val(newSelectAmount)
      }

      $(this).attr('value', this.value)

      $('.select-amount__item.active').removeClass('active')
      $('#select_amount_id').val(newSelectAmount).trigger('change')
      $(this).addClass('active')
    })
  }

  $('.gift-text').on('click', (e) => {
    $('#giftAddress').toggleClass('hide')
    $('.gift-text__img').toggleClass('hide')
    $('.gift-text__img--wrapper').toggleClass('mb-10')
  })

  $('.discount-text').on('click', (e) => {
    $('#discountCode').toggleClass('hide');
    $('#applyDiscountCode').toggleClass('hide');
  })

  $('#applyDiscountCode').on('click', async (e) =>{
    let disCountCodeVal = $('#discountCode').val().trim()
    if($('#discountCode').val() == '') {
      return false;
    } else {
      $('#applyDiscountCode').addClass('disabled-action');
    }

    await $.post(`/check-market-place-voucher`,
    {
      voucherCode: disCountCodeVal,
    })
    .done((response) => {
      console.log('success')
      $('#discount-warning-message').removeClass('hide').addClass('hide');
      $('#discount-success-message').removeClass('hide')
      $('.div-discount-summary').removeClass('hide')
      $('.div-pay-discount').removeClass('hide')
      $('#discount-amount').text(response.discountAmount)
      $('#pay-discount-code').text(response.discountAmount)
      $('#input-discount-code').val(response.voucherCode)
      $('#applyDiscountCode').removeClass('disabled-action');
      $('.pay-button.pay-with-crypto').removeClass('disabled-pay-button').addClass('disabled-pay-button')
    })
    .fail(function() {
      console.log('failed')
      $('#discount-warning-message').removeClass('hide')
      $('#discount-success-message').removeClass('hide').addClass('hide');
      $('.div-discount-summary').removeClass('hide').addClass('hide')
      $('.div-pay-discount').removeClass('hide')
      $('#discount-amount').text('0')
      $('#pay-discount-code').text('0')
      $('#input-discount-code').val('')
      $('#applyDiscountCode').removeClass('disabled-action');
      $('.pay-button.pay-with-crypto').removeClass('disabled-pay-button')
    })
    .always(function () {
      triggerTotalPriceCalculation();
      triggerCardFeeCalculation();
      initPayButton();
    });
  });
  
  const redirectToPurchaseSuccessPageForCard = (collectionId, paymentOrder = null) => {
    let url = (window.prefixPath == 'nft') ? '/nft/collectibles/' : '/collections/';
    if(gon.isLoggedIn){
      const giftAddress = $('#giftAddress').val();
      let sellerId = $('#seller-id').val();
      url += collectionId + '/purchase_success';

      url += '?seller_id=' + sellerId ?? '';

      if(giftAddress) url += '&gift_address=' + giftAddress;    
    } 
    else {
      const idempotency_key = paymentOrder?.idempotency_key;
      url += `${collectionId}/purchase_success?idempotency_key=${idempotency_key}`;
    }

    let kgNumber = $('#buy_qty').val() || $('#kg-input').val() || $('#select_amount_id').val() || $('#kg-number').val() || null
    if (kgNumber !== null) {
      url += '&kg_number=' + kgNumber
    }

    let amountNft = $('#nft-number-of-copies').val() || null
    if (amountNft !== null) {
      url += '&amount_nft=' + amountNft
    }
    window.location.href = url;
  }

  $(".back-button").click( function(event) {
    const stripeForm = $(".stripe-form");
    // const checkoutForm = $(".checkout-form");
    const checkoutForm = $(".checkout-form__new");
    if (!stripeForm.hasClass("d-none")) {
      event.preventDefault();
      stripeForm.addClass("d-none");
      checkoutForm.removeClass("d-none");
      $("#pay-width-card-loading-icon").addClass("d-none");
    }
  });
  $("#btn-decrement").click(decrement);
  $("#btn-increment").click(increment);
});



const increment = () => {
  let input = $('#nft_amount');
  let availableNft = $('#available_nft_for_resell').val();
  if (parseInt(input.val()) < parseInt(availableNft)) {
    input.val((parseInt(input.val()) + 1));
    input.trigger('input');
  }
}

const decrement = () => {
  let input = $('#nft_amount');
  if (parseInt(input.val()) > 1) {
    input.val((parseInt(input.val()) - 1));
    input.trigger('input');
  }
}
