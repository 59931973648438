const formatPrice = (price) => {
  if (price > 1000000) {
    price = (price / 1000000).toFixed(1) + 'M'
  } else if (price > 1000) {
    price = (price / 1000).toFixed(2) + 'K'
  } else if (price > 0) {
      price = (price % 1 != 0) ? (parseFloat(price)).toFixed(2) : price
  }
  return price
}
let isInsufficientFunds = false;

window.triggerTotalPriceCalculation = function triggerTotalPriceCalculation(kgNumber) {
  let projectKgPrice = null;

  if (kgNumber) {
    projectKgPrice = parseInt(kgNumber)
  } else {
    projectKgPrice = parseInt($('#kg-input').val()) || parseInt($('#select_amount_id').val())
  }

  let nftPrice = parseFloat($('#nft-price').val());
  let discountAmount = parseFloat($('#discount-amount').text()) || 0;

  let totalPrice = projectKgPrice + nftPrice - discountAmount;

  if(totalPrice < 0) {
    totalPrice = 0;
  }

  const ratio = $('#plastik-ratio').val()
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });

  const fiatPrice = (totalPrice * (10 ** 9)) / BigNumber(ratio)
  const fiatPriceAsFormat = formatter.format(fiatPrice || 0)
  $('#buy_qty').val(projectKgPrice)
  $('.price-in-dolar').text(`$${formatPrice(totalPrice || 0)}`)
  $('#buy-total-amt-dp').text(`${fiatPriceAsFormat}`)
  $('.price-in-plastik').text(`${fiatPriceAsFormat} PLASTIK`)
  $('#buy-total-amt-dp').attr('buyAmtPlastik', fiatPrice)
  $('#buy-total-amt-dp').attr('buyAmt', totalPrice)

  const currentBalance = parseFloat($('#cur-balance-as-number').val());
  if (parseFloat(currentBalance) === 0 || fiatPrice > currentBalance) {
    $('.insufficient-funds').removeClass('d-none')
    $('.sufficient-funds, .pay-with-crypto').addClass('d-none')
    // $('.pay-button.pay-with-crypto').addClass('disabled-pay-button')
    isInsufficientFunds = true;
  } else {
    $('.insufficient-funds').addClass('d-none')
    $('.sufficient-funds, .pay-with-crypto').removeClass('d-none')
    // $('.pay-button.pay-with-crypto').removeClass('disabled-pay-button')
    isInsufficientFunds = false;
  }
}

export { formatPrice, isInsufficientFunds }
