import IconCircle from '../../assets/images/ic-map-circle-hover-blue.svg';
import nextIcon from '../../assets/images/next-icon.svg';
import previousIcon from '../../assets/images/prev-icon.svg';
import yellowIconCircle from '../../assets/images/ic-map-circle-hover-yellow.svg';

export const initRecoveryProjectProfileSdMap = () => {
  if($('#show-recovery-project-sustainability-db').length > 0) {
    const profileImages = JSON.parse($('#recovery-project-images').val());

    let carouselHtml = ''
    profileImages.forEach(element => {
      carouselHtml += `<div class="owl-item">
                          <img class='' src=${element} />
                        </div>`
    })

    const markerPopupHtml = ({userImage, countryFlagClass, countryName, recoveredKgNumber, RpName, prgId}) => `<div class="map__pop-up">
                              <div class='map__pop-up__banner-image'>
                                <div class="main__carousel explore-recovery-project-profile-images owl-carousel owl-loaded" id="${prgId}exploreRecoveryProjectProfileImages">
                                  <div class="owl-stage-outer owl-height">
                                    <div class="owl-stage">
                                      ${carouselHtml}
                                    </div>
                                  </div>
                                  <button class="${profileImages.length == 1 ? "hide" : ""} home__nav home__nav--prev" data-nav="#${prgId}exploreRecoveryProjectProfileImages" type="button">
                                    <img class='' src=${previousIcon} />
                                  </button>
                                  <button class="${profileImages.length == 1 ? "hide" : ""} home__nav home__nav--next" data-nav="#${prgId}exploreRecoveryProjectProfileImages" type="button">
                                    <img class='' src=${nextIcon} />
                                  </button>
                                </div>
                              </div>
                              <div class="map__pop-up__title profile-map">
                                <div class='map__pop-up__title__recovert-project-info'>
                                  <div class="img">
                                    <img class='' src=${userImage} />
                                  </div>
                                  <div class="content">
                                    <div class='font-size-14-text font-weight-600-text text-truncate-1 map__pop-up__profile-name'>${RpName}</div>
                                    <span class="map__pop-up__description font-size-12-text text-truncate-1 neutral-500-color-text">
                                      <i class='${countryFlagClass}'></i>
                                      ${countryName}
                                    </span>
                                  </div>
                                </div>
                                <div class='map__pop-up__title__recycle-info'>
                                  <div class='recycle-quantity font-weight-600-text text-truncate-1'>
                                    ${recoveredKgNumber || 0} kg
                                  </div>
                                  <div class='font-size-12-text'>
                                    plastic recovered
                                  </div>
                                </div>
                              </div>
                            </div>`
    let recycler_country_coordinates_id = $('#recovery-map-coordinates-list');
    let coordinates = []

    try {
      coordinates = JSON.parse(recycler_country_coordinates_id.val())
    } catch {
      coordinates = []
    }

    const myIcon = L.icon({
      iconUrl: IconCircle,
      iconSize: [38, 38],
      popupAnchor: [0, -5],
      className: 'recycler-icon'
    });

    const myYellowIcon = L.icon({
      iconUrl: yellowIconCircle,
      iconSize: [38, 38],
      popupAnchor: [0, -5],
      className: 'recycler-icon'
    });

    const bounds = new L.LatLngBounds(new L.LatLng(85.0, -180.0), new L.LatLng(-85.0, 180.0));
    const map = L.map('recovery-project-big-map', {
      'center': bounds.getCenter(),
      'zoom': 0,
      'trackResize': false,
      'maxBound': bounds,
      'maxBoundsViscosity': 1.0,
      'layers': [
        L.tileLayer('https://api.mapbox.com/styles/v1/garciapd/clos40cjj00r601qo9ehuflt5/draft/tiles/{tileSize}/{z}/{x}/{y}@2x?access_token={accessToken}', {
          minZoom: 1,
          id: 'clos40cjj00r601qo9ehuflt5',
          tileSize: 512,
          zoomOffset: -1,
          accessToken: 'pk.eyJ1IjoiZ2FyY2lhcGQiLCJhIjoiY2w0cnd1dXk2MGcxYjNpbzB2NnViM3VudyJ9.t7T0_cx2_51iTXSUv9LiYA'
        })
      ]
    })

    map.on('drag', function () {
      map.panInsideBounds(bounds, { animate: false });
    });

    for (let i = 0; i < coordinates?.length || 0; i++) {
      if (coordinates[i][1] && coordinates[i][1].length === 2) {
        let marker = L.marker(coordinates[i][1], { icon: myIcon })
        marker.bindPopup(markerPopupHtml({
          userImage: coordinates[i][3][2], countryFlagClass: coordinates[i][4], countryName: coordinates[i][0], recoveredKgNumber: coordinates[i][5], RpName: coordinates[i][3][1], prgId: coordinates[i][6]
        })).openPopup();
        marker.addTo(map).on('click', () => {
          window.initExploreRecoveryProjectImagesCarousel();
        });
        marker.on('click', (e) => {
          e.target.setIcon(myYellowIcon);
        });
        marker.on('popupclose', function(e) {
          e.target.setIcon(myIcon);
        });
      }
    }

    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    map.dragging.disable();

    if(coordinates?.length === 1) {
      map.flyTo(coordinates[0][1], 5);
    }

    $(`#recovery-project-big-map`).on('click', () => {
      map.dragging.enable();
      map.touchZoom.enable();
      map.doubleClickZoom.enable();
      map.scrollWheelZoom.enable();
      map.boxZoom.enable();
      map.keyboard.enable();
    })
  }
}
