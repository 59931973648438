$(document).ready(function () {
	"use strict"; // start of use strict

	/*==============================
	Menu
	==============================*/
	$('.header__btn').on('click', function (e) {
		e.stopPropagation();
		$(this).addClass('header__btn--active');
		$('.header__menu').addClass('header__menu--active');
		if ($(this).hasClass('header__btn--active') == true) {
			$('body').css('overflow', 'hidden');
		} else {
			$('body').css('overflow', 'auto');
		}
	});

	$(document).click(function (e) {
		if ($('.header__btn').hasClass('header__btn--active') == true) {
			$('.header__menu').removeClass('header__menu--active');
			$('.header__btn').removeClass('header__btn--active');
			$('body').css('overflow', 'auto');
		}
	});

	if ($('.header__action--search')) {
		$('.header__search .close, .header__action--search').on('click', function () {
			$(".search__results__wrapper").toggleClass("collapse")
			$(".live_search__results__wrapper").toggleClass("collapse")
			$('.header__search').toggleClass('header__search--active');
		});
	}

	/*==============================
	Multi level dropdowns
	==============================*/
	$('ul.dropdown-menu [data-toggle="dropdown"]').on('click', function (event) {
		event.preventDefault();
		event.stopPropagation();

		$(this).siblings().toggleClass('show');
	});

	$(document).on('click', function (e) {
		$('.dropdown-menu').removeClass('show');
	});

	/*==============================
	Carousel
	==============================*/
	$('.main__carousel--collections').owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		dots: true,
		loop: true,
		autoplay: false,
		smartSpeed: 600,
		margin: 20,
		autoHeight: true,
		responsive: {
			0: {
				items: 2,
			},
			576: {
				items: 2,
			},
			768: {
				items: 3,
				margin: 30,
			},
			992: {
				items: 4,
				margin: 30,
			},
			1200: {
				items: 5,
				margin: 30,
				mouseDrag: false,
				dots: false,
			},
		}
	});

	const $owlDiscoverCompany = $('.home__carousel--discover-companies');
	$owlDiscoverCompany.on('initialized.owl.carousel', (event) => {
		let owl_id = '#' + event.target.id
		$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).addClass('d-none');
		$owlDiscoverCompany.removeClass('owl-hidden');
		$('#discoverCompaniesLoading').addClass('d-none');
	});

	$('.barcelona-testimonials--carousel').owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		dots: true,
		loop: true,
		autoplay: true,
		autoplayTimeout: 5000,
		smartSpeed: 600,
		margin: 20,
		autoHeight: true,
		items: 1,
		center: true,
		stagePadding: 500,
		nav: true,
		startPosition: 2,
		autoWidth: true,
	});

	// $('.home__carousel--discover-companies .owl-stage').children().each( function( index ) {
	// 	$(this).attr( 'data-position', index ); // NB: .attr() instead of .data()
	// });

	$('.home__carousel--discover-companies').owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		dots: false,
		loop: true,
		autoplay: false,
		smartSpeed: 600,
		margin: 15,
		autoHeight: true,
		responsiveClass:true,
		responsive: {
			0: {
				items: 1,
			},
			576: {
				items: 2,
			},
			768: {
				items: 2,
			},
			992: {
				items: 3,
			},
			1200: {
				items: 4,
				mouseDrag: false
			},
		}
	});

	checkClasses();
    $owlDiscoverCompany.on('refreshed.owl.carousel', function(event) {
        checkClasses();
    });
	$owlDiscoverCompany.on('prev.owl.carousel', function(event) {
        checkClasses();
    });
	$owlDiscoverCompany.on('next.owl.carousel', function(event) {
        checkClasses();
    });

	$owlDiscoverCompany.on('mouseenter', '.owl-item.active.firstActiveItem, .owl-item.active.lastActiveItem', function(){
		const windownWidth = $(window).width();
		if(windownWidth > 768){
			var activedWidth = $('.owl-item.active').width();
			var clonedWidth = $('.owl-item.cloned').width();
			var index = $(this).index();
			var marginVectorX = clonedWidth * index + 15 * (index - 1);

			if (windownWidth <= 820){
				marginVectorX += 15;
			}

			if($(this).hasClass('firstActiveItem')){
				$(this).parent().css({
					'transform': `translate3d(-${marginVectorX}px, 0, 0)`,
					'transition': ''
				})
			}
			else if($(this).hasClass('lastActiveItem')){
				marginVectorX = marginVectorX - clonedWidth * 2 + 10
				if (windownWidth <= 820){
					marginVectorX = marginVectorX + 2*clonedWidth;
				}
				else if( windownWidth >= 992 && windownWidth <= 1200){
					marginVectorX = marginVectorX + clonedWidth + 50;
				}else if (windownWidth > 1200 && windownWidth <=1320 ) {
					marginVectorX = marginVectorX + 35;
				}
				$(this).parent().css({
					'transform': `translate3d(-${marginVectorX}px, 0, 0)`,
					'transition': ''
				})
			}
		}
	 });

	$owlDiscoverCompany.on('mouseleave', '.owl-item.active.firstActiveItem, .owl-item.active.lastActiveItem', function(){
		const windownWidth = $(window).width();
		if(windownWidth > 768){
			$owlDiscoverCompany.trigger('refresh.owl.carousel');
		}
	});

    function checkClasses(){
        var total = $('.home__carousel--discover-companies .owl-stage .owl-item.active').length;

        $('.home__carousel--discover-companies .owl-stage .owl-item').removeClass('firstActiveItem lastActiveItem');

        $('.home__carousel--discover-companies .owl-stage .owl-item.active').each(function(index){
			if (index === 0) {
                // this is the first one
                $(this).addClass('firstActiveItem');
            }
            if (index === total - 1 && total>1) {
                // this is the last one
                $(this).addClass('lastActiveItem');
            }
        });
    }

	// $owlDiscoverCompany.on('mouseover', '.owl-item', function() {
	// 	// Handle the hover event here
	// 	$('.home__carousel--discover-companies .owl-stage').removeClass('hover-padding-left').removeClass('hover-padding-right');
	// 	if($(this).hasClass('firstActiveItem')){
	// 		$('.home__carousel--discover-companies .owl-stage').addClass('hover-padding-left')
	// 	}else if($(this).hasClass('lastActiveItem')){
	// 		$('.home__carousel--discover-companies .owl-stage').addClass('hover-padding-right')
	// 	}
	// });

	// $owlDiscoverCompany.on('mouseout', '.owl-item', function() {
	// 	$('.home__carousel--discover-companies .owl-stage').removeClass('hover-padding-left').removeClass('hover-padding-right')
	// });

	$('.main__carousel--live').owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		dots: true,
		loop: true,
		autoplay: true,
		autoplayHoverPause: true,
		autoplayTimeout: 10000,
		smartSpeed: 600,
		margin: 20,
		autoHeight: true,
		responsive: {
			0: {
				items: 1,
			},
			576: {
				items: 1,
			},
			768: {
				items: 1,
			},
			992: {
				items: 1,
			},
			1200: {
				items: 1,
			},
		}
	});

	$('.barcelona-carousel').owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		dots: ($(".barcelona-carousel .item").length > 1) ? true : false,
		loop: ($(".barcelona-carousel .item").length > 1) ? true : false,
		items: 1,
		autoplay: false,
		autoplayHoverPause: true,
		autoplayTimeout: 5000,
		smartSpeed: 600,
		margin: 20,
		autoHeight: true,
		responsive: {
			0: {
				items: 1,
				margin: 6,
				dots: true,
				loop: ($(".barcelona-carousel .item").length > 1) ? true : false,
			},
			576: {
				items: 1,
				margin: 6,
				dots: true,
				loop: ($(".barcelona-carousel .item").length > 1) ? true : false,
			},
			768: {
				items: 3,
				margin: 11,
			},
			992: {
				items: 4,
				margin: 11,
			},
			1200: {
				items: 4,
				margin: 11,
				mouseDrag: false,
				dots: false,
			},
		},
		onInitialized: function() {
			// hide previous button by default
			$('.barcelona-carousel .home__nav--prev').hide();
			$(".barcelona-carousel .home__nav--next").click(function() {
				$(".barcelona-carousel .home__nav--prev").show();
			});
		},
		onTranslated: function() {
			var carousel = $('.barcelona-carousel');
			if (carousel.find('.owl-item:first-child').hasClass('active')) {
				$(".barcelona-carousel .home__nav--prev").hide();
			}
			if (carousel.find('.owl-item:last-child').hasClass('active')) {
				$(".barcelona-carousel .home__nav--next").hide();
			}
			$(".barcelona-carousel .home__nav--next").click(function() {
				$(".barcelona-carousel .home__nav--prev").show();
			});
			$(".barcelona-carousel .home__nav--prev").click(function() {
				$(".barcelona-carousel .home__nav--next").show();
			});
		},
	});

	function landingPageCarousel(className, responsiveSettings) {
		var $carousel = $(className);

		$carousel.owlCarousel({
			mouseDrag: true,
			touchDrag: true,
			dots: false,
			loop: true,
			items: 1,
			autoplay: true,
			autoplayHoverPause: true,
			autoplayTimeout: 3000,
			smartSpeed: 600,
			margin: 20,
			autoHeight: true,
			responsive: responsiveSettings,
		});
	}

	// Green 17 carousel
	var recoveryProjectsResponsiveSettings = {
		0: {
			items: 1,
			margin: 6,
		},
		576: {
			items: 1,
			margin: 6,
			dots: false,
		},
		768: {
			items: 2,
			margin: 30,
		},
		992: {
			items: 2,
			margin: 11,
		},
		1200: {
			items: 2,
			margin: 50,
			mouseDrag: false,
			dots: false,
		},
		1500: {
			items: 2,
			margin: 150,
			mouseDrag: false,
			dots: false,
		},
	};
	landingPageCarousel('.green-17-carousel', recoveryProjectsResponsiveSettings);

	var partnersResponsiveSettings = {
		0: {
			items: 1,
			margin: 30,
		},
		600: {
			items: 1,
			margin: 25,
		},
		960: {
			items: 3,
			margin: 25,
		},
		1200: {
			items: 5,
			margin: 20,
			mouseDrag: false,
			dots: false,
		},
	}

	landingPageCarousel('.green-17-partner-carousel', partnersResponsiveSettings);
	landingPageCarousel('.green-17-partner-carousel-2', partnersResponsiveSettings);
	// End Green 17 Carousel

	$(document).on('initialized.owl.carousel', '.main__carousel--explore', (event) => {
		$('.main__carousel--explore').removeClass('owl-hidden');
		$('#nftCarouselLoading').addClass('d-none');
	})

	$('.main__carousel--explore').owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		dots: ($(".main__carousel--explore .item").length > 1) ? true : false,
		loop: ($(".main__carousel--explore .item").length > 1) ? true : false,
		items: 1,
		autoplay: false,
		autoplayHoverPause: true,
		autoplayTimeout: 5000,
		smartSpeed: 600,
		margin: 20,
		autoHeight: true,
		responsive: {
			0: {
				items: 1,
			},
			576: {
				items: 2,
			},
			768: {
				items: 3,
				margin: 30,
			},
			992: {
				items: 4,
				margin: 30,
			},
			1200: {
				items: 4,
				margin: 30,
				mouseDrag: false,
				dots: false,
			},
		}
	});

	$(document).on('to.owl.carousel', '.main__carousel--recovery-project', (event) => {
		$("img.lazyload").lazyload();
	});

	$(document).on('changed.owl.carousel', '.main__carousel--recovery-project', (event) => {
		$("img.lazyload").lazyload();
	});

	$(document).on('to.owl.carousel', '.campaign__carousel, .rp__images__carousel', (event) => {
		// add lazy load for image
		$('#homeCarousel--art').removeClass('owl-hidden');
		$(event.target).removeClass('owl-hidden');
		$("img.lazyload").lazyload();
	});

	$(document).on('changed.owl.carousel', '.campaign__carousel, .rp__images__carousel', (event) => {
		// add lazy load for image
		$('#homeCarousel--art').removeClass('owl-hidden');
		$(event.target).removeClass('owl-hidden');
		$("img.lazyload").lazyload();
	});

	$(document).on('changed.owl.carousel', '.green-17-carousel', (event) => {
		// add lazy load for image
		$("img.lazyload").lazyload();
	});

	$('.campaign__carousel').owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		loop: ($(".main__carousel--explore .item").length > 1) ? true : false,
		items: 1,
		autoplay: false,
		autoplayHoverPause: true,
		autoplayTimeout: 5000,
		smartSpeed: 600,
		margin: 20,
		autoHeight: true,
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 2,
				margin: 25,
			},
			960: {
				items: 2,
				margin: 25,
			},
			1200: {
				items: 2,
				margin: 25,
				mouseDrag: true,
				dots: false,
			},
		}
	});

	$('.rp__images__carousel').owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		loop: false,
		items: 1,
		autoplay: false,
		lazyLoad: true,
		autoplayHoverPause: true,
		autoplayTimeout: 5000,
		smartSpeed: 600,
		margin: 20,
		autoHeight: true,
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 1,
				margin: 25,
			},
			960: {
				items: 1,
				margin: 25,
			},
			1200: {
				items: 1,
				margin: 25,
				mouseDrag: true,
				dots: false,
			},
		}
	});

	window.initHomeCampaignsCarousel = function initHomeCampaignsCarousel() {
		if($('.main__carousel--campaigns--new').length) {
			$('.main__carousel--campaigns--new').owlCarousel({
				mouseDrag: true,
				touchDrag: true,
				loop: true,
				autoplay: false,
				autoHeight: true,
				center: true,
				dots: false,
				responsiveClass:true,
				slideTransition: 'ease-in-out',
				// navSpeed: 500,
				responsive: {
					0: {
						items: 3,
					},
					576: {
						items: 3,
					},
					992: {
						items: 3,
					},
					1200: {
						items: 3,
					},
				}
			});
		}
	}

	window.initDiscoverCarousel = function initDiscoverCarousel() {
		$(document).on('initialized.owl.carousel', '.main__carousel--discover', (event) => {
			let owl_id = '#' + event.target.id

			$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).addClass('d-none');
			if (event.item.count <= event.page.size) {
				$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).addClass('d-none');
			}

			$('.main__carousel--discover').removeClass('owl-hidden');
			$('#nftCarouselLoading').addClass('d-none');
		})

		if($('.main__carousel--discover').length) {
			$('.main__carousel--discover').owlCarousel({
				mouseDrag: true,
				touchDrag: true,
				dots: ($(".main__carousel--explore .item").length > 1) ? true : false,
				loop: ($(".main__carousel--explore .item").length > 1) ? true : false,
				items: 1,
				autoplay: false,
				autoplayHoverPause: true,
				autoplayTimeout: 5000,
				smartSpeed: 600,
				autoHeight: true,
				navigation:true,
				responsive: {
					0: {
						items: 1,
					},
					576: {
						items: 2,
						margin: 15,
					},
					768: {
						items: 3,
						margin: 15,
					},
					992: {
						items: 4,
						margin: 15,
					},
					1200: {
						items: 5,
						margin: 7,
						mouseDrag: false,
						dots: false,
					},
				}
			});
		}

		if($('.main__carousel--campaigns').length) {
			$('.main__carousel--campaigns').owlCarousel({
				mouseDrag: true,
				touchDrag: true,
				dots: ($(".main__carousel--campaigns .item").length > 1) ? true : false,
				loop: ($(".main__carousel--campaigns .item").length > 1) ? true : false,
				items: 1,
				autoplay: false,
				autoplayHoverPause: true,
				autoplayTimeout: 5000,
				smartSpeed: 600,
				autoHeight: true,
				navigation:true,
				responsive: {
					0: {
						items: 1,
					},
					576: {
						items: 2,
						margin: 15,
					},
					992: {
						items: 3,
						margin: 15,
					},
					1200: {
						items: 3,
						margin: 20,
						mouseDrag: false,
						dots: false,
					},
				}
			});
		}

		if($('.main__carousel--user-profile-campaigns').length) {
			$(document).on('initialized.owl.carousel', '.main__carousel--user-profile-campaigns', (event) => {
				$('.main__carousel--user-profile-campaigns').removeClass('owl-hidden');
				$('#nft-profile-campaign-loading').addClass('d-none');
			})
			var carouselProfileCampaign = $('.main__carousel--user-profile-campaigns').owlCarousel({
				mouseDrag: true,
				touchDrag: true,
				dots: false,
				loop: ($(".main__carousel--user-profile-campaigns .owl-item").length > 1) ? true : false,
				items: 1,
				autoplay: true,
				autoplayHoverPause: true,
				autoplayTimeout: 5000,
				stopOnHover : true,
				smartSpeed: 600,
				autoHeight: true,
				navigation:true,
				responsive: {
					0: {
						items: 1,
					},
					576: {
						items: 2,
						margin: 15,
					},
					992: {
						items: 2,
						margin: 15,
					},
					1200: {
						items: 2,
						margin: 20,
						mouseDrag: false,
						dots: false,
					},
				}
			});
		}

		if($('.main__carousel--recovery-entities').length) {
			$(document).on('initialized.owl.carousel', '.main__carousel--recovery-entities', (event) => {
				$('.main__carousel--recovery-entities').removeClass('owl-hidden');
				$('#nft-recovery-entities-loading').addClass('d-none');
			})

			var carouselRecoveryEntities = $('.main__carousel--recovery-entities').owlCarousel({
				mouseDrag: true,
				touchDrag: true,
				dots: false,
				loop: ($(".main__carousel--recovery-entities .owl-item").length > 1) ? true : false,
				items: 1,
				autoplay: true,
				autoplayHoverPause: true,
				autoplayTimeout: 5000,
				smartSpeed: 600,
				stopOnHover : true,
				autoHeight: true,
				navigation:true,
				responsive: {
					0: {
						items: 1,
					},
					576: {
						items: 2,
						margin: 16,
					},
					992: {
						items: 2,
						margin: 16,
					},
					1200: {
						items: 2,
						margin: 16,
						mouseDrag: false,
						dots: false,
					},
				}
			});
		}

		if($('.slide-wrap-homepage .main__carousel--home').length) {
			$('.slide-wrap-homepage .main__carousel--home').owlCarousel({
				mouseDrag: true,
				touchDrag: true,
				dots: ($(".main__carousel--home .item").length > 1) ? true : false,
				loop: ($(".main__carousel--home .item").length > 1) ? true : false,
				items: 1,
				autoplay: false,
				autoplayHoverPause: true,
				autoplayTimeout: 5000,
				smartSpeed: 600,
				autoHeight: true,
				navigation:true,
				responsive: {
					0: {
						items: 1,
					},
					576: {
						items: 2,
						margin: 15,
					},
					992: {
						items: 2,
						margin: 15,
					},
					1200: {
						items: 2,
						margin: 20,
						mouseDrag: false,
						dots: false,
					},
				}
			});
		}

    if($('.slide-wrap-campaigns .main__carousel--home').length) {
			$('.slide-wrap-campaigns .main__carousel--home').owlCarousel({
				mouseDrag: true,
				touchDrag: true,
				dots: ($(".main__carousel--home .item").length > 1) ? true : false,
				loop: ($(".main__carousel--home .item").length > 1) ? true : false,
				items: 1,
				autoplay: false,
				autoplayHoverPause: true,
				autoplayTimeout: 5000,
				smartSpeed: 600,
				autoHeight: true,
				navigation:true,
				responsive: {
					0: {
						items: 1,
					},
					576: {
						items: 2,
						margin: 15,
					},
					992: {
						items: 3,
						margin: 15,
					},
					1200: {
						items: 4,
						margin: 20,
						mouseDrag: false,
						dots: false,
					},
				}
			});
		}

		$(document).on('changed.owl.carousel', '.main__carousel--discover, .main__carousel--recovery-entities, .main__carousel--user-profile-campaigns, .home__carousel--discover-companies', (event) => {
			// add lazy load for image
			$("img.lazyload").lazyload();
			let owl_id = '#' + event.target.id
			// $(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).removeClass('d-none');
			// $(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).removeClass('d-none');

			if ( event.item.index === event.item.count - event.page.size || event.item.count == 1){
				$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).addClass('d-none');
			} else {
				$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).removeClass('d-none');
			}

			if ( event.item.index === 0 || event.item.count == 1 ){
				$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).addClass('d-none');
			} else {
				$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).removeClass('d-none');
			}
		})

		$(document).on('changed.owl.carousel', '.main__carousel--recovery-entities, .main__carousel--user-profile-campaigns', (event) => {
			// add lazy load for image
			$("img.lazyload").lazyload();
			let owl_id = '#' + event.target.id
			$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).removeClass('d-none');
			$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).removeClass('d-none');

			if (event.item.count == 1 || event.page.size == event.item.count){
				$('.main__carousel--recovery-entities, .main__carousel--user-profile-campaigns').trigger('stop.owl.autoplay');
				$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).addClass('d-none');
			}

			if ( event.item.index === 0 || event.item.count == 1 || event.page.size == event.item.count ){
				$('.main__carousel--recovery-entities, .main__carousel--user-profile-campaigns').trigger('stop.owl.autoplay');
				$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).addClass('d-none');
			} else {
				$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).removeClass('d-none');
			}
		})

		$(document).on('changed.owl.carousel', '.main__carousel--home', (event) => {
			// add lazy load for image
			$("img.lazyload").lazyload();
			let owl_id = '#' + event.target.id
			$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).removeClass('d-none');
			$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).removeClass('d-none');

			if ( event.item.index === event.item.count - event.page.size ){
				$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).addClass('d-none');
			} else {
				$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).removeClass('d-none');
			}

			if ( event.item.index === 0 ){
				$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).addClass('d-none');
			} else {
				$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).removeClass('d-none');
			}
		})

		$(document).on('changed.owl.carousel', '.main__carousel--campaigns', (event) => {
			// add lazy load for image
			$("img.lazyload").lazyload();
			let owl_id = '#' + event.target.id
			$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).removeClass('d-none');
			$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).removeClass('d-none');

			if ( event.item.index === event.item.count - event.page.size ){
				$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).addClass('d-none');
			} else {
				$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).removeClass('d-none');
			}

			if ( event.item.index === 0 ){
				$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).addClass('d-none');
			} else {
				$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).removeClass('d-none');
			}
		})
		// Or to go to a previous slide
		$('.main__carousel--discover .home__nav.home__nav--prev').on('click', function() {
			// add lazy load for image
  			$("img.lazyload").lazyload();
		});

		$('.main__carousel--home .home__nav.home__nav--prev').on('click', function() {
			// add lazy load for image
			$("img.lazyload").lazyload();
			// $('.main__carousel--home').trigger('prev.owl.carousel');
		});		

		$('.main__carousel--campaigns .home__nav.home__nav--prev').on('click', function() {
			// add lazy load for image
			$("img.lazyload").lazyload();
		});

		$('.barcelona-carousel .home__nav.home__nav--next').on('click', function() {
			// add lazy load for image
			$("img.lazyload").lazyload();
		});		
	}

	initDiscoverCarousel()

	const owl_discover_medium = $('.main__carousel--discover-medium-size');
	owl_discover_medium.on('initialized.owl.carousel', (event) => {
		let owl_id = '#' + event.target.id

		$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).addClass('d-none');
		if (event.item.count <= event.page.size) {
			$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).addClass('d-none');
		}

		owl_discover_medium.removeClass('owl-hidden')
		$('#allUserCarouselLoading, #nftCarouselLoading').addClass('d-none')
	})
	owl_discover_medium.owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		dots: ($(".main__carousel--explore .item").length > 1) ? true : false,
		loop: ($(".main__carousel--explore .item").length > 1) ? true : false,
		items: 1,
		autoplay: false,
		autoplayHoverPause: true,
		autoplayTimeout: 5000,
		smartSpeed: 600,
		autoHeight: true,
		responsive: {
			0: {
				items: 1,
			},
			569: {
				items: 1,
				margin: 30,
			},
			768: {
				items: 2,
				margin: 30,
			},
			992: {
				items: 2,
				margin: 20,
			},
			1200: {
				items: 2,
				margin: 20,
				mouseDrag: false,
				dots: false,
			},
		}
	});

	const owl_nft_collection = $('.main__carousel--nft-collection');
	owl_nft_collection.on('initialized.owl.carousel', (event) => {
		let owl_id = '#' + event.target.id

		$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).addClass('d-none');
		if (event.item.count <= event.page.size) {
			$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).addClass('d-none');
		}
		$(owl_nft_collection).removeClass('owl-hidden');
		$('#featuredNftCollectionLoading').addClass('d-none');
	})
	owl_nft_collection.owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		dots: ($(".main__carousel--nft-collection .item").length > 1) ? true : false,
		loop: ($(".main__carousel--nft-collection .item").length > 1) ? true : false,
		items: 1,
		autoplay: false,
		autoplayHoverPause: true,
		autoplayTimeout: 5000,
		smartSpeed: 600,
		autoHeight: true,
		responsive: {
			0: {
				items: 1,
			},
			576: {
				items: 1,
				margin: 10,
			},
			768: {
				items: 1,
				margin: 10,
			},
			992: {
				items: 2,
				margin: 20,
			},
			1200: {
				items: 3,
				margin: 20,
				mouseDrag: false,
				dots: false,
			},
		}
	});

	$('.asset__item a.card__cover').simpleLightbox({
		sourceAttr: 'data-lightbox',
		scaleImageToRatio: true,
		widthRatio: 1,
		heightRatio: 1
	});

	$('.card__cover--carousel').owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		dots: true,
		loop: true,
		autoplay: true,
		autoplayHoverPause: true,
		autoplayTimeout: 5000,
		autoplaySpeed: 800,
		smartSpeed: 800,
		margin: 20,
		items: 1,
	});

	const owl_recovery_project = $('.main__carousel--recovery-project')

	owl_recovery_project.owlCarousel({
		mouseDrag: true,
		touchDrag: true,
		dots: true,
		loop: true,
		autoplay: true,
		autoplayHoverPause: true,
		autoplayTimeout: 10000,
		smartSpeed: 600,
		margin: 20,
		autoHeight: true,
		responsive: {
			0: {
				items: 1,
			},
			576: {
				items: 1,
			},
			768: {
				items: 1,
			},
			992: {
				items: 1,
			},
			1200: {
				items: 1,

			},
		}
	});

	window.imagesCarouselRP = () => {
		$('.main__carousel--rp').owlCarousel({
			mouseDrag: true,
			touchDrag: true,
			dots: true,
			loop: true,
			autoplay: false,
			lazyLoad:true,
			autoplayHoverPause: true,
			autoplayTimeout: 10000,
			smartSpeed: 600,
			margin: 20,
			autoHeight: true,
			responsive: {
				0: {
					items: 1,
				},
				576: {
					items: 1,
				},
				768: {
					items: 1,
				},
				992: {
					items: 1,
				},
				1200: {
					items: 1,
	
				},
			}
		});
	}

	window.imagesCarouselRP();

	/*==============================
	Handle arrow
	==============================*/

	owl_discover_medium.on('changed.owl.carousel', (event) => {
		let owl_id = '#' + event.target.id
		$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).removeClass('d-none');
		$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).removeClass('d-none');

		if ( event.item.index === event.item.count - event.page.size ){
			$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).addClass('d-none');
		} else {
			$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).removeClass('d-none');
		}

		if ( event.item.index === 0 ){
			$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).addClass('d-none');
		} else {
			$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).removeClass('d-none');
		}
	})

	owl_nft_collection.on('changed.owl.carousel', (event) => {
		let owl_id = '#' + event.target.id
		$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).removeClass('d-none');
		$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).removeClass('d-none');

		if ( event.item.index === event.item.count - event.page.size ){
			$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).addClass('d-none');
		} else {
			$(`.home__nav.home__nav--next[data-nav="${owl_id}"]`).removeClass('d-none');
		}

		if ( event.item.index === 0 ){
			$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).addClass('d-none');
		} else {
			$(`.home__nav.home__nav--prev[data-nav="${owl_id}"]`).removeClass('d-none');
		}
	})

	/*==============================
	Navigation
	==============================*/
	window.triggerCarouselNavigation = (e) => {
		$(document).on('click', '.main__nav--prev, .home__nav--prev, .barca-nav--prev', function (e) {
			e.preventDefault()

			const carouselId = $(this).attr('data-nav');
			$(carouselId).trigger('prev.owl.carousel');
		});
		$(document).on('click', '.main__nav--next, .home__nav--next, .barca-nav--next', function (e) {
			e.preventDefault()

			const carouselId = $(this).attr('data-nav');
			$(carouselId).trigger('next.owl.carousel');
		});
	}

	window.triggerCarouselNavigation();

	/*==============================
	Partners
	==============================*/
	$('.partners').owlCarousel({
		mouseDrag: false,
		touchDrag: false,
		dots: false,
		loop: true,
		autoplay: true,
		autoplayTimeout: 5000,
		autoplayHoverPause: true,
		smartSpeed: 600,
		margin: 20,
		responsive: {
			0: {
				items: 2,
			},
			576: {
				items: 3,
				margin: 20,
			},
			768: {
				items: 4,
				margin: 30,
			},
			992: {
				items: 4,
				margin: 30,
			},
			1200: {
				items: 6,
				margin: 30,
			},
			1900: {
				items: 8,
				margin: 30,
			},
		}
	});

	window.initExploreRecoveryProjectImagesCarousel = (id = 'explore-recovery-project-profile-images') => {
		$(`.${id}`).owlCarousel({
			mouseDrag: true,
			touchDrag: true,
			loop: true,
			dots: false,
			items: 1,
			autoplay: false,
			autoplayHoverPause: true,
			autoplayTimeout: 5000,
			smartSpeed: 600,
			autoHeight: true,
			margin: 3,

			responsive: {
				0: {
					items: 1,
					margin: 2,
				},
				576: {
					items: 1,
					margin: 2,
				},
				768: {
					items: 1,
				},
				992: {
					items: 1,
				},
				1200: {
					items: 1,
				},
			}
		});
	}

	initExploreRecoveryProjectImagesCarousel();


	/*==============================
	Modal
	==============================*/
	$('.open-video, .open-map').magnificPopup({
		disableOn: 0,
		fixedContentPos: true,
		type: 'iframe',
		preloader: false,
		removalDelay: 300,
		mainClass: 'mfp-fade',
		closeOnBgClick: false,
		enableEscapeKey: false,
	});

	$('.asset__img').magnificPopup({
		fixedContentPos: true,
		type: 'image',
		closeOnContentClick: true,
		closeBtnInside: false,
		mainClass: 'my-mfp-zoom-in',
		closeOnBgClick: false,
		enableEscapeKey: false,
		image: {
			verticalFit: true
		},
		zoom: {
			enabled: true,
			duration: 400
		}
	});

	$('.open-modal--share').magnificPopup({
		fixedContentPos: true,
		overflowY: 'auto',
		type: 'inline',
		preloader: false,
		modal: false,
		removalDelay: 300,
		closeOnBgClick: true,
		enableEscapeKey: false,
		callbacks: {
      open: function() {
        $("img.lazyload").lazyload();
      },
    }
	});

	$('.open-modal--select-package').magnificPopup({
		fixedContentPos: true,
		overflowY: 'auto',
		type: 'inline',
		preloader: false,
		modal: false,
		removalDelay: 300,
		closeOnBgClick: true,
		enableEscapeKey: false,
		callbacks: {
			close: function() {
				$('.js__select-package-modal__select-package').removeClass('hide')
				$('.js__select-package-modal__info').addClass('hide')
			}
		}
	});

	window.connectWallet = function connectWallet() {
		$('.open-modal').magnificPopup('open');
	}

	var checkModalBarca = $('.mfp-viewable-background').length ? '' : 'my-mfp-zoom-in';
	$('.open-modal').magnificPopup({
		fixedContentPos: true,
		fixedBgPos: true,
		overflowY: 'auto',
		type: 'inline',
		preloader: false,
		focus: '#username',
		modal: false,
		removalDelay: 300,
		mainClass: checkModalBarca,
		closeOnBgClick: true,
		enableEscapeKey: false,
	});

	$('.crop-image').magnificPopup({
		fixedContentPos: true,
		overflowY: 'auto',
		type: 'inline',
		preloader: false,
		modal: false,
		removalDelay: 300,
		closeOnBgClick: false,
		enableEscapeKey: false,
	});

	$('.select-collection').magnificPopup({
		fixedContentPos: true,
		overflowY: 'auto',
		type: 'inline',
		preloader: false,
		modal: false,
		removalDelay: 300,
		closeOnBgClick: true,
		showCloseBtn: false,
		enableEscapeKey: false,
	});

	$('.nft-collection-delete').magnificPopup({
		fixedContentPos: true,
		overflowY: 'auto',
		type: 'inline',
		preloader: false,
		modal: false,
		removalDelay: 300,
		closeOnBgClick: true,
		showCloseBtn: false,
		enableEscapeKey: false,
	});

	$('.modal__close, .modal_cancel_close, .term-condition-close').on('click', function (e) {
		e.preventDefault();
		$.magnificPopup.close();
	});

	/*==============================
	Select
	==============================*/
	$('.main__select').select2({
		minimumResultsForSearch: Infinity
	});

	/*==============================
	Section bg
	==============================*/
	$('.main__video-bg, .author__cover--bg, .main__author, .collection__cover').each(function () {
		if ($(this).attr('data-bg')) {
			$(this).css({
				'background': 'url(' + $(this).data('bg') + ')',
				'background-position': 'center center',
				'background-repeat': 'no-repeat',
				'background-size': 'cover'
			});
		}
	});

	/*==============================
	Upload file
	==============================*/
	$('.sign__file-upload').on('change', function () {
		var videoLabel = $(this).attr('data-name');

		if ($(this).val() != '') {
			$(videoLabel).text($(this)[0].files[0].name);
		} else {
			$(videoLabel).text('Upload file');
		}
	});

	/*==============================
	Countdown
	==============================*/
	$('.asset__clock').countdown('2022/12/01', function (event) {
		$(this).html(event.strftime('%D days %H:%M:%S'));
	});

	/*==============================
	 Scrollbar
	 ==============================*/
	// var Scrollbar = window.Scrollbar;

	// if ($('#asset__actions--scroll').length) {
	//     Scrollbar.init(document.querySelector('#asset__actions--scroll'), {
	//         damping: 0.1,
	//         renderByPixels: true,
	//         alwaysShowTracks: true,
	//         continuousScrolling: false,
	//     });
	// }

	function readURLSingle(input) {
		if (input.files && input.files[0]) {
			var reader = new FileReader();
			reader.onload = function (e) {
				$('#my-preview-section').html('<img id="img_prv" width="300" height="300" src="preview.img" controls/>')
				$('#img_prv')[0].src = URL.createObjectURL(input.files[0]);
				$('#my-preview-section').hide();
				$('#my-preview-section').fadeIn(650);
			}
			reader.readAsDataURL(input.files[0]);
		}
	}

	function readURLSingleAudio(input) {
		if (input.files && input.files[0]) {
			var reader = new FileReader();
			reader.onload = function (e) {
				//	            $('#my-preview-section').css('background-image', 'url(/assets/audio-file-icon.png)');
				$('#my-preview-section').hide();
				$('#my-preview-section').fadeIn(650);

				$('#my-preview-section').html('<audio width="300" height="300" controls><source src="mov_bbb.mp4" id="audio_here"> </audio>')
				$('#audio_here')[0].src = URL.createObjectURL(input.files[0]);
				$('#audio_here').parent()[0].load();

				$('#imagePreviewRes').css({
					'width': '100px',
					'height': '100px',
					'background-size': 'cover',
					'background-repeat': 'no-repeat',
					'background-position': 'center',
					'margin-left': 'auto',
					'margin-right': 'auto',
				});
				$('#close-preview-button').css('display', 'inline-block');
				$('#imagePreviewRes').css('background-image', 'url(/assets/audio-file-icon.png)');
				$('#placeholder').fadeOut(100);
				$('#file-1').fadeOut(100);
				$('#choose_file_selected').fadeOut(100);
				$('#imagePreviewRes').hide();
				$('#imagePreviewRes').fadeIn(650);
			}
			reader.readAsDataURL(input.files[0]);
		}
	}

	function readURLSingleVideo(input) {
		if (input.files && input.files[0]) {
			var reader = new FileReader();
			reader.onload = function (e) {

				//                $('#my-preview-section').css('background-image', 'url(/assets/video-file-icon.png)');
				$('#my-preview-section').hide();
				$('#my-preview-section').fadeIn(650);

				$('#my-preview-section').html('<video width="300" height="300" controls><source src="mov_bbb.mp4" id="video_here"> </video>')
				$('#video_here')[0].src = URL.createObjectURL(input.files[0]);
				$('#video_here').parent()[0].load();

				$('#imagePreviewRes').css({
					'width': '100px',
					'height': '100px',
					'background-size': 'cover',
					'background-repeat': 'no-repeat',
					'background-position': 'center',
					'margin-left': 'auto',
					'margin-right': 'auto',
				});
				$('#close-preview-button').css('display', 'inline-block');
				$('#imagePreviewRes').css('background-image', 'url(/assets/video-file-icon.png)');
				$('#placeholder').fadeOut(100);
				$('#file-1').fadeOut(100);
				$('#choose_file_selected').fadeOut(100);
				$('#imagePreviewRes').hide();
				$('#imagePreviewRes').fadeIn(650);
			}
			reader.readAsDataURL(input.files[0]);
		}
	}

	// $("#file-1").change(function(e) {
	//     var file = e.currentTarget.files[0];
	//     if (file.type == "audio/mpeg") {
	//         readURLSingleAudio(this,'audio');
	//     }if (file.type == "video/mp4") {
	//         readURLSingleVideo(this,'audio');
	//     }else{
	//         readURLSingle(this);
	//     }
	// });

	$('.card-wrap--explore').hover(function () {
		$(this).find('.card-wrap--explore__buy-now').not('.disabled').addClass('hover');
	}, function () {
		$(this).find('.card-wrap--explore__buy-now').not('.disabled').removeClass('hover');
	})

  if($('body').find('.header').length > 0)
  {
    $('body').css('padding-top', '80px');
  }

  if($('body').find('.header .header__checkout-page').length > 0)
  {
    $('body').css('padding-top', '60px');
  }

  if($('.footer').find('.footer--checkout').length > 0)
  {
    $('.footer').css('min-height', 'unset !important');
  }

  if($('body').find('.rosita-header-bar').length > 0) {
    $('body').css('padding-top', '94px');
  }

	if($('body').find('.connect-wallet__login-form__email').length > 0) {
		$('.connect-wallet__login-form__email').on('keypress', function(event) {
			if (event.key === 'Enter') {
				event.preventDefault();
				window.connect('magic');
			}
		});
	} 

	function rositaCarousel() {
		var $carousel = $('.rosita-logros-carousel');

		$carousel.owlCarousel({
			mouseDrag: true,
			touchDrag: true,
			dots: false,
			loop: true,
			items: 1,
			autoplay: true,
			autoplayHoverPause: false,
			margin: 20,
			autoHeight: true,
			autoplayTimeout: 5000,
			responsive: {
				0: {
					items: 1,
					margin: 6,
					dots: true,
				},
				576: {
					items: 1,
					margin: 6,
					dots: false,
					autoplay: false,
					dots: true,
				},
				768: {
					items: 1,
					margin: 30,
					dots: true,
				},
				992: {
					items: 1,
					margin: 11,
					dots: true,
				},
				1200: {
					items: 1,
					margin: 50,
					mouseDrag: false,
					dots: true,
				},
				1500: {
					items: 1,
					margin: 150,
					mouseDrag: false,
					dots: true,
				},
			},
		});
	}
	rositaCarousel();
});
