import { debounce } from "./utils";
import IconCircle from '../../assets/images/ic-map-circle-hover.svg';
import IconHoverCircle from '../../assets/images/ic-map-circle-selected.svg';
import selectedIconCircle from '../../assets/images/ic-map-circle-hover-yellow.svg';
import nextIcon from '../../assets/images/next-icon.svg';
import previousIcon from '../../assets/images/prev-icon.svg';
import { infiniteScrollingItem } from "./infinity_scroll_item";

$(document).ready(function () {
  let mapInstance = null;
  $('#show-supported-recovery-project-map-button').on('click', () => {
    $.magnificPopup.open({
      closeOnBgClick: true ,
		  enableEscapeKey: false,
      items: {
        src: '#supported-recovery-project-show-map-popup'
      },
      type: 'inline',

    });
    if (!mapInstance) {
      if ($('#map').length > 0) {

        const buildCarouselImagesHtml = (images) => {
          let carouselHtml = ''
          images.forEach(image => {
            carouselHtml += `<div class="owl-item">
                                <img class='' src=${image} />
                              </div>`
          })

          return carouselHtml;
        }

        const markerPopupHtml = ({recyclerAddress, userImage, countryFlagClass, countryName, images, RpName, plasticSupported}) =>
          `<div class="map__pop-up">
            <div class='map__pop-up__banner-image'>
              <div class="main__carousel explore-recovery-project-profile-images owl-carousel owl-loaded" id="${recyclerAddress}exploreRecoveryProjectProfileImages">
                <div class="owl-stage-outer owl-height">
                  <div class="owl-stage">
                    ${buildCarouselImagesHtml(images)}
                  </div>
                </div>
                <button class="home__nav home__nav--prev" data-nav="#${recyclerAddress}exploreRecoveryProjectProfileImages" type="button">
                  <img class='' src=${previousIcon} />
                </button>
                <button class="home__nav home__nav--next" data-nav="#${recyclerAddress}exploreRecoveryProjectProfileImages" type="button">
                  <img class='' src=${nextIcon} />
                </button>
              </div>
            </div>
            <div class="map__pop-up__title">
              <div class='map__pop-up__title__recovert-project-info'>
                <div class="img">
                  <a href='/users/${recyclerAddress}/recovery_project_profile'>
                    <img class='' src=${userImage} />
                  </a>
                </div>
                <div class="content">
                  <div class='font-size-14-text font-weight-600-text text-truncate-1'>
                    <a href='/users/${recyclerAddress}/recovery_project_profile'>
                      ${RpName}
                    </a>
                  </div>
                  <span class="map__pop-up__description font-size-12-text text-truncate-1 neutral-500-color-text">
                    <i class='${countryFlagClass}'></i>
                    ${countryName}
                  </span>
                </div>
              </div>
              <div class='map__pop-up__title__recycle-info'>
                <div class='recycle-quantity font-weight-600-text text-truncate-1'>
                  ${plasticSupported} kg
                </div>
                <div class='font-size-12-text neutral-500-color-text map__pop-up__title__recycle-info__text'>
                  plastic recovered
                </div>
              </div>
            </div>
          </div>`

        let recycler_country_coordinates_id = $('#recycler_country_coordinates');
        let coordinates = []

        try {
          coordinates = JSON.parse(recycler_country_coordinates_id.val())
        } catch {
          coordinates = []
        }

        const myIcon = L.icon({
          iconUrl: IconCircle,
          iconSize: [38, 38],
          popupAnchor: [0, -10],
          className: 'recycler-icon'
        });

        const mySelectedIcon = L.icon({
          iconUrl: selectedIconCircle,
          iconSize: [38, 38],
          popupAnchor: [0, -10],
          className: 'recycler-icon'
        });

        const myHoverIcon = L.icon({
          iconUrl: IconHoverCircle,
          iconSize: [38, 38],
          popupAnchor: [0, -10],
          className: 'recycler-icon'
        });

        const bounds = new L.LatLngBounds(new L.LatLng(85.0, -180.0), new L.LatLng(-85.0, 180.0));

        const map = L.map('map', {
          'center': bounds.getCenter(),
          'zoom': 2,
          'trackResize': false,
          'maxBound': bounds,
          'maxBoundsViscosity': 1.0,
          'layers': [
            L.tileLayer('https://api.mapbox.com/styles/v1/garciapd/cl4w6ylk1003o15lkwraq18sv/draft/tiles/{tileSize}/{z}/{x}/{y}@2x?access_token={accessToken}', {
              minZoom: 1,
              id: 'cl4w6ylk1003o15lkwraq18sv',
              tileSize: 512,
              zoomOffset: -1,
              accessToken: 'pk.eyJ1IjoiZ2FyY2lhcGQiLCJhIjoiY2w0cnd1dXk2MGcxYjNpbzB2NnViM3VudyJ9.t7T0_cx2_51iTXSUv9LiYA'
            })
          ]
        })

        mapInstance = map

        map.on('drag', function () {
          map.panInsideBounds(bounds, { animate: false });
        });

        for (let i = 0; i < coordinates.length || 0; i++) {
          if (coordinates[i][1] && coordinates[i][1].length === 2) {
            let marker = L.marker(coordinates[i][1], { icon: myIcon })
            marker.bindPopup(markerPopupHtml({
              recyclerAddress: coordinates[i][3][0],
              images: coordinates[i][3][2],
              userImage: coordinates[i][3][3],
              countryFlagClass: coordinates[i][3][5],
              countryName: coordinates[i][0],
              RpName: coordinates[i][3][1],
              plasticSupported: coordinates[i][3][6] || 0,
            })).openPopup();
            marker.addTo(map).on('click', () => {
              window.initExploreRecoveryProjectImagesCarousel();
            });
            marker.on('mouseover', (e) => {
              e.target.setIcon(myHoverIcon);
              marker.on('mouseout', (e) => {
                e.target.setIcon(myIcon);
              });
            });
            marker.on('popupopen', (e) => {
              e.target.setIcon(mySelectedIcon);
              marker.on('mouseout', (e) => {
                e.target.setIcon(mySelectedIcon);
              });
            });
            marker.on('popupclose', function(e) {
              e.target.setIcon(myIcon);
            });
          }
        }

        map.touchZoom.disable();
        map.doubleClickZoom.disable();
        map.scrollWheelZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        map.dragging.disable();

        $(`#map`).on('click', () => {
          map.dragging.enable();
          map.touchZoom.enable();
          map.doubleClickZoom.enable();
          map.scrollWheelZoom.enable();
          map.boxZoom.enable();
          map.keyboard.enable();
        })
      }
    }
  })

  infiniteScrollingItem();

  function fetchCollectionFilter() {
    $.ajax({
      url: $('#userId').attr('href'),
      type: 'GET',
      dataType: 'script',
      data: {
        tab: $("#tabId").attr('data-tab'),
        network: $("#d-label__network").attr('data-filter'),
        sort_by: $("#d-label__sort-by").attr('data-filter'),
        sale_type: $("#d-label__sale-type").attr('data-filter')
      },
      success: function () {
        $("div.loading-gif.displayInMiddle").hide();
      }
    });
  }

  $('#user__dropdown-menu__network li').on('click', function () {
    $("div.loading-gif.displayInMiddle").show();
    $('.dropdown-toggle__network p').html($(this).find('.dropdown-menu__content').html() + '<span class="caret"></span>');
    $("#d-label__network").attr('data-filter', $(this).data('filter'))
    $("#d-label__network").dropdown("toggle");
    fetchCollectionFilter()
  })

  $('#user__dropdown-menu__sort-by li').on('click', function () {
    $("div.loading-gif.displayInMiddle").show();
    $('.dropdown-toggle__sort-by p').html($(this).find('.dropdown-menu__content').html() + '<span class="caret"></span>');
    $('#d-label__sort-by').attr('data-filter', $(this).data('filter'))
    $("#d-label__sort-by").dropdown("toggle");
    fetchCollectionFilter()
  })

  $('#user__dropdown-menu__sale-type li').on('click', function () {
    $('.dropdown-toggle__sale-type p').html($(this).find('.dropdown-menu__content').html() + '<span class="caret"></span>');
    $("#d-label__sale-type").attr('data-filter', $(this).data('filter'))
    $("#d-label__sale-type").dropdown("toggle");
    fetchCollectionFilter()
  })

  function fetchCampaignFilter() {
    $.ajax({
      url: $('#userId').attr('href'),
      type: 'GET',
      dataType: 'script',
      data: {
        tab: $("#tabId").attr('data-tab'),
        type: $("#d-label__type").attr('data-filter'),
        filter: $("#d-label__filter").attr('data-filter'),
        search: $("#d-label__search").attr('data-filter')
      },
      success: function () {
        $("div.loading-gif.displayInMiddle").hide();
      }
    });
  }

  $('#user__dropdown-menu__type li').on('click', function () {
    $("div.loading-gif.displayInMiddle").show();
    $('.dropdown-toggle__type p').html($(this).find('.dropdown-menu__content').html() + '<span class="caret"></span>');
    $("#d-label__type").attr('data-filter', $(this).data('filter'))
    $("#d-label__type").dropdown("toggle");
    fetchCampaignFilter()
  })

  $('#user__dropdown-menu__filter li').on('click', function () {
    $("div.loading-gif.displayInMiddle").show();
    $('.dropdown-toggle__filter p').html($(this).find('.dropdown-menu__content').html() + '<span class="caret"></span>');
    $("#d-label__filter").attr('data-filter', $(this).data('filter'))
    $("#d-label__filter").dropdown("toggle");
    fetchCampaignFilter()
  })

  $('#campaign_search_btn').on('click', function () {
    $("div.loading-gif.displayInMiddle").show();
    $("#d-label__search").attr('data-filter', $('#name-filter').val())
    fetchCampaignFilter()
  })

  $('#name-filter').keyup((e) => {
    if (e.key === "Enter" ) {
      $("div.loading-gif.displayInMiddle").show();
      $("#d-label__search").attr('data-filter', $('#name-filter').val())
      fetchCampaignFilter()
    }
  })

  $('.recycler__author__bio__btn').on('click', function () {
    if ($(this).find('i').hasClass('fa-chevron-down')) {
      $(this).find('i').removeClass('fa-chevron-down');
      $(this).find('i').addClass('fa-chevron-up');
      $('.recycler__author__bio--text--small').css('display', 'block');
      $('.recycler__author__bio--card').removeClass('collapsed');
    } else if ($(this).find('i').hasClass('fa-chevron-up')) {
      $(this).find('i').removeClass('fa-chevron-up');
      $(this).find('i').addClass('fa-chevron-down');
      $('.recycler__author__bio--text--small').css('display', '-webkit-box');
      $('.recycler__author__bio--card').addClass('collapsed');
    }
  })

  $('.down-arrow').click(function () {
    $(this).toggleClass('invert');
    $('.recycler__author__bio--text').toggleClass('expand');
  })

  function readURLSingleUser(input, file, previewSection, fileId, uploadId, closePreviewBtn) {
    var ftype = file.type;
    var fsize = file.size / 1024 / 1024; // in MBs
    if (fsize > 30) {
      return toastr.error('Invalid file size!. Must be less than 30MB');
    }
    var imgExt = ['image/png', 'image/jpeg', 'image/gif', 'image/webp'];

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        if (imgExt.includes(ftype)) {
          uploadId.css('display', 'none');
          previewSection.css('background-image', 'url(' + e.target.result + ')');
          previewSection.css({
            'background-size': 'cover',
            'background-repeat': 'no-repeat',
            'background-position': 'center',
            'margin-left': 'auto',
            'margin-right': 'auto',
          });
          previewSection.hide();
          previewSection.fadeIn(650);
          closePreviewBtn.css('display', 'flex')
        } else {
          return toastr.error('Invalid file type!');
        }
        fileId.fadeOut(100);
      }
      reader.readAsDataURL(input.files[0]);
    }
  }

  $(document).on('click', '.recycler__section__banner__tab__btn', function (e) {
    $('.recycler__section__banner__tab__btn').removeClass('active')
    $(e.currentTarget).addClass('active')
    const tab = $(e.currentTarget).data('filter') || 'direct'
    $(`.recycler__section__banner__body__title`).addClass('hide')
    $(`.recycler__section__banner__body__title.--${tab}`).removeClass('hide')

    if(tab == 'indirect') {
      $('.view-all').addClass('hide')
    } else {
      $('.view-all').removeClass('hide')
    }

    if($(".equivalence").length) {
      $(`.equivalence`).addClass('hide')
      $(`.equivalence.--${tab}`).removeClass('hide')
    }
  })


  $('.campaign-show-all').on('click', function(e) {
    $('.text-show-all').toggleClass('hide')
    $('.text-show-less').toggleClass('hide')
    $('.show-campaign-desc-arrow').toggleClass('invert')
    $('.campaign-description').toggleClass('text-truncate-2')
  })

  if ($("#description_detail").height() / 20 < 2) {
    $('.campaign-show-all').empty()
  }

  $('#scrollRightId').on('click', function (e) {
    $('#recycler__profile__tabs')[0].scrollLeft += $('#recycler__profile__tabs')[0].offsetWidth;
  })

  $('#scrollLeftId').on('click', function (e) {
    $('#recycler__profile__tabs')[0].scrollLeft -= $('#recycler__profile__tabs')[0].offsetWidth;
  })

  $(".nav-item").on('click', function (e) {
    $(".nav-link.active").removeClass("active")
    $(e.currentTarget).children(".nav-link").addClass("active")
  })

  $('#recycler__profile__tabs').scroll(function () {
    if (this.scrollLeft === 0) {
      $('#scrollLeftId').addClass('hide');
      $('#scrollRightId').removeClass('hide');
    } else if (this.scrollLeft >= $('#recycler__profile__tabs')[0].scrollWidth - $('#recycler__profile__tabs')[0].offsetWidth) {
      $('#scrollRightId').addClass('hide');
      $('#scrollLeftId').removeClass('hide');
    } else {
      $('#scrollLeftId').removeClass('hide');
      $('#scrollRightId').removeClass('hide');
    }
  })

  $('.dropdown-plastic-type__body').on('click', function (event) {
    event.stopPropagation();
  })

  $('#selectCountryOptions').on('change', function (event) {
    const country = $('#selectCountryOptions').val()

    if (country) {
      fetchStateOfCountry(country);
    }
  });

  function fetchStateOfCountry(country) {
    $.ajax({
      url: '/users/states',
      type: 'GET',
      async: false,
      data: { country: country }
    });
  }

  function fetchSustabilityProfileImages() {
    var resp = false
    return $.ajax({
      url: '/image-generator',
      type: 'POST',
      data: {
        "recycler_name": $(".owner-name").text(),
        "recycler_id": $("#user_id").val(),
        "weight": $(".plastic-type-val").val(),
        "material": $(".plastic-type").val(),
        "date": $("#dateOfRecycling").val(),
      },
      xhr: function () {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob'
        return xhr;
      },
      success: function (data) {
        if (data.errors) {
          toastr.error(data['error'])
        } else {
          resp = data
          var file = blobToFile(data, 'AutoGenerate.png')
          return file;
        }
      }
    });
  }
  if ($('#selectCountryOptions').length > 0) {
    var userCountry = $('#selectCountryOptions').data('user-country')
    $('#selectCountryOptions').val(userCountry).trigger('change.select2');
  }
  if ($('#selectCityOptions').length > 0) {
    var userCity = $('#selectCityOptions').data('user-city')
    $('#selectCityOptions').val(userCity).trigger('change.select2');
  }
  $("#recycler__details--expand-btn--mobile").on("click", function (e) {
    $("#recycler__details--mobile").toggleClass("collapsed")
    if ($("#recycler__details--expand-btn--mobile i").hasClass("fa-chevron-up")) {
      $("#recycler__details--expand-btn--mobile i").removeClass("fa-chevron-up")
      $("#recycler__details--expand-btn--mobile i").addClass("fa-chevron-down")
    } else {
      $("#recycler__details--expand-btn--mobile i").removeClass("fa-chevron-down")
      $("#recycler__details--expand-btn--mobile i").addClass("fa-chevron-up")
    }
  })

  const fetchAllTypePlactics = (screen) => {
    const address = $('#user-address').val();

    $.ajax({
      url: `/users/${address}/view_all_plastics_company`,
      type: 'get',
      dataType: 'json',
      data: {},
      success: (res) => {
        switch (screen) {
          case 'desktop':
            $(`.modal-body`).closest('#allPlasticTypeCompanyModal').find('.table-plastic-type').html(res.html)

            $.magnificPopup.open({
              closeOnBgClick: true,
              enableEscapeKey: true,
              items: {
                src: '#allPlasticTypeCompanyModal'
              }
            });
            break
          case 'mobile':
            $('#modal__plastic-type__data__mobile').html(res.html)
            break
        }
      }
    });
  }

  $('.js__view-all-plastic-type-company').on('click', (e) => {
    e.preventDefault();
    fetchAllTypePlactics('desktop')
  })

  $('.js__view-all-plastic-type-campaign').on('click', (e) => {
    e.preventDefault();
    fetchCampaignPlasticsTypes('desktop')
  })

  const fetchCampaignPlasticsTypes = (screen) => {
    const address = $('#campaign-address').val();

    $.ajax({
      url: `/campaigns/${address}/view_all_plastics_types`,
      type: 'get',
      dataType: 'json',
      data: {},
      success: (res) => {
        switch (screen) {
          case 'desktop':
            $(`.modal-body`).closest('#allPlasticTypeCampaignModal').find('.table-plastic-type').html(res.html)

            $.magnificPopup.open({
              closeOnBgClick: true,
              enableEscapeKey: true,
              items: {
                src: '#allPlasticTypeCampaignModal'
              }
            });
            break
          case 'mobile':
            $('#modal__plastic-type__data__mobile').html(res.html)
            break
        }
      }
    });
  }

  const handleHoverSocialIcon = (el) => {
    let preprocessingEl = el.slice(1)
    $(el).hover(() => {
      console.log($(el).children().attr('src'))
      $(el).children().attr('src', `/assets/ic-${preprocessingEl}-hover.svg`)
    }, () => {
      console.log($(el).children().attr('src'))
      $(el).children().attr('src', `/assets/ic-${preprocessingEl}.svg`)
    })
  }
  handleHoverSocialIcon('.website')
  handleHoverSocialIcon('.instagram')
  handleHoverSocialIcon('.facebook')
  handleHoverSocialIcon('.linkedin')
  handleHoverSocialIcon('.twitter')
  handleHoverSocialIcon('.ellipsis')

  $(document).on('click', '.supported-recovery-project__card--content__collections--img', (event) => {
    const $this = event.currentTarget;
    const userId = $($this).data("user");
    $('.rp__images__carousel').owlCarousel({items: 1, lazyLoad: true})
    $('.rp__images__carousel').trigger('to.owl.carousel', [+$($this).data("index"), 2])
    $.magnificPopup.open({
      closeOnBgClick: true,
      enableEscapeKey: false,
      fixedContentPos: false,
      items: {
        src: `#viewImagesRPModal${userId}`
      }
    });
  })

  $(document).on('click', '.rp-images-modal-back-btn', () => {
    $.magnificPopup.close()
  })

  $('.show-all-back-btn').on('click', () => {
    $.magnificPopup.close()
  })

  $('.recycler__author__follow, .recycler__author__follow_more_option').on('click', function(){
    $(this).addClass('disabled');
    $(".btn-action-text .loading-icon").removeClass('d-none')
    let url = '';
    if ($(this).hasClass('unfollow')){
      url = $('#unfollow_user_url').val();
    } else if($(this).hasClass('follow')){
      url = $('#follow_user_url').val();
    } else if($(this).hasClass('like')){
      url = $('#like_user_url').val();
    } else {
      return;
    }

    $.ajax({
      url: url,
      type: "post",
      dataType: "json",
      success: function(data){
        let likeCount = parseInt($('.profile_like_count').text())
        let followerCount = parseInt($('.profile_follow_count').text());
        let action = data.action

        switch (action) {
          case 'like_profile':
            $(".profile_like_count, .profile_like_count_mobile").text(likeCount + 1);
            if (gon.session) {
              $(".profile_follow_count, .profile_follow_count_mobile").text(followerCount + 1);
              $(".btn-action-text span, .text-action").text("Unfollow");
              $('.recycler__author__follow, .recycler__author__follow_more_option').removeClass('like').addClass('unfollow')
            }
            break;
          case 'follow':
            $(".profile_follow_count, .profile_follow_count_mobile").text(followerCount + 1);
            $(".btn-action-text span, .text-action").text("Unfollow");
            $('.recycler__author__follow, .recycler__author__follow_more_option').removeClass('follow').addClass('unfollow')
            break;
          case 'unfollow':
            $(".profile_follow_count, .profile_follow_count_mobile").text(followerCount - 1);
            $(".btn-action-text span, .text-action").text("Follow");
            $('.recycler__author__follow, .recycler__author__follow_more_option').removeClass('unfollow').addClass('follow')
            break;
        
          default:
            break;
        }

        toastr.success(data.message)
      },
      error: function(data){
        toastr.error(data.responseJSON.error)
      },
      complete: function(data){
        $('.recycler__author__follow, .recycler__author__follow_more_option').removeClass('disabled');
        $(".btn-action-text .loading-icon").addClass('d-none')
      }
    })
  });

  window.showCardListingVideo = function showCardListingVideo(element, collection_id){
    event.preventDefault();
    $(element).hide();

    if($(`#collection_img_${collection_id}`).length < 1 ||  $(`#collection_video_${collection_id}`).length < 1)
    {
      return console.log('error', 'element not exist!')
    }

    $(`#collection_img_${collection_id}`).addClass('d-none')
    $(`#collection_video_${collection_id}`).removeClass('d-none');

    var video = document.getElementById(`collection_video_${collection_id}`);
    var video_url = $(`#video_avatar_url_${collection_id}`).val();

    video.setAttribute('src', video_url);
    video.setAttribute('type', 'video/mp4');

    video.load();
    video.play();
  }
  
  $('.profile-create-collection__btn').on('click', function() {
    $(this).addClass("clicked");
  });

  $('.modal__close').on('click', function() {
    $('.profile-create-collection__btn').removeClass("clicked");
  });

  $('.redeem_code').on('click', '.redeem_code__claim--button', async function(e) {
    const url = $(e.currentTarget).data('url')
    const code = $(e.currentTarget).data('code')

    $(e.currentTarget).addClass('disabled');
    $('.loading-gif').show();
    $('body').css('overflow', 'hidden');

    await $.ajax({
      url: url,
      method: "POST",
      data: JSON.stringify({ code: code }),
      dataType: "script",
      headers: {
        'X-CSRF-TOKEN': document.querySelector('[name=csrf-token]').content,
        'Content-Type': 'application/json',
      },
    }).done(response => console.log("Redeem Success: ", response))
    .fail(err => console.log("Redeem Error: ", err))
  })

  const qrWrapper = $('#profile-qr')

  const qrCode = new QRCodeStyling({
    width: 200,
    height: 200,
    type: "svg",
    data: `${qrWrapper.data('url')}`,
    image: `${qrWrapper.data('img')}`,
    dotsOptions: {
      color: "#00060A",
      type: "square"
    },
    backgroundOptions: {
      color: "#fff",
    },
    imageOptions: {
      crossOrigin: "anonymous",
    }
  });

  qrCode.append(qrWrapper[0]);

  $('.share-impact__footer--qr__download.--png').on('click', function(e) {
    qrCode.download({ name: "qr", extension: "png" });
  })

  $('.share-impact__footer--qr__download.--svg').on('click', function(e) {
    qrCode.download({ name: "qr", extension: "svg" });
  })

  $('.profile-share--qr').on('click', function(e) {
    $('.share-impact__footer--qr__wrapper').toggleClass('hide')
  })
});

