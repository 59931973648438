import { initDropzone } from './utils/dropzone';
import { triggerEventRecoveryProjectSustainability } from './trigger_event_recovery_project_sustainability_tab.js'
import { handleEventForEditRoadmap, handleAddAchievementButton } from './handle_event_for_edit_roadmap.js'
import { initRecoveryProjectMap } from './recovery_project_map';
import { initRecoveryProjectProfileSdMap } from './recovery_project_profile_sd_map';
import { triggerEventRecoveryProjectImpactGallery } from './trigger_event_recovery_project_impact_gallery_tab.js';
import Cropper from 'cropperjs';

$(document).ready(function () {
  const setupForSocialMediaWallTab = () => {
    console.log('setupForSocialMediaWallTab')

    $('body').on('click', '.add-social-accounts-button, #open-edit-social-accounts', () => {
      $.magnificPopup.open({
        closeOnBgClick: false,
        enableEscapeKey: false,
        items: {
          src: '#addSocialMediaAccountsModal'
        },
        callbacks: {
          close: () => {
            document.getElementById('add-user-social-accounts-form').reset();
            // delete params open_modal when close modal
            window.history.replaceState(null, null, window.location.href.replace('open_modal=true&', '').replace('&open_modal=true', ''));
          }
        }
      });
    })

    $('body').on('click', '#sync-social-posts-now', function (e) {
      $("#sync-social-posts-now").prop('disabled', true)
      $("#sync-social-posts-now span").html('Processing...')

      $.ajax({
        url: '/users/sync_news',
        type: 'GET',
        async: false,
        success: function (respVal) {
          toastr.success('Your news is synchronizing. Please check back in few minutes.')
          setTimeout(() => window.location.reload(), 2000)
        }
      });
    })

    $('body').on('click', '#save-social-accounts-button', function (e) {
      const $form = $('#add-user-social-accounts-form');
      var data = $form.serialize();

      $.post($form.attr('action'), data).then((res) => {
        if (res['status'] == 200) {
          toastr.success(res['msg'])
          $.magnificPopup.close()
        } else {
          toastr.error(res['msg'])
        }
      })
    })

    $('body').on('click', '#discard-social-accounts-change-button', function (e) {
      $.magnificPopup.close();
    })

    $('body').on('click', '#connect-instagram, #connect-facebook, #remove-instagram, #remove-facebook', function (e) {
      const href = this.href
      e.preventDefault()

      const $form = $('#add-user-social-accounts-form');
      var data = $form.serialize();
      $.post($form.attr('action'), data).then((_) => {
        window.location = href
      })
    })

    $('body').on('click touchstart', '.thumbnail-player-icon', function (e) {
      const videoId = $(e.currentTarget).closest('.media-card').data('video-id');
      console.log("played youtube video: ", videoId)

      $.magnificPopup.open({
        closeOnBgClick: true,
        enableEscapeKey: false,
        items: {
          src: `#playMediaVideoModal${videoId}`
        },
        callbacks: {
          close: () => {
            $(`#playMediaVideoModal${videoId} video`).trigger('pause')
          }
        }
      });
    })
  }

  setupForSocialMediaWallTab();

  const handleForSustainabilityTab = () => {
    const isValidUrl = (str) => {
      const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      return !!pattern.test(str) || !str;
    }

    $('#openEditWhoWeAreModal, #openAddWhoWeAreModal').on('click', (e) => {
      let dropzoneObject = null;
      let dropzoneVideoObject = null;

      let deletedImageIds = [];

      const validateAreaFieldLength = (fieldId, maxLength, minLength) => {
        let currentLength = $(`#${fieldId}`).val().length

        return (currentLength <= maxLength && currentLength >= minLength)
      }

      const validateForm = () => {
      
        // if (!$('form#update-sustainability-profile-user-form').data('changed')) {
        //   return true
        // }
        if (!isValidUrl($('#sustainability_profile_video_url').val())) {
          return false
        }
        if (!validateAreaFieldLength('sustainability_profile_first_part', 2000, 240) && 
            !validateAreaFieldLength('sustainability_profile_second_part', 2000, 240)
          ) {
          return false
        }
        // if (!validateAreaFieldLength('sustainability_profile_first_part', 2000, 240)) {
        //   return false
        // }
        // if (!validateAreaFieldLength('sustainability_profile_second_part', 2000, 240)) {
        //   return false
        // }
        return true
      }

      $("form#update-sustainability-profile-user-form :input").change(function () {
        $("form#update-sustainability-profile-user-form").data('changed', true)
      });

      const resetErrorCss = (el) => {
        $(el).removeClass()
        $(el).addClass('error hide-error')
      }

      e.stopPropagation();
      $.magnificPopup.open({
        closeOnBgClick: false,
        enableEscapeKey: false,
        items: {
          src: '#editWhoWeAreModal'
        },
        callbacks: {
          close: () => {
            dropzoneObject && dropzoneObject.destroy()
            dropzoneVideoObject && dropzoneVideoObject.destroy()
          }
        }
      });

      $('.discard-changes').on('click', () => {
        $("#update-sustainability-profile-user-form").trigger('reset')
        resetErrorCss('#url-error')
        resetErrorCss('#video-error')
        resetErrorCss('#sus-first-error')
        resetErrorCss('#sus-second-error')
        $('#form-error').removeClass()
        $('#form-error').addClass('form-error d-none')
        $('form#update-sustainability-profile-user-form').data('changed', false)
        $.magnificPopup.close();
      })

      const dropzoneOptions = {
        formSelector: '#update-sustainability-profile-user-form',
        templateSelector: '#tpl',
        previewsContainerSelector: '#file-preview',
        uploadMultiple: true,
        fileLimit: 3,
        acceptedFiles: 'image/jpeg,image/png,image/jpg',
        isAddingImage: true
      }

      const dropzoneVideoOptions = {
        formSelector: '#video-zone',
        url: $("#update-sustainability-profile-user-form").attr("action"),
        templateSelector: '#tpl-video',
        previewsContainerSelector: '#file-preview-video',
        uploadMultiple: true,
        fileLimit: 1,
        maxFilesize: 50,
        acceptedFiles: 'video/mp4',
        isAddingImage: false
      }

      dropzoneVideoObject = initDropzone(dropzoneVideoOptions);

      const onDeleteFileAction = (file) => {
        if (file.mockExistingFile) {
          deletedImageIds.push(file.mockExistingFileId)
        }
      }

      dropzoneObject = initDropzone(dropzoneOptions, onDeleteFileAction);
      const existingImageList = JSON.parse($('#existing-images-list').val())

      existingImageList.forEach((imageObject) => {
        const mockFile = {
          name: 'FileName',
          size: '1000',
          type: 'image/jpeg',
          kind: 'file',
          imageUrl: imageObject[0],
          accepted: true,
          mockExistingFile: true,
          mockExistingFileId: imageObject[1]
        };

        dropzoneObject.emit("addedfile", mockFile);
        dropzoneObject.files.push(mockFile);
        dropzoneObject.emit("complete", mockFile);
      })

      $('.remove-video-file').on('click', () => {
        dropzoneVideoObject.removeAllFiles()
        $('#video-message').html('Drop your file here')
      })

      $('.remove-video-link').on('click', () => {
        $('#sustainability_profile_video_url').val('')
        resetCss('#url-error')
      })

      $('#sustainability_profile_video_url').on('change', () => {
        let str = $('#sustainability_profile_video_url').val()
        if (!isValidUrl(str)) {
          $('#url-error').removeClass('hide-error')
        } else {
          $('#url-error').addClass('hide-error')
        }
      })

      dropzoneVideoObject.on('error', (file, data) => {
        dropzoneVideoObject.removeFile(file);
        $('#video-error').text(data)
        $('#video-error').removeClass('hide-error')
      })

      $('#sustainability_profile_first_part').on('keyup', () => {
        validateAreaFieldLength('sustainability_profile_first_part', 2000, 240) ? $('#sus-first-error').addClass('hide-error') : $('#sus-first-error').removeClass('hide-error')
      })

      $('#sustainability_profile_second_part').on('keyup', () => {
        validateAreaFieldLength('sustainability_profile_second_part', 2000, 240) ? $('#sus-second-error').addClass('hide-error') : $('#sus-second-error').removeClass('hide-error')
      })

      $('#save-changes-who-we-are-form').on('click', () => {
        const $form = $('#update-sustainability-profile-user-form');
        let isValidInput = validateForm()
        if (!isValidInput) {
          $('#form-error').removeClass('d-none')
          return false
        } else {
          $("#save-changes-who-we-are-form").attr("disabled", "disabled").off('click');
          const files = dropzoneObject.files;
          const video = dropzoneVideoObject.files

          const fileInput = $('<input id="sustainability_profile_images" type="file" multiple="multiple" name="sustainability_profile[images][]" />');
          const videoInput = $('<input id="sustainability_profile_video" type="file" name="sustainability_profile[video]" />');

          // function fileListItems(files) {
          //   let clipboard = new ClipboardEvent("").clipboardData || new DataTransfer();

          //   files.forEach((_file, index) => {
          //     const processingFile = files[index];

          //     if (!processingFile.mockExistingFile) {
          //       clipboard.items.add(files[index]);
          //     }
          //   });

          //   return clipboard.files;
          // }

          function fileListItems(files) {
            let clipboard = new ClipboardEvent("").clipboardData || new DataTransfer();

            files.forEach((_file, index) => {
              const processingFile = files[index];

              if (!processingFile.mockExistingFile) {
                var tempFile = new File([files[index]], files[index].name);
                clipboard.items.add(tempFile);
              }
            });

            return clipboard.files;
          }

          const fileVideo = (video) => {
            let clipboard = new ClipboardEvent("").clipboardData || new DataTransfer();
            video.forEach((_file, index) => {
              const processingFile = video[index];

              if (!processingFile.mockExistingFile) {
                clipboard.items.add(video[index]);
              }
            });

            return clipboard.files;
          }

          fileInput[0].files = fileListItems(files);
          videoInput[0].files = fileVideo(video);
          $(fileInput).css('display', 'none');
          $(videoInput).css('display', 'none');
          $('#sustainability_profile_remove_image_indexs').val(deletedImageIds)
          $form.append(fileInput);
          $form.append(videoInput);
          $form.trigger('submit');
        }
      })
      // Bind crop image event
      if ($('#cropImageModal').length > 0) {
        $('#file-preview').on('click', '.dz-crop', function () {
          $.magnificPopup.open({
            closeOnBgClick: false,
            enableEscapeKey: false,
            items: {
              src: '#cropImageModal'
            },
            callbacks: {
              close: () => {
                console.log('true');
                $('#crop_button').unbind('click');
              }
            }
          });

          // Transform cropper dataURI output to a Blob which Dropzone accepts
          var dataURItoBlob = function (dataURI) {
            var byteString = atob(dataURI.split(',')[1]);
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: 'image/png' });
          };

          $('#imageCropper').attr('src', $(this).data('file-url'));
          var fileName = $(this).data('file-name');
          var image = $('#imageCropper')[0];
          var cropper = new Cropper(image);

          $('#crop_button').unbind('click');
          $('#crop_button').on('click', function () {

            var blob = cropper.getCroppedCanvas().toDataURL();
            $('#imageCropper').attr('src', blob);

            var croppedFile = dataURItoBlob(blob);
            croppedFile.name = fileName;
            cropper.destroy();

            var files = dropzoneObject.getAcceptedFiles();

            for (var i = 0; i < files.length; i++) {
              var file = files[i];
              if (file.name === fileName) {
                dropzoneObject.removeFile(file);
              }
            }

            dropzoneObject.addFile(croppedFile);

            $.magnificPopup.open({
              closeOnBgClick: false,
              enableEscapeKey: false,
              items: {
                src: '#editWhoWeAreModal'
              },
              callbacks: {
                close: () => {
                  dropzoneObject && dropzoneObject.destroy()
                }
              }
            });
          });
        })
      }
    });
  }

  const handleForNewSustainabilityTab = () => {
    const isValidUrl = (str) => {
      const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      return !!pattern.test(str) || !str;
    }

      let dropzoneObject = null;
      let dropzoneVideoObject = null;

      let deletedImageIds = [];

      const validateAreaFieldLength = (fieldId, maxLength, minLength) => {
        let currentLength = $(`#${fieldId}`).val().length

        return (currentLength <= maxLength && currentLength >= minLength)
      }

      const validateForm = () => {
      
        // if (!$('form#update-sustainability-profile-user-form').data('changed')) {
        //   return true
        // }
        if (!isValidUrl($('#sustainability_profile_video_url').val())) {
          return false
        }
        if (!validateAreaFieldLength('sustainability_profile_first_part', 2000, 240) && 
            !validateAreaFieldLength('sustainability_profile_second_part', 2000, 240)
          ) {
          return false
        }
        // if (!validateAreaFieldLength('sustainability_profile_first_part', 2000, 240)) {
        //   return false
        // }
        // if (!validateAreaFieldLength('sustainability_profile_second_part', 2000, 240)) {
        //   return false
        // }
        return true
      }

      $("form#update-sustainability-profile-user-form :input").change(function () {
        $("form#update-sustainability-profile-user-form").data('changed', true)
      });

      const resetErrorCss = (el) => {
        $(el).removeClass('hide-error')
        $(el).addClass('error')
      }

      $('.discard-changes').on('click', () => {
        $("#update-sustainability-profile-user-form").trigger('reset')
        resetErrorCss('#url-error')
        resetErrorCss('#video-error')
        resetErrorCss('#sus-first-error')
        resetErrorCss('#sus-second-error')
        $('#form-error').removeClass()
        $('#form-error').addClass('form-error d-none')
        $('form#update-sustainability-profile-user-form').data('changed', false)
        $.magnificPopup.close();
      })

      const dropzoneOptions = {
        formSelector: '#update-sustainability-profile-user-form',
        templateSelector: '#tpl',
        previewsContainerSelector: '#file-preview',
        uploadMultiple: true,
        fileLimit: 3,
        acceptedFiles: 'image/jpeg,image/png,image/jpg',
        isAddingImage: true
      }

      const dropzoneVideoOptions = {
        formSelector: '#video-zone',
        url: $("#update-sustainability-profile-user-form").attr("action"),
        templateSelector: '#tpl-video',
        previewsContainerSelector: '#file-preview-video',
        uploadMultiple: true,
        fileLimit: 1,
        maxFilesize: 50,
        acceptedFiles: 'video/mp4',
        isAddingImage: false
      }

      dropzoneVideoObject = initDropzone(dropzoneVideoOptions);

      const onDeleteFileAction = (file) => {
        if (file.mockExistingFile) {
          deletedImageIds.push(file.mockExistingFileId)
        }
      }

      dropzoneObject = initDropzone(dropzoneOptions, onDeleteFileAction);

      $('.remove-video-file').on('click', () => {
        dropzoneVideoObject.removeAllFiles()
        $('#video-message').html('Drop your file here')
      })

      $('.remove-video-link').on('click', () => {
        $('#sustainability_profile_video_url').val('')
        resetCss('#url-error')
      })

      $('#sustainability_profile_video_url').on('change', () => {
        let str = $('#sustainability_profile_video_url').val()
        if (!isValidUrl(str)) {
          $('#url-error').removeClass('hide-error')
        } else {
          $('#url-error').addClass('hide-error')
        }
      })

      dropzoneVideoObject.on('error', (file, data) => {
        dropzoneVideoObject.removeFile(file);
        $('#video-error').text(data)
        $('#video-error').removeClass('hide-error')
      })

      $('#sustainability_profile_first_part').on('keyup', () => {
        validateAreaFieldLength('sustainability_profile_first_part', 2000, 240) ? $('#sus-first-error').addClass('hide-error') : $('#sus-first-error').removeClass('hide-error')
      })

      $('#sustainability_profile_second_part').on('keyup', () => {
        validateAreaFieldLength('sustainability_profile_second_part', 2000, 240) ? $('#sus-second-error').addClass('hide-error') : $('#sus-second-error').removeClass('hide-error')
      })

      $('#save-changes-who-we-are-form').on('click', () => {
        const $form = $('#update-sustainability-profile-user-form');
        let isValidInput = validateForm()
        if (!isValidInput) {
          $('#form-error').removeClass('d-none')
          return false
        } else {
          $("#save-changes-who-we-are-form").attr("disabled", "disabled").off('click');
          const files = dropzoneObject.files;
          const video = dropzoneVideoObject.files

          const fileInput = $('<input id="sustainability_profile_images" type="file" multiple="multiple" name="sustainability_profile[images][]" />');
          const videoInput = $('<input id="sustainability_profile_video" type="file" name="sustainability_profile[video]" />');



          function fileListItems(files) {
            let clipboard = new ClipboardEvent("").clipboardData || new DataTransfer();

            files.forEach((_file, index) => {
              const processingFile = files[index];

              if (!processingFile.mockExistingFile) {
                var tempFile = new File([files[index]], files[index].name);
                clipboard.items.add(tempFile);
              }
            });

            return clipboard.files;
          }

          const fileVideo = (video) => {
            let clipboard = new ClipboardEvent("").clipboardData || new DataTransfer();
            video.forEach((_file, index) => {
              const processingFile = video[index];

              if (!processingFile.mockExistingFile) {
                clipboard.items.add(video[index]);
              }
            });

            return clipboard.files;
          }

          fileInput[0].files = fileListItems(files);
          videoInput[0].files = fileVideo(video);
          $(fileInput).css('display', 'none');
          $(videoInput).css('display', 'none');
          $('#sustainability_profile_remove_image_indexs').val(deletedImageIds)
          $form.append(fileInput);
          $form.append(videoInput);
          $form.trigger('submit');
        }
      })
      // Bind crop image event
      if ($('#cropImageModal').length > 0) {
        $('#file-preview').on('click', '.dz-crop', function () {
          $.magnificPopup.open({
            closeOnBgClick: false,
            enableEscapeKey: false,
            items: {
              src: '#cropImageModal'
            },
            callbacks: {
              close: () => {
                console.log("true")
                $('#crop_button').unbind('click');
              }
            }
          });

          // Transform cropper dataURI output to a Blob which Dropzone accepts
          var dataURItoBlob = function (dataURI) {
            var byteString = atob(dataURI.split(',')[1]);
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: 'image/png' });
          };

          $('#imageCropper').attr('src', $(this).data('file-url'));
          var fileName = $(this).data('file-name');
          var image = $('#imageCropper')[0];
          var cropper = new Cropper(image);

          $('#crop_button').unbind('click');
          $('#crop_button').on('click', function () {

            var blob = cropper.getCroppedCanvas().toDataURL();
            $('#imageCropper').attr('src', blob);

            var croppedFile = dataURItoBlob(blob);
            croppedFile.name = fileName;
            cropper.destroy();

            var files = dropzoneObject.getAcceptedFiles();

            for (var i = 0; i < files.length; i++) {
              var file = files[i];
              if (file.name === fileName) {
                dropzoneObject.removeFile(file);
              }
            }
            dropzoneObject.addFile(croppedFile);
            $.magnificPopup.close({
              items: {
                src: '#cropImageModal'
              },
            });
          });
        })
      }
  }

  const handleModalForRoadMap = () => {
    $('.open-modal--suppoters').magnificPopup({
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: 'auto',
      type: 'inline',
      preloader: false,
      modal: false,
      closeOnBgClick: true,
      enableEscapeKey: false,
    });

    $('.open-modal--achievement-info').magnificPopup({
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: 'auto',
      type: 'inline',
      preloader: false,
      modal: false,
      closeOnBgClick: true,
      enableEscapeKey: false,
    });

    $('.open-modal--medias').magnificPopup({
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: 'auto',
      type: 'inline',
      preloader: false,
      modal: false,
      closeOnBgClick: true,
      enableEscapeKey: false,
    });

    $('.popup-video').magnificPopup({
      disableOn: 0,
      fixedContentPos: true,
      type: 'iframe',
      preloader: false,
      removalDelay: 300,
      closeOnBgClick: false,
      enableEscapeKey: false,
      callbacks: {
        beforeOpen: function() {
          this.contentContainer.css('background', 'transparent');
          this.contentContainer.css('max-width', '700px').height('auto');
          this.contentContainer.css('padding', '20px')
        },
      },
      iframe: {
        markup: '<div class="mfp-iframe-scaler">'+
                '<div class="mfp-close"></div>'+
                '<iframe class="mfp-iframe" frameborder="0" donotallowfullscreen></iframe>'+
                '</div>'
      },
    });

    $('.popup-video-mp4').magnificPopup({
      type: 'inline',
      callbacks: {
        beforeOpen: function() {
          this.contentContainer.css('background', 'transparent');
          this.contentContainer.css('max-width', '700px').height('auto');
          this.contentContainer.css('padding', '10px')
          $('#video-banner').show(); // Show the video banner
        },
        beforeClose: function() {
          $('#video-banner').hide(); // Hide the video banner
        }
      }
    });
  }

  const handleForUploadProof = () => {
    $('.achievement-card--wrapper__content--proof, .achievement-card--wrapper__content--proof--mobile').on('click', (e) => {
      const openUploadProofModal = () => {
        let dropzoneObject = null;
        const dropzoneOptions = {
          formSelector: '#upload-achievement-proof-form',
          templateSelector: '#tpl1',
          previewsContainerSelector: '#file-preview1',
          uploadMultiple: true,
          fileLimit: 4,
          acceptedFiles: 'image/jpeg,image/png,image/jpg',
          isAddingImage: true
        }

        dropzoneObject = initDropzone(dropzoneOptions);

        dropzoneObject.on('addedfile', () => {
          $('#image-error').removeClass()
          $('#image-error').addClass('error d-none')
          $('#form-error').addClass('form-error d-none')
        })

        dropzoneObject.on('error', (file, data) => {
          $('#image-error').removeClass('d-none')
          if (data !== 'Image file is invalid. Please use JPG or PNG image formats') {
            $('#image-error').text(data)
          } else {
            $('#image-error').text('Image file is invalid. Please use JPG or PNG image formats')
          }
        })

        $.magnificPopup.open({
          closeOnBgClick: false,
          enableEscapeKey: false,
          items: {
            src: '#uploadProofModal'
          }
        });

        // Bind crop image event
        if ($('#cropImageModal').length > 0) {
          $('#file-preview1').on('click', '.dz-crop', function () {
            $.magnificPopup.open({
              closeOnBgClick: false,
              enableEscapeKey: false,
              items: {
                src: '#cropImageModal',
                callbacks: {
                  close: () => {
                    console.log('true');
                    $('#crop_button').unbind('click');
                  }
                }
              }
            });

            // Transform cropper dataURI output to a Blob which Dropzone accepts
            let dataURItoBlob = function (dataURI) {
              let byteString = atob(dataURI.split(',')[1]);
              let ab = new ArrayBuffer(byteString.length);
              let ia = new Uint8Array(ab);
              for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
              }
              return new Blob([ab], { type: 'image/png' });
            };

            $('#imageCropper').attr('src', $(this).data('file-url'));
            let fileName = $(this).data('file-name');
            let image = $('#imageCropper')[0];
            let cropper = new Cropper(image);

            $('#crop_button').unbind('click');
            $('#cropImageModal').on('click', '.mfp-close', function (e) {
              let images = document.querySelectorAll('#imageCropper');
              images.forEach(function(image) {
                let cropper = image.cropper;
                if (cropper) {
                  cropper.destroy();
                }
              });

              e.stopPropagation();
              $.magnificPopup.open({
                items: {
                  src: '#uploadProofModal'
                },
                modal: true
              });
            });
            $('.dz-remove').on('click', function (e) {
              let images = document.querySelectorAll('#imageCropper');
              images.forEach(function(image) {
                let cropper = image.cropper;
                if (cropper) {
                  cropper.destroy();
                }
              });

            });
            $('#crop_button').on('click', function () {

              let blob = cropper.getCroppedCanvas().toDataURL();
              $('#imageCropper').attr('src', blob);
              let croppedBlob = dataURItoBlob(blob);

              let croppedFile = new File([croppedBlob], fileName, { type: 'image/png' });
              cropper.destroy();

              let files = dropzoneObject.getAcceptedFiles();

              for (let i = 0; i < files.length; i++) {
                let file = files[i];
                if (file.name === fileName) {
                  dropzoneObject.removeFile(file);
                }
              }

              dropzoneObject.addFile(croppedFile);

              $.magnificPopup.open({
                closeOnBgClick: false,
                enableEscapeKey: false,
                items: {
                  src: '#uploadProofModal'
                },
                callbacks: {
                  close: () => {
                    dropzoneObject && dropzoneObject.destroy()
                  }
                }
              });
            });
          })
        }

        const resetErrorCss = (el) => {
          $(el).removeClass()
          $(el).addClass('error hide-error')
        }

        const validateForm = () => {
          let flag = true
          // if (!$('#upload_proof_proof_description').val() || $('#upload_proof_proof_description').val()) {
          //   flag = false
          // }
          if (!$('#upload_proof_proof_description').val()) {
            flag = false;
            $('#des-error').removeClass('hide-error')
            $('#des-error').text('Please add a description here.')
          }
          return flag
        }

        const validateAddedDropzone = () => {
          let flag = true
          if (dropzoneObject.files.length == 0) {
            flag = false
            $('#image-error').removeClass('d-none')
            $('#image-error').text('You need to upload at least 1 photo.')
          }
          return flag
        }

        $('.discard-changes').on('click', () => {
          $("#upload-achievement-proof-form").trigger('reset')
          resetErrorCss('#des-error')
          $('#form-error').removeClass()
          $('#form-error').addClass('form-error d-none')
          $('form#upload-achievement-proof-form').data('changed', false)
          $.magnificPopup.close();
        })

        $('#upload_proof_proof_description').on('keyup', () => {
          // if ($('#upload_proof_proof_description').val().length > 240) {
          //   $('#des-error').removeClass('hide-error')
          //   $('#des-error').text('Description is too long. Please use a maximum of 240 characters')
          // } else {
          //   $('#des-error').addClass('hide-error')
          // }
          if (!$('#upload_proof_proof_description').val()) {
            $('#des-error').removeClass('hide-error')
            $('#des-error').text('Please add a description here.')
          } else {
            $('#des-error').addClass('hide-error')
            $('#form-error').addClass('form-error d-none')
          }
        })

        $('#save-upload-proof').on('click', () => {
          if ($('#upload_proof_proof_description').val() && dropzoneObject.files.length != 0) {
            $("#save-upload-proof").attr("disabled", "disabled").off('click');
          }

          let isFormInputValid = validateForm()
          let isDropzoneValid = validateAddedDropzone()
          if (!isFormInputValid || !isDropzoneValid) {
            $('#form-error').removeClass('d-none')
            return false
          } else {
            $('#form-error').removeClass()
            $('#form-error').addClass('form-error d-none')
            const $form = $('#upload-achievement-proof-form');

            const files = dropzoneObject.files;

            const fileInput = $('<input id="upload_proof_images" type="file" multiple="multiple" name="upload_proof[images][]" />')

            function fileListItems(files) {
              let clipboard = new ClipboardEvent("").clipboardData || new DataTransfer();

              files.forEach((_file, index) => {
                clipboard.items.add(files[index]);
              });

              return clipboard.files;
            }

            fileInput[0].files = fileListItems(files);
            $(fileInput).css('display', 'none');
            $form.append(fileInput);
            $form.trigger('submit');
          }
        })
        $.magnificPopup.open({
          closeOnBgClick: true,
          enableEscapeKey: false,
          items: {
            src: '#uploadProofModal'
          },
          callbacks: {
            close: () => {
              dropzoneObject && dropzoneObject.destroy()
            }
          }
        });
      }

      let achievement_id = $(e.target).closest('.openUploadProofModal').data('id')
      let isAdminEdit = $("#is_admin_edit").val()

      $.ajax({
        url: `/achievements/${achievement_id}/get_proof`,
        type: 'get',
        dataType: 'json',
        data: {
          is_admin_edit: isAdminEdit,
        },
        success: (res) => {
          $('#uploadProofModal .modal-body').html(res.html);
          openUploadProofModal();
        }
      });
    })
  }

  if ($('#recovery-project-profile-page, #profile-page').length > 0) {
    triggerEventRecoveryProjectSustainability();
    handleForSustainabilityTab();
    handleForUploadProof();
    handleModalForRoadMap();
    handleEventForEditRoadmap();
    handleAddAchievementButton();
    handle_for_show_more_info_achievement()

    $('#open-edit-photos').on('click', () => {
      let dropzoneObject = null;
      let deletedImageIds = [];

      $.magnificPopup.open({
        closeOnBgClick: false,
        enableEscapeKey: false,
        items: {
          src: '#addPhotosModal'
        },
        callbacks: {
          close: () => {
            dropzoneObject && dropzoneObject.destroy();
          }
        }
      });

      $('.discard-change-button').on('click', () => {
        $.magnificPopup.close();
      })

      $('#save-image-account-button').on('click', () => {
        $("#save-image-account-button").attr("disabled", "disabled").off('click');
        const $form = $('#add-user-photo-form');

        const files = dropzoneObject.files;

        const fileInput = $('<input type="file" multiple="multiple" name="add_user_photos[photos][]" />');

        function fileListItems(files) {
          let clipboard = new ClipboardEvent("").clipboardData || new DataTransfer();

          files.forEach((_file, index) => {
            const processingFile = files[index];

            if (!processingFile.mockExistingFile) {
              var tempFile = new File([files[index]], files[index].name);
              clipboard.items.add(tempFile);
            }
          });

          return clipboard.files;
        }

        fileInput[0].files = fileListItems(files);
        $(fileInput).css('display', 'none');
        $('#add_user_photos_delete_image_ids').val(deletedImageIds)
        $form.append(fileInput);
        $form.trigger('submit');
      })

      const dropzoneOptions = {
        formSelector: '#add-user-photo-form',
        templateSelector: '#tpl',
        previewsContainerSelector: '#file-preview',
        uploadMultiple: true,
        fileLimit: 6,
        acceptedFiles: 'image/jpeg,image/png,image/jpg',
        isAddingImage: true
      }

      const onDeleteFileAction = (file) => {
        if (file.mockExistingFile) {
          deletedImageIds.push(file.mockExistingFileId)
        }
      }

      dropzoneObject = initDropzone(dropzoneOptions, onDeleteFileAction);
      const existingImageList = JSON.parse($('#existing-images-list').val())

      existingImageList.forEach((imageObject) => {
        const mockFile = {
          name: 'FileName',
          size: '1000',
          type: 'image/jpeg',
          kind: 'file',
          imageUrl: imageObject[0],
          accepted: true,
          mockExistingFile: true,
          mockExistingFileId: imageObject[1]
        };

        dropzoneObject.emit("addedfile", mockFile);
        dropzoneObject.files.push(mockFile);
        dropzoneObject.emit("complete", mockFile);
      })

      // Bind crop image event
      if ($('#cropImageModal').length > 0) {
        $('#file-preview').on('click', '.dz-crop', function () {
          $.magnificPopup.open({
            closeOnBgClick: false,
            enableEscapeKey: false,
            items: {
              src: '#cropImageModal'
            },
            callbacks: {
              close: () => {
                $('#crop_button').unbind('click');
              }
            }
          });

          // Transform cropper dataURI output to a Blob which Dropzone accepts
          let dataURItoBlob = function (dataURI) {
            let byteString = atob(dataURI.split(',')[1]);
            let ab = new ArrayBuffer(byteString.length);
            let ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: 'image/png' });
          };

          $('#imageCropper').attr('src', $(this).data('file-url'));
          let fileName = $(this).data('file-name');
          let image = $('#imageCropper')[0];
          let cropper = new Cropper(image);

          $('#crop_button').unbind('click');
          $('#cropImageModal').on('click', '.mfp-close', function (e) {
            let images = document.querySelectorAll('#imageCropper');
            images.forEach(function(image) {
              let cropper = image.cropper;
              if (cropper) {
                cropper.destroy();
              }
            });

            e.stopPropagation();
            $.magnificPopup.open({
              items: {
                src: '#addPhotosModal'
              },
              modal: true
            });
          });
          $('.dz-remove').on('click', function (e) {
            let images = document.querySelectorAll('#imageCropper');
            images.forEach(function(image) {
              let cropper = image.cropper;
              if (cropper) {
                cropper.destroy();
              }
            });

          });
          $('#crop_button').on('click', function () {

            let blob = cropper.getCroppedCanvas().toDataURL();
            $('#imageCropper').attr('src', blob);

            let croppedFile = dataURItoBlob(blob);
            croppedFile.name = fileName;
            cropper.destroy();

            let files = dropzoneObject.getAcceptedFiles();

            for (let i = 0; i < files.length; i++) {
              let file = files[i];
              if (file.name === fileName) {
                dropzoneObject.removeFile(file);
              }
            }

            dropzoneObject.addFile(croppedFile);

            $.magnificPopup.open({
              closeOnBgClick: false,
              enableEscapeKey: false,
              items: {
                src: '#addPhotosModal'
              },
              callbacks: {
                close: () => {
                  dropzoneObject && dropzoneObject.destroy()
                }
              }
            });
          });
        })
      }
    })

    // Show all photos
    $('#open-photo-popup').on('click', () => {
      $.magnificPopup.open({
        closeOnBgClick: true,
        enableEscapeKey: false,
        items: {
          src: '#allPhotoModal'
        }
      });

      triggerEventClickImg();
    })

    const triggerEventClickImg = () => {
      $('.view-img-detail').on('click', ({ target }) => {
        const src = $(target).attr('src');
        $('.img-detail').find('img').attr('src', src);

        $.magnificPopup.open({
          closeOnBgClick: false,
          enableEscapeKey: false,
          items: {
            src: '#viewPhotoDetailModal'
          }
        });
      })
    }

    // View image detail
    $('#viewPhotoDetailModal').on('click', '.mfp-close', function (e) {
      e.stopPropagation();
      $.magnificPopup.open({
        items: {
          src: '#allPhotoModal'
        },
        modal: true
      });
    });

    // Loading tab content
    $('.profile-tab-link').on('click', (e) => {
      e.preventDefault();
      showLoadingPage();
      const url = $(e.target).attr('href');
      window.history.pushState({}, '', url);

      $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        data: {
          json_request: true
        },
        success: (res) => {
          $('.profile-tab-content').html(res.html);
          $("img.lazyload").lazyload();
          switch (res.tab) {
            case 'who_we_are':
              handleForSustainabilityTab()
              break;
            case 'roadmap':
              handleForUploadProof()
              handleModalForRoadMap()
              handleEventForEditRoadmap()
              handleAddAchievementButton()
              handle_for_show_more_info_achievement()
              break;
            case 'sustainability':
              triggerEventRecoveryProjectSustainability();
              initRecoveryProjectProfileSdMap();
              break;
          }

          if ($('.recovery-project-profile__gallery').length > 0) {
            triggerEventRecoveryProjectImpactGallery()
            $('img.lazyload').lazyload();
          }
          hideLoadingPage();
        }
      });
    });

    const showLoadingPage = () => {
      $(".loading-gif").show();
    }

    const hideLoadingPage = () => {
      $(".loading-gif").hide();
      $('body').css('overflow', 'auto');
    }
  }

  if ($('#new-susmission').length > 0) {
    handleForNewSustainabilityTab();
  }

  // Impact gallary Tab
  if ($('.recovery-project-profile__gallery').length > 0) {
    triggerEventRecoveryProjectImpactGallery()
  }

  // For company
  const embeddingSelectPackageEvent = () => {
    $('.package__submit__button').on('click', function () {
      $('.js__select-package-modal__select-package').addClass('hide')
      $('.js__select-package-modal__info').removeClass('hide')
    })
  }

  const checkSelectPackageExist = (containerId) => {
    return containerId.find('.select-package-modal__contain').length > 0;
  }

  const resetSelectPackage = () => {
    $('.js__select-package-modal__select-package').removeClass('hide')
    $('.js__select-package-modal__info').addClass('hide')
  }

  const openSelectPackageDesktopModal = () => {
    $.magnificPopup.open({
      fixedContentPos: true,
      overflowY: 'auto',
      type: 'inline',
      preloader: false,
      modal: false,
      removalDelay: 300,
      closeOnBgClick: true,
      enableEscapeKey: false,
      callbacks: {
        close: function () {
          resetSelectPackage()
        }
      },
      items: {
        src: '#selectPackageModal'
      }
    });
  }

  embeddingSelectPackageEvent()

  $('.js__select-package.--desktop').on('click', function (e) {
    e.preventDefault();

    if (checkSelectPackageExist($('#selectPackageModal'))) {
      openSelectPackageDesktopModal();
    } else {
      const address = $('#user-address').val();
      $.ajax({
        url: `/users/${address}/select_package`,
        type: 'get',
        dataType: 'json',
        data: {},
        success: (res) => {
          $('#selectPackageModal .js__select-package-modal__select-package').html(res.html);
          openSelectPackageDesktopModal();
          embeddingSelectPackageEvent();
        }
      });
    }
  })

  const openRecoveryProjectProfileModalMobile = (modal) => {
    $('#recovery-project-profile-page').addClass('hide');
    $('#recovery-project-profile-page__modal').removeClass('hide');

    $(modal).removeClass('hide')
  }

  $('.js__select-package.--mobile').on('click', function (e) {
    e.preventDefault();

    openRecoveryProjectProfileModalMobile('.modal__select-package')
    if (!checkSelectPackageExist($('#recovery-project-profile-page__modal .modal__select-package.--mobile'))) {
      const address = $('#user-address').val();
      $.ajax({
        url: `/users/${address}/select_package`,
        type: 'get',
        dataType: 'json',
        data: {},
        success: (res) => {

          $('.modal__select-package.--mobile .js__select-package-modal__select-package').html(res.html);
          embeddingSelectPackageEvent();
        }
      });

    }
  })
  // End For company

  // Mobile Modal Event
  $('#recovery-project-profile-page__modal .js__modal__close').on('click', function () {
    $('#recovery-project-profile-page').removeClass('hide');
    $('#recovery-project-profile-page__modal').addClass('hide');

    // Reset For company modal
    resetSelectPackage();
    $('.modal__select-package').addClass('hide')

    // Reset All Plastic type
    $('.modal__plastic-type').addClass('hide')

    // Reset All Suppoter
    $('.modal__supporters').addClass('hide')
  })
  // End Show Mobile Modal Event

  function handle_for_show_more_info_achievement() {

    $('.achievement-info-modal #btn_close').on('click', () => {
      $.magnificPopup.close();
    })
  }

  function openModal(index, modal, modalImage, images) {
    modal.css("display", "block");
    modalImage.html("");
    const img = $("<img>").attr("src", images.eq(index).attr("src"));
    modalImage.append(img);
    return index;
  }

  function closeModal(modal) {
    modal.css("display", "none");
  }

  function prevImage(modal, modalImage, images, currentIndex) {
    currentIndex = (currentIndex - 1 + images.length) % images.length;
    currentIndex = openModal(currentIndex, modal, modalImage, images);
    return currentIndex;
  }

  function nextImage(modal, modalImage, images, currentIndex) {
    currentIndex = (currentIndex + 1) % images.length;
    const nextImageSrc = images.eq(currentIndex).attr("src");
    
    if (nextImageSrc !== "/assets/image-lazy-load.gif") {
      currentIndex = openModal(currentIndex, modal, modalImage, images);
    } else {
      currentIndex = nextImage(modal, modalImage, images, currentIndex);
    }
    
    return currentIndex;
  }

  window.clickImage = function(images, modal) {
    const modalContent = modal.find(".modal-image-content");
    const modalImage = modalContent.find(".modal-image");
    const prevButton = modalContent.find(".prev");
    const nextButton = modalContent.find(".next");
    const closeButton = modal.find(".close-modal");
    let currentIndex = 0;
  
    if (images.length <= 1) {
      prevButton.addClass('hide');
      nextButton.addClass('hide');
    }
  
    images.parent().on("click", "img", function() {
      const index = images.index(this);
      currentIndex = openModal(index, modal, modalImage, images);
    });
  
    prevButton.on("click", function() {
      currentIndex = prevImage(modal, modalImage, images, currentIndex);
    });
  
    nextButton.on("click", function() {
      currentIndex = nextImage(modal, modalImage, images, currentIndex);
    });
  
    closeButton.on("click", function() {
      closeModal(modal);
    });
  };
  
  
  let imagesDetail = $(".img-item-detail img");
  let modalDetail = $("#image-modal_detail");
  let imagesProfile = $(".display-image img");
  let modalProfile = $("#image-modal");

  imagesProfile.on("click", function() {
    window.clickImage(imagesProfile, modalProfile);
  });

  imagesDetail.on("click", function() {
    window.clickImage(imagesDetail, modalDetail);
  });
})
