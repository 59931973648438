import { initRecoveryProjectProfileSdMap } from './recovery_project_profile_sd_map';

$(document).ready(function() {
  initRecoveryProjectProfileSdMap();
  // View image detail
  $('#viewPhotoDetailModal').on('click', '.mfp-close, .show-all-back-btn', function(e) {
    e.stopPropagation();
    $.magnificPopup.open({
      items: {
        src: '#allPhotoModal'
      },
      modal: true
    });
  });

  // Open share impack model in photos modal

  $('#viewPhotoDetailModal, #allPhotoModal').on('click', '.user-share-impact', function(e) {
    e.stopPropagation();
    $.magnificPopup.open({
      items: {
        src: '#shareImpactModal'
      },
      modal: true
    });
  });
})
