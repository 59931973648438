import Cropper from 'cropperjs';
import { initDropzone } from './utils/dropzone';

$(document).ready(function() {
  $('#edit-about').on('click', (e) => {
    const validateForm = () => {
      if(!$('form#update-about-us-form').data('changed')) {
        return true
      }
      if ($('#about_us_about_us').val().length > 240) {
        return false
      }
      return true
    }

    $("form#update-about-us-form :input").change(function() {
      $("form#update-about-us-form").data('changed', true)
    });

    $('#about_us_about_us').on('keyup', () => {
      $('#about_us_about_us').val().length > 240 ? $('#about-us-error').removeClass('hide-error') : $('#about-us-error').addClass('hide-error')
    })

    const resetErrorCss = (el) => {
      $(el).removeClass()
      $(el).addClass('error hide-error')
    }

    $('.discard-changes').on('click', () => {
      $("#update-about-us-form").trigger('reset')
      resetErrorCss('#about-us-error')
      $('form#update-about-us-form').data('changed', false)
      $.magnificPopup.close();
    })

    e.stopPropagation();
    $.magnificPopup.open({
      closeOnBgClick: false,
      enableEscapeKey: false,
      items: {
        src: '#editAboutUsModal'
      }
    });

    $('#save-changes-about-us-form').on('click', () => {
      $("#save-changes-about-us-form").attr("disabled", "disabled").off('click');

      const $form = $('#update-about-us-form');
      let isValidInput = validateForm()
      if (!isValidInput) {
        return false
      } else {
        $form.trigger('submit');
      }
    })
  })

  $('#openEditCoverModal').on('click', (e) => {
    let dropzoneAvatarObject = null;
    let dropzoneCoverObject = null;
    let isDeleteExistCover = false;
    let isDeleteExistAvatar = false;

    const resetErrorCss = (el) => {
      $(el).removeClass()
      $(el).addClass('error hide-error')
    }

    $('.discard-changes').on('click', () => {
      $("#edit-cover-form").trigger('reset')
      resetErrorCss('#about-us-error')
      $('#form-error').removeClass()
      $('#form-error').addClass('form-error d-none')
      $('form#update-about-us-form').data('changed', false)
      $.magnificPopup.close();
    })

    e.stopPropagation();
    $.magnificPopup.open({
      closeOnBgClick: false,
      enableEscapeKey: false,
      items: {
        src: '#editCoverModal'
      },
      callbacks: {
        close: () => {
          dropzoneAvatarObject && dropzoneAvatarObject.destroy()
          dropzoneCoverObject && dropzoneCoverObject.destroy()
        }
      }
    });

    const dropzoneAvatarOptions = {
      formSelector: '#edit-cover-form',
      templateSelector: '#tpl',
      previewsContainerSelector: '#file-preview',
      uploadMultiple: true,
      fileLimit: 1,
      acceptedFiles: 'image/jpeg,image/png,image/jpg',
      isAddingImage: true
    }

    const dropzoneCoverOptions = {
      formSelector: '#cover-zone',
      url: $("#edit-cover-form").attr("action"),
      templateSelector: '#tpl-cover',
      previewsContainerSelector: '#file-preview-cover',
      uploadMultiple: true,
      fileLimit: 1,
      acceptedFiles: 'image/jpeg,image/png,image/jpg',
      isAddingImage: true
    }

    const onDeleteCoverAction = (file) => {
      if(file.mockExistingFile) {
        isDeleteExistCover = true
      }
    }
    
    const onDeleteAvatarAction = (file) => {
      if(file.mockExistingFile) {
        isDeleteExistAvatar = true
      }
    }

    dropzoneAvatarObject = initDropzone(dropzoneAvatarOptions, onDeleteAvatarAction);
    dropzoneCoverObject = initDropzone(dropzoneCoverOptions, onDeleteCoverAction);

    const existingCoverImage = JSON.parse($('#existing-cover-image').val())
    const existingAvatarImage = JSON.parse($('#existing-avatar-image').val())

    if (existingCoverImage.length !== 0) {
      const mockFileCover = {
        name: 'FileName',
        size: '1000',
        type: 'image/jpeg',
        kind: 'file',
        imageUrl: existingCoverImage[0],
        accepted: true,
        mockExistingFile: true,
        mockExistingFileId: existingCoverImage[1]
      };
  
      dropzoneCoverObject.emit("addedfile", mockFileCover);
      dropzoneCoverObject.files.push(mockFileCover);
      dropzoneCoverObject.emit("complete", mockFileCover);
    }

    if (existingAvatarImage.length !== 0) {
      const mockFileAvatar = {
        name: 'FileName',
        size: '1000',
        type: 'image/jpeg',
        kind: 'file',
        imageUrl: existingAvatarImage[0],
        accepted: true,
        mockExistingFile: true,
        mockExistingFileId: existingAvatarImage[1]
      };
  
      dropzoneAvatarObject.emit("addedfile", mockFileAvatar);
      dropzoneAvatarObject.files.push(mockFileAvatar);
      dropzoneAvatarObject.emit("complete", mockFileAvatar);
    }

    $('#save-changes-cover-form').on('click', () => {
      $("#save-changes-cover-form").attr("disabled", "disabled").off('click');
      
      const $form = $('#edit-cover-form');
      const cover = dropzoneCoverObject.files
      const avatar = dropzoneAvatarObject.files

      const coverInput = $('<input id="update_cover_cover" type="file" name="update_cover[cover]" />');
      const avatarInput = $('<input id="update_cover_avatar" type="file" name="update_cover[avatar]" />');

      function fileListItems(files) {
        let clipboard = new ClipboardEvent("").clipboardData || new DataTransfer();

        files.forEach((_file, index) => {
          const processingFile = files[index];

          if (!processingFile.mockExistingFile) {
            var tempFile = new File([files[index]], files[index].name);
            clipboard.items.add(tempFile);
          }
        });

        return clipboard.files;
      }

      coverInput[0].files = fileListItems(cover);
      avatarInput[0].files = fileListItems(avatar);
      $(coverInput).css('display', 'none');
      $(avatarInput).css('display', 'none');
      $('#update_cover_remove_cover').val(isDeleteExistCover)
      $('#update_cover_remove_avatar').val(isDeleteExistAvatar)
      
      $form.append(coverInput);
      $form.append(avatarInput);

      $form.trigger('submit');
    })

    $('.preview-changes').on('click', () => {
      const cover = dropzoneCoverObject.files

      const coverInput = $('<input id="update_cover_cover" type="file" name="update_cover[cover]" />');

      function fileListItems(files) {
        let clipboard = new ClipboardEvent("").clipboardData || new DataTransfer();

        files.forEach((_file, index) => {
          const processingFile = files[index];

          if (!processingFile.mockExistingFile) {
            var tempFile = new File([files[index]], files[index].name);
            clipboard.items.add(tempFile);
          }
        });

        return clipboard.files;
      }

      coverInput[0].files = fileListItems(cover);
      const coverImage = coverInput[0].files[0];
      const reader = new FileReader();

      if (coverImage) {
        const reader = new FileReader();
    
        reader.onload = function (e) {
          const imageUrl = e.target.result;
          $('#userProfileCoverBg').css('background-image', 'url(' + imageUrl + ')');
        };
    
        reader.readAsDataURL(coverImage);
      }
      $.magnificPopup.close();
    })

    if ($('#cropImageModal').length > 0) {
      $('#file-preview').on('click', '.dz-crop', function() {
        $.magnificPopup.open({
          closeOnBgClick: false,
          enableEscapeKey: false,
          items: {
            src: '#cropImageModal'
          },
          callbacks: {
            close: () => {
              dropzoneAvatarObject && dropzoneAvatarObject.destroy()
              $('#crop_button').unbind('click');
            }
          }
        });
      
        // Transform cropper dataURI output to a Blob which Dropzone accepts
        var dataURItoBlob = function (dataURI) {
          var byteString = atob(dataURI.split(',')[1]);
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          return new Blob([ab], { type: 'image/png' });
        };
      
        $('#imageCropper').attr('src', $(this).data('file-url'));
        var fileName = $(this).data('file-name');
        var image = $('#imageCropper')[0];
        var cropper = new Cropper(image);
        
      
        $('#crop_button').unbind('click');
        $('#cropImageModal').on('click', '.mfp-close', function (e) {
          let images = document.querySelectorAll('#imageCropper');
            images.forEach(function(image) {
              let cropper = image.cropper;
              if (cropper) {
                cropper.destroy();
              }
            });
          e.stopPropagation();
          $.magnificPopup.open({
            items: {
              src: '#editCoverModal'
            },
            modal: true
          });
        });
        $('.dz-remove').on('click', function (e) {
          let images = document.querySelectorAll('#imageCropper');
            images.forEach(function(image) {
              let cropper = image.cropper;
              if (cropper) {
                cropper.destroy();
              }
            });
        });
        $('#crop_button').on('click', function () {
          var blob = cropper.getCroppedCanvas().toDataURL();
          $('#imageCropper').attr('src', blob);
          
          var croppedFile = dataURItoBlob(blob);
          croppedFile.name = fileName;
          cropper.destroy();
      
          var files = dropzoneAvatarObject.getAcceptedFiles();
      
          for (var i = 0; i < files.length; i++) {
            var file = files[i];
            if (file.name === fileName) {
              dropzoneAvatarObject.removeFile(file);
            }
          }
          dropzoneAvatarObject.addFile(croppedFile);
          $.magnificPopup.open({
            closeOnBgClick: false,
            enableEscapeKey: false,
            items: {
              src: '#editCoverModal'
            },
            callbacks: {
              close: () => {
                dropzoneAvatarObject && dropzoneAvatarObject.destroy()
              }
            }
          });
        });
      })

      $('#file-preview-cover').on('click', '.dz-crop', function() {
        $.magnificPopup.open({
          closeOnBgClick: false,
          enableEscapeKey: false,
          items: {
            src: '#cropImageModal'
          },
          callbacks: {
            close: () => {
              console.log('true');
              $('#crop_button').unbind('click');
            }
          }
        });
      
        // Transform cropper dataURI output to a Blob which Dropzone accepts
        var dataURItoBlob = function (dataURI) {
          var byteString = atob(dataURI.split(',')[1]);
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          return new Blob([ab], { type: 'image/png' });
        };
      
        $('#imageCropper').attr('src', $(this).data('file-url'));
        var fileName = $(this).data('file-name');
        var image = $('#imageCropper')[0];
        var cropper = new Cropper(image);
        
      
        $('#crop_button').unbind('click');
        $('#cropImageModal').on('click', '.mfp-close', function (e) {
          cropper.destroy();
          e.stopPropagation();
          $.magnificPopup.open({
            items: {
              src: '#editCoverModal'
            },
            modal: true
          });
        });
        $('#crop_button').on('click', function () {
          var blob = cropper.getCroppedCanvas().toDataURL();
          $('#imageCropper').attr('src', blob);
          
          var croppedFile = dataURItoBlob(blob);
          croppedFile.name = fileName;
          cropper.destroy();
      
          var files = dropzoneCoverObject.getAcceptedFiles();
      
          for (var i = 0; i < files.length; i++) {
            var file = files[i];
            if (file.name === fileName) {
              dropzoneCoverObject.removeFile(file);
            }
          }
          dropzoneCoverObject.addFile(croppedFile);
          $.magnificPopup.open({
            closeOnBgClick: false,
            enableEscapeKey: false,
            items: {
              src: '#editCoverModal'
            },
            callbacks: {
              close: () => {
                dropzoneCoverObject && dropzoneCoverObject.destroy()
              }
            }
          });
        });
      })
    }
  })
})

