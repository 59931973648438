import IconMarker from '../../assets/images/ic-marker.svg';
import { debounce } from "./utils";
$(document).ready(function () {

  const myIcon = L.icon({
    iconUrl: IconMarker,
    iconSize: [60, 60],
    popupAnchor: [0, -10],
    className: 'recycler-icon'
  });

  let countryName, cityName, locationAddress, postalCode;
  $('#location_address, #postal_code, #selectCountryOptions, #selectCityOptions').on('change', debounce(function(){
    countryName = $('#selectCountryOptions').val()
    cityName = $('#selectCityOptions').val()
    locationAddress = $('#location_address').val()
    postalCode = $('#postal_code').val()

    if(countryName && cityName){
      $.ajax({
        url: '/find-coordinates',
        type: "GET",
        dataType: "json",
        data: {
          countryName: countryName,
          cityName: cityName,
          locationAddress: locationAddress,
          postalCode: postalCode,
        },
        success: function(data){
          console.log('data', data.coordinate)
          var newLatLng = new L.LatLng(data.coordinate[0], data.coordinate[1]);
          marker.setLatLng(newLatLng);
          window.formMap.setView(newLatLng ? newLatLng : window.formMap.getCenter(), window.formMap.getZoom())
        },
        error: function(e){
          toastr.error('Generate marker failed')
        }
      })
    }
  }, 200))

  if($('#prg-form-map').length > 0) {
    generateMarkerMap();
  }

  function generateMarkerMap(coordinate){
    let coordinate1 = [-9.39288367353, 35.946850084];
    let coordinate2 = [3.03948408368, 43.7483377142]
    var mapLink = '<a href="http://www.esri.com/">Esri</a>';
    var wholink = 'i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';

    const bounds = new L.LatLngBounds(coordinate1, coordinate2);
    const map = L.map('prg-form-map', {
      'center': bounds.getCenter(),
      'zoom': 10,
      'trackResize': false,
      'bounds': bounds,
      'maxBoundsViscosity': 1.0,
      'layers': [
        L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
        attribution: '&copy; '+mapLink+', '+wholink,
        maxZoom: 20,
        subdomains:['mt0','mt1','mt2','mt3']
        })
      ]
    })

    window.marker = L.marker(map.getCenter(), { icon: myIcon }).addTo(map),
    window.formMap = map;
  }

  window.chooseImage = function chooseImage(e){
    $(e).trigger('click');
  }

  window.removeImage = function removeImage(el, event){
    event.stopPropagation();
    $(el).val('');
    $(el).trigger('change')
  }
});
