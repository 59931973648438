import 'dropzone';

export const initDropzone = (options, onDeleteFileAction) => {
  Dropzone.autoDiscover = false;

  const {
    formSelector,
    templateSelector,
    previewsContainerSelector,
    uploadMultiple,
    fileLimit,
    acceptedFiles,
    isAddingImage,
    initAction,
    url,
    maxFilesize,
    isDropzoneRoadmap,
    videoId,
  } = options;

  const dropzoneOptions = {
    maxFiles: fileLimit ? fileLimit : 1,
    acceptedFiles: acceptedFiles ? acceptedFiles : null,
    autoProcessQueue: false,
    previewsContainer: previewsContainerSelector,
    addRemoveLinks: true,
    dictRemoveFile: '',
    maxFilesize: maxFilesize, // MB,
    url: url
  }

  if (document.querySelector(templateSelector)) {
    dropzoneOptions.previewTemplate = document.querySelector(templateSelector).innerHTML
  }

  const dropzoneObject = new Dropzone(formSelector, dropzoneOptions);

  const makeImageTemplate = (file, imageUrl, index) => {
    const img = document.createElement('img');
    let dataExist = false
    dataExist = file.mockExistingFileId ? true : false
    file.previewElement.setAttribute("data-existed-id", file.mockExistingFileId);
    file.previewElement.classList.add("file-added");
    img.src = imageUrl
    $(file.previewElement).find('.dz-details')[0].appendChild(img);
    file.previewElement.setAttribute("data-existed", dataExist)
    file.previewElement.setAttribute("data-order", index)
  }

  const makeVideoTemplate = (file, videoUrl, index) => {
    const vid = `<video class="video" width="100%" height="100%" controls>
                        <source src="${videoUrl}" type="video/mp4">
                        </video>
                <div class="playbtn"><img src="/assets/play-button.svg"></div>
                `
    const template = document.createElement('div');
    file.previewElement.classList.add("file-added");
    let dataExist = false
    dataExist = file.mockExistingFileId ? true : false
    file.previewElement.setAttribute("data-existed-id", file.mockExistingFileId);
    template.innerHTML = vid;
    template.classList.add("video-wrapper");
    $(file.previewElement).find('.dz-details')[0].appendChild(template);
    file.previewElement.setAttribute("data-existed", dataExist)
    file.previewElement.setAttribute("data-order", index)
  }

  const makeIframeTemplate = (file, videoUrl, index) => {
    const vid = `
                  <div class="video--thumbnail">
                    <img class="thumbnail--video" src="https://img.youtube.com/vi/${file.videoId}/0.jpg" style="" />
                    <div class="play-btn-iframe"><img src="/assets/play-button.svg"/></div>
                  </div>
                  <iframe class="iframe-video" style="display:none;" src="${videoUrl}" frameborder="0" allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                `
    const template = document.createElement('div');
    file.previewElement.classList.add("file-added");
    let dataExist = false
    dataExist = file.mockExistingFileId ? true : false
    file.previewElement.setAttribute("data-existed-id", file.mockExistingFileId);
    template.innerHTML = vid;
    $(file.previewElement).find('.dz-details')[0].appendChild(template);
    file.previewElement.setAttribute("data-existed", dataExist)
    file.previewElement.setAttribute("data-order", index)

  }

  const dropzoneAddFileActionRoadmap = (file) => {
    let dropzoneEffected = $(dropzoneObject.element).closest(".achievement").data("id")
    $(`#avatar-error-${dropzoneEffected}`).addClass("d-none")
    $(`#avatar-error-${dropzoneEffected}`).text("Avatar is required please add as least 1 file.")
    let numberItems = file.previewElement.parentElement.childElementCount
    if (numberItems > 10) {
      dropzoneObject.removeFile(file)
      return
    }
    if(file.imageUrl) {
      makeImageTemplate(file, file.imageUrl, numberItems - 1)
    } else {
      if (file.type === "video/mp4") {
        if(file.videoUrl) { 
          makeVideoTemplate(file, file.videoUrl, numberItems - 1)
        } else {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = function(event) {
            makeVideoTemplate(file, event.target.result, numberItems - 1)
          };
        }
      } else if (file.type === "video/url") {
        makeIframeTemplate(file, file.videoUrl, numberItems - 1)
      } else {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function(event) {
          makeImageTemplate(file, event.target.result, numberItems - 1)
        };
      }
    }
  }

  const dropzoneAddImageFileAction = (file) => {
    if (file.imageUrl) {
      const img = document.createElement('img');
      img.src = file.imageUrl
      $(file.previewElement).find('.dz-details')[0].appendChild(img);
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = function (event) {
        const img = document.createElement('img');
        img.src = event.target.result
        img.setAttribute('data-file-name', file.name);
        $(file.previewElement).find('.dz-details')[0].appendChild(img);

        const cropButton = document.createElement('a');
        cropButton.setAttribute('href', 'javascript:undefined;');
        cropButton.setAttribute('class', 'dz-crop');
        cropButton.setAttribute('data-file-name', file.name);
        cropButton.setAttribute('data-file-url', img.src);
        $(file.previewElement)[0].appendChild(cropButton);
      };
    }
  }

  const dropzoneAddFileAction = (file) => {
    $(file.previewElement).find('.dz-filename').html(file.name)
    $('#video-error').removeClass()
    $('#video-error').addClass('error')
    $('#video-error').addClass('hide-error')
  }

  if (isAddingImage) {
    dropzoneObject.on('addedfile', dropzoneAddImageFileAction);
  } else if (isDropzoneRoadmap){
    dropzoneObject.on('addedfile', dropzoneAddFileActionRoadmap);
  } else {
    dropzoneObject.on('addedfile', dropzoneAddFileAction);
  }

  if (onDeleteFileAction) {
    dropzoneObject.on('removedfile', onDeleteFileAction);
  }

  dropzoneObject.on("error", (file, errorMessage) => {
    dropzoneObject.removeFile(file)
    let dropzoneEffected = $(dropzoneObject.element).closest(".achievement").data("id")
    $(`#avatar-error-${dropzoneEffected}`).removeClass("d-none")
    if(errorMessage === 'You can not upload any more files.') {
      $(`#avatar-error-${dropzoneEffected}`).text(errorMessage)
    } else {
      $(`#avatar-error-${dropzoneEffected}`).text("The file exceeds 25MB")
    }
  });

  return dropzoneObject;
}
