import { debounce } from "./utils"
import { infiniteScrollingItem } from "./infinity_scroll_item"

$(document).ready(function () {
  window.prefixPath = $('#prefix_path').val();

  $('.filter-option-item').on('click', () => {
    $('#dropdownMenuFilterPrice').dropdown("toggle");
  })

  window.getRecentSearches = function getRecentSearches() {
    return JSON.parse(localStorage.getItem('recentSearches'))
  }

  window.clearRecentSearches = function clearRecentSearches() {
    localStorage.setItem('recentSearches', JSON.stringify([]))
  }

  window.removeRecentSearch = function removeRecentSearch(searchResult) {
    let currentRecentSearches = JSON.parse(localStorage.getItem('recentSearches')) || []
    localStorage.setItem('recentSearches', JSON.stringify(currentRecentSearches.filter(s => s !== searchResult)))
  }

  $(".header__search").on("submit", function () {
    let searchValue = null
    if($(".header__search__input").length > 0) {
      searchValue = $(".header__search__input").val()
    }

    let currentRecentSearches = JSON.parse(localStorage.getItem('recentSearches')) || []
    localStorage.setItem(
      'recentSearches', JSON.stringify(
        [
          searchValue,
          ...currentRecentSearches.filter(x => x !== searchValue)
        ]
      )
    )
  })

  $('button.close').click(function(e) {
    e.preventDefault();
  });

  $('.header__action-btn--mobile').click(function() {
    $(".search__results__wrapper").removeClass("collapse");
  });


  $(document).click(function (e) {
    // open search history modal if user click on search
    if ($(e.target).is('.header__search, .header__search *:not(button.close *), .search__results__wrapper *, .header__action-btn--mobile *')) {
      if ($(e.target).is('.live_search__results__wrapper, .live_search__results__wrapper *')) return;
      $(".search__results__wrapper").removeClass("collapse")

      $("main.main").addClass("outfocus")

      if ($(e.target).is('.search__result__value')) {
        $(".header__search__input").val($(e.target).text())
        $(".header__search").trigger("submit")
      }
    } else {
      $(".search__results__wrapper").addClass("collapse")
      $(".live_search__results__wrapper").addClass("collapse")
      $("main.main").removeClass("outfocus")
    }
  })

  $('.header__nav-link, .header__menu__btn, .view-notification, #dropdownMenuProfile').on('click', () => {
    $(".search__results__wrapper").addClass("collapse")
    $(".live_search__results__wrapper").addClass("collapse")
    $("main.main").removeClass("outfocus")
  })

  $(".main__filter-element, .main__filter-element--explore").on("click", function () {
    $("div.loading-gif.displayInMiddle").show();
    $(".main__filter-element, .main__filter-element--explore").removeClass("active")
    $(this).addClass("active")
    if ($("#js__search__results--recyclers__paginate").length) $("#js__search__results--recyclers__paginate").addClass("d-none")

    if ($(this).data("filter") === "users") {
      $(".main__filter-wrap.result-filter.--users").removeClass("hidden")
      if ($("#js__search__results--users__paginate").length) $("#js__search__results--users__paginate").removeClass("d-none")
    } else if ($(this).data("filter") === "recyclers") {
      $(".main__filter-wrap.result-filter.--recyclers").removeClass("hidden")
      if ($("#js__search__results--users__paginate").length) $("#js__search__results--users__paginate").addClass("d-none")
      if ($("#js__search__results--recyclers__paginate").length) $("#js__search__results--recyclers__paginate").removeClass("d-none")
    } else if ($(this).data("filter") === "nft-collections") {
      $(".main__filter-wrap.result-filter.--nft-collections").removeClass("hidden")
      if ($("#js__search__results--users__paginate").length) $("#js__search__results--users__paginate").addClass("d-none")
    } else if ($(this).data("filter") === "guarantees") {
      $(".main__filter-wrap.result-filter.--guarantees").removeClass("hidden")
      if ($("#js__search__results--users__paginate").length) $("#js__search__results--users__paginate").addClass("d-none")
    } else if ($(this).data("filter") === "campaigns") {
      $(".main__filter-wrap.result-filter.--campaigns").removeClass("hidden")
      if ($("#js__search__results--users__paginate").length) $("#js__search__results--users__paginate").addClass("d-none")
    } else {
      $(".main__filter-wrap.result-filter.--collections").removeClass("hidden")
      if ($("#js__search__results--users__paginate").length) $("#js__search__results--users__paginate").addClass("d-none")
    }

    let params = new URLSearchParams(window.location.search)
    let filter = params.has('filter') ? params.get('filter') : ''
    const selected = $(this).data("filter") || filter
    $(`.roue--${selected}`).addClass("active")
  })

  let params = new URLSearchParams(window.location.search)
  let filter = params.has('filter') ? params.get('filter') : ''
  if (filter === 'recyclers') {
    $(`.search__results`).removeClass("active")
    $(`.search__results--${filter}`).addClass("active")
  }

  $('.header__search__input').keyup((e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "ArrowLeft" || e.key === "ArrowRight") return;
    const tab = $('#live_search_current_tab').val() || ''

    $('.live_search__results__loading__wrapper').removeClass('hide');
    $('.live_search__results__container, .header__search--all-results, .live_search__empty__wrapper').hide();
    $('.search__results__wrapper__body__content').hide();

    fetchSearch(e.currentTarget.value, tab)
  })

  const fetchSearch = debounce((query, tab) => {
    ajaxSearch(query, tab)
  }, 500)

  const fetchSearchAll = debounce(query => {
    searchAll(query)
  }, 500)

  const ajaxSearch = (query, tab) => {
    const prefixPath = $("#prefix_path").val()
    let url = prefixPath ? '/nft' : ''
    $.ajax({
      url: `${url}/live-search?query=${encodeURIComponent(query)}&tab=${tab}`,
      method: 'get',
      dataType: 'script',
      success: function() {
        $('.live_search__results__loading__wrapper').addClass('hide');
        $('.live_search__results__container, .header__search--all-results').show();
        $('.search__results__wrapper__body__content').show();
      },
    })
  }

  const searchAll = query => {
    window.location.replace(`/search_all?query=${encodeURIComponent(query)}`);
  }

  $('.explore_search_term').on('input', debounce((e)=>{
    let currentTab = $('.main__filter-element--explore.active').data('filter')
    $(`#list-collections > .search__results--${currentTab} > div:not(.infinite-loading)`).remove()
    $('.preview-loading').removeClass('hide');
    let searchTerm = e.target.value.trim();

    if (currentTab == 'collections' || currentTab == 'nft_collections') {
      let artsMostPopularLink = $('#explore_most_popular').attr('href');
      let artsRecentActivityLink = $('#explore_recent_activity').attr('href');

      let indexQueryParamMostPopular = artsMostPopularLink.indexOf('&query=');
      let indexQueryParamRecentActivity = artsRecentActivityLink.indexOf('&query=');

      if(indexQueryParamMostPopular > 1){
        artsMostPopularLink = artsMostPopularLink.replace(artsMostPopularLink.substr(indexQueryParamMostPopular), '')
        artsRecentActivityLink = artsRecentActivityLink.replace(artsRecentActivityLink.substr(indexQueryParamRecentActivity), '')
      }
      $('#explore_most_popular').attr('href', artsMostPopularLink += `&query=${searchTerm}`);
      $('#explore_recent_activity').attr('href', artsRecentActivityLink += `&query=${searchTerm}`);
    }

    if (currentTab == 'collections') currentTab = 'arts';

    let url = (window.prefixPath == 'nft') ? '/nft' : '';
    url += "/category_filter";

    if (currentTab == 'campaigns') {
      updateSearchParam(searchTerm)
    }
    $.ajax({
      url,
      method: 'get',
      dataType: 'script',
      data: {tab: currentTab, query: searchTerm, sort_by: $('.explore_search_term').data("sort-by")},
      success: function(){
        infiniteScrollingItem()
      }
    });
  }, 500));

  $('#list-collections').on('DOMSubtreeModified', function(){
    infiniteScrollingItem()
  });
  
  function fetchCampaignFilter(searchValue) {
    let url = (window.prefixPath == 'nft') ? '/nft' : '';
    url += "/category_filter";
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'script',
      data: {tab: 'campaigns', query: searchValue.trim()}
    });
  }

  function updateSearchParam(searchValue) {
    $('.btn_filter-left').attr('href', function(index, href) {
      let url = new URL(href, window.location.origin);
      url.searchParams.set('query', searchValue);
      return url.toString();
    });

    $('.btn_filter-right').attr('href', function(index, href) {
      let url = new URL(href, window.location.origin);
      url.searchParams.set('query', searchValue);
      return url.toString();
    });
  }

  let currentTab = $('.main__filter-element--explore.active').data('filter')
  if (currentTab == 'campaigns') {
    $('#explore_search_btn').on('click', debounce((event) => {
      event.stopPropagation()
      $(`#list-collections > .search__results--campaigns > div:not(.infinite-loading)`).remove()
      $('.preview-loading').removeClass('hide');
      let searchValue = $('.explore_search_term').val();
      fetchCampaignFilter(searchValue)
      updateSearchParam(searchValue)
    }, 500))
  
    $('.explore_search_term').keyup(debounce((e) => {
      if (e.key === "Enter" ) {
        $(`#list-collections > .search__results--campaigns > div:not(.infinite-loading)`).remove()
        $('.preview-loading').removeClass('hide');
        let searchValue = $('.explore_search_term').val();
        fetchCampaignFilter(searchValue);
        updateSearchParam(searchValue);
      }
    }, 500))
  }

  // User explore search
  $('#search-form').on('input', debounce((e)=> {
    e.preventDefault();
    $('#user-spinner-loading-gif').removeClass('d-none');
    $('#search-results').hide();

    let searchTerm = e.target.value;
    let url = (window.prefixPath == 'nft') ? '/nft' : '';
    url += "/category_filter";
    let hide_option_checked = $('#hidden_not_recovered_btn').is(":checked");
    let current_filter = $('.btn__filter--explore-users.active').children('a').data('sort-by');
    let usersMostPoplularLink = $('#users_explore_most_popular_btn').attr('href');
    let usersRecentActivityLink = $('#users_explore_recent_activity_btn').attr('href');
    let hideuserWithoutRecoveredLink = $('#hidden_not_recovered_link').attr('href');
    $('#users_explore_most_popular_btn').attr('href', usersMostPoplularLink += `&query=${searchTerm}`);
    $('#users_explore_recent_activity_btn').attr('href', usersRecentActivityLink += `&query=${searchTerm}`);
    $('#hidden_not_recovered_link').attr('href', hideuserWithoutRecoveredLink += `&query=${searchTerm}`);
    $.ajax({
      url,
      method: 'get',
      dataType: 'script',
      data: {tab: currentTab, query: searchTerm, sort_by: current_filter, filter_without_recovered: hide_option_checked},
      success: function() {
        $('#user-spinner-loading-gif').addClass('d-none');
        $('#search-results').show();
      },
    });
  }, 500));

  $('#search-form').on('keydown', function(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      return false;
    }
  });

  if (currentTab == 'nft_collections') {
    $('#list-collections .search_section .btn_filter').on('click', function(e) {
      $('#list-collections .search_section .btn_filter').removeClass('active')
      $(e.currentTarget).addClass('active')
    })
  }

  function redirectToLink() {
    let link = $(this).find('a.owner-avatar');
    if (link.length > 0) {
      window.location.href = link.attr('href');
    }
  }

  function redirectToLinkNftCard(event) {
    let link = $(this).find('#nft_card_path');
    if (!$(event.target).is('a') && link.length > 0) {
      window.location.href = link.val();
    }
  }
  
  $('.search-collection-banner--featured__content').on('click', redirectToLinkNftCard);
  $('.trending-nft-collection-card').on('click', redirectToLink);
  $('.nft-collection-card').on('click', redirectToLinkNftCard);
  $('.explore-users__container').on('click', '.explore-users__card--item-container', redirectToLink);

  $('.search__results__wrapper__header--tab').on('click', function(e) {
    $('.search__results__wrapper__header--tab').removeClass('active')
    $(e.currentTarget).addClass('active')
  });
  $('.collection_card__wrapper--content').on('click', redirectToLinkNftCard);  

  // Enable the buttons when the window is fully loaded
  $('#usersMostRecentBtn, #usersMostPopularBtn').css('pointer-events', 'unset');
  $('#usersMostRecentBtn, #usersMostPopularBtn').prop('disabled', false);
})
