import { symbol } from "prop-types";

$(document).ready(function () {
  $(document).on('click', '.view-notification', function () {
    $.ajax({
      url: "/notifications",
      type: "get",
      dataType: "script",
      data: {}
    })
  })

  $(document).on('click', '.dark-theme-slider', function () {
    lightSelected = $(this).hasClass('lightTheme');
    document.getElementById('themeChange').setAttribute('href', lightSelected ? 'dark' : '');
  });

  $(document).on('click', '.copyUserAddress', function () {
    var copyText = document.getElementById("userAddress");
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");
    toastr.success('Copied successfully.')
  });

  $(document).on("click", ".dashboard-load-more", function (e) {
    $.ajax({
      url: "/category_filter",
      type: "get",
      dataType: "script",
      data: { page_no: $(this).data("page-no"), category: $(this).data("category"), sort_by: $(this).data("sort-by") }
    })
  });

  $(window).scroll(function () {
    if ($(window).scrollTop() == $(document).height() - $(window).height()) {
      $(".dashboard-load-more").click()
    }
  });

  $(".scrollbar-activity").scroll(function () {
    if ($(".scrollbar-activity").scrollTop() > $(".overall-activities").height() - $(".scrollbar-activity").height()) {
      $(".common-activity-load-more").click()
    }

  })

  $(document).on("click", ".user-load-more", function (e) {
    $.ajax({
      url: "/users/load_tabs",
      type: "get",
      dataType: "script",
      data: { id: $(this).data("id"), page_no: $(this).data("page-no"), tab: $(this).data("tab") }
    })
  });

  $(document).on("click", ".common-activity-load-more", function (e) {
    $.ajax({
      url: "/load_more_activities",
      type: "get",
      dataType: "script",
      data: { id: $(this).data("id"), page_no: $(this).data("page-no"), tab: $(this).data("tab") }
    })
  });

  $(document).on("click", ".dashboard-sort-by", function (e) {
    e.preventDefault()
    var dataParam = {}
    if ($(".top-filter li.active a").data("name")) {
      dataParam["category"] = $(".top-filter li.active a").data("name")
    }

    if ($(this).data("name")) {
      dataParam["sort_by"] = $(this).data("name")
    }

    $.ajax({
      url: "/category_filter",
      type: "get",
      dataType: "script",
      data: dataParam
    })
  })

  // add lazy load for image
  $("img.lazyload").lazyload();
});

window.showListSupportersModal = async function showListSupportersModal(id) {
  await $.magnificPopup.open({
    closeOnBgClick: true,
    enableEscapeKey: false,
    items: {
      src: '#list_supporter_modal'
    }
  });

  $('#list_supporters').html('');
  $('#icon-loading-list-supporters').removeClass('hide');
  $.ajax({
    url: '/loading-list-supporters',
    type: 'GET',
    dataType: "script",
    data: {
      achievement_id: id
    }
  })
  .done(function (data) {
    $('#icon-loading-list-supporters').addClass('hide');
  });
}

window.openContactModal = function openContactModal() {
  $.magnificPopup.open({
    fixedContentPos: true,
    overflowY: 'auto',
    type: 'inline',
    preloader: false,
    modal: false,
    removalDelay: 300,
    closeOnBgClick: true,
    enableEscapeKey: false,
    items: {
      src: '#contactModal'
    }
  });
}

window.openWhitePaperModal = function openWhitePaperModal() {
  $.magnificPopup.open({
    fixedContentPos: true,
    overflowY: 'auto',
    type: 'inline',
    preloader: false,
    modal: false,
    removalDelay: 300,
    closeOnBgClick: true,
    enableEscapeKey: false,
    items: {
      src: '#whitePaperModal'
    }
  });
}

window.openAboutUsModal = function openAboutUsModal() {
  $(".about-us").magnificPopup({
  }).magnificPopup('open');
}
