// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

// Support component names relative to this directory:
// var componentRequireContext = require.context("components", true);
// var ReactRailsUJS = require("react_ujs");
var currentController = $('meta[name=psj]').attr('controller');
var currentAction = $('meta[name=psj]').attr('action');
// ReactRailsUJS.useContext(componentRequireContext);

// import "bootstrap"
import "stylesheets/application.scss"

require('packs/jquery-3.5.1.min');
require('packs/utils/jquery.lazyload.js');
require('packs/bootstrap.bundle.min');
require('packs/owl.carousel');
require('packs/isotope.pkgd.min');
require('packs/file_script');
require('packs/formatter.js');
require('packs/ethereum/web3.js');
require('packs/ethereum/connect_steps.js');
require('packs/ethereum/bid_nft.js');
require('packs/ethereum/magic_flow.js');
require('packs/common.js');
require('packs/collections.js');
require('packs/users.js');
require('packs/crop_image.js');
require('packs/jquery.magnific-popup.min');
require('packs/select2.min');
require('packs/smooth-scrollbar.js');
require('packs/jquery.countdown.min');
require('packs/simple-lightbox.jquery.min.js');
require('packs/utils/recovery_project_helper.js')
require('packs/daterangepicker');
require('packs/menu_bar.js');
require('packs/ethereum/helper.js');
require('packs/ethereum/stripe_utils.js');
require('packs/utils/crop_image.js');
require('packs/create_prg.js');
global.html2canvas = require('html2canvas');
global.QRCodeStyling = require('qr-code-styling');

global.toastr = require("toastr")
global.BigNumber = require('bignumber.js');

require('leaflet.markercluster');

if(currentController === 'users') {
  require('packs/recovery_project_profile.js');
  require('packs/recovery_project_profile_sustability_dashboard.js');
  require('packs/company_profile.js');
  require('packs/profile_update.js');
}

if(currentController === 'campaigns') {
  require('packs/campaign.js');
}

require('packs/home');
require('packs/Leaflet.CountrySelect');
require('packs/main');

if(currentController === 'dashboard') {
  require('packs/recovery_projects.js');
}

setTimeout(function () {
  require('packs/chosen.jquery');
  require('packs/script');
  // require('packs/main');
  require('packs/search');
  require('packs/art_collection');
  // require('packs/home');
  require('packs/social-share-button');
  require('packs/stats.js');

  if(['collections', 'nft_collections', 'collectibles'].includes(currentController)) {
    require('packs/merge_nft');

    if(['checkout_prg', 'checkout_art'].includes(currentAction)) {
      require('packs/stripe.js');
    }
  }

}, 500);
