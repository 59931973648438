// BIDDING MODEL STARTS HERE
// Process and Approve section
$(document).on("click", ".triggerBiddingValidation", function (e) {
  clearToastr();
  e.preventDefault()
  var form = $("#biddingForm")[0]
  if ($('#bid_qty').length && !validNum($('#bid_qty').val())) {
    return toastr.error('Please enter valid quantity');
  } else if (!validFloat($('#bid_amt').val())) {
    return toastr.error('Please enter valid price')
  } else if (form.checkValidity()) {
    $("#biddingForm :input").prop("disabled", true);
    var contractAddress = $('#bid_currency :selected').attr('address');
    var decimals = $('#bid_currency :selected').attr('decimals');
    initBidProcess(contractAddress, decimals);
  } else if ($("#bid_qty")[0].validationMessage !== "") {
    return toastr.error($("#bid_qty")[0].validationMessage)
  }
})

// TODO: WHILE ADDING NEW CUREENCIES HAVE TO MAKE LOGIC TO FETCH DECIMALS HERE
window.initBidProcess = function initBidProcess(contractAddress, contractDecimal) {
  var curErc20Balance = $('#erc20_balance').text()
  var ethBalance = $('#eth_balance').text()
  var totalAmt = $("#bid-total-amt-dp").attr('bidAmt')
  var symbol = $('#bid_currency :selected').text();
  console.log(curErc20Balance)
  console.log(totalAmt)
  console.log((isGreaterThanOrEqualTo(curErc20Balance, totalAmt)))
  console.log(symbol)
  if (isGreaterThanOrEqualTo(curErc20Balance, totalAmt)) {
    console.log('ONe')
    $('.convertEth').addClass("hide")
    initApproveBidProcess(contractAddress)
  } else if (symbol === 'WBNB' && isGreaterThanOrEqualTo(ethBalance, totalAmt)) {
    console.log('Two')
    convertBnbToWbnb(totalAmt - curErc20Balance)
  } else {
    console.log('THree')
    $("#biddingForm :input").prop("disabled", false);

    // $("#placeBid").modal("hide")
    $.magnificPopup.close();

    return toastr.error('Not enough balance')
  }
}

window.bidConvertSuccess = function bidConvertSuccess(transactionHash) {
  $('.convertProgress').addClass('hide')
  $('.convertDone').removeClass('hide')
  var contractAddress = $('#bid_currency option:selected, this').attr('address')
  initApproveBidProcess(contractAddress)
}

window.bidConvertFailed = function bidConvertFailed(errorMsg) {
  toastr.error(errorMsg)
  hideAll()
  $('.allStart').removeClass('hide').addClass('grey')
  $('.convertRetry').removeClass('hide')
}

window.initApproveBidProcess = function initApproveBidProcess(contractAddress, decimals = 18) {
  hideAll()
  $('.convertDone').removeClass('hide')
  $('.approvebidProgress').removeClass('hide')
  $('.signbidStart').removeClass('hide')
  $.magnificPopup.close();
  $.magnificPopup.open({
    closeOnBgClick: true,
    enableEscapeKey: false,
    items: {
      src: '#placeBid'
    },
    type: 'inline'
  });

  approveERC20(contractAddress, 'erc20', $("#bid-total-amt-dp").attr('bidAmt'), decimals)
}

window.bidApproveSuccess = function bidApproveSuccess(transactionHash, contractAddress) {
  $('.approvebidProgress').addClass('hide')
  $('.approvebidDone').removeClass('hide')
  var contractAddress = $('#bid_currency option:selected, this').attr('address')
  initSignBidProcess(contractAddress)
}

window.bidApproveFailed = function bidApproveFailed(errorMsg) {
  toastr.error(errorMsg)
  hideAll()
  $('.convertDone').removeClass('hide')
  $('.approvebidRetry').removeClass('hide')
  $('.signbidStart').removeClass('hide')
}

$(document).on("click", ".approvebidRetry", function () {
  var contractAddress = $('#bid_currency option:selected, this').attr('address')
  initApproveBidProcess(contractAddress)
})

window.initSignBidProcess = function initSignBidProcess(contractAddress) {
  hideAll()
  $('.convertDone').removeClass('hide')
  $('.approvebidDone').removeClass('hide')
  $('.signbidProgress').removeClass('hide')
  var details = window.fetchCollectionDetails(null, contractAddress)
  if (details) {
    console.log(details['asset_address'], details['token_id'], $("#bid_qty").val(), $("#bid-total-amt-dp").attr('bidAmt'),
      details['pay_token_address'], details['pay_token_decimal'], details['collection_id'], $("#bid-total-amt-dp").attr('bidPayAmt'))
    bidAsset(details['asset_address'], details['token_id'], $("#bid_qty").val(), $("#bid-total-amt-dp").attr('bidAmt'),
      details['pay_token_address'], details['pay_token_decimal'], details['collection_id'], $("#bid-total-amt-dp").attr('bidPayAmt'))
  } else {
    bidSignFailed('Unable to fetch tokan details. Please try again later')
  }
}

window.bidSignSuccess = function bidSignSuccess(collectionId) {
  toastr.success('Bidding succces.')
  window.location.href = '/collections/' + collectionId
}

window.bidSignFailed = function bidSignFailed(errorMsg) {
  toastr.error(errorMsg)
  hideAll()
  $('.convertDone').removeClass('hide')
  $('.approvebidDone').removeClass('hide')
  $('.signbidRetry').removeClass('hide')
}

$(document).on("click", ".signbidRetry", function () {
  var contractAddress = $('#bid_currency option:selected, this').attr('address')
  initSignBidProcess(contractAddress)
})


// COMMON METHODS FOR BIDDING MODEL
function hideAll() {
  $('.allProgress').addClass('hide')
  $('.allDone').addClass('hide')
  $('.allRetry').addClass('hide')
  $('.allStart').addClass('hide')
}
